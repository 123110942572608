import React from "react";
import {
  HeaderContext,
  NavigationWrapper
} from "components/navigation-wrapper";
import clsx from "clsx";
import { useWindowScrollTop } from "hooks";
import styles from "./standard.module.scss";

type Props = {
  fixedPaneContents?: JSX.Element;
  bottomNavbarContents?: JSX.Element;
  getHeaderFlexItems?: (context: HeaderContext) => JSX.Element | undefined;
  fixedPaneClassName?: string;
  scrollablePaneClassName?: string;
  mobileHeaderWithBorder?: boolean;
};

export const StandardPage: React.FC<Props> = ({
  fixedPaneContents,
  bottomNavbarContents,
  getHeaderFlexItems,
  fixedPaneClassName,
  scrollablePaneClassName,
  mobileHeaderWithBorder,
  children
}) => {
  const scrollTop = useWindowScrollTop(100);

  return (
    <NavigationWrapper
      getHeaderFlexItems={getHeaderFlexItems}
      mobileHeaderWithBorder={mobileHeaderWithBorder}
    >
      <div className={styles["layout"]}>
        {fixedPaneContents && (
          <section
            className={clsx(
              styles["fixed-pane"],
              scrollTop > 0 && styles["fixed-pane--shadowed"],
              fixedPaneClassName
            )}
          >
            {fixedPaneContents}
          </section>
        )}
        <section
          data-testid={"scrollable-pane"}
          className={clsx(styles["scrollable-pane"], scrollablePaneClassName)}
        >
          {children}
        </section>
        {bottomNavbarContents && (
          <section className={styles["bottom-navbar"]}>
            {bottomNavbarContents}
          </section>
        )}
      </div>
    </NavigationWrapper>
  );
};
