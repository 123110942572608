import { getAbsenceReportingService } from "./absence-reporting-service";
import {
  EmployeeData,
  EmployeeHistory,
  EmployeeSchedule,
  emptyHistory,
  emptySchedule
} from "../employee/employee-model";
import { addDays } from "date-fns";
import { getEmployeeService } from "../employee/employee-service";
import { AbsenceAlert } from "./absence-questions";
import {
  AbsenceConfig,
  AbsenceType,
  ApiValidationError,
  IncidentQuestionModel
} from "./incident";
import _, { isEmpty } from "lodash-es";
import {
  CancelResult,
  QuestionAnswer,
  SubmitResult,
  WorkflowSection
} from "./absence-reporting-types";
import { WorkflowBuilder } from "./workflow-builder";
import { getSessionService } from "features/session/session-service";
import {
  maybeUpdateLinkability,
  shouldAskIfCanBeLinked
} from "features/report-absence/absence-reporting-utils";
import { ARWorkflowState } from "./absence-reporting-state";
import { enCA } from "date-fns/locale";

export type WorkflowSnapshot = {
  questions: WorkflowSection[];
  alerts: AbsenceAlert[];
  errors: ApiValidationError[];
};

export class ARWorkflow {
  private static _instance: ARWorkflow;

  // Questions at the end of the workflow for review/submission of incident
  static finalQuestionIds = ["the-end", "placeholder"];

  schedule: EmployeeSchedule = emptySchedule();
  history: EmployeeHistory = emptyHistory();
  state: ARWorkflowState;
  locale: Locale;

  constructor(locale: Locale) {
    this.locale = locale;
    this.state = new ARWorkflowState(locale);
  }

  // async loadCICConfiguration() {
  //   this.ar3CompaniesToWhichUserHaveAcccesCount = await getEmployeeService().getCompaniesCount(
  //     "3"
  //   );
  // }

  async loadConfiguration(
    clientCode: string,
    employeeId: string,
    isCICUser: boolean,
    allowBackground: boolean,
    isNewEmployee?: boolean
  ) {
    if (
      this.state.clientCode === clientCode &&
      this.state.employeeId === employeeId
    ) {
      return;
    }

    try {
      const ar = getAbsenceReportingService();
      const es = getEmployeeService();

      const workState = (await getEmployeeService().getEmployeeData(clientCode, employeeId)).employeeState ?? "";
      const countryCode = (await getEmployeeService().getEmployeeData(clientCode, employeeId)).employeeCountry;
      let state = "";
       
      if(isNewEmployee)
      {
        state= this.state.countryCode + ":" + this.state.provinceCode;
      }
      else if (countryCode === "" || countryCode === null){
        state = "";
      }
      else if(workState === "" || workState === null){
        state = countryCode;
      }
      else {
        state = countryCode + ":" + workState;
      }
      
      const configPromise = ar
        .loadConfig(clientCode, state)
        .then(c => (this.state.config = c));

      const schedulePromise = es
        .getSchedule(
          clientCode,
          employeeId,
          addDays(new Date(), -60),
          addDays(new Date(), 365)
        )
        .then(schedule => {
          this.schedule = schedule;
        })
        .catch(error => {
          this.state.apiErrors = this.state.apiErrors.concat(error);
        });
   
      const historyPromise = es
        .getAbsences(clientCode, employeeId)
        .then(h => (this.history = h));

      const eeDataPromise = isNewEmployee
        ? null
        : this.getEmployeeData(clientCode, employeeId);

      const waitFor: Array<Promise<
        AbsenceConfig | EmployeeHistory | void
      > | null> = [configPromise, historyPromise, eeDataPromise];

      if (isCICUser) {
        const selectedClient = sessionStorage.getItem("client_code");
        const companyNamePromise = getEmployeeService()
          .searchCompany("3", clientCode)
          .then(clientData => {
            this.state.companyName = clientData.items.find(item => item.clientCode === selectedClient)?.name || "";
          });

        waitFor.push(companyNamePromise);
      }

      if (allowBackground) {
        // Don't wait for schedule, since it's not needed until later
        void schedulePromise;
      } else {
        waitFor.push(schedulePromise);
      }

      return Promise.all(waitFor).then(async () => {

        if (this.state.config?.reasonOfAbsence?.answers.length === 0){
          sessionStorage.setItem("containsReasonsOfAbsence", "false");
          }
          else{
            sessionStorage.setItem("containsReasonsOfAbsence", "true");
          }
        this.state.restoreShifts();
        await this.restoreClosingScripts();
        this.state.clientCode = clientCode;
        this.state.employeeId = employeeId;
        this.setDefaultQuestionAnswers();
        this.buildQuestions();
      });
    } catch (error) {
      this.state.apiErrors = this.state.apiErrors.concat(error as Error);
      console.error(error);
    }
  }

  async restoreClosingScripts() {
    if (
      this.state.incident.claimStatus === "S" &&
      this.state.submitResult &&
      this.state.incident.absenceIncidentId
    ) {
      const ar = getAbsenceReportingService();
      const closingScripts = await ar.loadClosingScript(
        this.state.incident.absenceIncidentId
      );
      this.state.restoreClosingScripts(closingScripts);
    }
  }

  async restoreIncident(
    incidentId: number,
    defaultClientCode: string,
    currentUserEmployeeId: string,
    isCICUser: boolean,
    hasEmployees: boolean
  ): Promise<{
    restoredEmployeeId: string | null;
    restoredClientCode: string | null;
  }> {
    try {
      const ar = getAbsenceReportingService();
      const incident = await ar.loadIncident(incidentId);

      if (incident) {
        this.state.restore(
          incident,
          currentUserEmployeeId,
          isCICUser,
          hasEmployees
        );

        const model = await ar.loadConfigQuestions(incidentId);

        this.prepareConfigQuestions(model);
        this.setDefaultQuestionAnswers();
      }

      return {
        restoredClientCode: incident?.clientCode ?? defaultClientCode,
        restoredEmployeeId: incident?.employeeNumber ?? null
      };
    } catch (error) {
      this.state.apiErrors = this.state.apiErrors.concat(error as Error);
      console.error(error);
    }

    return {
      restoredClientCode: null,
      restoredEmployeeId: null
    };
  }

  restartWorkflow() {
    this.state = new ARWorkflowState(this.locale);
    this.schedule = emptySchedule();
    this.history = emptyHistory();
  }

  buildQuestions() {
    new WorkflowBuilder(
      this.state,
      this.schedule,
      this.getPreviousAbsences()
    ).buildQuestions();
  }

  static start(locale?: Locale): ARWorkflow {
    const user = getSessionService().getUser();
    if (!user?.client_code)
      console.error("Client code is not found for the user");

    //TODO: implement handling of incorrect client codes for employee
    ARWorkflow._instance = new ARWorkflow(locale ?? enCA);
    return ARWorkflow._instance;
  }

  static current(locale?: Locale): ARWorkflow {
    if (!ARWorkflow._instance) return ARWorkflow.start(locale);

    return ARWorkflow._instance;
  }

  isReadyForSave = () => {
    return !!this.state.incident.primaryReason;
  };

  private setDefaultQuestionAnswers() {
    this.state.answers.set("call-center-employee-phone-number", {
      answer: this.state.callCenterEmployeePhoneNumber ?? "",
      answered: true
    });

    if (!this.state.answers.get("CQ_BEST_PHONE_NUMBER_TO_BE_REACHED")?.answer) {
      this.state.answers.set("CQ_BEST_PHONE_NUMBER_TO_BE_REACHED", {
        answer: this.state.callCenterEmployeePhoneNumber ?? "",
        answered: true
      });
    }

    if (!this.state.answers.get("CQ_EMAIL_ADDRESS_TO_RECEIVE")?.answer) {
      this.state.answers.set("CQ_EMAIL_ADDRESS_TO_RECEIVE", {
        answer: this.state.employeeEmail ?? "",
        answered: true
      });
    }
  }

  private prepareConfigQuestions = (model: IncidentQuestionModel) => {
    this.state.configQuestions = _.flatMap(
      _.flatMap(
        [...model.preQualifyingQuestions, ...model.questions].map(p =>
          p.sections.map(s => s.questions)
        )
      )
    );

    const questionAlerts = _.flatMap(this.state.configQuestions, q =>
      (q.alerts || [])
        .filter(alert => !this.state.viewedAlertsIds.includes(alert.alertId))
        .map<AbsenceAlert>(alert => ({
          questionId: q.name,
          alertId: alert.alertId,
          alertText: alert.alertText
        }))
    );

    const reasonOfAbsenceAlerts = model.reasonOfAbsenceAlerts
      .filter(alert => !this.state.viewedAlertsIds.includes(alert.alertId))
      .map<AbsenceAlert>(alert => ({
        questionId:
          alert.alertLevel === "Primary"
            ? "primary-reason"
            : "secondary-reason",
        alertId: alert.alertId,
        alertText: alert.alertText
      }));

    this.state.allAlerts = [...reasonOfAbsenceAlerts, ...questionAlerts];

    this.buildQuestions();
  };

  answerQuestion(questionId: string, answer: QuestionAnswer): boolean {
    const question = this.state.questions.find(q => q.id === questionId);
    if (question === undefined) {
      console.error("Cannot find question", questionId);
      return false;
    }

    if (
      question?.absenceReasonInfo &&
      question?.absenceReasonInfo?.subAnswers
    ) {
      const subAnswer = question?.absenceReasonInfo?.subAnswers.find(
        sq => sq.answerId === this.state.incident.secondaryReason
      );

      if (subAnswer && question?.absenceReasonInfo) {
        question.absenceReasonInfo.reportableDaysInFuture =
          subAnswer?.reportableDaysInFuture;
        question.absenceReasonInfo.reportableDaysInPast =
          subAnswer?.reportableDaysInPast;
      }
    }

    const isAnswerDifferent = !_.isEqual(question.answer, answer);
    if (isAnswerDifferent) {
      this.state.needsSave = true;
    }

    question.setAnswer(answer);
    this.buildQuestions();

    return isAnswerDifferent;
  }

  setQuestionResponses = () => {
    this.state.incident.questionResponses = Array.from(
      this.state.answers.entries()
    ).map(a => {
      return {
        key: a[0],
        value: a[1].answer
      };
    });
  };

  save = async (synchronous = true) => {
    if (!this.isReadyForSave()) {
      return;
    }

    this.setQuestionResponses();

    if (synchronous) {
      this.state.isSavingSynchronously = true;

      try {
        const incident = this.state.incident;
        const askIfCanBeLinked = shouldAskIfCanBeLinked(incident);
        incident.employeeNumber = this.state.employeeId;

        const result = await getAbsenceReportingService().saveIncident(
          this.state.clientCode,
          incident,
          askIfCanBeLinked,
          this.state.locale.code
        );

        this.state.errors = [];
        if (!result.success) {
          this.state.errors = result.validationErrors;
          if (!isEmpty(result.validationErrors)) {
            console.error("Validation error", result.validationErrors);
          }
        } else if (result.returnValue) {
          const savedIncident = result.returnValue.absenceIncidentModel;

          this.state.incident = {
            ...savedIncident,
            linkability: maybeUpdateLinkability(
              askIfCanBeLinked,
              savedIncident,
              incident
            )
          };

          if (result.returnValue.absenceQuestionReturnModel) {
            this.prepareConfigQuestions(
              result.returnValue.absenceQuestionReturnModel
            );
          }
        }
      } catch (error) {
        this.state.apiErrors = this.state.apiErrors.concat(error as Error);
      } finally {
        this.state.isSavingSynchronously = false;
      }
    } else {
      void getAbsenceReportingService()
        .saveIncident(
          this.state.clientCode,
          this.state.incident,
          false,
          this.state.locale.code
        )
        .then(_.noop)
        .catch(error => {
          this.state.apiErrors = this.state.apiErrors.concat(error);
        });
      // Because the save may complete at any time, we don't want to overwrite
      // state that may have changed locally
    }
  };

  isReadyForSubmit = () =>
    this.isReadyForSave() && this.state.incident.absenceDates.length > 0;

  submit: () => Promise<SubmitResult> = async () => {
    if (!this.isReadyForSubmit()) {
      console.error("Submit failed (not ready for submit)");
      return { success: false };
    }

    if (!this.state.incident.absenceIncidentId) {
      console.error("Submit failed (incident has not been saved)");
      return { success: false };
    }

    this.setQuestionResponses();

    try {
      const result = await getAbsenceReportingService().submitIncident(
        this.state.incident,
        this.state.locale.code
      );
      this.state.errors = result.validationErrors;
      if (!isEmpty(result.validationErrors)) {
        console.error("Validation error", result.validationErrors);
      }
      this.state.submitResult = {
        absenceIncidentId: this.state.incident.absenceIncidentId,
        success: result.success,
        closingScripts: result.success
          ? result.returnValue?.closingScripts.map(cs => cs.text)
          : [],
        validationErrors: result.validationErrors
      };
      this.state.submitted = result.success;

      this.buildQuestions();

      return this.state.submitResult;
    } catch (error) {
      this.state.submitResult = {
        absenceIncidentId: this.state.incident.absenceIncidentId,
        success: false
      };
      this.state.apiErrors = this.state.apiErrors.concat(error as Error);
      return this.state.submitResult;
    }
  };

  getNextUnansweredQuestion = (currentSection: number): number | undefined => {
    return this.state.getNextUnansweredQuestion(currentSection);
  };

  waitForSave = (sectionIndex: number) => {
    const section = this.state.sections[sectionIndex];

    // Default behavior is to wait for a save to complete.
    // Config questions have a flag that may allow saving in the background.
    return section.config === undefined
      ? true
      : section.config.reloadIncidentData !== false;
  };

  saveAndCheckForErrorsOrAlerts = async (currentQuestionId: string) => {
    this.state.needsSave = false;

    await this.save(true);
    this.state.buildQuestionAlerts(currentQuestionId);

    const hasNewAlerts = this.checkForAlerts();

    const success = this.state.errors.length === 0;
    if (!success) {
      this.state.needsSave = true;
    }

    return success && !hasNewAlerts;
  };

  checkForAlerts = () =>
    this.state.questionAlerts.some(
      a => !this.state.viewedAlertsIds.includes(a.alertId)
    );

  saveInBackground = () => {
    this.state.needsSave = false;
    void this.save(false);
  };

  getPreviousAbsences = () => {
    return this.history.previousAbsences.filter(
      a => a.absenceIncidentId !== this.state.incident.absenceIncidentId
    );
  };

  getAbsenceType = (): AbsenceType | undefined => {
    return this.state.incident.absenceType;
  };

  cancelIncident: () => Promise<CancelResult> = async () => {
    if (!this.state.incident.absenceIncidentId) {
      return { success: true };
    }

    try {
      const result = await getAbsenceReportingService().cancelIncidents(
        {},
        [this.state.incident.absenceIncidentId],
        this.state.locale.code
      );
      return result;
    } catch (error) {
      return { success: false };
    }
  };

  public async getLatestInProgressAbsenceForEmployee(
    employeeId?: string,
    clientCode?: string,
    isNewEmployee?: boolean
  ): Promise<number | null> {
    const latestInProgressIncident = isNewEmployee
      ? null
      : await getAbsenceReportingService().getLatestInProgressAbsence(
          employeeId,
          clientCode
        );

    return latestInProgressIncident?.absenceIncidentId ?? null;
  }

  private getEmployeeData = async (clientCode: string, employeeId: string) => {
    if (employeeId) {
      await getEmployeeService()
        .getEmployeeData(clientCode, employeeId)
        .then((employeeData: EmployeeData) => {
          this.setEmployeeData(employeeData);
        })
        .catch(rej => console.log(rej));
    } else {
      this.setEmployeeData(null);
    }
  };

  private setEmployeeData = (employeeData: EmployeeData | null) => {
    if (employeeData) {
      this.state.employeeFirstName = employeeData.firstName;
      this.state.employeeLastName = employeeData.lastName;
      this.state.callCenterEmployeePhoneNumber = employeeData?.phoneNumber;
      this.state.employeeEmail = employeeData?.email;
    } else {
      this.state.employeeFirstName = null;
      this.state.employeeLastName = null;
      this.state.callCenterEmployeePhoneNumber = null;
      this.state.employeeEmail = null;
    }
  };
}
