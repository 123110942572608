import React from "react";
import styles from "./user-widget.module.scss";
import clsx from "clsx";
import { getSessionService } from "features/session/session-service";
import { ProfileIcon } from "components/icons";
import {LanguageSelectorButton} from "components/buttons/languageSelector/language-button";


type Props = {
  theme: "light" | "dark";
};

const UserWidget: React.FunctionComponent<Props> = ({ theme }) => {
  const sessionService = getSessionService();
  const user = sessionService.getUser();

  function titleCaseName(name: string): string {
    return name.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  }

  return (
    <div
      className={clsx(
        styles["user-widget"],
        theme === "light"
          ? styles["user-widget--light"]
          : styles["user-widget--dark"]
      )}
    >
      <div className={styles["user-lang"]}>
        <LanguageSelectorButton/>
      </div>
      <div
        className={clsx(
          styles["user-image"],
          theme === "light"
            ? styles["user-image-bg-dark"]
            : styles["user-image-bg-light"]
        )}
      >
        <ProfileIcon
          className={clsx(
            styles["icon"],
            theme === "light" ? styles["icon-light"] : styles["icon-dark"]
          )}
        />
      </div>
      <div>
        {user?.name && (
          <div className={styles["user-name"]}>{titleCaseName(user.name)}</div>
        )}
        {user?.role && (
          <div className={styles["user-role"]}>{titleCaseName(user.role)}</div>
        )}
      </div>
    </div>
  );
};

export default UserWidget;
