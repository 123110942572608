import {
  AbsenceClaimData,
  ClaimSummary,
  ClaimType,
  FmlClaimData,
  LeavePolicy,
  StdCaseData
} from "features/claims/claims-model";
import { MyAbilitiArClaim } from "graphql-types.gen";
import { ApiClaimsService } from "features/claims/claims-service-api";
import { ClaimTypeFilter } from "pages/claims/helpers";

export const getClaimsService = (): IClaimsService => {
  // ClaimsService is mocked using an Apollo client link
  return new ApiClaimsService();
};

export interface ListClaimsOptions {
  claimType?: ClaimType;
  page: number;
  pageSize: number;
}

export interface ClaimsListResult {
  claims: ClaimSummary[];
  total: number;
}

export interface IClaimsService {
  listActiveClaims(
    employeeNumber: string,
    policyCodes?: string[]
  ): Promise<ClaimsListResult>;
  getIncompleteClaims(
    employeeNumber: string,
    clientCode: string
  ): Promise<ClaimSummary[]>;
  listHistoricalClaims(
    options: ListClaimsOptions,
    employeeNumber: string
  ): Promise<ClaimsListResult>;
  loadFmlClaim(caseId: number): Promise<FmlClaimData>;
  loadStdClaim(caseId: number): Promise<StdCaseData>;
  loadClaimTypeFilters(clientCode: string): Promise<ClaimTypeFilter[]>;
  getClaimsByIds(
    employeeNumber: string,
    clientCode: string,
    claimNumbers: string[]
  ): Promise<ClaimsListResult>;
  loadAbsenceDetails(
    id: number
  ): Promise<
    AbsenceClaimData & { childIncidents: MyAbilitiArClaim["childIncidents"] }
  >;
  getLeavePolicySummary(): Promise<LeavePolicy[]>;
  listClaims(
    employeeNumber: string,
    policyCodes?: string[],
    claimNumbers?: string[]
  ): Promise<ClaimsListResult>;
}
