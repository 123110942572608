import { getLocale, getParentLocaleCode, SupportedLanguage } from "./locales";
import { substitute } from "i18n/core";
import { createShortcut } from "utils/util";

export type TranslationTable = Map<string, string>;

export type TranslationStruct = {
  text: string;
  vars?: Record<string, string>;
};

export class TranslationSet {
  table: TranslationTable;
  locale: Locale;
  langCode: SupportedLanguage;
  parentLangCode: string;

  constructor(langCode: SupportedLanguage, table: TranslationTable) {
    this.langCode = langCode;
    this.locale = getLocale(langCode);
    this.parentLangCode = getParentLocaleCode(this.locale.code ?? "en");
    this.table = table;
  }

  translate = (src: string | TranslationStruct) => {
    if (typeof src === "string") {
      return this.table.get(src);
    }
    if (!src.text) return null;
    const template = this.table.get(src.text);
    return template
      ? substitute(template, src.vars ?? {})
      : this.langCode === SupportedLanguage.EnglishCA
      ? src.text
      : `${this.langCode}: ${src.text}`;
  };

  has = (src: string) => this.table.has(src);

  tryTranslate = (text: string, prefix: string) => {
    const handle = prefix + "." + createShortcut(text);
    return this.has(handle) ? this.translate(handle) : text;
  };
}
