import React, { Suspense } from "react";
import { UserLanguageProvider } from "context/user-language-context";
import { TranslationProvider } from "context/translation-context";
import { AppRoutes } from "app.routes";
import Modal from "react-modal";
import IdleContainer from "components/idle-container/idle-container";
import { LogoutModalProvider } from "context/logout-modal-context";
import LogoutContainer from "components/logout-modal/logout-container";
import { LoadingSpinner } from "components/loading-spinner";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./app-insights/appInsights";

// Include the standard styles for the app
require("styles/main.scss");

Modal.setAppElement("#root");

export const App: React.FunctionComponent = () => {
  console.info(`version info: ${process.env.REACT_APP_VERSION_INFO}`);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <UserLanguageProvider>
          <TranslationProvider>
            <LogoutModalProvider>
              <AppRoutes />
              <IdleContainer />
              <LogoutContainer />
            </LogoutModalProvider>
          </TranslationProvider>
        </UserLanguageProvider>
      </AppInsightsContext.Provider>
    </Suspense>
  );
};
