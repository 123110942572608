import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import * as Routes from "routes";
import {
  useDesktopBreakpoint,
  useOnClickOutside,
  useReportAbsenceLink
} from "hooks";
import { ContainedButton } from "components/buttons";
import {
  DocumentIcon,
  ExternalLinkIcon,
  HomeIcon,
  LogoutIcon,
  QueryOutlineIcon
} from "components/icons";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Translation } from "components/translation";
import { useLogoutModalContext } from "context/logout-modal-context";
import UserWidget from "components/user-widget/user-widget";
import CloseButton from "components/buttons/close-button/close-button";
import { openFreshdesk } from "components/freshdesk";
import { ClientLogo } from "components/clientLogo";
import { useFeatures } from "hooks/features";
import { getAbsenceReportingService } from "features/report-absence/absence-reporting-service";
import { useWebConfigurationContext } from "context/web-configuration-context";
import { useLanguageContext } from "context/user-language-context";
import { SupportedLanguage } from "features/translations/locales";

export type HeaderContext = {
  isDesktop: boolean;
  toggleMenu: MenuToggleFunction;
};
export type MenuToggleFunction = (
  menuAction?: "open" | "close" | "toggle"
) => void;

type NavigationWrapperProps = {
  getHeaderFlexItems?: (context: HeaderContext) => JSX.Element | undefined;
  mobileHeaderWithBorder?: boolean;
};
/**
 * A component that can be used to wrap content with a navigation layout,
 * including a header with user menu and sidebar/hamburger-menu navigation links.
 */
export const NavigationWrapper: React.FunctionComponent<NavigationWrapperProps> = ({
  getHeaderFlexItems,
  mobileHeaderWithBorder,
  children
}) => {
  const isDesktop = useDesktopBreakpoint();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = useCallback<MenuToggleFunction>(
    (menuAction = "toggle") => {
      switch (menuAction) {
        case "toggle":
          setMenuOpen(wasOpen => !wasOpen);
          break;
        case "open":
          setMenuOpen(true);
          break;
        case "close":
          setMenuOpen(false);
          break;
      }
    },
    []
  );

  const header = useMemo(() => {
    return getHeaderFlexItems?.({ isDesktop, toggleMenu });
  }, [getHeaderFlexItems, isDesktop, toggleMenu]);

  if (isDesktop) {
    return (
      <DesktopWrapper desktopHeaderFlexItems={header}>
        {children}
      </DesktopWrapper>
    );
  } else {
    return (
      <MobileWrapper
        mobileHeaderFlexItems={header}
        menuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        withBorder={mobileHeaderWithBorder}
      >
        {children}
      </MobileWrapper>
    );
  }
};

type MobileWrapperProps = {
  mobileHeaderFlexItems?: JSX.Element;
  menuOpen: boolean;
  toggleMenu: MenuToggleFunction;
  withBorder?: boolean;
};
const MobileWrapper: React.FunctionComponent<MobileWrapperProps> = props => {
  return (
    <div className={styles["layout"]}>
      <MobileMenu
        isOpen={props.menuOpen}
        closeMenu={() => props.toggleMenu("close")}
      />
      <div className={styles["content"]}>
        <header
          className={
            props.withBorder
              ? styles["top-navbar-with-border"]
              : styles["top-navbar"]
          }
        >
          {props.mobileHeaderFlexItems}
        </header>
        <main className={styles["main"]}>{props.children}</main>
      </div>
    </div>
  );
};

const MobileMenu: React.FunctionComponent<{
  isOpen: boolean;
  closeMenu: () => void;
}> = props => {
  const { isOpen, closeMenu } = props;
  const menuStyles = clsx(styles["menu"], isOpen && styles["menu-open"]);
  const coverStyles = clsx(styles["cover"], isOpen && styles["cover-visible"]);
  const menuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuRef, () => closeMenu());

  return (
    <>
      <div className={coverStyles} aria-hidden="true" />
      <div className={menuStyles} ref={menuRef} aria-hidden={!isOpen}>
        {isOpen && (
          <>
            <CloseButton
              theme={"dark"}
              className={styles["close-button"]}
              onClick={() => closeMenu()}
            />

            <UserWidget theme={"dark"} />

            <NavigationWidget />
          </>
        )}
      </div>
    </>
  );
};

type DesktopWrapperProps = {
  desktopHeaderFlexItems?: JSX.Element;
};

const DesktopWrapper: React.FunctionComponent<DesktopWrapperProps> = props => {
  // The "flex-wrapper" div is a workaround to get IE11 to make the items
  // inside the container with a min-height of 100vh be the proper height
  // see: https://github.com/philipwalton/flexbugs#flexbug-3
  return (
    <div className={styles["flex-wrapper"]}>
      <div className={styles["layout"]}>
        <DesktopMenu />
        <div className={styles["content"]}>
          <header className={styles["top-navbar"]}>
            <>
              {props.desktopHeaderFlexItems}
              <UserWidget theme={"light"} />
            </>
          </header>
          <main className={styles["main"]}>{props.children}</main>
        </div>
      </div>
    </div>
  );
};

const DesktopMenu: React.FunctionComponent = () => {
  const { canReportAbsence, goToReportAbsence } = useReportAbsenceLink();
  const webConfigurationContext = useWebConfigurationContext();
  const clientCode = sessionStorage.getItem("client_code");
  const { currentLang } = useLanguageContext();

  const [absenceConfiguration, setAbsenceConfiguration] = useState<
    { locale: string; description: string }[]
  >([]);
  const [reportAbsenceText, setReportAbsenceText] = useState<string>("");


  let placeHolder = "";
  if (currentLang === SupportedLanguage.EnglishCA || currentLang === SupportedLanguage.EnglishUS) {
    placeHolder = "EN";
  } else if (currentLang === SupportedLanguage.FrenchCA) {
    placeHolder = "FR";
  } else if (currentLang === SupportedLanguage.Spanish) {
    placeHolder = "ES";
  }

  useEffect(() => {
    if (clientCode != null) {
      getAbsenceReportingService()
        .loadConfig(clientCode, "")
        .then((ar) => {
          if (ar.aR3ReportAbsenceButtonTextConfiguration != null) {
            const flattenedConfiguration = ar.aR3ReportAbsenceButtonTextConfiguration.reduce(
              (acc, val) => acc.concat(val),
              [] as { locale: string; description: string }[]
            );

            setAbsenceConfiguration([...flattenedConfiguration]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [clientCode]);

  useEffect(() => {
    const reportAbsenceDescription = absenceConfiguration.find((x) => x.locale === placeHolder)?.description;
    if (reportAbsenceDescription) {
      setReportAbsenceText(reportAbsenceDescription);
    } else {
      setReportAbsenceText(""); // Reset if no match
    }
  }, [placeHolder, absenceConfiguration]);

  const buttonText =
    reportAbsenceText !== "" ? reportAbsenceText : <Translation tag="dashboard.report_absence_button" />;

  return (
    <>
      <nav className={styles["menu"]}>
        <div className={styles["logo"]}>
          <ClientLogo logoStyle="light" clickable />
        </div>

        {canReportAbsence && (
          <ContainedButton
            testId={"report-absence-button"}
            className={styles["primary-action-button"]}
            onClick={() => goToReportAbsence()}
          >
            {buttonText}
          </ContainedButton>
        )}

        <NavigationWidget />
      </nav>
    </>
  );
};

export const LogoutButton: React.FunctionComponent = () => {
  const { setIsOpen } = useLogoutModalContext();

  return (
    <button
      className={styles["button-link"]}
      onClick={() => setIsOpen(true)}
      data-testid={"logout"}
    >
      <LogoutIcon className={styles["nav-icon"]} />
      <Translation tag="nav.log_out" />
    </button>
  );
};

export const NeedHelpButton: React.FC = () => {
  return (
    <button
      className={styles["button-link"]}
      onClick={openFreshdesk}
      data-testid={"help"}
    >
      <QueryOutlineIcon className={styles["nav-icon"]} />
      <Translation tag="nav.need_help" />
    </button>
  );
};

const NavigationWidget: React.FunctionComponent = () => {
  const { connectLink } = useFeatures();

  return (
    <nav id="navigation-menu">
      <ul className={styles["nav-links"]}>
        <li>
          <NavLink
            to={Routes.Dashboard.generate({})}
            activeClassName={styles["current-route"]}
          >
            <HomeIcon className={styles["nav-icon"]} />
            <Translation tag="nav.dashboard" />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={Routes.ClaimsList.generate({})}
            activeClassName={styles["current-route"]}
          >
            <DocumentIcon className={styles["nav-icon"]} />
            <Translation tag="nav.all_cases" />
          </NavLink>
        </li>
        <li>
          <NeedHelpButton />
        </li>
        {connectLink && (
          <li>
            <a href={connectLink}>
              <ExternalLinkIcon className={styles["nav-icon"]} />
              <Translation tag="nav.goto_connect" />
            </a>
          </li>
        )}
        <li>
          <LogoutButton />
        </li>
      </ul>
    </nav>
  );
};
