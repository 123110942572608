/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "./auth-provider";
import { getSessionService } from "features/session/session-service";
import { Translation } from "components/translation";
import { LoadingSpinner } from "components/loading-spinner";
import { ApplicationRestrictionView } from "pages/application-access/application-restriction";

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
  checkAccess?: boolean;
}

export const AuthenticatedRoute = ({
  component,
  checkAccess = true,
  ...rest
}: IProps) => {
  const sessionService = getSessionService();
  const renderFn = (Component: any) => (props: any) => (
    <AuthConsumer>
      {({ authContext }) => {
        if (!!Component && sessionService.isAuthenticated()) {
          if (checkAccess) {
            return sessionService.hasAccess() ? (
              <Component {...props} />
            ) : (
              <ApplicationRestrictionView />
            );
          } else {
            return <Component {...props} />;
          }
        } else {
          authContext &&
            authContext.signinRedirect &&
            authContext.signinRedirect();
          return (
            <>
              <div>
                <header className="app-header">
                  <div className="loading">
                    <h3>
                      <Translation tag="login.redirectToAuthServer" />
                    </h3>
                    <LoadingSpinner />
                  </div>
                </header>
              </div>
            </>
          );
        }
      }}
    </AuthConsumer>
  );

  return <Route {...rest} render={renderFn(component)} />;
};
