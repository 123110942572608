import {
  AbsenceConfig,
  AbsenceIncident,
  AbsenceReasonAnswerInfo,
  AbsenceShift,
  ApiValidationError,
  IncidentClosingScript,
  WorkShift,
  WorkShiftForDate
} from "./incident";
import {
  AbsenceAlert,
  ConfigAbsenceQuestion,
  SubmitErrorMsg
} from "./absence-questions";
import {
  areTimesEqual,
  makeTime,
  parseTime,
  Time,
  TimeInterval,
  toDateInterval,
  toTimeInterval
} from "shared-types/time";
import {
  addDays,
  format,
  isAfter,
  isSameDay,
  isSameHour,
  parseISO,
  setHours,
  setMinutes
} from "date-fns";
import {
  OnBehalfOf,
  SubmitResult,
  WorkflowAnswer,
  WorkflowSection
} from "./absence-reporting-types";
import { getDefaultShift, getWorkShiftOptions } from "utils/shift-utils";
import { determineIsPartialDate } from "./absence-reporting-utils";

export class ARWorkflowState {
  incident: AbsenceIncident = {
    primaryReason: "",
    secondaryReason: undefined,
    absenceDates: [],
    absenceIncidentId: undefined,
    bestPhoneNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    requestDate: undefined,
    returnToWorkDate: undefined,
    questionResponses: [],
    linkability: { canBeLinked: undefined }
  };

  needsSave = false;
  isSavingSynchronously = false;
  showSameShiftForDates: boolean | undefined;
  config?: AbsenceConfig | null = null;
  answers: Map<string, WorkflowAnswer> = new Map();
  configQuestions: ConfigAbsenceQuestion[] = [];
  questions: WorkflowSection[] = [];
  sections: WorkflowSection[] = [];
  allAlerts: AbsenceAlert[] = [];
  questionAlerts: AbsenceAlert[] = [];
  errors: ApiValidationError[] = [];
  apiErrors: Error[] = [];
  timeIntervals: TimeInterval[] = [];
  restoredTimeIntervals: TimeInterval[] = [];
  shifts: WorkShiftForDate[] = [];
  tempShiftOptions: WorkShift[] = [];

  submitResult?: SubmitResult;
  absenceOnBehalf?: string;
  employeeSearch?: string;
  employeeId?: string;
  employeeFirstName?: string | null;
  employeeLastName?: string | null;
  employeeEmail?: string | null;
  companyName?: string;
  clientCode?: string;
  callCenterEmployeePhoneNumber?: string | null;
  errorMsg: SubmitErrorMsg | null = null;
  //ar3CompaniesToWhichUserHaveAcccesCount?: number;
  restoredIncidentId?: number | null;
  viewedAlertsIds: string[] = [];
  submitted = false;
  locale: Locale;
  addNewEmployeeErrors?: string[];
  addNewEmployeeState?: boolean;
  provinceCode?: string;
  countryCode?: string;

  constructor(locale: Locale) {
    this.locale = locale;
  }

  getNextUnansweredQuestion(index = 0) {
    const qi =
      index >= this.questions.length
        ? undefined
        : this.questions.slice(index).findIndex(q => q.requiresAnswer);
    return qi;
  }

  restore(
    incident: AbsenceIncident,
    currentUserEmployeeId: string,
    isCICUser: boolean,
    hasEmployees: boolean
  ) {
    this.incident = incident;
    this.submitted = false;
    this.submitResult = undefined;
    this.errors = [];
    this.restoredIncidentId = incident.absenceIncidentId;

    if (!isCICUser && hasEmployees) {
      this.absenceOnBehalf =
        incident.employeeNumber !== currentUserEmployeeId
          ? OnBehalfOf.OtherEmployee
          : OnBehalfOf.Myself;
    }

    this.restoreTimes();
    this.restoreShifts();
    this.restoreQuestions();
    this.restoreEmployee();

    if (this.incident.claimStatus === "S") {
      this.submitResult = {
        success: true,
        absenceIncidentId: this.incident.absenceIncidentId
      };
    }
  }

  private restoreQuestions = () => {
    this.answers.clear();
    this.incident.questionResponses?.forEach(r => {
      this.answers.set(r.key, { answer: r.value, answered: true });
    });
  };

  private restoreTimes = () => {
    this.timeIntervals = this.incident.absenceDates.map(d =>
      toTimeInterval(d.shiftStartTime, d.shiftEndTime)
    );
    this.restoredTimeIntervals = JSON.parse(JSON.stringify(this.timeIntervals));
  };

  public restoreShifts = () => {
    const shiftOptions = getWorkShiftOptions(this.config);

    this.shifts = this.incident.absenceDates.map((d, idx) => {
      const startTime = parseTime(
        d.scheduledShiftStartTime?.toLocaleTimeString("en-US") ?? "",
        makeTime(9, 0, "am")
      );

      const endTime = parseTime(
        d.scheduledShiftEndTime?.toLocaleTimeString("en-US") ?? "",
        makeTime(5, 0, "pm")
      );

      const shift = {
        startTime,
        endTime,
        startTimeHours: ARWorkflowState.determineHoursFromTime(startTime),
        endTimeHours: ARWorkflowState.determineHoursFromTime(endTime),
        isDefault: false,
        order: idx
      };

      return {
        date: d.shiftStartTime.toISOString(),
        shift
      };
    });

    if (shiftOptions) {
      const defaultWorkShifts: WorkShiftForDate[] = shiftOptions
        .filter(shiftOption => {
          const shiftAlreadyExists = this.shifts.find(
            s =>
              areTimesEqual(s.shift.startTime, shiftOption.startTime) &&
              areTimesEqual(s.shift.endTime, shiftOption.endTime)
          );

          if (shiftAlreadyExists) {
            return false;
          }

          return true;
        })
        .map(shiftOption => ({
          date: "",
          shift: {
            ...shiftOption,
            startTimeHours: ARWorkflowState.determineHoursFromTime(
              shiftOption.startTime
            ),
            endTimeHours: ARWorkflowState.determineHoursFromTime(
              shiftOption.endTime
            )
          }
        }));

      this.shifts = [...defaultWorkShifts, ...this.shifts];
    }
  };

  public restoreClosingScripts = (closingScripts: IncidentClosingScript[]) => {
    if (this.incident.claimStatus === "S" && this.submitResult) {
      this.submitResult.closingScripts = closingScripts.map(cs => cs.text);
    }
  };

  private restoreEmployee = () => {
    if (
      this.incident.questionResponses?.some(
        question => question.key === "call-center-employee-phone-number"
      )
    ) {
      this.callCenterEmployeePhoneNumber = this.incident.questionResponses?.filter(
        question => question.key === "call-center-employee-phone-number"
      )[0].value;
    } else if (
      this.incident.questionResponses?.some(
        question => question.key === "CQ_BEST_PHONE_NUMBER_TO_BE_REACHED"
      )
    ) {
      this.callCenterEmployeePhoneNumber = this.incident.questionResponses?.filter(
        question => question.key === "CQ_BEST_PHONE_NUMBER_TO_BE_REACHED"
      )[0].value;
    }
  };

  static mapDates = (dates: Date[]): AbsenceShift[] => {
    const sortedDates = [...dates].sort(
      (d1, d2) => d1.getTime() - d2.getTime()
    );
    return sortedDates.map(d => {
      return {
        isPartialAbsence: false,
        shiftStartTime: d,
        shiftEndTime: d,
        scheduledShiftStartTime: d,
        scheduledShiftEndTime: d
      };
    });
  };

  static applyTimes = (
    dates: AbsenceShift[],
    intervals: TimeInterval[]
  ): TimeInterval[] => {
    const timeIntervals: TimeInterval[] = [];

    dates.forEach(d => {
      const interval = intervals.find(i =>
        isSameDay(parseISO(i.date), d.shiftStartTime)
      ) || {
        date: format(d.shiftStartTime, "yyyy-MM-dd"),
        startTime: makeTime(8, 0, "am"),
        endTime: makeTime(4, 0, "pm")
      };
      const dates = toDateInterval(interval);
      d.shiftStartTime = dates.startDate;
      d.shiftEndTime = dates.endDate;
      d.isPartialAbsence = determineIsPartialDate(d);

      timeIntervals.push(interval);
    });

    return timeIntervals;
  };

  static applyShifts = (
    dates: AbsenceShift[],
    shifts: WorkShiftForDate[],
    intervals: TimeInterval[],
    overwriteTimeIntervals?: boolean,
    shiftOptions?: WorkShift[]
  ): WorkShiftForDate[] => {
    const defaultShift = getDefaultShift(shiftOptions);
    let tempShifts: WorkShiftForDate[] = [];

    dates.forEach(d => {
      const workShift: WorkShift | undefined =
        shifts.find(s => isSameDay(parseISO(s.date), d.shiftStartTime))
          ?.shift ?? defaultShift;

      if (workShift) {
        const startDate = setHours(
          setMinutes(d.shiftStartTime, workShift.startTime.minute),
          ARWorkflowState.determineHoursFromTime(workShift.startTime)
        );

        // 2020-11-19 (AZ): Using the 'shiftStartTime' for the 'endDate' to ensure
        // we're not treating the shift as an overnight shift if the user had specified
        // absence times that were for an overnight period. If the shift is truly an
        // overnight shift it will be handled in this function.
        const endDate = setHours(
          setMinutes(d.shiftStartTime, workShift.endTime.minute),
          ARWorkflowState.determineHoursFromTime(workShift.endTime)
        );

        const interval = intervals.find(i =>
          isSameDay(parseISO(i.date), d.shiftStartTime)
        );

        if (interval && overwriteTimeIntervals) {
          interval.startTime = workShift.startTime;
          interval.endTime = workShift.endTime;
        }

        d.scheduledShiftStartTime = startDate;
        d.scheduledShiftEndTime = isAfter(startDate, endDate) || isSameHour(startDate, endDate)
          ? addDays(endDate, 1)
          : endDate;

        tempShifts.push({
          date: d.shiftStartTime.toISOString(),
          shift: workShift
        });
      }
    });

    if (shiftOptions) {
      const defaultWorkShifts: WorkShiftForDate[] = shiftOptions
        .filter(shiftOption => {
          const shiftAlreadyExists = tempShifts.find(
            ts =>
              areTimesEqual(ts.shift.startTime, shiftOption.startTime) &&
              areTimesEqual(ts.shift.endTime, shiftOption.endTime)
          );

          if (shiftAlreadyExists) {
            return false;
          }

          return true;
        })
        .map(shiftOption => ({
          date: "",
          shift: {
            ...shiftOption,
            startTimeHours: ARWorkflowState.determineHoursFromTime(
              shiftOption.startTime
            ),
            endTimeHours: ARWorkflowState.determineHoursFromTime(
              shiftOption.endTime
            )
          }
        }));

      tempShifts = [...defaultWorkShifts, ...tempShifts];
    }

    return tempShifts;
  };

  private static determineHoursFromTime(time: Time): number {
    if (time.period === "pm" && time.hour !== 12) {
      return time.hour + 12;
    } else if (time.period === "am" && time.hour === 12) {
      return 0;
    } else {
      return time.hour;
    }
  }

  setShowSameShiftForDates = (val: boolean) => {
    this.showSameShiftForDates = val;
  };

  setShifts = (
    shifts: WorkShiftForDate[],
    overwriteTimeIntervals?: boolean
  ): void => {
    this.shifts = ARWorkflowState.applyShifts(
      this.incident.absenceDates,
      shifts,
      this.timeIntervals,
      overwriteTimeIntervals,
      getWorkShiftOptions(this.config)
    );
    this.setTimes(this.timeIntervals);
  };

  setDates = (dates: Date[]): void => {
    this.incident.absenceDates = ARWorkflowState.mapDates(dates);
    this.setTimes(this.timeIntervals);
    this.setShifts(this.shifts);

    this.incident.absenceDates.forEach(ad => {
      const workShift: WorkShift | undefined =
        this.shifts.find(s => isSameDay(parseISO(s.date), ad.shiftStartTime))
          ?.shift ?? getDefaultShift(getWorkShiftOptions(this.config));
      const interval = this.timeIntervals.find(i =>
        isSameDay(parseISO(i.date), ad.shiftStartTime)
      );
      const restoredInterval = this.restoredTimeIntervals.find(i =>
        isSameDay(parseISO(i.date), ad.shiftStartTime)
      );

      if (interval && !restoredInterval && workShift) {
        interval.startTime = workShift.startTime;
        interval.endTime = workShift.endTime;
      }
    });
  };

  setTimes = (intervals: TimeInterval[]): void => {
    this.timeIntervals = ARWorkflowState.applyTimes(
      this.incident.absenceDates,
      intervals
    );
  };

  getTimes = (): TimeInterval[] => this.timeIntervals;

  getShifts = (): WorkShiftForDate[] => this.shifts;

  getReasonOfAbsenceInfo = (): AbsenceReasonAnswerInfo | undefined => {
    return this.config?.reasonOfAbsence?.answers.find(
      a => a.answerId === this.incident.primaryReason
    );
  };

  setSubmitError = (questionId: string, msg: string) => {
    this.errorMsg = {
      questionId,
      msg
    };
  };

  getSubmitErrorMsgForQuestion = (questionId: string): string | undefined => {
    if (this.errorMsg?.questionId === questionId) {
      return this.errorMsg.msg;
    }

    return undefined;
  };

  clearSubmitError = (questionId?: string): void => {
    if (questionId) {
      if (questionId === this.errorMsg?.questionId) {
        this.errorMsg = null;
      }
    } else {
      this.errorMsg = null;
    }
  };

  insertViewedAlertId = (alertId: string): void => {
    this.viewedAlertsIds = [...this.viewedAlertsIds, alertId];
  };

  resetViewedAlerts = () => {
    this.viewedAlertsIds = [];
  };

  buildQuestionAlerts = (currentQuestionId: string) => {
    this.questionAlerts = this.allAlerts.filter(
      alert => alert.questionId === currentQuestionId
    );
  };
}
