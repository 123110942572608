import React, { useState } from "react";
import { DropdownSelect } from "components/dropdown-select";
import { useLanguageContext } from "context/user-language-context";
import { SupportedLanguage } from "features/translations/locales";
import { useFormat } from "hooks/format";

import { useTranslation } from "hooks/translation";

  export function LanguageSelectorButton(){
    const { setLanguage } = useLanguageContext();
    const { currentLang } = useLanguageContext();
    const [currentLanguage, setCurrentLanguage] = useState("");
    const { currentLocale } = useFormat();
    const t = useTranslation();
    const languages : string[] = [t({ tag: "languages.english" }), t({ tag: "languages.french" }), t({ tag: "languages.spanishSelected" })];
   

    const getLanguages: { label : string, value : string}[] = languages.map(language=>{
    return {label: language, value: language};
    })

    let placeHolder = "";
    if (currentLang === SupportedLanguage.EnglishCA || currentLang === SupportedLanguage.EnglishUS){
        placeHolder = t({ tag: "languages.english" });
    }
    else if (currentLang === SupportedLanguage.FrenchCA){
        placeHolder = t({ tag: "languages.french" });
    }
    else if (currentLang === SupportedLanguage.Spanish){
        placeHolder = t({ tag: "languages.spanishSelected" });        
    }

    const changeLanguage = (languageSelected : string)=>{
        if (languageSelected === t({ tag: "languages.english" })){
            setCurrentLanguage(t({ tag: "languages.english" }));
            setLanguage(SupportedLanguage.EnglishCA)
            sessionStorage.setItem("lang", "en")
        }

        else if (languageSelected === t({ tag: "languages.french" })){
            setCurrentLanguage(t({ tag: "languages.french" }));
            setLanguage(SupportedLanguage.FrenchCA)
            sessionStorage.setItem("lang", "fr")            
        }
        else if (languageSelected === t({ tag: "languages.spanishSelected" })){
            setCurrentLanguage(t({ tag: "languages.spanishSelected" }));
            setLanguage(SupportedLanguage.Spanish)
            sessionStorage.setItem("lang", "es")
        }
    }
    return (
        <div>
            <DropdownSelect
            onChange={o=>{changeLanguage(o.value)}}
            value={currentLanguage}
            placeholder={placeHolder}
            options={getLanguages}/>
        </div>    
    );
  };
