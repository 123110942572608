import React, { useEffect, useState } from "react";
import { BaseModal } from "components/base-modal/base-modal";
import { ContainedButton, TextButton } from "components/buttons";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import styles from "./styles.module.scss";
import "filepond/dist/filepond.min.css";
import "./filepond.scss";

import { FilePondFile, ProcessServerConfigFunction } from "filepond";
import axios from "axios";
import { getAxios, getRestApiUrl } from "api/api-rest";
import CloseButton from "components/buttons/close-button/close-button";
import { getSessionService } from "features/session/session-service";
import {
  ApiValidationError,
  localizeError
} from "features/report-absence/incident";
import { ErrorMessage } from "components/error-message";
import { TextArea } from "components/text-field/text-area";
import clsx from "clsx";
import { DocumentEntityType } from "./documents";
import { useTranslation } from "hooks/translation";
import { useDesktopBreakpoint } from "hooks";
import { useFormat } from "hooks/format";
import { createShortcut } from "utils/util";
import { AppContext } from "context/app-context";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

export const UploadReferralSource = "MyAbiliti_AR";

const MaxNoteLength = 100;
const MaxFileSizeMb = 20;

type UploadDocumentProps = {
  onClose: () => void;
  isOpen: boolean;
  entityType: DocumentEntityType;
  entityId: number;
};

type FileState = {
  progress: number;
  error?: string;
};

export const UploadDocumentModal: React.FC<UploadDocumentProps> = ({
  onClose,
  isOpen,
  entityType,
  entityId
}) => {
  const t = useTranslation();
  const isDesktop = useDesktopBreakpoint();

  const [files, setFiles] = useState<FilePondFile[]>([]);

  const [notes, setNotes] = useState<{ [id: string]: string }>({});

  const [fileState, setFileState] = useState<{ [id: string]: FileState }>({});

  const [pond, setPond] = useState<FilePond | null>();

  const [uploadErrors, setUploadErrors] = useState<ApiValidationError[]>();

  const clientCode = getSessionService().getUser()?.client_code;

  const hasErrors = Object.values(notes).some(v => v.length > MaxNoteLength) || Object.values(fileState).some(s => s.error);

  const [uploading, setUploading] = useState(false);

  const { currentLocale } = useFormat();

  useEffect(() => {
    if (!isOpen) {
      setUploadErrors([]);
      setUploading(false);
      setFiles([]);
      setNotes({});
      setFileState({});
      pond?.removeFiles();
    }
  }, [isOpen, pond]);

  const upload = () => {
    setUploadErrors([]);
    setUploading(true);
    pond?.processFiles();
  };

  const processHandler: ProcessServerConfigFunction = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("Data.Description", metadata?.["desc"] ?? "");
    formData.append("Data.EntityType", "" + entityType);
    formData.append("Data.EntityId", "" + entityId); //1191
    formData.append("Data.Source", UploadReferralSource);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const url =
        getRestApiUrl() +
        "/connect/v1.0/Document/upload?clientCode=" +
        clientCode;
      const response = await getAxios().post(url, formData, {
        cancelToken: source.token,
        onUploadProgress: e => {
          progress(e.lengthComputable, e.loaded, e.total);
        }
      });
      load(response.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (axios.isCancel(e)) {
        console.log("Request canceled", e?.message);
      } else {
        console.log("error", e.request);
        let errorObj = null;

        if (e.request?.response) {
          try {
            const data = JSON.parse(e.request.response);
            if (data[0]?.errorDescription) {
              setUploadErrors(data);
              errorObj = data[0];
            }
          } catch (pe) {}
        } else {
        }

        if (errorObj === null) {
          errorObj = { errorDescription: e?.message };
          setUploadErrors([
            {
              errorDescription: e?.message,
              errorCode: "Upload error",
              errorFields: []
            }
          ]);
        }

        error(errorObj);
        setUploading(false);
      }
    }

    return {
      abort: () => {
        source.cancel("Operation canceled by the user.");
      }
    };
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title={t({ tag: "docs.upload_documents" })}
      closeModal={onClose}
    >
      <div className={styles["doc-upload-modal"]}>
        {!files.length && (
          <div className={styles["doc-upload-files-desc"]}>
            {t({ tag: "docs.upload_pick_description" })}
          </div>
        )}

        <div
          className={clsx(
            styles["doc-upload-filepond"],
            uploading && styles["doc-upload-filepond-uploading"],
            files.length > 0 && styles["doc-upload-filepond-files"]
          )}
        >
          <FilePond
            ref={ref => setPond(ref)}
            //maxFiles={3}
            allowMultiple={true}
            acceptedFileTypes={[
              "image/gif",
              "image/jpeg",
              "image/png",
              "image/bmp",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ]}
            instantUpload={false}
            allowFileSizeValidation={true}
            maxFileSize={`${MaxFileSizeMb}MB`}
            labelMaxFileSize={`${t({ text: "docs.max_file_size" })} (${MaxFileSizeMb} Mb)`}
            labelMaxFileSizeExceeded={`${t({ text: "docs.exceed_max_file_size" })} (${MaxFileSizeMb} Mb)`}
            onupdatefiles={fileItems => {
              setFiles(fileItems);
            }}
            server={{
              process: processHandler
            }}
            onprocessfileprogress={(f, pr) => {
              if (uploadErrors?.length) return;
              setFileState(p => {
                p[f.id] = { progress: Math.round(pr * 100) };
                return p;
              });
              setFiles(ff => [...ff]);
            }}
            onprocessfile={(e, f) => {
              if (uploadErrors?.length) return;
              console.log("onprocessfile", e, f);
              setFileState(p => {
                p[f.id] = { progress: 101 };
                return p;
              });
              setFiles(ff => [...ff]);
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onerror={(e: any, f) => {
              const text = e?.main ?? e?.errorDescription;
              const errorText = AppContext.getTranslations().tryTranslate(
                text,
                "error"
              );
              console.log("onerror", createShortcut(text), f, e);
              if (f) {
                setFileState(p => {
                  p[f.id] = {
                    error: errorText ?? t({ tag: "docs.upload_error" }),
                    progress: 0
                  };
                  return p;
                });
                setFiles(ff => [...ff]);
              }
            }}
            name="files"
            labelIdle={
              isDesktop
                ? `<div class="drop-label-content"> 
                    <img src="/images/claims/file-upload.svg">
                    <div class="drop-label-text">${t({
                      tag: "docs.upload_drag_drop_or"
                    })} 
                      <span class="filepond--label-action">${t({
                        tag: "docs.browse_files"
                      })}</span>
                    </div>
                  </div>`
                : `<div class="drop-label-content"> 
                    <div class="filepond--label-action browse-btn">
                      <span>${t({ tag: "docs.browse_files" })}</span>
                    </div>
                  </div>`
            }
          />
        </div>
        <div className={styles["doc-upload-content"]}>
          {!uploading && files.length > 0 && (
            <div className={styles["doc-upload-desc"]}>
              {t({ tag: "docs.upload_ask_description" })}
            </div>
          )}
          {uploadErrors && uploadErrors.length > 0 && (
            <ErrorMessage className={styles["doc-upload-error"]}>
              {uploadErrors.map((e, i) => (
                <div key={i}>
                  {localizeError(currentLocale.code, e)?.errorDescription ?? e.errorCode ?? "Upload error"}
                </div>
              ))}
            </ErrorMessage>
          )}
          {files.map(f => (
            <div key={f.id}>
              <div className={styles["doc-upload-file"]}>
                <div>{f.filename}</div>
                {fileState[f.id]?.progress > 0 && (
                  <div className={styles["doc-upload-file-state"]}>
                    {fileState[f.id].progress >= 100
                      ? t({ tag: "docs.uploaded" })
                      : t({ tag: "docs.uploading" }) +
                        " " +
                        fileState[f.id].progress +
                        "%"}
                  </div>
                )}
                {fileState[f.id]?.error && (
                  <div className={styles["doc-upload-file-error"]}>
                    {fileState[f.id].error}
                  </div>
                )}
                {!uploading && (
                  <CloseButton
                    onClick={() => {
                      setNotes(n => {
                        delete n[f.id];
                        return n;
                      });
                      setFileState(s => {
                        delete s[f.id]
                        return s;
                      });
                      pond?.removeFile(f.id);
                    }}
                  />
                )}
              </div>
              <div>
                <TextArea
                  id={f.id + "-note"}
                  testId={f.id + "-note"}
                  name={f.id + "-note"}
                  labelText={t({ text: "docs.upload_desc_label" })}
                  helperText={t({
                    text: "docs.upload_char_limit",
                    vars: {
                      limit: "" + MaxNoteLength
                    }
                  })}
                  maxLength={MaxNoteLength}
                  isInvalid={notes[f.id]?.length > MaxNoteLength}
                  autoResize={true}
                  disabled={fileState[f.id]?.progress >= 100}
                  value={notes[f.id]}
                  onChange={e => {
                    setNotes(n => {
                      n[f.id] = e.target.value;
                      return n;
                    });
                    f.setMetadata("desc", e.target.value);
                    setFiles(fi => [...fi]);
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        <div className={styles["doc-upload-actions"]}>
          <TextButton onClick={onClose}>
            {t({ tag: "docs.upload_close" })}
          </TextButton>
          <ContainedButton
            onClick={upload}
            disabled={!files.length || hasErrors || uploading}
          >
            {t({ tag: "docs.upload" })}
          </ContainedButton>
        </div>
      </div>
    </BaseModal>
  );
};
