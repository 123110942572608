import { formatISO, parseISO } from "date-fns";
import { getParentLocaleCode } from "features/translations/locales";
import {
  AbsenceClosingScriptModel,
  AbsenceDateModelInput,
  AbsenceIncidentInputModelInput,
  CanBeALinkedIncident,
  Connect_QuestionModel,
  DropDownOptionModel,
  IncidentQuestionFieldsFragment,
  KeyValuePairOfStringAndString,
  LoadIncidentQuery,
  LocaleModel,
  Maybe,
  PageSectionModel,
  PrimaryReasonAnswerModel,
  QuestionAlertModel,
  RefStaticResourceModel,
  SaveAbsenceMutation,
  SectionQueryFragment,
  ValidationError,
  Page,
  PageSection,
  Question,
  QuestionAlert,
  DropDownOption,
  ValidationConstraint,
  Compare,
  HierarchicalOrganizationModel,
  VisibilityConditionsModel,
  WorkScheduleInfoModel,
  Ar3IvrConfigurationModel
} from "graphql-types.gen";
import { LocaleTextEntry } from "shared-types/api";
import { hasValue } from "utils/util";
import {
  AbsenceAlert,
  AbsenceQuestionsPage,
  AbsenceQuestionsSection,
  NewEployeeQuestionsSection,
  NewEmployeeQuestion,
  DropDownOption as DropDownOptions,
  KeyValuePairOfStringAndString as KeyValuePair,
  QuestionAlert as QuestionAlerts,
  ValidationConstraint as ValidationConstraints,
  Compare as Compares,
  AnswerChoice,
  ConfigAbsenceQuestion,
  ConfigQuestionType,
  VisibilityConditions
} from "./absence-questions";
import {
  AbsenceIncident,
  AbsenceIncidentLinkability,
  AbsenceReason,
  AbsenceReasonAnswerInfo,
  AbsenceType,
  EmployeeVerificationFields,
  OrganizationHierarchy,
  ApiValidationError,
  IncidentClosingScript,
  IncidentQuestionModel,
  QuestionResponse,
  SubAnswer,
  WorkScheduleInfo,
  localizeError,
  AR3IvrConfiguration
} from "./incident";

export function parseValidationErrors(
  locale: string,
  errors?:
    | readonly Maybe<
        Pick<ValidationError, "errorCode" | "errorDescription" | "errorFields">
      >[]
    | null
): ApiValidationError[] {
  if (!errors) return [];

  return errors
    .filter(hasValue)
    .map(e => {
      if (e.errorCode) {
        const err = {
          errorCode: e.errorCode,
          errorDescription: e.errorDescription,
          errorFields: e.errorFields?.filter(ef => ef).map(ef => ef ?? "") ?? []
        };
        return localizeError(locale, err as ApiValidationError);
      }
      return null;
    })
    .filter(hasValue);
}

export function parseEmployeeVerificationFields(
  employeeVerificationFields:
    | readonly Maybe<
        Pick<
          Page,
          "pageId" | "pageHeading" | "pageHeadingDescription" | "sections"
        >
      >[]
    | null
): EmployeeVerificationFields[] {
  if (!employeeVerificationFields || !employeeVerificationFields.length) {
    return [];
  } else {
    return employeeVerificationFields.filter(hasValue).map(e => ({
      pageId: e.pageId,
      pageHeading: e.pageHeading?.filter(hasValue).map(ph => ({
        locale: ph.locale || "",
        description: ph.description || ""
      })),
      pageHeadingDescription: e.pageHeadingDescription
        ?.filter(hasValue)
        .map(phd => ({
          locale: phd.locale || "",
          description: phd.description || ""
        })),
      sections: parseNewEmployeeSections(e.sections || [])
    }));
  }
}

export function parseNewEmployeeSections(
  sections?: readonly Maybe<
    Pick<
      PageSection,
      "questions" | "sectionHeading" | "sectionHeadingDescription" | "sectionId"
    >
  >[]
): NewEployeeQuestionsSection[] {
  if (!sections || !sections.length) {
    return [];
  }

  return sections.filter(hasValue).map(s => ({
    sectionId: s.sectionId,
    sectionHeading: s.sectionHeading?.filter(hasValue).map(sh => ({
      locale: sh.locale || "",
      description: sh.description || ""
    })),
    sectionHeadingDescription: s.sectionHeadingDescription
      ?.filter(hasValue)
      .map(shd => ({
        locale: shd.locale || "",
        description: shd.description || ""
      })),
    questions: parseQuestion(s.questions || [])
  }));
}

export function parseQuestion(
  questions: readonly Maybe<Question>[]
): NewEmployeeQuestion[] {
  if (!questions || !questions.length) {
    return [];
  }

  return questions.filter(hasValue).map(q => ({
    name: q.name,
    label: q.label?.filter(hasValue).map(l => ({
      locale: l.locale || "",
      description: l.description || ""
    })),
    questionType: q.questionType,
    longDescription: q.longDescription?.filter(hasValue).map(ld => ({
      locale: ld.locale || "",
      description: ld.description || ""
    })),
    placeholder: q.placeholder?.filter(hasValue).map(p => ({
      locale: p.locale || "",
      description: p.description || ""
    })),
    source: q.source,
    defaultValue: q.defaultValue,
    reloadIncidentData: q.reloadIncidentData,
    resolvedValue: q.resolvedValue,
    isVisible: q.isVisible,
    isReadOnly: q.isReadOnly,
    options: parseOptions(q.options || []),
    validationConstraint: parseValidationConstraint(q.validationConstraint),
    visibilityConditions: parseVisibilityConditions(q.visibilityConditions),
    alerts: parseQuestionAlerts(q.alerts || []),
    config: q.config
  }));
}

export function parseValidationConstraint(
  validationConstraint?: Maybe<ValidationConstraint>
): ValidationConstraints {
  return {
    compare: parseCompare(validationConstraint?.compare),
    digits: validationConstraint?.digits,
    required: validationConstraint?.required || null,
    min: validationConstraint?.min,
    max: validationConstraint?.max,
    number: validationConstraint?.number
  };
}

export function parseCompare(compare?: Maybe<Compare>): Compares {
  return {
    fieldToCompare: compare?.fieldToCompare,
    operator: compare?.operator,
    valueToCompare: compare?.valueToCompare
  };
}

export function parseQuestionAlerts(
  alerts?: readonly Maybe<QuestionAlert>[]
): QuestionAlerts[] {
  if (!alerts || !alerts.length) {
    return [];
  }

  return alerts.filter(hasValue).map(a => ({
    alertId: a.alertId,
    alertLevel: a.alertLevel,
    alertText: a.alertText?.filter(hasValue).map(at => ({
      locale: at.locale || "",
      description: at.description || ""
    }))
  }));
}

export function parseOptions(
  options?: readonly Maybe<DropDownOption>[]
): DropDownOptions[] {
  if (!options || !options.length) {
    return [];
  }

  return options.filter(hasValue).map(o => ({
    value: o.value,
    text: o.text?.filter(hasValue).map(t => ({
      locale: t.locale || "",
      description: t.description || ""
    })),
    metadata: parseMetaData(o.metadata || [])
  }));
}

export function parseMetaData(
  metadata?: readonly Maybe<KeyValuePairOfStringAndString>[]
): KeyValuePair[] {
  if (!metadata || !metadata.length) {
    return [];
  }

  return metadata.filter(hasValue).map(m => ({
    key: m.key,
    value: m.value
  }));
}

export function parseOrganizationHierarchy(
  organizationHierarchy?:
    | readonly Maybe<
        Pick<
          HierarchicalOrganizationModel,
          "organizationId" | "organizationLevel" | "organizationName"
        > & {
          readonly childOrganizations?:
            | readonly Maybe<
                Pick<
                  HierarchicalOrganizationModel,
                  "organizationId" | "organizationLevel" | "organizationName"
                >
              >[]
            | null;
        }
      >[]
    | null
): OrganizationHierarchy[] {
  if (!organizationHierarchy || !organizationHierarchy.length) {
    return [];
  }

  return organizationHierarchy.filter(hasValue).map(o => ({
    organizationId: o.organizationId,
    organizationLevel: o.organizationLevel,
    organizationName: o.organizationName,
    childOrganizations: o.childOrganizations?.map(c => ({
      organizationId: c?.organizationId ?? 0,
      organizationLevel: c?.organizationLevel ?? 0,
      organizationName: c?.organizationName ?? ""
    }))
  }));
}

export function parseQuestionReturnModel(
  data: IncidentQuestionFieldsFragment | undefined
): IncidentQuestionModel {
  if (data) {
    return {
      questions: parseSectionQueryFragment(data.questions),
      preQualifyingQuestions: parseSectionQueryFragment(
        data.preQualifyingQuestions
      ),
      reasonOfAbsenceAlerts: parseAlerts(data.reasonOfAbsenceAlerts)
    };
  } else {
    return {
      questions: [],
      preQualifyingQuestions: [],
      reasonOfAbsenceAlerts: []
    };
  }
}

export function parseSectionQueryFragment(
  questions?: readonly Maybe<SectionQueryFragment>[] | null
): AbsenceQuestionsPage[] {
  if (!questions) return [];

  return questions
    .filter(hasValue)
    .map(q => {
      if (q.pageId && q.pageHeading && q.pageHeadingDescription && q.sections) {
        return {
          pageId: q.pageId,
          pageHeading: parseLocaleModel(q.pageHeading),
          pageHeadingDescription: parseLocaleModel(q.pageHeadingDescription),
          sections: parseSections(q.sections)
        };
      }
      return null;
    })
    .filter(hasValue);
}

export function parseSections(
  sections: readonly Maybe<
    Pick<
      PageSectionModel,
      "questions" | "sectionHeading" | "sectionHeadingDescription" | "sectionId"
    >
  >[]
): AbsenceQuestionsSection[] {
  if (!sections) return [];

  return sections
    .filter(hasValue)
    .map(s => {
      if (
        s.questions &&
        s.sectionHeading &&
        s.sectionHeadingDescription &&
        s.sectionId
      ) {
        return {
          questions: parseQuestionModel(s.questions),
          sectionHeading: parseLocaleModel(s.sectionHeading),
          sectionHeadingDescription: parseLocaleModel(
            s.sectionHeadingDescription
          ),
          sectionId: s.sectionId
        };
      }

      return null;
    })
    .filter(hasValue);
}

export function parseQuestionModel(
  questions: readonly Maybe<Connect_QuestionModel>[]
): ConfigAbsenceQuestion[] {
  if (!questions) return [];
  return questions
    .filter(hasValue)
    .map(q => {
      if (q.name && q.label && q.questionType) {
        const parsedConfigQuestionType = parseConfigQuestionType(
          q.questionType
        );
        const parsedAnswerChoices = parseAnswerChoices(q.options);

        return {
          name: q.name,
          label: parseLocaleModel(q.label),
          questionType: parsedConfigQuestionType,
          longDescription: q.longDescription
            ? parseLocaleModel(q.longDescription)
            : undefined,
          placeholder: q.placeholder
            ? parseLocaleModel(q.placeholder)
            : undefined,
          source: q.source,
          defaultValue: parseDefaultValue(
            parsedConfigQuestionType,
            parsedAnswerChoices,
            q.defaultValue
          ),
          resolvedValue: q.resolvedValue,
          isVisible: q.isVisible,
          isReadOnly: q.isReadOnly,
          options: parsedAnswerChoices,
          validationConstraint: q.validationConstraint,
          visibilityConditions: parseVisibilityConditions(
            q.visibilityConditions
          ),
          alerts: parseAlerts(q.alerts),
          config: q.config
        } as ConfigAbsenceQuestion;
      }
      return null;
    })
    .filter(hasValue);
}

function parseDefaultValue(
  questionType: ConfigQuestionType,
  options: AnswerChoice[],
  defaultValue?: string | null
): string | undefined | null {
  if (questionType === "dropdown" && defaultValue !== undefined) {
    if (options.some(option => option.value === defaultValue)) {
      return defaultValue;
    }

    return undefined;
  }

  return defaultValue;
}

export function parseAlerts(
  alerts?: readonly Maybe<QuestionAlertModel>[] | null
): AbsenceAlert[] {
  if (!alerts) return [];
  return alerts
    .filter(hasValue)
    .map(a => {
      if (a.alertId && a.alertText) {
        return {
          alertId: a.alertId,
          alertLevel: a.alertLevel,
          alertText: parseLocaleModel(a.alertText)
        } as AbsenceAlert;
      }
      return null;
    })
    .filter(hasValue);
}

export function parseVisibilityConditions(
  conditions?: readonly Maybe<VisibilityConditionsModel>[] | null
): VisibilityConditions[] {
  if (!conditions) return [];
  return conditions
    .filter(hasValue)
    .map(cond => {
      if (cond.id && cond.operator && cond.values) {
        return {
          id: cond.id,
          operator: cond.operator,
          values: [...cond.values]
        } as VisibilityConditions;
      }
      return null;
    })
    .filter(hasValue);
}

export function parseAnswerChoices(
  options?: readonly Maybe<DropDownOptionModel>[] | null
): AnswerChoice[] {
  if (!options) return [];
  return options
    .filter(hasValue)
    .map(o => {
      if (o.text && o.value) {
        return {
          text: parseLocaleModel(o.text),
          value: o.value
        } as AnswerChoice;
      }
      return null;
    })
    .filter(hasValue);
}

export function parseConfigQuestionType(type: string): ConfigQuestionType {
  switch (type) {
    case "textArea":
      return "textArea";
    case "card":
      return "card";
    case "dropdown":
      return "dropdown";
    case "date":
      return "date";
    case "boolean":
      return "boolean";
    case "numeric":
      return "numeric";
    case "phone":
      return "phone";
    case "email":
      return "email";
    case "text":
    default:
      return "text";
  }
}

export function parseLocaleModel(
  localeModel: readonly Maybe<Pick<LocaleModel, "locale" | "description">>[]
): LocaleTextEntry[] {
  if (!localeModel) return [];
  return localeModel.filter(hasValue).map(lm => ({
    description: lm.description as string,
    locale: lm.locale as string
  }));
}

type SaveAbsenceIncidentResultModel = NonNullable<
  NonNullable<
    NonNullable<SaveAbsenceMutation["saveAbsenceIncident"]>["returnValue"]
  >["absenceIncidentModel"]
>;

type LoadedAbsenceIncidentResultModel = NonNullable<
  LoadIncidentQuery["absenceIncident"]
>;

export function parseAbsenceIncident(
  data: SaveAbsenceIncidentResultModel
): AbsenceIncident {
  const absenceDates =
    data.absenceDates?.filter(hasValue).map(date => ({
      isPartialAbsence: date.isPartialAbsence ?? false,
      shiftStartTime: parseISO(date.shiftStartTime),
      shiftEndTime: parseISO(date.shiftEndTime),
      scheduledShiftStartTime: date.scheduledShiftStartTime
        ? parseISO(date.scheduledShiftStartTime)
        : undefined,
      scheduledShiftEndTime: date.scheduledShiftEndTime
        ? parseISO(date.scheduledShiftEndTime)
        : undefined
    })) ?? [];

  return {
    primaryReason: data.primaryReason,
    secondaryReason: data.secondaryReason,
    absenceDates,
    absenceIncidentId: data.absenceIncidentId,
    bestPhoneNumber: data.bestPhoneNumber ?? undefined,
    firstName: data.firstName ?? undefined,
    lastName: data.lastName ?? undefined,
    employeeNumber: data.employeeNumber ?? undefined,
    clientCode:
      !data.clientCode || data.clientCode === "" ? undefined : data.clientCode,
    requestDate: data.requestDate ? parseISO(data.requestDate) : undefined,
    returnToWorkDate: data.returnToWorkDate
      ? parseISO(data.returnToWorkDate)
      : undefined,
    absenceType: getAbsenceType(data.absenceType),
    linkability: parseAbsenceIncidentLinkability(
      data.CanBeALinkedIncident,
      data.linkedIncident ?? undefined
    ),
    questionResponses: getQuestionResponses(data.questionResponses),
    maxDaysAllowed: data.maxDaysAllowed,
    maxReportableDays: data.maxReportableDays
  };
}

export function parseLoadedAbsenceIncident(
  data: LoadedAbsenceIncidentResultModel
): AbsenceIncident {
  const absenceDates =
    data.absenceDates?.filter(hasValue).map(date => ({
      isPartialAbsence: date.isPartialAbsence ?? false,
      shiftStartTime: parseISO(date.shiftStartTime),
      shiftEndTime: parseISO(date.shiftEndTime),
      scheduledShiftStartTime: date.scheduledShiftStartTime
        ? parseISO(date.scheduledShiftStartTime)
        : undefined,
      scheduledShiftEndTime: date.scheduledShiftEndTime
        ? parseISO(date.scheduledShiftEndTime)
        : undefined
    })) ?? [];

  return {
    primaryReason: data.primaryReason,
    secondaryReason: data.secondaryReason,
    absenceDates,
    absenceIncidentId: data.absenceIncidentId,
    bestPhoneNumber: data.bestPhoneNumber ?? undefined,
    firstName: data.firstName ?? undefined,
    lastName: data.lastName ?? undefined,
    employeeNumber: data.employeeNumber ?? undefined,
    clientCode:
      !data.clientCode || data.clientCode === "" ? undefined : data.clientCode,
    requestDate: data.requestDate ? parseISO(data.requestDate) : undefined,
    returnToWorkDate: data.returnToWorkDate
      ? parseISO(data.returnToWorkDate)
      : undefined,
    absenceType: getAbsenceType(data.absenceType),
    linkability: parseAbsenceIncidentLinkability(
      data.CanBeALinkedIncident,
      data.linkedIncident ?? undefined
    ),
    questionResponses: getQuestionResponses(data.questionResponses),
    claimStatus: data.claimStatus ?? undefined,
    maxDaysAllowed: data.maxDaysAllowed,
    maxReportableDays: data.maxReportableDays
  };
}

export function parseWorkScheduleInfo(
  data?: WorkScheduleInfoModel | null
): WorkScheduleInfo {
  if (data) {
    return {
      scheduleOverrideWarning: data?.warnWhenOverridingSchedule ?? false,
      warningMessages: parseLocaleModel(data?.warningMessages ?? [])
    };
  }
  return {
    scheduleOverrideWarning: false,
    warningMessages: []
  };
}

export function parseAR3IVRConfiguration(
  data?: Ar3IvrConfigurationModel | null
): AR3IvrConfiguration {
  if (data) {
    return {
      maxShiftLengthThresholdInMinutes:
        data?.maxShiftLengthThresholdInMinutes ?? 0
    };
  }
  return {
    maxShiftLengthThresholdInMinutes: 0
  };
}

function parseAbsenceIncidentLinkability(
  canBeALinkedIncident:
    | Maybe<
        Pick<
          CanBeALinkedIncident,
          "canBeLinked" | "latestDate" | "totalNoOfDaysReportedExcludingCurrent"
        >
      >
    | undefined,
  isLinked: boolean | undefined
): AbsenceIncidentLinkability {
  if (!hasValue(canBeALinkedIncident)) {
    return { canBeLinked: undefined };
  }

  if (!canBeALinkedIncident.canBeLinked) {
    return { canBeLinked: false };
  }

  return {
    canBeLinked: canBeALinkedIncident.canBeLinked,
    latestDate: parseISO(canBeALinkedIncident.latestDate),
    totalNoOfDaysReportedExcludingCurrent:
      canBeALinkedIncident.totalNoOfDaysReportedExcludingCurrent,
    isLinked
  };
}

export function getAbsenceType(type?: string | null): AbsenceType | undefined {
  switch (type) {
    case "continuous":
      return "continuous";
    case "intermittent":
      return "intermittent";
    default:
      return;
  }
}

export function getQuestionResponses(
  responses?:
    | readonly Pick<KeyValuePairOfStringAndString, "key" | "value">[]
    | null
): QuestionResponse[] {
  if (responses) {
    return responses.map(
      r => ({ key: r.key, value: r.value } as QuestionResponse)
    );
  }
  return [];
}

export function parseReasonOfAbsence(
  reasonOfAbsence?: Maybe<{
    readonly answers?: Maybe<
      ReadonlyArray<
        Maybe<
          Pick<
            PrimaryReasonAnswerModel,
            | "answerId"
            | "maxDaysAllowed"
            | "reportableDaysInFuture"
            | "reportableDaysInPast"
          > & {
            readonly locales?: Maybe<
              ReadonlyArray<Maybe<Pick<LocaleModel, "locale" | "description">>>
            >;
            readonly iconResource?: Maybe<
              Pick<RefStaticResourceModel, "sourceUrl">
            >;
            readonly subAnswers?: Maybe<
              ReadonlyArray<
                Maybe<
                  Pick<
                    PrimaryReasonAnswerModel,
                    | "answerId"
                    | "reportableDaysInFuture"
                    | "reportableDaysInPast"
                  > & {
                    readonly locales?: Maybe<
                      ReadonlyArray<
                        Maybe<Pick<LocaleModel, "locale" | "description">>
                      >
                    >;
                  }
                >
              >
            >;
          }
        >
      >
    >;
  }> | null
): AbsenceReason {
  if (!reasonOfAbsence || !reasonOfAbsence.answers) return { answers: [] };
  return {
    answers: reasonOfAbsence.answers
      .filter(hasValue)
      .map(a => {
        if (a.locales) {
          return {
            answerId: a.answerId,
            locales: parseLocaleModel(a.locales),
            maxDaysAllowed: a.maxDaysAllowed,
            reportableDaysInFuture: a.reportableDaysInFuture,
            reportableDaysInPast: a.reportableDaysInPast,
            subAnswers: parseSubAnswers(a.subAnswers),
            imageUrl: a.iconResource?.sourceUrl
          } as AbsenceReasonAnswerInfo;
        }
        return null;
      })
      .filter(hasValue)
  };
}

export function parseSubAnswers(
  subAnswers?:
    | readonly Maybe<
        Pick<
          PrimaryReasonAnswerModel,
          "answerId" | "reportableDaysInFuture" | "reportableDaysInPast"
        > & {
          locales?:
            | readonly Maybe<Pick<LocaleModel, "locale" | "description">>[]
            | null;
        }
      >[]
    | null
): SubAnswer[] {
  if (!subAnswers) return [];

  return subAnswers
    .filter(hasValue)
    .map(
      ({ locales, answerId, reportableDaysInFuture, reportableDaysInPast }) => {
        if (locales) {
          return {
            locales,
            answerId,
            reportableDaysInFuture,
            reportableDaysInPast
          } as SubAnswer;
        }
        return null;
      }
    )
    .filter(hasValue);
}

export function parseClosingScripts(
  scripts?:
    | readonly Maybe<
        Pick<AbsenceClosingScriptModel, "closingScriptId"> & {
          readonly text?:
            | readonly Maybe<Pick<LocaleModel, "description" | "locale">>[]
            | null
            | undefined;
        }
      >[]
    | null
): IncidentClosingScript[] {
  if (!scripts) return [];
  return scripts
    .filter(hasValue)
    .map(s => {
      if (s.text) {
        return {
          closingScriptId: s.closingScriptId,
          text: parseLocaleModel(s.text)
        } as IncidentClosingScript;
      }

      return null;
    })
    .filter(hasValue);
}

export function toAbsenceIncidentInputModelInput(
  incident: AbsenceIncident,
  locale: string
): AbsenceIncidentInputModelInput {
  return {
    absenceIncidentId: incident.absenceIncidentId,
    absenceType: incident.absenceType,
    bestPhoneNumber: incident.bestPhoneNumber,
    employeeNumber: incident.employeeNumber,
    firstName: incident.firstName,
    lastName: incident.lastName,
    linkedIncident:
      incident.linkability.canBeLinked && incident.linkability.isLinked,
    primaryReason: incident.primaryReason,
    secondaryReason: incident.secondaryReason,
    questionResponses: incident.questionResponses,
    requestDate: incident.requestDate && formatISO(incident.requestDate),
    returnToWorkDate:
      incident.returnToWorkDate && formatISO(incident.returnToWorkDate),
    reportingLocale: getParentLocaleCode(locale),
    absenceDates: incident.absenceDates?.map<AbsenceDateModelInput>(date => ({
      isPartialAbsence: date.isPartialAbsence,
      shiftStartTime: date.shiftStartTime && formatISO(date.shiftStartTime),
      shiftEndTime: date.shiftEndTime && formatISO(date.shiftEndTime),
      scheduledShiftStartTime:
        date.scheduledShiftStartTime && formatISO(date.scheduledShiftStartTime),
      scheduledShiftEndTime:
        date.scheduledShiftEndTime && formatISO(date.scheduledShiftEndTime)
    }))
  };
}
