import { format, isValid, Locale } from "date-fns";
import { useLanguageContext } from "context/user-language-context";
import { getLocale } from "features/translations/locales";

export type FormatWidth = "narrow" | "abbreviated" | "wide";

export const formatWithLocale = (
  date: Date,
  formatStr: string,
  locale: Locale,
  invalidLabel = ""
) => {

  if (!isValid(date)) return invalidLabel;

  return format(
    date,
    formatStr,
    locale
      ? {
          locale: locale
        }
      : {}
  );
};

export function useFormat() {
  const { currentLang } = useLanguageContext();

  const currentLocale = getLocale(currentLang);

  const formatLocal = (date: Date, formatStr: string,
    invalidLabel = "") =>
    formatWithLocale(date, formatStr, currentLocale, invalidLabel);

  const getDaysOfWeek = (width: FormatWidth = "narrow") => {
    const localize = currentLocale?.localize;
    const weekdays = localize
      ? Array.from(Array(7).keys()).map(i => localize.day(i, { width }))
      : ["S", "M", "T", "W", "T", "F", "S"];
    return weekdays;
  };

  return { currentLocale, formatLocal, getDaysOfWeek };
}
