import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router";
import * as Routes from "./routes";
import { Logout } from "auth/logout";
import { LogoutCallback } from "auth/logoutCallback";
import { SilentRenew } from "auth/silentRenew";
import { NotFoundPage } from "pages/not-found";
import { AuthenticatedRoute } from "auth/authenticated-route";
import { Callback } from "auth/callback";
import { DashboardPage } from "pages/dashboard/dashboard";
import FmlClaimPage from "pages/claims/fml-claim";
import StdClaimPage from "pages/claims/std-claim";
import { ClaimsListPage } from "pages/claims/claims-list";
import { ClaimsHistoryPage } from "pages/claims/claims-history";
import { getSessionService } from "features/session/session-service";
import { ApplicationRestrictionView } from "pages/application-access/application-restriction";
import AbsenceClaimPage from "pages/claims/absence/absence-claim";
import AbsenceClaimAllDatesPage from "pages/claims/absence/absence-claim-all-dates";
import { LeavePolicyPage } from "pages/leave-policy/leave-policy";
import AppHealth from "./pages/health";

const ReportAbsencePage = lazy(async () => import("./pages/report-absence"));

export const AppRoutes: React.FunctionComponent = () => {
  const sessionService = getSessionService();
  const isCICUser = sessionService.isCICUser();

  return (
    <Switch>
      <Route exact={true} path="/signin-oidc" component={Callback} />

      <Route exact path={Routes.Root.path}>
        <Redirect to={Routes.Dashboard.generate({})} />
      </Route>

      <Route exact path={Routes.Dashboard.path}>
        {isCICUser ? (
          <Redirect to={Routes.ReportAbsence.generate({})} />
        ) : (
          <AuthenticatedRoute
            exact
            path={Routes.Dashboard.path}
            component={DashboardPage}
          />
        )}
      </Route>

      <AuthenticatedRoute
        path={Routes.ReportAbsence.path}
        component={ReportAbsencePage}
      />

      <AuthenticatedRoute
        path={Routes.AccessDenied.path}
        component={ApplicationRestrictionView}
      />

      <AuthenticatedRoute
        exact
        path={Routes.ClaimsHistory.path}
        component={ClaimsHistoryPage}
      />

      <AuthenticatedRoute
        exact
        path={Routes.ClaimsList.path}
        component={ClaimsListPage}
      />

      <AuthenticatedRoute
        path={Routes.ViewFmlClaim.path}
        component={FmlClaimPage}
      />

      <AuthenticatedRoute
        path={Routes.ViewStdClaim.path}
        component={StdClaimPage}
      />

      <AuthenticatedRoute
        path={Routes.ViewAbsenceClaimAllDates.path}
        component={AbsenceClaimAllDatesPage}
      />

      <AuthenticatedRoute
        path={Routes.ViewAbsenceClaim.path}
        component={AbsenceClaimPage}
      />

      <AuthenticatedRoute
        exact={true}
        path="/logout"
        component={Logout}
        checkAccess={false}
      />
      <AuthenticatedRoute
        exact={true}
        path="/logout/callback"
        component={LogoutCallback}
        checkAccess={false}
      />
      <AuthenticatedRoute
        exact={true}
        path="/silentrenew"
        component={SilentRenew}
        checkAccess={false}
      />

      <AuthenticatedRoute
        exact={true}
        path={Routes.LeavePolicy.path}
        component={LeavePolicyPage}
      />

      <Route exact={true} path="/health" component={AppHealth} />

      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default AppRoutes;
