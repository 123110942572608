import {
  ApiCallResult,
  AbsenceIncident,
  AddNewEmployeePermission,
  EmployeeVerificationFields,
  OrganizationHierarchy,
  IncidentSaveResult,
  IncidentSubmitResult,
  AbsenceConfig,
  IncidentQuestionModel,
  emptyIncident,
  mapIncidentTypes,
  AbsenceIncidentLinkability,
  IncidentsCancelResult,
  LatestInProgressAbsenceResult,
  IncidentClosingScript
} from "features/report-absence/incident";
import { parseTime, Time } from "shared-types/time";
import { IAbsenceReportingService } from "./absence-reporting-types";
import { delay } from "utils/util";
import { ConfigAbsenceQuestion } from "./absence-questions";
import { formatISO, subBusinessDays } from "date-fns";
import {
  CancelAbsenceRequestModelInput,
  SaveEmployeeModelInput
} from "graphql-types.gen";

// Make sure this is 0 when running tests. Otherwise if the test has frozen the clock,
// it will need to manually advance time in order for the delay to complete
const DELAY_MS = 0;

export class MockAbsenceReportingService implements IAbsenceReportingService {
  incidentCanBeLinked = true;
  countSaveIncident = 0;
  countAskIfCanBeLinked = 0;

  private static _instance: MockAbsenceReportingService;
  static singletonInstance(reset = false) {
    if (!this._instance || reset) {
      this._instance = new MockAbsenceReportingService();
    }
    return this._instance;
  }

  async loadConfig(clientCode: string, state: string): Promise<AbsenceConfig> {
    await delay(DELAY_MS);
    return absenceConfig;
  }

  async loadAddingNewEmployeePermission(
    clientCode: string
  ): Promise<AddNewEmployeePermission> {
    await delay(DELAY_MS);
    return { showAddNewEmployeeLink: true };
  }

  async loadEmployeeVerificationFields(
    clientCode: string
  ): Promise<EmployeeVerificationFields[]> {
    await delay(DELAY_MS);
    return [];
  }

  async loadOrganizationHierarchy(
    clientCode: string
  ): Promise<OrganizationHierarchy[]> {
    await delay(DELAY_MS);
    return [];
  }

  async addNewEmployee(
    clientCode: string,
    input: SaveEmployeeModelInput
  ): Promise<ApiCallResult> {
    await delay(DELAY_MS);
    return { success: true, validationErrors:[]};
  }

  async loadIncident(incidentId: number): Promise<AbsenceIncident | null> {
    await delay(DELAY_MS);
    const incident = this.getIncidentFromStore();
    if (incident) {
      incident.absenceIncidentId = incidentId;
      mapIncidentTypes(incident);
    }
    return incident;
  }

  async saveIncident(
    clientCode: string,
    incident: AbsenceIncident,
    askIfCanBeLinked: boolean
  ): Promise<IncidentSaveResult> {
    this.countSaveIncident++;

    await delay(DELAY_MS);

    if (askIfCanBeLinked) {
      this.countAskIfCanBeLinked++;
    }

    const savedIncident = { ...incident };
    if (!savedIncident.absenceIncidentId) {
      const i = this.getIncidentFromStore();
      savedIncident.absenceIncidentId = i?.absenceIncidentId
        ? i.absenceIncidentId + 1
        : 1;
    }
    localStorage.setItem("test-incident", JSON.stringify(savedIncident));

    const linkability: AbsenceIncidentLinkability =
      this.incidentCanBeLinked &&
      incident.primaryReason === "af22da76-a6ae-4357-8e55-1f08de51bd11" // Personal emergency
        ? {
            canBeLinked: this.incidentCanBeLinked,
            latestDate: subBusinessDays(new Date(Date.now()), 2)
          }
        : { canBeLinked: false };

    return {
      returnValue: {
        absenceIncidentModel: {
          ...savedIncident,
          linkability: askIfCanBeLinked
            ? linkability
            : { canBeLinked: undefined }
        },
        absenceQuestionReturnModel: configQuestions
      },
      success: true,
      validationErrors: []
    };
  }

  async submitIncident(
    incident: AbsenceIncident
  ): Promise<IncidentSubmitResult> {
    await delay(DELAY_MS);
    return {
      returnValue: {
        absenceIncidentModel: this.getIncidentFromStore() || emptyIncident(),
        closingScripts: [
          {
            closingScriptId: "Main",
            text: [
              {
                description:
                  "<p>Your absence report has been submitted, and a confirmation e-mail has been sent to you at <strong>placeholder@google.com</strong>.</p>",
                locale: "EN"
              },
              {
                description:
                  "<p>FR - Your absence report has been submitted, and a confirmation e-mail has been sent to you at <strong>placeholder@google.com</strong>.</p>",
                locale: "FR"
              }
            ]
          }
        ]
      },
      success: true,
      validationErrors: []
    };
  }

  async loadConfigQuestions(
    incidentId: number
  ): Promise<IncidentQuestionModel> {
    await delay(DELAY_MS);
    return configQuestions;
  }

  async cancelIncidents(
    cancelReason: CancelAbsenceRequestModelInput,
    incidentIds: number[]
  ): Promise<IncidentsCancelResult> {
    await delay(DELAY_MS);
    return { success: true };
  }

  async getLatestInProgressAbsence(
    employeeNumber?: string
  ): Promise<LatestInProgressAbsenceResult | null> {
    await delay(DELAY_MS);

    const id = sessionStorage.getItem("test-incident-in-progress");
    if (!!id) {
      return { absenceIncidentId: +id };
    }

    return null;
  }

  async loadClosingScript(
    incidentId: number
  ): Promise<IncidentClosingScript[]> {
    await delay(DELAY_MS);
    return [];
  }

  private getIncidentFromStore = (): AbsenceIncident | null => {
    const i = localStorage.getItem("test-incident");
    const incident = i ? JSON.parse(i) : null;
    return incident;
  };
}

const defaults: Time = { hour: 9, minute: 0, period: "am" };
export const absenceConfig: AbsenceConfig = {
  reasonOfAbsence: {
    answers: [
      {
        answerId: "af22da76-a6ae-4357-8e55-1f08de51bd11",
        maxDaysAllowed: null,
        reportableDaysInFuture: 20,
        reportableDaysInPast: 20,
        locales: [
          {
            description: "Personal Emergency",
            locale: "EN"
          },
          {
            description: "Urgence personnelle",
            locale: "FR"
          }
        ],
        subAnswers: []
      },
      {
        answerId: "67abb32e-8142-48d8-859f-6b7f609e8132",
        maxDaysAllowed: 5,
        reportableDaysInFuture: null,
        reportableDaysInPast: null,
        locales: [
          {
            description: "Personal day off",
            locale: "EN"
          },
          {
            description: "Personal day off (FR)",
            locale: "FR"
          }
        ],
        subAnswers: [
          {
            answerId: "e85448bd-c953-463d-8ccc-21605b7531fc",
            reportableDaysInFuture: null,
            reportableDaysInPast: null,
            locales: [
              {
                description: "Vacation",
                locale: "EN"
              },
              {
                description: "Vacation (FR)",
                locale: "FR"
              }
            ]
          },
          {
            answerId: "ab2c675d-96e3-42e6-955b-ec7f3da32425",
            reportableDaysInFuture: 4,
            reportableDaysInPast: 4,
            locales: [
              {
                description: "Personal Emergency",
                locale: "EN"
              },
              {
                description: "Personal Emergency (FR)",
                locale: "FR"
              }
            ]
          }
        ]
      },
      {
        answerId: "06b1f618-b520-46c7-b3e0-7d2123aa96e7",
        maxDaysAllowed: 3,
        reportableDaysInFuture: null,
        reportableDaysInPast: null,
        locales: [
          {
            description: "Bereavement",
            locale: "EN"
          },
          {
            description: "Deuil",
            locale: "FR"
          }
        ],
        subAnswers: []
      },
      {
        answerId: "649c3758-1e73-459d-b742-9132e66494e3",
        maxDaysAllowed: 3,
        reportableDaysInFuture: null,
        reportableDaysInPast: null,
        locales: [
          {
            description: "Planned Leaves including Vacation",
            locale: "EN"
          },
          {
            description: "Autres congés",
            locale: "FR"
          }
        ],
        subAnswers: []
      },
      {
        answerId: "8bf3d326-4d64-4e90-8b5a-b5ecb1b4074a",
        maxDaysAllowed: 5,
        reportableDaysInFuture: null,
        reportableDaysInPast: null,
        locales: [
          {
            description: "Work Related Illness or Injury",
            locale: "EN"
          },
          {
            description: "Blessure ou maladie liée au travail",
            locale: "FR"
          }
        ],
        subAnswers: []
      },
      {
        answerId: "1f192c55-ed75-47c2-a6a5-e5f83b7c1422",
        maxDaysAllowed: 5,
        reportableDaysInFuture: null,
        reportableDaysInPast: null,
        locales: [
          {
            description: "Personal Illness, Injury, or Surgery",
            locale: "EN"
          },
          {
            description: "Maladie, blessure ou chirurgie personnelle",
            locale: "FR"
          }
        ],
        subAnswers: [
          {
            answerId: "20bfb435-f06a-434b-b1a5-60a8cd3b441a",
            reportableDaysInFuture: 1,
            reportableDaysInPast: 1,
            locales: [
              {
                description: "test2",
                locale: "EN"
              },
              {
                description: "test2Fr",
                locale: "FR"
              }
            ]
          },
          {
            answerId: "d154c3c2-883e-44ed-8a93-c7ca1ca49d11",
            reportableDaysInFuture: 1,
            reportableDaysInPast: 1,
            locales: [
              {
                description: "test1",
                locale: "EN"
              },
              {
                description: "test1Fr",
                locale: "FR"
              }
            ]
          }
        ]
      }
    ]
  },
  isPartialAbsences: true,
  displayRTWDateContinuous: true,
  displayRTWDateIntermittent: true,
  isRTWDateMandatoryContinuous: true,
  isRTWDateMandatoryIntermittent: true,
  absenceDurationMinutes: 15,
  linkedIncidentsLookbackThresholdDays: 7,
  workScheduleInfo: {
    scheduleOverrideWarning: true,
    warningMessages: [
      {
        description: "Selected times are outside of listed work schedules.",
        locale: "EN"
      },
      {
        description:
          "FRENCH: Selected times are outside of listed work schedules.",
        locale: "FR"
      }
    ]
  },
  workShifts: [
    {
      startTime: parseTime("16:00:00", defaults),
      endTime: parseTime("00:00:00", defaults),
      isDefault: false,
      order: 2
    },
    {
      startTime: parseTime("09:00:00", defaults),
      endTime: parseTime("17:00:00", defaults),
      isDefault: true,
      order: 1
    },
    {
      startTime: parseTime("00:00:00", defaults),
      endTime: parseTime("08:00:00", defaults),
      isDefault: false,
      order: 3
    },
    {
      startTime: parseTime("08:00:00", defaults),
      endTime: parseTime("16:00:00", defaults),
      isDefault: false,
      order: 1
    }
  ]
};

export const configQuestions: IncidentQuestionModel = {
  questions: [
    {
      pageHeading: undefined,
      pageHeadingDescription: undefined,
      pageId: "1",
      sections: [
        {
          sectionHeading: undefined,
          sectionHeadingDescription: undefined,
          sectionId: "1",
          questions: [
            getMockSingleLineQuestion(false),
            getMockSingleLineQuestion(true),
            getMockMultilineQuestionConfig(false),
            getMockSelectQuestionConfig(false),
            getMockDateQuestionConfig(false),
            getMockBooleanQuestionConfig(),
            getMockNumericQuestionConfig(false),
            getMockPhoneNumberQuestionConfig(),
            getMockEmailQuestionConfig()
          ]
        }
      ]
    }
  ],
  preQualifyingQuestions: [],
  reasonOfAbsenceAlerts: [
    {
      alertId: "alert-reason-1",
      alertText: [
        {
          description: "Absence Alert",
          locale: "EN"
        },
        {
          description: "FR - Absence Alert",
          locale: "FR"
        }
      ]
    }
  ]
};

export function getMockMultilineQuestionConfig(
  hasDefaultValue: boolean
): ConfigAbsenceQuestion {
  return {
    name: "multi-line-question",
    questionType: "textArea",
    label: [
      {
        description: "Multi Line Question",
        locale: "EN"
      },
      {
        description: "FR-Multi Line Question",
        locale: "FR"
      }
    ],
    longDescription: [
      {
        description: "Multi Line Question Description",
        locale: "EN"
      },
      {
        description: "FR-Multi Line Question Description",
        locale: "FR"
      }
    ],
    placeholder: [
      {
        description: "Multi Line Placeholder",
        locale: "EN"
      },
      {
        description: "FR-Multi Line Placeholder",
        locale: "FR"
      }
    ],
    defaultValue: hasDefaultValue ? "default value" : undefined,
    source: "CompanySpecific",
    isVisible: true,
    isReadOnly: false,
    validationConstraint: {
      required: true,
      max: 50
    },
    alerts: [
      {
        alertId: "alert-1",
        alertText: [
          {
            description: "Question Alert",
            locale: "EN"
          },
          {
            description: "FR - Question Alert",
            locale: "FR"
          }
        ]
      }
    ]
  };
}

export function getMockSelectQuestionConfig(
  hasDefaultValue: boolean
): ConfigAbsenceQuestion {
  return {
    name: "select-question",
    label: [
      {
        description: "Select Question",
        locale: "EN"
      },
      {
        description: "FR-Select Question",
        locale: "FR"
      }
    ],
    questionType: "dropdown",
    source: "CompanySpecific",
    options: [
      {
        value: "option1",
        text: [
          {
            description: "EN-Option 1",
            locale: "EN"
          },
          {
            description: "FR-Option 1",
            locale: "FR"
          }
        ]
      },
      {
        value: "option2",
        text: [
          {
            description: "EN-Option 2",
            locale: "EN"
          },
          {
            description: "FR-Option 2",
            locale: "FR"
          }
        ]
      },
      {
        value: "option3",
        text: [
          {
            description: "EN-Option 3",
            locale: "EN"
          },
          {
            description: "FR-Option 3",
            locale: "FR"
          }
        ]
      },
      {
        value: "option4",
        text: [
          {
            description: "EN-Option 4",
            locale: "EN"
          },
          {
            description: "FR-Option 4",
            locale: "FR"
          }
        ]
      },
      {
        value: "option5",
        text: [
          {
            description: "EN-Option 5",
            locale: "EN"
          },
          {
            description: "FR-Option 5",
            locale: "FR"
          }
        ]
      }
    ],
    defaultValue: hasDefaultValue ? "option3" : undefined,
    isVisible: true,
    isReadOnly: false,
    validationConstraint: {
      required: true,
      max: 50
    }
  };
}

export function getMockBooleanQuestionConfig(
  defaultValue?: boolean
): ConfigAbsenceQuestion {
  return {
    name: "boolean-question",
    questionType: "boolean",
    label: [
      {
        description: "Do you want to apply for STD Referral?",
        locale: "EN"
      },
      {
        description: "FR - Do you want to apply for STD Referral?",
        locale: "FR"
      }
    ],
    defaultValue:
      defaultValue !== undefined ? defaultValue.toString() : undefined,
    isReadOnly: false,
    isVisible: true,
    source: "COMPANY_SPECIFIC",
    validationConstraint: {
      required: true
    }
  };
}

export function getMockPhoneNumberQuestionConfig(): ConfigAbsenceQuestion {
  return {
    name: "phone-question",
    questionType: "phone",
    label: [
      {
        description: "Please enter your phone number.",
        locale: "EN"
      },
      {
        description: "FR - Please enter your phone number.",
        locale: "FR"
      }
    ],
    isReadOnly: false,
    isVisible: true,
    source: "COMPANY_SPECIFIC",
    validationConstraint: {
      required: true
    }
  };
}

export function getMockEmailQuestionConfig(): ConfigAbsenceQuestion {
  return {
    name: "email-question",
    questionType: "email",
    label: [
      {
        description: "Please enter your email.",
        locale: "EN"
      },
      {
        description: "FR - Please enter your email.",
        locale: "FR"
      }
    ],
    isReadOnly: false,
    isVisible: true,
    source: "COMPANY_SPECIFIC",
    validationConstraint: {
      required: true
    }
  };
}

export function getMockDateQuestionConfig(
  hasDefaultValue: boolean
): ConfigAbsenceQuestion {
  return {
    name: "date-question",
    questionType: "date",
    label: [
      {
        description: "Date Question",
        locale: "EN"
      },
      {
        description: "FR-Date Question",
        locale: "FR"
      }
    ],
    longDescription: [
      {
        description: "Date Question Description",
        locale: "EN"
      },
      {
        description: "FR-Date Question Description",
        locale: "FR"
      }
    ],
    placeholder: [
      {
        description: "Date Placeholder",
        locale: "EN"
      },
      {
        description: "FR-Date Placeholder",
        locale: "FR"
      }
    ],
    defaultValue: hasDefaultValue ? formatISO(new Date()) : undefined,
    source: "CompanySpecific",
    isVisible: true,
    isReadOnly: false,
    validationConstraint: {
      required: true
    }
  };
}

export function getMockNumericQuestionConfig(
  hasDefaultValue: boolean
): ConfigAbsenceQuestion {
  return {
    name: "numeric-question",
    questionType: "numeric",
    label: [
      {
        description: "Numeric Question (1-500)",
        locale: "EN"
      },
      {
        description: "FR - Numeric Question",
        locale: "FR"
      }
    ],
    defaultValue: hasDefaultValue ? "42" : undefined,
    isReadOnly: false,
    isVisible: true,
    source: "COMPANY_SPECIFIC",
    validationConstraint: {
      required: true,
      min: 1,
      max: 500
    }
  };
}

export function getMockSingleLineQuestion(
  hasDefaultValue: boolean
): ConfigAbsenceQuestion {
  return {
    name: hasDefaultValue
      ? "single-line-default-question"
      : "single-line-question",
    questionType: "text",
    label: [
      {
        description: "Single Line Question 2",
        locale: "EN"
      },
      {
        description: "FR-Single Line Question 2",
        locale: "FR"
      }
    ],
    longDescription: [
      {
        description: "Single Line Question Description 2",
        locale: "EN"
      },
      {
        description: "FR-Single Line Question Description 2 ",
        locale: "FR"
      }
    ],
    placeholder: [
      {
        description: "Placeholder 2",
        locale: "EN"
      },
      {
        description: "FR-Placeholder 2",
        locale: "FR"
      }
    ],
    defaultValue: hasDefaultValue ? "default value" : undefined,
    source: "CompanySpecific",
    isVisible: true,
    isReadOnly: false
  };
}
