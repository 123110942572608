import React, { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import { DesktopTable, MobileTable } from "components/table/table";
import Pagination from "components/pagination/pagination";
import { TimeIcon, MoonIcon } from "components/icons";
import { useDesktopBreakpoint } from "hooks";
import { useTranslation } from "hooks/translation";
import { formatTime, isTimeAfter } from "shared-types/time";
import { ClaimDate } from "graphql-types.gen";
import _ from "lodash-es";
import styles from "./claim-child-details-table.module.scss";
import clsx from "clsx";
import { useFormat } from "hooks/format";

type DesktopTableContent = {
  incidentNumber: number;
  date: string | JSX.Element;
  startTime: string;
  endTime: string;
  duration: number | null;
  unpaidTimeInMinutes?: string | number | null;
};

type Header = {
  header: string;
  key: string;
};

type MobileTableContent = {
  upperHeader?: string;
  header?: string;
  lowerHeader?: string;
  footer?: string;
};

type Props = {
  absenceDates: (ClaimDate & { incidentNumber: number })[];
};

const ClaimChildDetailsTable: React.FC<Props> = ({ absenceDates }) => {
  const [page, setPage] = useState(0);
  const [itemsNumber, setItemsNumber] = useState(0);
  const [desktopTableData, setDesktopTableData] = useState<
    DesktopTableContent[][]
  >([[]]);
  const [mobileTableData, setMobileTableData] = useState<MobileTableContent[]>([
    {}
  ]);
  const [headers, setHeaders] = useState<Header[]>([]);
  const t = useTranslation();
  const { formatLocal } = useFormat();
  const isDesktop = useDesktopBreakpoint();

  useEffect(() => {
    absenceDates.sort((a, b) => {
      const aDate = a.startDate ? new Date(a.startDate).getTime() : 0;
      const bDate = b.startDate ? new Date(b.startDate).getTime() : 0;

      if (aDate > bDate) {
        return -1;
      }

      if (bDate > aDate) {
        return 1;
      }

      return 0;
    });

    const timeIcon = renderToString(
      <TimeIcon className={styles["header-lower__time-icon"]} />
    );
    const moonIcon = renderToString(
      <MoonIcon className={styles["header__moon-icon"]} />
    );

    const tableData = absenceDates.map(o => {
      const startTime = o.startDate ? o.startDate.split("T")[1].split(":") : "";
      const startHours = Number(startTime[0]);
      const startMinutes = Number(startTime[1]);
      const startPeriod = startHours >= 12 ? "pm" : "am";
      const formattedStartHours = startHours % 12 || 12;
      const formattedStartTime = formatTime({
        hour: formattedStartHours,
        minute: startMinutes,
        period: startPeriod
      });

      const endTime = o.endDate ? o.endDate.split("T")[1].split(":") : "";
      const endHours = Number(endTime[0]);
      const endMinutes = Number(endTime[1]);
      const endPeriod = endHours >= 12 ? "pm" : "am";
      const formattedEndHours = endHours % 12 || 12;
      const formattedEndTime = formatTime({
        hour: formattedEndHours,
        minute: endMinutes,
        period: endPeriod
      });

      let duration = 0;
      if (o.startDate && o.endDate) {
        const s = new Date(o.startDate).getTime();
        const e = new Date(o.endDate).getTime();
        const hourInMiliSec = 60 * 60 * 1000;

        duration = (e - s) / hourInMiliSec;
      }

      const isNightShift = isTimeAfter(
        {
          hour: formattedStartHours,
          minute: startMinutes,
          period: startPeriod
        },
        {
          hour: formattedEndHours,
          minute: endMinutes,
          period: endPeriod
        }
      );

      return {
        desktop: {
          incidentNumber: o.incidentNumber,
          date: o.startDate ? (
            <div className={styles["date-wrapper"]}>
              <div>
                {formatLocal(new Date(o.startDate), "iiii, MMMM d, yyyy")}
              </div>
              {isNightShift && (
                <MoonIcon
                  className={clsx(
                    styles["header__moon-icon"],
                    styles["header__moon-icon--desktop"]
                  )}
                />
              )}
            </div>
          ) : (
            ""
          ),
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          duration,
          unpaidTimeInMinutes: o.unpaidTimeInMinutes || 0
        },
        mobile: {
          upperHeader: o.startDate
            ? formatLocal(new Date(o.startDate), "iiii")
            : "",
          header: `<div class=${styles["header__wrapper"]}><div><span class=${
            styles["header__date-separator"]
          }>${
            o.startDate
              ? formatLocal(new Date(o.startDate), "MMMM d, yyyy")
              : ""
          }</span><span>${formattedStartTime} to ${formattedEndTime}</span></div>${
            isNightShift ? moonIcon : ""
          }</div>`,
          lowerHeader: `${timeIcon}<span>${duration} hours</span><span class=${
            styles["header-lower__date-separator"]
          }>|</span><span>${o.unpaidTimeInMinutes || 0} minutes unpaid</span>`,
          footer: `${t({ tag: "claims.table.confirmation_number" })}: ${
            o.incidentNumber
          }`
        }
      };
    });

    const desktopTableData = tableData.map(el => el.desktop);
    const mobileTableData = tableData.map(el => el.mobile);

    const headerTranslation: {
      [key: string]: { value: string; align: string };
    } = {
      incidentNumber: {
        value: t({ tag: "claims.table.confirmation_number" }),
        align: "left"
      },
      date: { value: t({ tag: "claims.table.date" }), align: "left" },
      startTime: {
        value: t({ tag: "claims.table.start_time" }),
        align: "left"
      },
      endTime: { value: t({ tag: "claims.table.end_time" }), align: "left" },
      duration: { value: t({ tag: "claims.table.duration" }), align: "right" },
      unpaidTimeInMinutes: {
        value: t({ tag: "claims.table.unpaid_time_in_minutes" }),
        align: "right"
      }
    };

    const keys = Object.keys(desktopTableData[0]);
    const headers: { key: string; header: string; align: string }[] = keys.map(
      key => ({
        key,
        header: headerTranslation[key].value,
        align: headerTranslation[key].align
      })
    );

    setItemsNumber(desktopTableData.length);
    setHeaders(headers);
    setDesktopTableData(_.chunk(desktopTableData, 10));
    setMobileTableData(mobileTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absenceDates]);

  const changePage = (page: number) => {
    setPage(page - 1);
  };

  return (
    <>
      {isDesktop ? (
        <>
          <DesktopTable
            headers={headers}
            data={desktopTableData[page]}
            footer={{
              incidentNumber: t({ tag: "claims.table.footer" }),
              date: null,
              startTime: null,
              endTime: null,
              duration: _.sum(
                _.map(_.flatten(desktopTableData), row => row.duration)
              ),
              unpaidTimeInMinutes: _.sum(
                _.map(
                  _.flatten(desktopTableData),
                  row => row.unpaidTimeInMinutes
                )
              )
            }}
          />
          <div className={styles["pagination-wrapper"]}>
            <Pagination
              allItemsNumber={itemsNumber}
              pagesNumber={desktopTableData.length}
              currentPage={page + 1}
              numberOfRows={desktopTableData[page].length}
              rowsPerPage={10}
              onChangePage={changePage}
            />
          </div>
        </>
      ) : (
        <MobileTable data={mobileTableData} />
      )}
    </>
  );
};

export default ClaimChildDetailsTable;
