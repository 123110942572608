import React, { useState, useEffect } from "react";
import { StandardPage } from "page-templates/standard";
import ClaimChildDetailsTable from "components/claimChildDetailsTable/claim-child-details-table";
import { LoadingSpinner } from "components/loading-spinner";
import { ApiErrorView } from "components/api-error/api-error-view";
import { useApiRequest, useDesktopBreakpoint } from "hooks";
import { useTranslation } from "hooks/translation";
import { getClaimsService } from "features/claims/claims-service";
import { ViewAbsenceClaimAllDates } from "routes";
import { useRouteParams } from "routes/definition";
import { ClaimDesktopHeaderFragment, MobileHeaderFragment } from "../helpers";
import { ClaimDate } from "graphql-types.gen";
import _ from "lodash-es";
import styles from "./absence-claim-all-dates.module.scss";

type TableContent = ClaimDate & { incidentNumber: number };

const AbsenceClaimAllDatesPage: React.FC = () => {
  const params = useRouteParams(ViewAbsenceClaimAllDates);
  const t = useTranslation();
  const [absenceDates, setAbsenceDates] = useState<TableContent[]>([]);

  const caseId = parseInt(params.claimId, 10);

  const { data: absenceClaim, error, isLoading } = useApiRequest(
    async () => getClaimsService().loadAbsenceDetails(caseId),
    [params.claimId]
  );

  const isDesktop = useDesktopBreakpoint();

  useEffect(() => {
    if (absenceClaim) {
      const mappedAbsenceClaim = absenceClaim.absenceDates.map(claim => {
        let incidentNumber = absenceClaim.incidentId;

        if (absenceClaim.childIncidents?.length) {
          absenceClaim.childIncidents.forEach(child => {
            child?.claimDates.forEach(incident => {
              if (_.isEqual(claim, incident) && child?.incidentId) {
                incidentNumber = child.incidentId;
              }
            });
          });
        }

        return {
          incidentNumber,
          ...claim
        };
      });

      setAbsenceDates(mappedAbsenceClaim);
    }
  }, [absenceClaim]);

  return (
    <StandardPage
      getHeaderFlexItems={({ isDesktop }) =>
        isDesktop ? (
          <ClaimDesktopHeaderFragment />
        ) : (
          <MobileHeaderFragment>
            <div className={styles["all-dates__header--mobile"]}>
              {t({
                tag: "claims.table.header",
                vars: {
                  caseId: caseId.toString()
                }
              })}
            </div>
          </MobileHeaderFragment>
        )
      }
      fixedPaneContents={
        isDesktop ? (
          <div className={styles["all-dates__header"]}>
            {t({
              tag: "claims.table.header",
              vars: {
                caseId: caseId.toString()
              }
            })}
          </div>
        ) : (
          <div></div>
        )
      }
      fixedPaneClassName={
        isDesktop ? undefined : styles["all-dates__fixed-pane--mobile"]
      }
    >
      {isLoading || error ? (
        <>{isLoading ? <LoadingSpinner /> : <ApiErrorView />}</>
      ) : (
        absenceClaim && <ClaimChildDetailsTable absenceDates={absenceDates} />
      )}
    </StandardPage>
  );
};

export default AbsenceClaimAllDatesPage;
