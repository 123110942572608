import { LocaleModel } from "graphql-types.gen";
import { SupportedLanguage } from "features/translations/locales";
import { hasValue } from "utils/util";

export type LocaleTextEntry = {
  readonly description: string;
  readonly locale: string;
};

export type LocaleTexts = ReadonlyArray<LocaleTextEntry>;

// This should only be used for development/testing
export const LocaleText = {
  en: (englishText: string): LocaleTextEntry[] => [
    { description: englishText, locale: "EN" }
  ],
  en_fr: (englishText: string): LocaleTextEntry[] => [
    { description: englishText, locale: "EN" },
    { description: `FRENCH: ${englishText}`, locale: "FR" }
  ]
};

export function toLocaleText(
  localeModel: readonly LocaleModel[],
  englishFallback: string | undefined = undefined
): LocaleTexts {
  if (localeModel.length > 0) {
    return localeModel as LocaleTexts;
  } else if (englishFallback !== undefined) {
    return LocaleText.en(englishFallback);
  } else {
    return [];
  }
}

export function textForLang(strings: LocaleTexts, lang: SupportedLanguage) {
  const filteredList = strings.filter(hasValue);
  if (filteredList.length === 0) return "";

  const text = filteredList.find(t => matchLocale(t.locale, lang));
  return (
    (text?.description ||
      filteredList.find(t => !!t.description)?.description) ??
    ""
  );
}

function matchLocale(locale: string, lang: SupportedLanguage) {
  switch (lang) {
    case SupportedLanguage.EnglishCA:
      return ["EN", "en", "en-CA"].includes(locale);
    case SupportedLanguage.EnglishUS:
      return ["EN", "en", "en-US"].includes(locale);
    case SupportedLanguage.FrenchCA:
      return ["FR", "fr", "fr-CA"].includes(locale);
    case SupportedLanguage.Spanish:
      return ["ES", "es"].includes(locale);
  }
}
