import { getApiGraphQL } from "./api-graphql";
import { gql } from "@apollo/client";
import { parse, parseISO } from "date-fns";
import {
  EmployeeHistory,
  EmployeeSchedule,
  CheckEmployeeByNumber,
  EmployeeScheduleDate,
  IEmployeeService,
  PreviousAbsence,
  QuickSearchEmployee,
  SearchCompanies,
  EmployeeData
} from "features/employee/employee-model";
import _ from "lodash-es";
import {
  EmployeeScheduleQuery,
  Maybe,
  MyAbsencesQuery,
  ScheduleDate,
  QuickSearchEmployeeQuery,
  CheckEmployeeByNumberQuery,
  EsEmployeeByEeNumberQuery,
  SearchCompaniesQuery,
  CompaniesCountQuery
} from "graphql-types.gen";
import { hasValue } from "utils/util";

export class ApiEmployeeService implements IEmployeeService {
  getSchedule = async (
    clientCode: string,
    employeeNumber: string,
    from?: Date,
    to?: Date
  ) =>
    new Promise<EmployeeSchedule>((resolve, reject) => {
      getApiGraphQL()
        .query<EmployeeScheduleQuery>({
          query: gql`
            query EmployeeSchedule(
              $clientCode: String!
              $employeeNumber: String!
              $from: DateTime
              $to: DateTime
            ) {
              schedule(
                clientCode: $clientCode
                employeeNumber: $employeeNumber
                start: $from
                end: $to
              ) {
                dates {
                  date
                  hoursWorked
                }
              }
            }
          `,
          variables: {
            clientCode,
            employeeNumber,
            from: from?.toISOString(),
            to: to?.toISOString()
          }
        })
        .then(result => {
          const dates = result.data?.schedule?.dates ?? [];
          resolve({ dates: this.parseDates(dates) });
        })
        .catch(error => {
          reject(error);
        });
    });

  checkEmployeeByNumber = async (clientCode: string, employeeNumber: string) =>
    new Promise<CheckEmployeeByNumber>((resolve, reject) => {
      getApiGraphQL()
        .query<CheckEmployeeByNumberQuery>({
          query: gql`
            query CheckEmployeeByNumber(
              $clientCode: String
              $employeeNumber: String
            ) {
              checkEmployeeByNumber(
                clientCode: $clientCode
                employeeNumber: $employeeNumber
              ) {
                employeeNumber
                firstName
                lastName
                hasAccess
                employeeId
              }
            }
          `,
          variables: {
            clientCode,
            employeeNumber
          }
        })
        .then(result => {
          resolve(result?.data?.checkEmployeeByNumber || null);
        })
        .catch(error => {
          reject(error);
        });
    });

  getEmployeeData = async (clientCode: string, employeeNumber: string) =>
    new Promise<EmployeeData>((resolve, reject) => {
      getApiGraphQL()
        .query<EsEmployeeByEeNumberQuery>({
          query: gql`
            query eSEmployeeByEENumber(
              $clientCode: String!
              $employeeNumber: String
            ) {
              eSEmployeeByEENumber(
                clientCode: $clientCode
                employeeNumber: $employeeNumber
              ) {
                phoneNumber
                altPhoneNumber
                workPhoneNumber
                workAltPhoneNumber
                firstName
                lastName
                homeEmail
                businessEmail
                employeeAddresses {
                  countryCode
                  provinceStateCode
                }
              }
            }
          `,
          variables: {
            clientCode,
            employeeNumber
          }
        })
        .then(result => {
          let phoneNumber = "";

          if (!!result.data?.eSEmployeeByEENumber?.phoneNumber) {
            phoneNumber = result.data?.eSEmployeeByEENumber?.phoneNumber;
          } else if (!!result.data?.eSEmployeeByEENumber?.altPhoneNumber) {
            phoneNumber = result.data?.eSEmployeeByEENumber?.altPhoneNumber;
          } else if (!!result.data?.eSEmployeeByEENumber?.workPhoneNumber) {
            phoneNumber = result.data?.eSEmployeeByEENumber?.workPhoneNumber;
          } else if (!!result.data?.eSEmployeeByEENumber?.workAltPhoneNumber) {
            phoneNumber = result.data?.eSEmployeeByEENumber?.workAltPhoneNumber;
          }

          let email = "";
          if (!!result.data?.eSEmployeeByEENumber?.businessEmail) {
            email = result.data?.eSEmployeeByEENumber?.businessEmail;
          } else if (!!result.data?.eSEmployeeByEENumber?.homeEmail) {
            email = result.data?.eSEmployeeByEENumber?.homeEmail;
          }
          const employeeAddress = result.data?.eSEmployeeByEENumber?.employeeAddresses;
          resolve({
            firstName: result.data?.eSEmployeeByEENumber?.firstName ?? "",
            lastName: result.data?.eSEmployeeByEENumber?.lastName ?? "",
            phoneNumber,
            email,
            employeeState: employeeAddress?.find(x=> x?.provinceStateCode)?.provinceStateCode ?? "",
            employeeCountry: employeeAddress?.find(x=> x?.countryCode)?.countryCode ?? ""
          });
        })
        .catch(error => {
          reject(error);
        });
    });

  searchEmployees = async (searchTerm: string, clientCode: string) =>
    new Promise<QuickSearchEmployee>((resolve, reject) => {
      getApiGraphQL()
        .query<QuickSearchEmployeeQuery>({
          query: gql`
            query QuickSearchEmployee(
              $searchTerm: String!
              $clientCode: String!
            ) {
              quickSearchEmployees(
                searchTerm: $searchTerm
                clientCode: $clientCode
              ) {
                items {
                  document {
                    birthDate
                    clientCode
                    employeeData
                    employeeDropDownLabelDetails
                    employeeId
                    employeeNumber
                    firstName
                    jobTitle
                    lastName
                    organizationId
                    organizationName
                  }
                  highlightData {
                    field
                    highlightData
                  }
                }
              }
            }
          `,
          variables: {
            searchTerm,
            clientCode
          }
        })
        .then(result => {
          if (result.errors) {
            console.error("Error in searchEmployees", result.errors);
          }

          const items = result.data?.quickSearchEmployees?.items ?? [];
          resolve({
            items: items.map(item => ({
              document: { ...item?.document },
              highlightData:
                item?.highlightData && Array.isArray(item?.highlightData)
                  ? [...item.highlightData]
                  : []
            }))
          });
        })
        .catch(error => {
          reject(error);
        });
    });

  searchCompany = async (absenceReportingVersion: string, searchTerm: string) =>
    new Promise<SearchCompanies>((resolve, reject) => {
      getApiGraphQL()
        .query<SearchCompaniesQuery>({
          query: gql`
            query SearchCompanies(
              $absenceReportingVersion: String!
              $searchTerm: String!
            ) {
              searchCompanies(
                absenceReportingVersion: $absenceReportingVersion
                searchTerm: $searchTerm
              ) {
                items {
                  clientCode
                  companyId
                  name
                }
              }
            }
          `,
          variables: {
            absenceReportingVersion,
            searchTerm
          }
        })
        .then(result => {
          if (result.errors) {
            console.error("Error in searchCompanies", result.errors);
          }
          const items = result.data?.searchCompanies?.items ?? [];
          resolve({
            items: items.map(item => ({
              clientCode: item?.clientCode,
              companyId: item?.companyId,
              name: item?.name
            }))
          });
        })
        .catch(error => {
          reject(error);
        });
    });

  getCompaniesCount = async (absenceReportingVersion: string) =>
    new Promise<number>((resolve, reject) => {
      getApiGraphQL()
        .query<CompaniesCountQuery>({
          query: gql`
            query CompaniesCount($absenceReportingVersion: String!) {
              companiesCount(
                absenceReportingVersion: $absenceReportingVersion
                searchTerm: ""
              )
            }
          `,
          variables: {
            absenceReportingVersion
          }
        })
        .then(result => {
          if (result.errors) {
            console.error("Error in companiesCount", result.errors);
          }

          resolve(result.data.companiesCount);
        })
        .catch(error => {
          reject(error);
        });
    });

  getAbsences = async (clientCode?: string, employeeNumber?: string) =>
    new Promise<EmployeeHistory>((resolve, reject) => {
      const parameters = {
        claimStatus: "S",
        employeeNumber: employeeNumber
      };
      getApiGraphQL()
        .query<MyAbsencesQuery>({
          query: gql`
            query MyAbsences(
              $clientCode: String
              $parameters: ClaimSearchRequestInput
            ) {
              searchClaims(
                incidentTypes: [INCIDENT_MYABILITI_AR]
                request: {
                  pageNumber: 1
                  pageSize: 1000
                  parameters: $parameters
                  clientCode: $clientCode
                }
              ) {
                totalRecords
                items {
                  ... on MyAbilitiARClaim {
                    incidentId
                    claimDates {
                      startDate
                      endDate
                      isPartialAbsence
                      scheduledShiftEndTime
                      scheduledShiftStartTime
                    }
                    primaryReason
                    secondaryReason
                  }
                }
              }
            }
          `,
          variables: {
            clientCode,
            parameters
          }
        })
        .then(result => {
          if (result.errors) {
            console.error("Error in getAbsences", result.errors);
          }
          resolve({
            previousAbsences: parseMyAbsences(
              result.data?.searchClaims?.items ?? []
            )
          });
        })
        .catch(error => {
          reject(error);
        });
    });

  private parseDates(
    dates?: readonly Maybe<Pick<ScheduleDate, "date" | "hoursWorked">>[] | null
  ): EmployeeScheduleDate[] {
    if (!dates) return [];

    //We are receiving UTC time from the API but parseISO() was converting to local time and misadjusting dates. Using parse and stripping the time values retains the correct date value.
    return dates.filter(hasValue).map(d => ({
      date: parse(d.date.substr(0, 10), "yyyy-MM-dd", Date.now()),
      hoursWorked: d.hoursWorked
    }));
  }
}

type MyAbsencesType = NonNullable<
  NonNullable<MyAbsencesQuery["searchClaims"]>["items"]
>[number];

function parseMyAbsences(
  absences: ReadonlyArray<MyAbsencesType>
): PreviousAbsence[] {
  return _.flatMap(absences.filter(hasValue), absence => {
    return absence.claimDates.map<PreviousAbsence>(claimDate => ({
      absenceIncidentId: absence.incidentId,
      primaryReason: absence.primaryReason,
      secondaryReason: absence.secondaryReason,
      shiftStartTime: claimDate.startDate
        ? parseISO(claimDate.startDate)
        : undefined,
      shiftEndTime: claimDate.endDate ? parseISO(claimDate.endDate) : undefined,
      isPartialAbsence: !!claimDate.isPartialAbsence,
      startDate: claimDate.startDate
        ? parseISO(claimDate.startDate)
        : undefined,
      endDate: claimDate.endDate ? parseISO(claimDate.endDate) : undefined,
      scheduledShiftStartTime: claimDate.scheduledShiftStartTime
        ? parseISO(claimDate.scheduledShiftStartTime)
        : undefined,
      scheduledShiftEndTime: claimDate.scheduledShiftEndTime
        ? parseISO(claimDate.scheduledShiftEndTime)
        : undefined
    }));
  });
}
