import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "components/buttons";
import { LeftArrowIcon, RightArrowIcon } from "components/icons";
import { Translation } from "components/translation";
import { LeavePolicy } from "features/claims/claims-model";
import { Policy } from "../policy/policy";
import styles from "./policy-summary.module.scss";
import _ from "lodash-es";

type Props = {
  leavePolicies: LeavePolicy[];
};

export const PolicySummary: React.FC<Props> = ({ leavePolicies }) => {
  const chunkRef = useRef<(HTMLDivElement | null)[]>([]);
  const numberOfItems = 3;

  const [chunkIndex, setChunkIndex] = useState<number>(0);

  const policiesChunks: LeavePolicy[][] = _.chunk(leavePolicies, numberOfItems);

  useEffect(() => {
    chunkRef.current[chunkIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest"
    });
  }, [chunkIndex]);

  const goToChunk = (increment: number) => {
    setChunkIndex(chunkIndex + increment);
  };

  return (
    <div className={styles["policy-summary"]}>
      <div className={styles["title"]}>
        <Translation tag={"dashboard.breakdown_by_policy"} />
      </div>

      <div className={styles["content"]}>
        {chunkIndex !== 0 && (
          <div className={styles["arrow-left"]}>
            <IconButton onClick={() => goToChunk(-1)}>
              <LeftArrowIcon className={styles["icon"]}></LeftArrowIcon>
            </IconButton>
          </div>
        )}

        {policiesChunks.length > 0 && (
          <div className={styles["policies-carousel"]}>
            {policiesChunks.map((policiesChunk, chunkIndex) => (
              <div
                key={policiesChunk.map(policy => policy.policyCode).join(",")}
                ref={div => (chunkRef.current[chunkIndex] = div)}
                className={styles["policies-chunk"]}
              >
                {policiesChunk.map(policy => (
                  <Policy key={policy.policyCode} policy={policy}></Policy>
                ))}
              </div>
            ))}
          </div>
        )}

        {(chunkIndex + 1) * numberOfItems < leavePolicies.length && (
          <div className={styles["arrow-right"]}>
            <IconButton onClick={() => goToChunk(1)}>
              <RightArrowIcon className={styles["icon"]}></RightArrowIcon>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};
