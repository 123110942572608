import React from "react";
import { useRouteParams } from "routes/definition";
import { ViewAbsenceClaim } from "routes";
import { Tag } from "components/tag/tag";
import styles from "../styles.module.scss";
import absenceStyles from "./absence-claim.module.scss";
import SummaryLabel from "components/summary-label/summary-label";
import { compareAsc, differenceInHours, differenceInMinutes } from "date-fns";
import { TagForClaimStatus } from "components/tag/tag-for-claim-status";
import { LoadingSpinner } from "components/loading-spinner";
import {
  ClaimDesktopHeaderFragment,
  ClaimMobileHeaderFragment,
  DesktopConfirmationNumberLabel
} from "../helpers";
import { AbsenceClaimData } from "features/claims/claims-model";
import { Translation } from "components/translation";
import { useApiRequest } from "hooks";
import { useTranslation } from "hooks/translation";
import { StandardPage } from "page-templates/standard";
import { getClaimsService } from "features/claims/claims-service";
import { ApiErrorView } from "components/api-error/api-error-view";
import { CalendarIcon, SmallRightArrowIcon, TimeIcon } from "components/icons";
import FramedIcon from "components/icons/framed-icon/framed-icon";
import { Link } from "react-router-dom";
import { TextButton } from "components/buttons";
import * as Routes from "routes";
import { ClientTranslationProps } from "i18n";
import { Maybe, MyAbilitiArClaim } from "graphql-types.gen";
import { useFormat } from "hooks/format";

const AbsenceClaimPage: React.FC = () => {
  const params = useRouteParams(ViewAbsenceClaim);
  const t = useTranslation();

  const caseId = parseInt(params.claimId, 10);

  const { data: absenceClaim, error, isLoading } = useApiRequest(
    async () => getClaimsService().loadAbsenceDetails(caseId),
    [params.claimId]
  );

  return (
    <StandardPage
      getHeaderFlexItems={({ isDesktop }) =>
        isDesktop ? (
          <ClaimDesktopHeaderFragment />
        ) : (
          <ClaimMobileHeaderFragment caseId={params.claimId} />
        )
      }
      fixedPaneContents={
        absenceClaim && (
          <>
            <div>
              <div className={styles["tag-row"]}>
                <Tag tagStyle="blue">
                  <Translation tag={"claims.tag.absence"} />
                </Tag>
                <TagForClaimStatus
                  status={absenceClaim.claimStatus}
                  //locale={absenceClaim.claimStatusLocale}
                />
              </div>
              <h1 className={styles["page-title"]}>
                {absenceClaim.secondaryReasonLocale?.length
                  ? `${t(absenceClaim.primaryReasonLocale)} (${t(
                      absenceClaim.secondaryReasonLocale
                    )})`
                  : t(absenceClaim.primaryReasonLocale)}
              </h1>
            </div>

            {!absenceClaim.childIncidents?.length && (
              <DesktopConfirmationNumberLabel claimId={caseId.toString()} />
            )}
          </>
        )
      }
    >
      {isLoading || error ? (
        <>{isLoading ? <LoadingSpinner /> : <ApiErrorView />}</>
      ) : (
        absenceClaim && <AbsenceClaimContent claim={absenceClaim} />
      )}
    </StandardPage>
  );
};

const AbsenceClaimContent: React.FC<{
  claim: AbsenceClaimData & {
    childIncidents: Maybe<readonly Maybe<MyAbilitiArClaim>[]> | undefined;
  };
}> = ({ claim }) => {
  const t = useTranslation();
  const { formatLocal } = useFormat();

  const childIncidentsRTWDates =
    claim.childIncidents
      ?.map(incident => Date.parse(incident?.returnToWorkDate ?? ""))
      .filter(rtwDt => !isNaN(rtwDt)) ?? [];
  const returnToWorkDate =
    childIncidentsRTWDates.length > 0
      ? new Date(Math.max(...childIncidentsRTWDates))
      : claim.returnToWorkDate;

  const dateTimeStringLength = 23;
  const parseDateWithoutTimezone = (value: string): number =>
    Date.parse(
      value && value.length > dateTimeStringLength
        ? value.substring(0, dateTimeStringLength)
        : value
    );

  const absenceStartDatesInMsSinceEpoch = claim.absenceDates
    .map(absenceDate => parseDateWithoutTimezone(absenceDate.startDate ?? ""))
    .filter(msDate => !isNaN(msDate));
  const absenceEndDatesInMsSinceEpoch = claim.absenceDates
    .map(absenceDate => parseDateWithoutTimezone(absenceDate.endDate ?? ""))
    .filter(msDate => !isNaN(msDate));

  const firstDateOfAbsence = new Date(
    absenceStartDatesInMsSinceEpoch.length > 0
      ? Math.min(...absenceStartDatesInMsSinceEpoch)
      : claim.firstDateOfAbsence.toString()
  );
  const lastDateOfAbsence = new Date(
    absenceEndDatesInMsSinceEpoch.length > 0
      ? Math.max(...absenceEndDatesInMsSinceEpoch)
      : claim.lastDateOfAbsence.toString()
  );
  const isSingleDayAbsence =
    compareAsc(
      new Date(firstDateOfAbsence).setHours(0, 0, 0, 0),
      new Date(lastDateOfAbsence).setHours(0, 0, 0, 0)
    ) === 0;
  const isContinuous = claim.absenceType === "continuous";

  return (
    <div>
      {claim && (
        <>
          <div className={absenceStyles["date-container"]}>
            <div>
              <div className={styles["left-and-right"]}>
                {!isSingleDayAbsence && (
                  <h3 className={styles["mini-header"]}>
                    <Translation
                      tag={"absence_detail.absence_period_with_duration"}
                      vars={{
                        absence_duration: claim.absenceDates.length.toString()
                      }}
                    />
                  </h3>
                )}
                {isSingleDayAbsence && (
                  <h3 className={styles["mini-header"]}>
                    <Translation tag={"absence_detail.absence_period"} />
                  </h3>
                )}
              </div>
              <div className={absenceStyles["absence-period"]}>
                <div className="d-flex">
                  <FramedIcon
                    icon={TimeIcon}
                    className={absenceStyles["icon"]}
                  />
                  {isSingleDayAbsence && (
                    <>
                      <SummaryLabel
                        label={`${formatLocal(firstDateOfAbsence, "eeee")}`}
                        value={formatLocal(firstDateOfAbsence, "MMMM d, yyyy")}
                        subValue={t({
                          tag: "absence_detail.absence_time_period",
                          vars: {
                            absence_time_from: formatLocal(
                              firstDateOfAbsence,
                              "hh:mm a"
                            ),
                            absence_time_to: formatLocal(
                              lastDateOfAbsence,
                              "hh:mm a"
                            )
                          }
                        })}
                        absenceTime={t(
                          getSingelDayAbsenceDuration(
                            firstDateOfAbsence,
                            lastDateOfAbsence
                          )
                        )}
                        unpaidAbsenceTime={
                          claim.absenceDates[0].unpaidTimeInMinutes
                            ? t({
                                tag: "absence_detail.unpaid_minutes",
                                vars: {
                                  minutesCount: claim.absenceDates[0].unpaidTimeInMinutes.toString()
                                }
                              })
                            : ""
                        }
                      />
                    </>
                  )}
                  {!isSingleDayAbsence && (
                    <>
                      <div>
                        <SummaryLabel
                          label={
                            isContinuous
                              ? t({
                                  tag: "absence_detail.continuous_start_label",
                                  vars: {
                                    dayOfWeek: formatLocal(
                                      firstDateOfAbsence,
                                      "eeee"
                                    )
                                  }
                                })
                              : t({
                                  tag:
                                    "absence_detail.non_continuous_start_label",
                                  vars: {
                                    daysCount: claim.absenceDates.length.toString()
                                  }
                                })
                          }
                          value={formatLocal(
                            firstDateOfAbsence,
                            "MMMM d, yyyy"
                          )}
                          subValue={formatLocal(firstDateOfAbsence, "hh:mm a")}
                        />
                      </div>

                      <SmallRightArrowIcon
                        className={absenceStyles["arrow"]}
                      ></SmallRightArrowIcon>

                      <div className="d-flex">
                        <SummaryLabel
                          label={
                            isContinuous
                              ? t({
                                  tag: "absence_detail.continuous_end_label",
                                  vars: {
                                    dayOfWeek: formatLocal(
                                      lastDateOfAbsence,
                                      "eeee"
                                    )
                                  }
                                })
                              : ""
                          }
                          value={formatLocal(lastDateOfAbsence, "MMMM d, yyyy")}
                          subValue={formatLocal(lastDateOfAbsence, "hh:mm a")}
                        />
                      </div>
                    </>
                  )}
                </div>
                {claim.absenceDates?.length > 1 && (
                  <div className={absenceStyles["footer-wrapper"]}>
                    <div className={absenceStyles["all-dates__wrapper"]}>
                      <Link
                        to={Routes.ViewAbsenceClaimAllDates.generate({
                          claimId: claim.claimNumber
                        })}
                      >
                        <TextButton
                          className={absenceStyles["all-dates__button"]}
                        >
                          {t({ tag: "absence_detail.all_dates" })}
                        </TextButton>
                      </Link>
                    </div>
                    {!isContinuous && !isSingleDayAbsence && (
                      <p className={absenceStyles["items-not-shown"]}>
                        <Translation
                          tag={"absence_detail.items_not_shown"}
                          vars={{
                            daysCount: (
                              claim.absenceDates.length - 2
                            ).toString()
                          }}
                        />
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            {returnToWorkDate && (
              <div className={absenceStyles["rtw-date"]}>
                <h3 className={styles["mini-header"]}>
                  <Translation tag={"absence_detail.rtw_date"} />
                </h3>
                <div className="d-flex">
                  <FramedIcon
                    icon={CalendarIcon}
                    className={absenceStyles["icon"]}
                  />
                  <SummaryLabel
                    label={formatLocal(
                      new Date(returnToWorkDate.toString()),
                      "eeee"
                    )}
                    value={formatLocal(
                      new Date(returnToWorkDate.toString()),
                      "MMMM d, yyyy"
                    )}
                  />
                </div>
              </div>
            )}
          </div>

          <div className={absenceStyles["link-cases"]}>
            {(claim.associatedLeaveCaseId || claim.associatedSTDCaseId) && (
              <h3 className={styles["mini-header"]}>
                <Translation tag={"absence_detail.linked_cases"} />
              </h3>
            )}
            {claim.associatedLeaveCaseId && (
              <div className={absenceStyles["fml-associated-case-id"]}>
                <p>
                  <Translation tag={"absence_detail.fml_case_id"} />
                </p>
                <Link
                  className="text-link"
                  to={Routes.ViewFmlClaim.generate({
                    claimId: claim.associatedLeaveCaseId.toString()
                  })}
                >
                  {claim.associatedLeaveCaseId}
                </Link>
              </div>
            )}
            {claim.associatedSTDCaseId && (
              <>
                <p>
                  <Translation tag={"absence_detail.std_case_id"} />
                </p>
                <Link
                  to={Routes.ViewStdClaim.generate({
                    claimId: claim.associatedSTDCaseId.toString()
                  })}
                >
                  {claim.associatedSTDCaseId}
                </Link>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

function getSingelDayAbsenceDuration(
  firstDateOfAbsence: Date,
  lastDateOfAbsence: Date
): ClientTranslationProps {
  const differencInMinutes = differenceInMinutes(
    lastDateOfAbsence,
    firstDateOfAbsence
  );
  const differencInHours = differenceInHours(
    lastDateOfAbsence,
    firstDateOfAbsence
  );

  if (differencInHours === 0) {
    return {
      tag: "absence_detail.absence_minutes",
      vars: {
        minutesCount: differencInMinutes.toString()
      }
    };
  }

  return differencInMinutes % 60 === 0
    ? {
        tag: "absence_detail.absence_hours",
        vars: {
          hoursCount: differencInHours.toString()
        }
      }
    : {
        tag: "absence_detail.absence_hours_and_minutes",
        vars: {
          hoursCount: differencInHours.toString(),
          minutesCount: (differencInMinutes % 60).toString()
        }
      };
}

export default AbsenceClaimPage;
