import React, { useEffect } from "react";
import { useHistory } from "react-router";

/**
 * Reset the scroll position to the top of the page when the location changes.
 */
const ScrollToTop: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, [history]);

  return null;
};

export default ScrollToTop;
