import axios from "axios";
import { AppContext } from "context/app-context";
import { getSessionService } from "../features/session/session-service";

//const instance = axios.create();

export const getAxios = () => {
  const sessionService = getSessionService();
  if (sessionService.isAuthenticated()) {
    const token = sessionService.getAccessToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
  return axios;
  //return instance;
};

export const getRestApiUrl = () => {
  const url = process.env.REACT_APP_ABILITI_API_REST_URL;
  if (!url && !AppContext.isSandbox())
    throw new Error("Abiliti REST Api url is not defined");
  return url;
};
