// IMPORTANT:
// the following lines are polyfills for IE11 and must be the first lines.
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
//
import * as React from "react";
import * as ReactDOM from "react-dom";

import { App } from "app";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { enableES5 } from "immer";
import { AuthProvider } from "auth/auth-provider";
import { WebConfigurationContextProvider } from "context/web-configuration-context";
import { BrowserSessionProvider } from "context/session-context";
import { Tooltip } from "components/tool-tip";
import { getApiGraphQL } from "api/api-graphql";
import ScrollToTop from "components/scroll-to-top/scroll-to-top";

import { getSessionService } from "features/session/session-service";

enableES5();

const client = getApiGraphQL();

getSessionService().resetSessionToken();

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthProvider>
        <ApolloProvider client={client}>
          <BrowserSessionProvider>
            <BrowserRouter>
              <WebConfigurationContextProvider>
                <App />
                <Tooltip />
                <ScrollToTop />
              </WebConfigurationContextProvider>
            </BrowserRouter>
          </BrowserSessionProvider>
        </ApolloProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
