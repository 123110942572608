/* eslint-disable react/jsx-no-literals */
import { useUniqueId } from "hooks";
import { isEmpty } from "lodash-es";
import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useState,
  TextareaHTMLAttributes,
  RefObject,
  FormEvent,
  useCallback,
  createRef
} from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

export const getHeight = (rows: number, el: HTMLTextAreaElement): number => {
  const {
    borderBottomWidth,
    borderTopWidth,
    fontSize,
    lineHeight,
    paddingBottom,
    paddingTop
  } = window.getComputedStyle(el);

  const lh =
    lineHeight === "normal"
      ? parseFloat(fontSize) * 1.2
      : parseFloat(lineHeight);

  const rowHeight =
    rows === 0
      ? 0
      : lh * rows +
        parseFloat(borderBottomWidth) +
        parseFloat(borderTopWidth) +
        parseFloat(paddingBottom) +
        parseFloat(paddingTop);

  const scrollHeight =
    el.scrollHeight +
    parseFloat(borderBottomWidth) +
    parseFloat(borderTopWidth);

  return Math.max(rowHeight, scrollHeight);
};

export const resize = (
  rows: number,
  el: HTMLTextAreaElement | null,
  margin = 0
): void => {
  if (el) {
    el.style.height = "0";
    el.style.overflowY = "hidden";
    el.style.height = `${getHeight(rows, el) + margin}px`;
  }
};

interface TextAreaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "rows"> {
  forwardedRef?: RefObject<HTMLTextAreaElement>;
  rows?: string | number | undefined;
  value?: string;

  id: string;
  name: string;
  labelText: string;
  maxTextLength?: number;
  className?: string;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  readOnly?: boolean;
  testId?: string;
  isInvalid?: boolean;
  autoResize?: boolean;
  autoComplete?: InputHTMLAttributes<HTMLTextAreaElement>["autoComplete"];

  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;

  embeddedButton?: React.FunctionComponent;
}

export const TextArea = forwardRef<HTMLTextAreaElement | null, TextAreaProps>(
  (props, ref) => {
    const rows = props.rows ? parseInt("" + props.rows, 10) : 0;
    const heightMargin = 16;

    const { id, className, helperText } = props;
    const [currentValue, setCurrentValue] = useState<string>("");
    const helperTextId = useUniqueId("help-text-");

    const inputRef = createRef<HTMLTextAreaElement>();
    useEffect(() => {
      inputRef.current?.setCustomValidity(props.isInvalid ? "Invalid" : "");

      if (props.autoResize) {
        resize(rows, inputRef.current, heightMargin);
      }
    });

    // this next line is to unify the inputRef with ref. I'm not sure whether
    // we can rely on using the ref that may have been passed in to the
    // component, hence this hack
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => inputRef.current!);

    let labelClasses = styles["text-field-label"];
    if (!isEmpty(props.value || currentValue)) {
      labelClasses += ` ${styles["text-field-label--shrink"]}`;
    }

    let containerClasses = styles["text-field-container"];
    if (className) {
      containerClasses += ` ${className}`;
    }

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setCurrentValue(e.currentTarget.value);

      if (props.onBlur) {
        props.onBlur(e);
      }
    };

    const handleInput = useCallback(
      (e: FormEvent<HTMLTextAreaElement>) => {
        if (props.autoResize) {
          resize(rows, inputRef.current, heightMargin);
        }
      },
      [props.autoResize, rows, inputRef]
    );

    return (
      <div className={containerClasses}>
        <textarea
          className={clsx(
            styles["text-field-input"],
            styles["text-field-textarea"],
            !props.labelText && styles["text-field-input--no-label"]
          )}
          ref={inputRef}
          id={id}
          onBlur={handleBlur}
          onChange={props.onChange}
          onFocus={props.onFocus}
          name={props.name}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.readOnly}
          value={props.value}
          data-testid={props.testId}
          aria-describedby={helperText && helperTextId}
          aria-invalid={props.isInvalid}
          autoComplete={props.autoComplete}
          onInput={handleInput}
          rows={rows}
          maxLength={props.maxTextLength}
        />

        <label htmlFor={id} className={labelClasses}>
          {props.labelText}
        </label>

        {props.maxLength && (
          <div
            className={clsx(
              styles["text-area-count"],
              !isEmpty(helperText) && styles["text-area-count-under"]
            )}
          >
            {props.value?.length || 0} / {props.maxLength}
          </div>
        )}

        {!isEmpty(helperText) && (
          <div
            id={helperTextId}
            aria-hidden={!helperText}
            data-testid={`${props.testId}-helper-text`}
            className={styles["text-field-helper-text"]}
          >
            {helperText || <>&nbsp;</>}
          </div>
        )}
      </div>
    );
  }
);
