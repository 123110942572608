import React from "react";
import styles from "./dashboard.module.scss";
import {
  useApiRequest,
  useDesktopBreakpoint,
  useReportAbsenceLink
} from "hooks";
import { ContainedButton, IconButton } from "components/buttons";
import { Translation } from "components/translation";
import { getSessionService } from "features/session/session-service";
import { ToDoActivities } from "components/dashboard/to-do-activities/to-do-activities";
import { RecentActivity } from "components/dashboard/recent-activity/recent-activity";
import { ClaimSummary, LeavePolicy } from "features/claims/claims-model";
import { getClaimsService } from "features/claims/claims-service";
import { StandardPage } from "page-templates/standard";
import { MenuToggleFunction } from "components/navigation-wrapper";
import { MenuIcon } from "components/icons";
import { ClientLogo } from "components/clientLogo";
import { useTranslation } from "hooks/translation";
import { ApiErrorView } from "components/api-error/api-error-view";
import { PolicySummary } from "components/dashboard/policy-summary/policy-summary";
import { PolicySummaryMobile } from "components/dashboard/policy-summary-mobile/policy-summary-mobile";
import {
  appInsightLogQuestion,
  appInsightSetUserId
} from "app-insights/appInsights";

export const DashboardPage: React.FunctionComponent = () => {
  const isDesktopView = useDesktopBreakpoint();
  const { canReportAbsence, goToReportAbsence } = useReportAbsenceLink();
  const sessionService = getSessionService();
  const user = sessionService.getUser();
  const t = useTranslation();

  const firstPolicyToShowCode = "fmla";

  if (user?.ee_no) {
    appInsightSetUserId(user?.ee_no);
  }

  //appInsightLogQuestion(`User ${user?.name} landed on dashboard`);

  const { data: claims, error } = useApiRequest<ClaimSummary[]>(
    async () =>
      getClaimsService()
        .listActiveClaims(user?.ee_no || "")
        .then(result => result.claims),
    []
  );

  const { data: inProgressClaims, error: searchClaimsError } = useApiRequest<
    ClaimSummary[]
  >(
    async () =>
      getClaimsService()
        .getIncompleteClaims(user?.ee_no || "", user?.client_code || "")
        .then(result => result),
    []
  );

  const { data: leavePolicies } = useApiRequest<LeavePolicy[]>(async () => {
    if (!!claims?.length) {
      return getClaimsService()
        .getLeavePolicySummary()
        .then(policies =>
          policies
            .filter(policy => policy.remaining != null && policy.remaining >= 0 && policy.eligibility)
            .sort((a, b) =>
              a.policyCode === firstPolicyToShowCode
                ? -1
                : b.policyCode === firstPolicyToShowCode
                ? 1
                : t(a.policyCodeLocale).localeCompare(t(b.policyCodeLocale))
            )
        );
    }
    return [];
  }, [claims]);

  return (
    <StandardPage
      getHeaderFlexItems={ctx =>
        ctx.isDesktop ? undefined : (
          <DashboardMobileHeaderFragment toggleMenu={ctx.toggleMenu} />
        )
      }
    >
      <div className={styles["dashboard-page"]} data-testid={"dashboard"}>
        <div className={styles["welcome-container"]}>
          <div className={styles["welcome-box"]}>
            <h1>
              <Translation tag="dashboard.welcome" />
            </h1>
            <div className={styles["welcome-name"]}>
              {sessionService
                .getUser()
                ?.given_name.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                  return g1.toUpperCase() + g2.toLowerCase();
                })}
            </div>
            <img
              className={styles["welcome-image"]}
              src={
                process.env.REACT_APP_ASSETS_BASE +
                (isDesktopView
                  ? "/images/dashboard/dashboard_illu_desktop.svg"
                  : "/images/dashboard/dashboard_illu_mobile.svg")
              }
            />
          </div>

          {isDesktopView && !!leavePolicies?.length && (
            <div className={styles["policy-summary-box"]}>
              <PolicySummary
                leavePolicies={leavePolicies ?? []}
              ></PolicySummary>
            </div>
          )}
        </div>

        {!isDesktopView && canReportAbsence && (
          <ContainedButton
            className={styles["report-absence"]}
            onClick={() => goToReportAbsence()}
            testId={"report-absence-button"}
          >
            <Translation tag="dashboard.report_absence_button" />
          </ContainedButton>
        )}

        {!isDesktopView && !!leavePolicies?.length && (
          <div className={styles["policy-summary-box-mobile"]}>
            <PolicySummaryMobile
              leavePolicies={leavePolicies ?? []}
            ></PolicySummaryMobile>
          </div>
        )}
        <section>
          <ToDoActivities claims={inProgressClaims || []} />
          {inProgressClaims?.length === 0 && !searchClaimsError && (
            <div className={styles["empty-cases"]}>
              <img
                className={styles["icon"]}
                src={
                  process.env.REACT_APP_ASSETS_BASE +
                  "/images/claims/empty-state.svg"
                }
              ></img>
              <h3 className={styles["head"]}>
                <Translation
                  tag={"claims.no_case.all"}
                  vars={{
                    navSection: t({ tag: "dashboard.recent_activity" })
                  }}
                />
              </h3>
              <h4 className={styles["context"]}>
                <Translation tag={"claims.no_case.start_reporting"} />
              </h4>
            </div>
          )}
          {searchClaimsError && <ApiErrorView />}
        </section>
        <section>
          <RecentActivity claims={claims ?? []} />
          {claims?.length === 0 && !error && (
            <div className={styles["empty-cases"]}>
              <img
                className={styles["icon"]}
                src={
                  process.env.REACT_APP_ASSETS_BASE +
                  "/images/claims/empty-state.svg"
                }
              ></img>
              <h3 className={styles["head"]}>
                <Translation
                  tag={"claims.no_case.all"}
                  vars={{
                    navSection: t({ tag: "dashboard.recent_activity" })
                  }}
                />
              </h3>
              <h4 className={styles["context"]}>
                <Translation tag={"claims.no_case.start_reporting"} />
              </h4>
            </div>
          )}
          {error && <ApiErrorView />}
        </section>
      </div>
    </StandardPage>
  );
};

const DashboardMobileHeaderFragment: React.FC<{
  toggleMenu: MenuToggleFunction;
}> = ({ toggleMenu }) => {
  return (
    <>
      <IconButton
        className={styles["menu-button"]}
        onClick={() => {
          toggleMenu();
        }}
        testId={"nav-menu"}
      >
        <MenuIcon />
      </IconButton>

      <div className={styles["logo"]}>
        <ClientLogo logoStyle="dark" clickable />
      </div>

      {/* Blank space to ensure logo is centered in nav bar */}
      <div className={styles["blank-space"]}></div>
    </>
  );
};
