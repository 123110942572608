import { ARWorkflowState } from "features/report-absence/absence-reporting-state";
import {
  QuestionAnswer,
  WorkflowQuestionTemplate
} from "features/report-absence/absence-reporting-types";
import { continuationQuestionDescription } from "features/report-absence/sections/section-helpers";
import { hasValue } from "utils/util";

export function buildContinuationSection(
  state: ARWorkflowState,
  locale: Locale
): WorkflowQuestionTemplate {
  const incident = state.incident;
  const showContinuationQuestion = incident.linkability.canBeLinked ?? false;

  const description = continuationQuestionDescription(
    state.config?.reasonOfAbsence.answers ?? [],
    incident,
    locale
  );

  const linkability = incident.linkability;
  const answerValue = linkability.canBeLinked
    ? linkability.isLinked === undefined
      ? undefined
      : !linkability.isLinked
    : undefined;

  return {
    id: "continuation",
    type: "BooleanQuestion",
    answer: () => ({
      type: "boolean",
      value: answerValue
    }),
    setAnswer: (answer: QuestionAnswer) => {
      if (answer.type === "boolean" && incident.linkability.canBeLinked) {
        incident.linkability.isLinked = !answer.value;
      }
    },
    promptTag: () => ({ tag: "reportAbsence.continuation.back_to_work" }),
    descriptionTag: description ? () => description : undefined,
    requiresAnswer: () => {
      const isAnswered = answerValue !== undefined;
      return showContinuationQuestion && !isAnswered;
    },
    hidden: () => !showContinuationQuestion,
    validateAnswer: (answer: QuestionAnswer) => {
      if (
        answer.type === "boolean" &&
        answer.value === false &&
        incident.maxReportableDays &&
        incident.linkability.canBeLinked &&
        incident.linkability.totalNoOfDaysReportedExcludingCurrent &&
        incident.absenceDates.length +
          incident.linkability.totalNoOfDaysReportedExcludingCurrent >
          incident.maxReportableDays
      ) {
        return {
          isValid: false,
          message: {
            tag: "reportAbsence.continuation.exceedMaxDays",
            vars: {
              days: "" + incident.maxReportableDays
            }
          }
        };
      }
      return { isValid: true };
    },
    isSubmissionMode: true,
    summary:
      hasValue(answerValue) && hasValue(description)
        ? () => ({
            type: "boolean",
            answer: answerValue,
            label: description
          })
        : undefined
  };
}
