import { enUS, frCA, es, enCA } from "date-fns/locale";

// Correspond to ISO 639-1 language codes + country code
export enum SupportedLanguage {
  EnglishCA = "en-CA",
  EnglishUS = "en-US",
  FrenchCA = "fr-CA",
  Spanish = "es"
}

export const getParentLocaleCode = (langCode: string) => {
  const lang = getLanguageFromCode(langCode);
  return getParentLanguageCode(lang);
};

export const getParentLanguageCode = (lang: SupportedLanguage) => {
  switch (lang) {
    case SupportedLanguage.FrenchCA:
      return "fr";
    case SupportedLanguage.Spanish:
      return "es";
  }
  return "en";
};

export const getLanguageFromCode = (langCode: string): SupportedLanguage => {
  switch (langCode) {
    case "en":
    case "EN":
    case SupportedLanguage.EnglishCA:
      return SupportedLanguage.EnglishCA;

    case SupportedLanguage.EnglishUS:
      return SupportedLanguage.EnglishUS;

    case "fr":
    case "FR":
    case SupportedLanguage.FrenchCA:
      return SupportedLanguage.FrenchCA;

    case SupportedLanguage.Spanish:
      return SupportedLanguage.Spanish;

    default:
      return SupportedLanguage.EnglishCA;
  }
};

export const getLocale = (langCode: string) => {
  const lang = getLanguageFromCode(langCode);
  switch (lang) {
    case SupportedLanguage.EnglishUS:
      return enUS;

    case SupportedLanguage.FrenchCA:
      return frCA;
    case SupportedLanguage.Spanish:
      return es;
  }
  return enCA;
};

export const matchLocales = (code1: string, code2?: string) =>
  code2 && getLanguageFromCode(code1) === getLanguageFromCode(code2);
