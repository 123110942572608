import React, { useCallback, useEffect, useRef, useState } from "react";
import { ClaimSummary } from "features/claims/claims-model";
import styles from "./recent-activity.module.scss";
import { Translation } from "components/translation";
import { Link } from "react-router-dom";
import * as Routes from "routes";
import { ClaimCard } from "components/claim-card/claim-card";
import _ from "lodash-es";
import { hasValue, isIE } from "utils/util";
import clsx from "clsx";
import { useWindowSize } from "hooks";
import { setCardLayout } from "pages/claims/helpers";

type Props = {
  claims: ClaimSummary[];
};

export const RecentActivity: React.FC<Props> = ({ claims }) => {
  const carouselRef = useRef<HTMLUListElement>(null);
  const horseRefs = useRef<(HTMLLIElement | null)[]>([]);
  const [currentHorseIndex, setHorseIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState<number | undefined>(
    undefined
  );

  const nominalCardWidth = 360;
  const cardsPerChunk = Math.max(
    1,
    Math.floor((carouselWidth ?? 0) / nominalCardWidth)
  );
  const claimChunks = _.chunk(claims, cardsPerChunk);

  useEffect(() => {
    const updateCarouselWidth = () => {
      setCarouselWidth(carouselRef.current?.offsetWidth ?? 0);
    };
    const onResize = _.debounce(() => {
      updateCarouselWidth();
    }, 300);

    window.addEventListener("resize", onResize);
    updateCarouselWidth();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (isIE) {
      // IE11 doesn't support IntersectionObserver
      return;
    }

    const callback: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = (horseRefs.current as Element[]).indexOf(entry.target);
          setHorseIndex(index);
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      root: carouselRef.current,
      threshold: 0.6
    });
    horseRefs.current.filter(hasValue).forEach(horse => {
      observer.observe(horse);
    });

    return function cleanup() {
      observer.disconnect();
    };
  }, [claims, cardsPerChunk]);

  const gotoHorse = useCallback(index => {
    horseRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const showIndicator = claims.length > cardsPerChunk;

  const [windowWidth] = useWindowSize();
  const MOBILE_BREAK_POINT = 720;
  const numberOfColumns = claims.length;
  const cardListClass = styles["carousel"];

  useEffect(() => {
    const cardList = document.querySelector<HTMLElement>(`.${cardListClass}`);

    if (cardList) {
      setCardLayout({
        cardList,
        windowWidth,
        numberOfColumns,
        mobileBreakPoint: MOBILE_BREAK_POINT
      });
    }
  });

  return (
    <>
      <div className={styles["heading"]}>
        <h2>
          <Translation tag={"dashboard.recent_activity"} />
        </h2>
        <Link to={Routes.ClaimsHistory.generate({})}>
          <Translation tag={"nav.history"} />
        </Link>
      </div>
      <ul className={cardListClass} ref={carouselRef}>
        {claimChunks.map((chunk, chunkIndex) => (
          <li
            key={chunk.map(c => c.incidentId).join(",")}
            ref={li => (horseRefs.current[chunkIndex] = li)}
            className={styles["horse"]}
          >
            {chunk.map(claim => (
              <ClaimCard
                key={claim.incidentId}
                className={styles["card"]}
                claim={claim}
              />
            ))}
            {/*Insert blanks to ensure all cards are the same size */}
            {_.times(cardsPerChunk - chunk.length, i => (
              <div key={i} className={styles["card"]} />
            ))}
          </li>
        ))}
      </ul>
      {!isIE && showIndicator && (
        <CarouselIndicator
          dotCount={claimChunks.length}
          activeDot={currentHorseIndex}
          onClick={gotoHorse}
        />
      )}
    </>
  );
};

type CarouselIndicatorProps = {
  dotCount: number;
  activeDot: number;
  onClick: (index: number) => void;
};
const CarouselIndicator: React.FC<CarouselIndicatorProps> = ({
  dotCount,
  activeDot,
  onClick
}) => {
  return (
    <div className={styles["indicator"]}>
      {_.range(dotCount).map(i => {
        const isClickable = isDotClickable(i, activeDot, dotCount);
        const isHidden = isDotHidden(i, activeDot, dotCount);
        return (
          <div
            key={i}
            className={clsx(
              styles["dot-wrapper"],
              isClickable && styles["dot-wrapper--clickable"]
            )}
            hidden={isHidden}
            onClick={
              isClickable
                ? () => {
                    onClick(i);
                  }
                : undefined
            }
          >
            <span
              className={clsx(
                styles["dot"],
                activeDot === i && styles["dot--active"]
              )}
            />
          </div>
        );
      })}
    </div>
  );
};

// Rules for dot clickability and appearance:
// 1-5 dots:
//   * All dots use elongated style
//   * All dots are clickable
// 5 or more dots:
//   * The active dot, and those flanking it, use elongated style
//     (there are always three long dots, so first and last are special case)
//   * Only elongated dots are clickable
function isDotClickable(dotIndex: number, activeDot: number, dotCount: number) {
  if (dotCount <= 5) {
    return true;
  } else {
    const threshold = 0 < activeDot && activeDot < dotCount - 1 ? 1 : 2;
    return Math.abs(activeDot - dotIndex) <= threshold;
  }
}

function isDotHidden(dotIndex: number, activeDot: number, dotCount: number) {
  if (dotCount <= 5) {
    return false;
  } else {
    let startMargin = 4;
    let endMargin = 3;

    if (activeDot === 0) {
      endMargin = 6;
    }

    if (activeDot === 1) {
      endMargin = 5;
    }

    if (activeDot === 2) {
      endMargin = 4;
    }

    if (activeDot === dotCount - 1) {
      startMargin = 6;
    }

    if (activeDot === dotCount - 2) {
      startMargin = 5;
    }

    return (
      dotIndex <= activeDot - startMargin || dotIndex >= activeDot + endMargin
    );
  }
}
