import React from "react";
import { AuthConsumer } from "./auth-provider";

export const LogoutCallback = () => (
  <AuthConsumer>
    {({ authContext }) => {
      authContext &&
        authContext.signoutRedirectCallback &&
        authContext.signoutRedirectCallback();
      return <span></span>;
    }}
  </AuthConsumer>
);
