import { isTimeAfter, TimeInterval } from "shared-types/time";
import { addDays, Locale, parseISO } from "date-fns";
import { every, orderBy, some } from "lodash-es";
import { AbsenceType } from "features/report-absence/incident";
import { ClientTranslationProps } from "i18n";
import { ClaimSummary } from "features/claims/claims-model";
import { formatWithLocale } from "hooks/format";

export function formatStartingOnDate(date: Date, locale: Locale): ClientTranslationProps {
  const localOffset = date.getTimezoneOffset() * 60000; // Get the local time zone offset in milliseconds
  const adjustedDate = new Date(date.getTime() + localOffset); // Adjust the date by adding the offset

  return {
    tag: "timePeriods.starting",
    vars: {
      date: formatWithLocale(adjustedDate, "MMM d, yyyy", locale)
    }
  };
}

export function formatAbsenceDates(
  dates: TimeInterval[],
  absenceType: AbsenceType | undefined,
  locale: Locale
): ClientTranslationProps | string {
  const sortedDates = orderBy(dates, ["date"]);

  if (absenceType === "continuous" && sortedDates?.length > 0) {
    const startDate = formatWithLocale(
      parseISO(sortedDates[0].date),
      "MMM d, yyyy",
      locale
    );
    const endDate = formatWithLocale(
      parseISO(sortedDates[sortedDates.length - 1].date),
      "MMM d, yyyy",
      locale
    );
    if (startDate === endDate) {
      return startDate;
    }
    return { tag: "timePeriods.startToEnd", vars: { startDate, endDate } };
  }

  if (sortedDates.length > 2) {
    const startDate = parseISO(sortedDates[0].date);
    const endDate = parseISO(sortedDates[sortedDates.length - 1].date);

    const formattedStartDate = formatWithLocale(
      startDate,
      "MMM d, yyyy",
      locale
    );
    const formattedEndDate = formatWithLocale(endDate, "MMM d, yyyy", locale);

    const uniqueDates = new Set(sortedDates.map(date => date.date));

    return {
      tag: "timePeriods.daysBetween",
      vars: {
        numDays: uniqueDates.size.toString(),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      }
    };
  } else if (sortedDates.length === 2) {
    const firstDate = formatWithLocale(
      parseISO(sortedDates[0].date),
      "MMM d, yyyy",
      locale
    );
    const secondDate = formatWithLocale(
      parseISO(sortedDates[1].date),
      "MMM d, yyyy",
      locale
    );

    if (firstDate === secondDate) {
      return formatWithLocale(
        parseISO(sortedDates[0].date),
        "MMM d, yyyy",
        locale
      );
    }

    return {
      tag: "timePeriods.startAndEnd",
      vars: { startDate: firstDate, endDate: secondDate }
    };
  } else if (sortedDates.length === 1) {
    return formatWithLocale(
      parseISO(sortedDates[0].date),
      "MMM d, yyyy",
      locale
    );
  }

  return "";
}

export function formatClaimDates(claim: ClaimSummary, locale: Locale) {
  switch (claim.claimType) {
    case "fml": {
      return claim.startDate !== undefined
        ? formatStartingOnDate(claim.startDate, locale)
        : "";
    }
    case "std": {
      const date = claim.startDate ?? claim.firstDateOfAbsence;
      return date !== undefined ? formatStartingOnDate(date, locale) : "";
    }
    case "absence": {
      return formatAbsenceDates(claim.absenceDates, claim.absenceType, locale);
    }
    default: {
      return formatAbsenceDates(claim.absenceDates, claim.absenceType, locale);
    }
  }
}

export function containsOvernightShift(dates: TimeInterval[]): boolean {
  return some(dates, date => isTimeAfter(date.startTime, date.endTime));
}

export function getOvernightShiftMessage(
  dates: TimeInterval[],
  absenceType: AbsenceType,
  locale: Locale
): ClientTranslationProps | undefined {
  if (dates.length === 1) {
    return {
      tag: "warnings.overnight_shift",
      vars: {
        date: formatWithLocale(
          addDays(parseISO(dates[0].date), 1),
          "iiii, MMMM d, yyyy",
          locale
        )
      }
    };
  } else if (absenceType === "continuous") {
    const sortedDates = orderBy(dates, ["date"]);

    return {
      tag: "warnings.overnight_shift",
      vars: {
        date: formatWithLocale(
          addDays(parseISO(sortedDates[sortedDates.length - 1].date), 1),
          "iiii, MMMM d, yyyy",
          locale
        )
      }
    };
  } else if (absenceType === "intermittent") {
    if (every(dates, date => isTimeAfter(date.startTime, date.endTime))) {
      return {
        tag: "warnings.intermittent_all_overnight_shift"
      };
    } else if (containsOvernightShift(dates)) {
      return {
        tag: "warnings.intermittent_some_overnight_shift"
      };
    }
  }
}
