import React from "react";
import { Translation } from "components/translation";
import { LeavePolicy } from "features/claims/claims-model";
import { Policy } from "../policy/policy";
import styles from "./policy-summary-mobile.module.scss";

type Props = {
  leavePolicies: LeavePolicy[];
};

export const PolicySummaryMobile: React.FC<Props> = ({ leavePolicies }) => {
  return (
    <div className={styles["policy-summary-mobile"]}>
      <div className={styles["title"]}>
        <Translation tag={"dashboard.breakdown_by_policy"} />
      </div>
      <div className={styles["policies"]}>
        {leavePolicies.map(policy => (
          <Policy key={policy.policyCode} policy={policy}></Policy>
        ))}
      </div>
    </div>
  );
};
