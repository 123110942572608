import React from "react";
import styles from "./tag.module.scss";
import clsx from "clsx";
import { KeyCode, KeyName } from "shared-types/key-events";
import { usePreventOutlineOnClick } from "hooks";

export type TagStyle = "green" | "blue" | "gray" | "red" | "orange" | "purple";

type TagProps = {
  tagStyle: TagStyle;
};

export const Tag: React.FC<TagProps> = props => {
  return (
    <span className={clsx(styles["container"], styles[props.tagStyle])}>
      {props.children}
    </span>
  );
};

type TagButtonProps = {
  selectedStyle: TagStyle;
  onClick: (selected: boolean) => void;
  selected: boolean;
};

export const TagButton: React.FC<TagButtonProps> = props => {
  const sendClick = () => props.onClick(!props.selected);
  const { onMouseDown, onMouseUp, onFocus } = usePreventOutlineOnClick();

  return (
    <span
      role={"button"}
      aria-pressed={props.selected}
      className={clsx(
        styles["container"],
        styles["clickable"],
        props.selected ? styles[props.selectedStyle] : styles["gray"]
      )}
      tabIndex={0}
      onClick={sendClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onFocus={onFocus}
      onKeyDown={event => {
        if (
          event.keyCode === KeyCode.ENTER ||
          event.key === KeyName.ENTER ||
          event.keyCode === KeyCode.SPACE ||
          event.key === KeyName.SPACE
        ) {
          sendClick();
          event.preventDefault();
        }
      }}
    >
      {props.children}
    </span>
  );
};
