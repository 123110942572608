import fetch from "cross-fetch";
import { AppContext } from "../context/app-context";
import { getSessionService } from "../features/session/session-service";
import { IAbilitiUser } from "features/session/session-model";
import {
  getLanguageFromCode,
  SupportedLanguage
} from "features/translations/locales";
import JwtDecode from "jwt-decode";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import fragmentTypes from "graphql-fragment-types.gen";
import { mockApiSchema } from "api/api-graphql-mock";
import { SchemaLink } from "@apollo/client/link/schema";

const getApiUrl = () => {
  const url = process.env.REACT_APP_ABILITI_API_GQL_URL;
  if (!url && !AppContext.isSandbox())
    throw new Error("Abiliti GQL Api url is not defined");
  return url;
};

let _apolloClient: ApolloClient<NormalizedCacheObject>;
export const getApiGraphQL = function () {
  if (!_apolloClient) {
    _apolloClient = createApolloClient(
      () => getSessionService().getAccessToken(),
      AppContext.isSandbox()
    );
  }

  return _apolloClient;
};

export function createApolloClient(
  getAccessToken: () => string | null,
  useMocks = false
) {
  // We could get a little boost from automatic query batching, but it requires HotChocolate to
  // be upgraded to at least v11 (v10 has a bug that requires synchronous IO for batch queries:
  // https://github.com/ChilliCream/hotchocolate/issues/2273).
  // To enable batching, just replace "createHttpLink" with "new BatchHttpLink"
  const serverLink = useMocks
    ? new SchemaLink({ schema: mockApiSchema() })
    : createHttpLink({ uri: getApiUrl(), fetch });

  const authLink = setContext((_, { headers }) => {
    const token = getSessionService().getAccessToken();
    const requestHeaders = {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ""
      }
    };

    if (!AppContext.isSandbox()) {
      let decodedToken: IAbilitiUser | { locale: string } = {
        locale: SupportedLanguage.EnglishUS
      };

      if (token) {
        decodedToken = JwtDecode(token);
      }

      const language = getLanguageFromCode(
        decodedToken.locale || SupportedLanguage.EnglishUS
      );

      requestHeaders.headers["Accept-Language"] = language;
    }

    return requestHeaders;
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    console.error(networkError);
    console.error(graphQLErrors);
  });

  // Remove the __typename attribute from objects being passed in as graphql query variables.
  // __typename is needed for correct fragment handling, but just gets in the way for mutations.
  // https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: unknown, value: unknown) =>
        key === "__typename" ? undefined : value;
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }
    return forward(operation);
  });

  const cache = new InMemoryCache({
    addTypename: true,
    possibleTypes: fragmentTypes.possibleTypes
  });

  return new ApolloClient({
    link: ApolloLink.from([cleanTypeName, errorLink, authLink, serverLink]),
    cache,
    name: "transform-web-client",
    version: "1.0",
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        // watchQuery is only used by the useQuery hook
        fetchPolicy: "network-only",
        errorPolicy: "none"
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "none"
      },
      mutate: {
        errorPolicy: "all"
      }
    }
  });
}
