import React from "react";
import { Translation } from "components/translation";

export const NotFoundPage: React.FunctionComponent = () => {
  return (
    <div>
      <Translation tag="errors.page_not_found" />
    </div>
  );
};
