import {
  QuestionAnswer,
  WorkflowQuestionBase,
  WorkflowQuestionTemplate,
  WorkflowSection
} from "./absence-reporting-types";
import { buildReasonSection as buildReasonSections } from "./sections/reasons";
import { buildDateTimesSections } from "./sections/dates-times";
import { buildRtwSections } from "./sections/rtw";
import { buildConfigSections } from "./sections/config-questions";
import { buildContinuationSection } from "features/report-absence/sections/continuation";
import {
  EmployeeSchedule,
  PreviousAbsence
} from "features/employee/employee-model";
import { getSessionService } from "features/session/session-service";
import { WorkShiftForDate, WorkShift } from "features/report-absence/incident";
import { buildCallCenterSection } from "./sections/call-center";
import { ARWorkflowState } from "./absence-reporting-state";
import { buildConfirmationSection } from "./sections/confirmation";
import { ModifySubmittedAbsencePermission } from "permissions";

export class WorkflowBuilder {
  private readonly state: ARWorkflowState;
  private readonly schedule: EmployeeSchedule;
  private readonly previousAbsences: PreviousAbsence[];

  constructor(
    state: ARWorkflowState,
    schedule: EmployeeSchedule,
    previousAbsences: PreviousAbsence[]
  ) {
    this.state = state;
    this.schedule = schedule;
    this.previousAbsences = previousAbsences;
  }

  private static renderQuestionTemplate(
    qt: WorkflowQuestionTemplate & { shifts: WorkShift[] }
  ): WorkflowSection {
    const base: WorkflowQuestionBase = {
      id: qt.id,
      index: qt.index,
      answer: qt.answer(),
      setAnswer: qt.setAnswer,
      validateAnswer: qt.validateAnswer,
      requiresAnswer: qt.requiresAnswer(),
      isValid: qt.validateAnswer?.(qt.answer())?.isValid !== false,
      config: qt.config,
      choices: qt.choices?.(),
      hidden: qt.hidden?.(),
      absenceReasonInfo: qt.absenceReasonInfo?.(),
      promptTag: qt.promptTag?.(),
      descriptionTag: qt.descriptionTag?.(),
      postSubmit: qt.postSubmit,
      summary: qt.summary?.(),
      hideInSummary: qt.hideInSummary,
      isSubmissionMode: qt.isSubmissionMode,
      getSubmitErrorMsg: qt.getSubmitErrorMsg
    };
    
    switch (qt.type) {
      case "CalendarQuestion":
        return {
          ...base,
          type: "CalendarQuestion",
          schedule: qt.schedule(),
          previousAbsences: qt.previousAbsences()
        };

      case "ShiftQuestion":
        return {
          ...base,
          type: "ShiftQuestion",
          workShiftOptions: qt.shifts || qt.workShiftOptions(),
          addTempShift: qt.addTempShift,
          initialSameShiftToggle: qt.initialSameShiftToggle,
          sameShiftToggleHandler: qt.sameShiftToggleHandler,
          maxShiftLengthThresholdInMinutes: qt.maxShiftLengthThresholdInMinutes
        };

      case "TimeQuestion":
        return {
          ...base,
          type: "TimeQuestion",
          scheduleOverrideWarning: qt.scheduleOverrideWarning(),
          scheduleOverrideMessage: qt.scheduleOverrideMessage(),
          selectedWorkShifts: qt.selectedWorkShifts(),
          previousAbsences: qt.previousAbsences(),
          maxShiftLengthThresholdInMinutes: qt.maxShiftLengthThresholdInMinutes
        };
      default:
        return { ...base, type: qt.type };
    }
  }

  buildQuestions = () => {
    const sessionService = getSessionService();
    const isCICUser = sessionService.isCICUser();
    const canModifySubmittedIncident = sessionService.hasPrivileges(
      ModifySubmittedAbsencePermission
    );
    const shifts = this.state.shifts.map(
      (shift: WorkShiftForDate) => shift.shift
    );

    if (
      this.state.incident.claimStatus === "S" &&
      !canModifySubmittedIncident
    ) {
      this.state.questions = [buildConfirmationSection(this.state)].map(
        item => {
          return WorkflowBuilder.renderQuestionTemplate({
            ...(item as WorkflowQuestionTemplate),
            shifts
          });
        }
      );
    } else if (isCICUser) {
      this.state.questions = this.getCICSectionTemplates().map(item => {
        return WorkflowBuilder.renderQuestionTemplate({
          ...(item as WorkflowQuestionTemplate),
          shifts
        });
      });
    } else {
      this.state.questions = this.getSectionTemplates().map(item => {
        return WorkflowBuilder.renderQuestionTemplate({
          ...(item as WorkflowQuestionTemplate),
          shifts
        });
      });
    }

    this.state.sections = this.state.questions.filter(
      q => q.hidden !== true && (!this.state.submitted || q.postSubmit)
    );

    const i = this.state.sections.findIndex(q => q.requiresAnswer);
    if (i >= 0) this.state.sections = this.state.sections.slice(0, i + 1);

    let index = 0;
    this.state.sections.forEach(q => (q.index = index++));
  };

  getCICSectionTemplates = () => {
    return [
      buildCallCenterSection(this.state),
      ...this.commonSectionTemplates()
    ];
  };

  getSectionTemplates = () => {
    let checkReasonOfAbsence = false;
    if(this.state.config?.reasonOfAbsence?.answers.length === 0){
      checkReasonOfAbsence = true;
    }
    return [
      {
        id: "start",
        type: "ReportStart",
        answer: () => ({ type: "empty" }),
        setAnswer: (answer: QuestionAnswer) => {
          return;
        },
        requiresAnswer: () => checkReasonOfAbsence
      },
      ...this.commonSectionTemplates()
    ];
  };

  commonSectionTemplates = () => {
    return [
      ...buildReasonSections(this.state),
      ...buildDateTimesSections(
        this.state,
        this.schedule,
        this.previousAbsences
      ),
      buildContinuationSection(this.state, this.state.locale),
      ...buildRtwSections(this.state),
      ...buildConfigSections(this.state, this.state.locale),
      {
        id: "summary",
        type: "AbsenceSummary",
        promptTag: () => ({ tag: "reportAbsence.all_for_now" }),
        answer: () => ({ type: "empty" }),
        setAnswer: (answer: unknown) => {
          return;
        },
        requiresAnswer: () => !this.state.submitted,
        isSubmissionMode: true
      },
      buildConfirmationSection(this.state)
    ];
  };
}
