import React from "react";
import styles from "./card.module.scss";
import clsx from "clsx";

type Props = {
  className?: string;
  testId?: string;
};

const Card: React.FC<Props> = props => {
  return (
    <div
      className={clsx(styles["container"], props.className)}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};

export default Card;
