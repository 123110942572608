import * as React from "react";
import { AuthConsumer } from "./auth-provider";

export const Logout = () => (
  <AuthConsumer>
    {({ authContext }) => {
      authContext && authContext.logout && authContext.logout();
      return <span></span>;
    }}
  </AuthConsumer>
);
