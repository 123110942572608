// IE11 detection from https://stackoverflow.com/a/49328524
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isIE = /*@cc_on!@*/ false || !!document.documentMode;

export function hasValue<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export async function delay(ms: number) {
  return new Promise(resolve => {
    if (ms === 0) {
      resolve(0);
    } else {
      setTimeout(resolve, ms);
    }
  });
}

export const createShortcut = (text: string) => {
  if (!text) return "";
  return text
    .replace(/[&]/gi, "-and-")
    .replace(/[^a-z0-9]+/gi, "-")
    .replace(/^-*|-*$/g, "")
    .toLowerCase();
};
