import { useApolloClient } from "@apollo/client";
import { submitLogout } from "api";
import { useSessionContext } from "context/session-context";
import React, { useEffect, useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { useHistory } from "react-router";
import * as Routes from "routes";
import IdleModal from "./idle-modal";

const IDLE_TIMEOUT = parseInt(
  process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || "14"
); // minutes
const MODAL_DURATION = 60; // seconds

const IdleContainer = () => {
  const { clearSession } = useSessionContext();
  const history = useHistory();
  const apolloClient = useApolloClient();

  const setTimeoutRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(MODAL_DURATION);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const logout = async () => {
    await submitLogout();
    // TODO Consolidate logout with LogoutButton
    apolloClient.stop();
    await apolloClient.resetStore();
    clearSession();

    history.push(Routes.Logout.generate({}));
    closeModal();
  };

  useEffect(() => {
    if (!modalIsOpen) {
      setTimeLeft(MODAL_DURATION);
    } else {
      if (timeLeft === 0) {
        void logout();
      }

      setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, modalIsOpen]);

  const idleTimeoutMs = IDLE_TIMEOUT * 60 * 1000;

  return (
    <>
      <IdleTimer
        onIdle={openModal}
        timeout={idleTimeoutMs}
        ref={setTimeoutRef}
      />
      <IdleModal
        isOpen={modalIsOpen}
        timeLeft={timeLeft}
        logout={logout}
        closeModal={closeModal}
      />
    </>
  );
};

export default IdleContainer;
