import { useLanguageContext } from "context/user-language-context";
import { useTranslationContext } from "context/translation-context";
import { ClientTranslationProps } from "i18n";
import { LocaleTexts, textForLang } from "shared-types/api";
import { AppContext } from "context/app-context";
import { TranslationStruct } from "features/translations/translation-model";

/**
 * Get a translation function for the user's currently selected language.
 * The source argument may be either a ClientTranslationProps (tag-based client-side strings)
 * or LocaleText[] (for text from the API).
 */
export function useTranslation(): TranslationFunction {
  const { currentLang } = useLanguageContext();
  const { getLocalizedString } = useTranslationContext();

  return source => {
    if (source === undefined) {
      return "";
    } else if (Array.isArray(source)) {
      return textForLang(source as LocaleTexts, currentLang);
    } else {
      const serverTranslation = AppContext.getTranslations().translate(
        //check for Translation API entry
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        source as any
      );
      if (serverTranslation) return serverTranslation;
      return getLocalizedString(source as ClientTranslationProps, currentLang);
    }
  };
}

type TranslationFunction = (
  source:
    | TranslationStruct
    | ClientTranslationProps
    | LocaleTexts
    | string
    | undefined
) => string;
