import React from "react";
import styles from "./styles.module.scss";
import { ContainedButton, TextButton } from "components/buttons";
import { Translation } from "components/translation";
import { countdownTimerFromSeconds } from "shared-types/time";
import { cloneDeep } from "lodash-es";
import { BaseModal, modalStyle } from "components/base-modal/base-modal";
import { useTranslation } from "hooks/translation";

type ModalProps = {
  isOpen: boolean;
  timeLeft: number;
  logout: () => void;
  closeModal: () => void;
};

const IdleModal = ({ isOpen, timeLeft, logout, closeModal }: ModalProps) => {
  const t = useTranslation();

  const idlePopupStyles = cloneDeep(modalStyle);
  if (idlePopupStyles.content) {
    idlePopupStyles.content.maxWidth = "500px";
  }

  return (
    <BaseModal
      isOpen={isOpen}
      title={t({ tag: "idle_modal.title" })}
      customStyles={idlePopupStyles}
      closeModal={closeModal}
      testId="idle-modal"
    >
      <div>
        <p>
          <Translation tag="idle_modal.description" />
        </p>

        <p className={styles["countdown-timer"]}>
          {countdownTimerFromSeconds(timeLeft)}
        </p>

        <div className={styles["button-row"]}>
          <TextButton onClick={logout} testId="log-out">
            <Translation tag="nav.log_out" />
          </TextButton>

          <ContainedButton onClick={closeModal} testId={"stay-logged-in"}>
            <Translation tag="idle_modal.stay_logged_in" />
          </ContainedButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default IdleModal;
