import { getSessionService } from "features/session/session-service";
import { ConfigAbsenceQuestion } from "../absence-questions";
import { ARWorkflowState } from "../absence-reporting-state";
import {
  QuestionAnswer,
  ValidationResult,
  WorkflowQuestionTemplate
} from "../absence-reporting-types";
import { validatePhoneNumber } from "./section-helpers";

export function buildCallCenterSection(
  state: ARWorkflowState
): WorkflowQuestionTemplate {
  const sessionService = getSessionService();
  const isCICUser = sessionService.isCICUser();

  const name = "call-center-employee-phone-number";
  return {
    config: {
      name,
      questionType: "phone",
      label: [],
      labelLocalizedString: {
        tag: "reportAbsence.call_center.prompt",
        vars: {
          employee_full_name:
            state.employeeFirstName && state.employeeLastName
              ? `${state.employeeFirstName} ${state.employeeLastName}`
              : ""
        }
      }
    } as ConfigAbsenceQuestion,
    id: name,
    type: "CallCenterEmployeePhoneNumber",
    answer: () =>
      ({
        type: "string",
        value: state.answers.get(name)?.answer
      } as QuestionAnswer),
    setAnswer: (answer: QuestionAnswer) => {
      if (answer.type === "string") {        
        state.answers.set("CQ_BEST_PHONE_NUMBER_TO_BE_REACHED", {
          answer: answer.value || "",
          answered: true
        });

        state.answers.set(name, {
          answer: answer.value || "",
          answered: true
        });

        state.callCenterEmployeePhoneNumber = answer.value;
      }
    },
    requiresAnswer: () => false,
    hidden: () => isCICUser === false,
    validateAnswer: (answer: QuestionAnswer): ValidationResult => {
      if (answer.type === "string" && !answer.value) {
        return { isValid: true };
      }
      if (answer.type === "string" && answer.value !== undefined) {
        return validatePhoneNumber(answer.value)
          ? { isValid: true }
          : {
              isValid: false,
              message: { tag: "reportAbsence.validation.phone" }
            };
      } else {
        return { isValid: false };
      }
    },
    hideInSummary: true,
    isSubmissionMode: false
  };
}
