import {
  useApiRequest,
  useDesktopBreakpoint,
  useWindowScrollTop,
  useWindowSize
} from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { ClaimSummary } from "features/claims/claims-model";
import { getClaimsService } from "features/claims/claims-service";
import { StandardPage } from "page-templates/standard";
import {
  ClaimDesktopHeaderFragment,
  ClaimFilterUrlParamName,
  ClaimTypeFilter,
  FilterType,
  getFilterLabel,
  getFilterTypeFromString,
  MobileHeaderFragment,
  setCardLayout
} from "pages/claims/helpers";
import { Translation } from "components/translation";
import { getSessionService } from "features/session/session-service";
import styles from "pages/claims/claims-list.module.scss";
import { TagButton } from "components/tag/tag";
import { LoadingSpinner } from "components/loading-spinner";
import { ClaimCard } from "components/claim-card/claim-card";
import { useTranslation } from "hooks/translation";
import { ApiErrorView } from "components/api-error/api-error-view";
import { useHistory, useLocation } from "react-router";

const PAGE_SIZE = 20;

export const ClaimsHistoryPage: React.FC = () => {
  const isDesktop = useDesktopBreakpoint();

  const [activeFilter, setActiveFilter] = useState<FilterType>("all");
  const [claims, setClaims] = useState<ClaimSummary[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const totalClaims = useRef<number>();
  const hasMore =
    totalClaims.current === undefined
      ? "unknown"
      : totalClaims.current > claims.length;

  const t = useTranslation();
  const sessionService = getSessionService();

  const history = useHistory();

  const queryClaimType =
    new URLSearchParams(useLocation().search).get(
      ClaimFilterUrlParamName.ClaimType
    ) || "all";

  useEffect(() => {
    const filterType = getFilterTypeFromString(queryClaimType);
    setActiveFilter(filterType || "all");
  }, [queryClaimType]);

  const { data: filters = [] } = useApiRequest<ClaimTypeFilter[]>(
    async () =>
      getClaimsService()
        .loadClaimTypeFilters(sessionService.getUser()?.client_code || "")
        .then(result => result),
    []
  );

  // Load the next page and append it to the list of claims
  const { isLoading, error } = useApiRequest<ClaimSummary[]>(
    async op => {
      const result = await getClaimsService().listHistoricalClaims(
        {
          claimType: activeFilter === "all" ? undefined : activeFilter,
          pageSize: PAGE_SIZE,
          page: nextPage
        },
        sessionService.getUser()?.ee_no || ""
      );
      if (!op.cancelled) {
        setClaims(c => c.concat(result.claims));
        totalClaims.current = result.total;
      }
      return result.claims;
    },
    [activeFilter, nextPage]
  );

  // Reset everything when the active filter changes
  useEffect(() => {
    totalClaims.current = undefined;
    setClaims([]);
    setNextPage(1);
  }, [activeFilter]);

  // Trigger the next page request when scrolled near the bottom of the page
  const scrollTop = useWindowScrollTop(200, { leading: false, trailing: true });
  useEffect(() => {
    if (hasMore === false) {
      return;
    }

    const BUFFER = 200;
    const isAtBottom =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - BUFFER;
    if (isAtBottom) {
      const newNextPage = Math.floor(claims.length / PAGE_SIZE) + 1;
      setNextPage(newNextPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);

  const [windowWidth] = useWindowSize();
  const MOBILE_BREAK_POINT = 1024;
  const CARD_WIDTH = 352;
  const GRID_GAP = 32;
  const cardListClass = styles["card-list"];

  useEffect(() => {
    const cardList = document.querySelector<HTMLElement>(`.${cardListClass}`);

    if (cardList) {
      setCardLayout({
        cardList,
        windowWidth,
        mobileBreakPoint: MOBILE_BREAK_POINT,
        cardWidth: CARD_WIDTH,
        gridGap: GRID_GAP
      });
    }
  });

  const updateFilter = (filter: FilterType) => {
    history.push({
      search: `?${ClaimFilterUrlParamName.ClaimType}=${filter}`
    });
  };

  return (
    <StandardPage
      getHeaderFlexItems={isDesktop =>
        isDesktop ? (
          <ClaimDesktopHeaderFragment />
        ) : (
          <MobileHeaderFragment>
            <Translation tag={"nav.history"} />
          </MobileHeaderFragment>
        )
      }
      fixedPaneContents={
        <>
          {isDesktop && (
            <h1 className={styles["page-title"]}>
              <Translation tag={"nav.history"} />
            </h1>
          )}
          <span>
            <Translation tag={"claims.see_discharged_cases"} />
          </span>
          <div className={styles["tag-filter-row"]}>
            {filters.map(f => (
              <TagButton
                key={f.filter}
                selectedStyle={"blue"}
                selected={f.filter === activeFilter}
                onClick={() => updateFilter(f.filter)}
              >
                <Translation {...f.label} />
              </TagButton>
            ))}
          </div>
        </>
      }
    >
      {error ? (
        <ApiErrorView />
      ) : (
        <>
          {totalClaims.current && totalClaims.current > 0 ? (
            <div className={cardListClass}>
              {claims.map(claim => (
                <ClaimCard
                  key={`${claim.claimType}-${claim.incidentId}`}
                  className={styles["claim-card"]}
                  claim={claim}
                />
              ))}
            </div>
          ) : (
            <>
              {activeFilter === "all" && (
                <div className={styles["empty-cases"]}>
                  <img
                    className={styles["icon"]}
                    src={
                      process.env.REACT_APP_ASSETS_BASE +
                      "/images/claims/folder-empty-state.svg"
                    }
                  />
                  <h3 className={styles["head"]}>
                    <Translation
                      tag={"claims.no_case.all"}
                      vars={{
                        navSection: t({ tag: "nav.history" })
                      }}
                    />
                  </h3>
                  <h4 className={styles["context"]}>
                    <Translation tag={"claims.no_case.cases_will_appear"} />
                  </h4>
                </div>
              )}
              {activeFilter !== "all" && (
                <div className={styles["empty-cases"]}>
                  <img
                    className={styles["icon"]}
                    src={
                      process.env.REACT_APP_ASSETS_BASE +
                      "/images/claims/empty-state.svg"
                    }
                  />
                  <h3 className={styles["head"]}>
                    <Translation
                      strong
                      tag={"claims.no_case.filtered"}
                      vars={{ filter: t(getFilterLabel(activeFilter)) }}
                      html={true}
                    />
                  </h3>
                  <h4 className={styles["context"]}>
                    <Translation
                      strong
                      tag={"claims.no_case.try_another_filter"}
                      vars={{
                        filter: t(getFilterLabel(activeFilter)),
                        navSection: t({ tag: "dashboard.recent_activity" })
                      }}
                      html={true}
                    />
                  </h4>
                </div>
              )}
            </>
          )}
        </>
      )}
      {/* Allow the spinner to take up space even if it's not visible, so it has a */}
      {/* better chance to be seen if the user scrolls to the bottom quickly */}
      {hasMore !== false && (
        <LoadingSpinner
          className={isLoading ? styles["visible"] : styles["invisible"]}
        />
      )}
    </StandardPage>
  );
};
