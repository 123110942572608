import { TranslationTable } from "features/translations/translation-model";
import { getRestApiUrl, getAxios } from "./api-rest";

export const loadTranslations = async (
  lang: string
): Promise<TranslationTable> => {
  try {
    const url =
      getRestApiUrl() +
      "/translation/v1/Translations?application=AbilitiAbsence&locale=" +
      lang;
    const response = await getAxios().get(url);
    return new Map(Object.entries(response.data));
  } catch (e) {
    console.error(e);
    return new Map();
  }
};
