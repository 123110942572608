import { loadTranslations } from "api/api-translation";
import { AppContext } from "context/app-context";
import { getParentLocaleCode, SupportedLanguage } from "./locales";
import { TranslationSet, TranslationTable } from "./translation-model";

export const initTranslations = async (lang: SupportedLanguage) => {
  const code = getParentLocaleCode(lang as string);
  const table = await getTranslationSource().load(code);
  const t = new TranslationSet(lang, table);
  AppContext.setTranslations(t);
};

export interface ITranslationSource {
  load: (lang: string) => Promise<Map<string, string>>;
}

export const getTranslationSource = (): ITranslationSource => {
  if (AppContext.isSandbox()) {
    return new MockTranslationSource();
  }
  return new ApiTranslationSource();
};

export class MockTranslationSource implements ITranslationSource {
  dictionary = { text: "text-FR" };

  load = async (lang: string) => {
    return Promise.resolve(
      new Map(Object.entries(this.dictionary)) as TranslationTable
    );
  };
}

export class ApiTranslationSource implements ITranslationSource {
  load = async (lang: string) => {
    return loadTranslations(lang);
  };
}
