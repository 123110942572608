import { AppContext } from "context/app-context";
import { ApiAbsenceReportingService } from "../../api/api-absence-reporting";
import { MockAbsenceReportingService } from "./absence-reporting-service-mock";
import { IAbsenceReportingService } from "./absence-reporting-types";

export const getAbsenceReportingService = (): IAbsenceReportingService => {
  if (AppContext.isSandbox()) {
    return MockAbsenceReportingService.singletonInstance();
  }
  return new ApiAbsenceReportingService();
};
