import { QuestionAnswer, ValidationResult } from "../absence-reporting-types";
import {
  AbsenceIncident,
  AbsenceReasonAnswerInfo,
  AbsenceShift
} from "../incident";
import { isBefore, isSameDay, parseISO, isValid, parse } from "date-fns";
import { first, orderBy } from "lodash-es";
import { ClientTranslationProps } from "i18n";
import { formatWithLocale } from "hooks/format";

export const validateName = (name: string) => {
  const regName = /^[ a-zA-Z\-']+$/;

  return regName.test(name);
};

export function validateRTWAnswer(
  answer: QuestionAnswer,
  absenceDates: AbsenceShift[],
  isMandatory: boolean
): ValidationResult {
  if (answer.type === "rtw") {
    const lastAbsenceDate = first(
      orderBy(absenceDates, "shiftStartTime", "desc")
    );

    const determineDateValidity = (rtwDate: Date): boolean => {
      if (lastAbsenceDate) {
        return lastAbsenceDate.isPartialAbsence
          ? isSameDay(lastAbsenceDate.shiftStartTime, rtwDate) ||
              isBefore(lastAbsenceDate.shiftStartTime, rtwDate)
          : isBefore(lastAbsenceDate.shiftStartTime, rtwDate);
      }
      return true;
    };

    return isMandatory
      ? {
          isValid:
            !!answer.value &&
            !!answer.value.returnToWorkDate &&
            determineDateValidity(answer.value.returnToWorkDate)
        }
      : {
          isValid:
            answer.value === undefined ||
            answer.value.returnToWorkDate === undefined ||
            determineDateValidity(answer.value.returnToWorkDate)
        };
  }
  return { isValid: true };
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  return phoneNumber.replace(/[^0-9]/g, "").length === 10;
}

export function formatPhoneNumber(completeTenDigits: string): string {
  return (
    "(" +
    completeTenDigits.substr(0, 3) +
    ") " +
    completeTenDigits.substr(3, 3) +
    "-" +
    completeTenDigits.substr(6, 4)
  );
}

export function validateEmail(email: string): boolean {
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^([a-zA-Z0-9_\-\.\+]+)@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
  );
  return pattern.test(email);
}

export function continuationQuestionDescription(
  absenceReasons: AbsenceReasonAnswerInfo[],
  incident: Pick<
    AbsenceIncident,
    "primaryReason" | "secondaryReason" | "linkability"
  >,
  locale: Locale
): ClientTranslationProps | undefined {
  const primaryReasonData = absenceReasons.find(
    a => a.answerId === incident.primaryReason
  );
  const primaryReasonText = primaryReasonData?.locales;
  const secondaryReasonText =
    incident.secondaryReason &&
    primaryReasonData?.subAnswers?.find(
      secondary => secondary.answerId === incident.secondaryReason
    )?.locales;

  const date =
    incident.linkability.canBeLinked && incident.linkability.latestDate;
  const dateText = date && formatWithLocale(date, "MMMM d, yyyy", locale);

  if (primaryReasonText && secondaryReasonText && dateText) {
    return {
      tag: "reportAbsence.continuation.description2",
      vars: {
        primaryReason: primaryReasonText,
        secondaryReason: secondaryReasonText,
        date: dateText
      }
    };
  } else if (primaryReasonText && dateText) {
    return {
      tag: "reportAbsence.continuation.description1",
      vars: {
        primaryReason: primaryReasonText,
        date: dateText
      }
    };
  } else {
    return undefined;
  }
}

export function formatDateTimeAnswer(answer: string, locale: Locale): string {
  const parsedISO = parseISO(answer);
  if (isValid(parsedISO)) {
    return formatWithLocale(parsedISO, "MMM d, yyyy", locale);
  }
  const parsedDate = parse(answer, "M/d/yyyy hh:mm:ssa", new Date());
  if (isValid(parsedDate)) {
    return formatWithLocale(parsedDate, "MMM d, yyyy", locale);
  }
  return "";
}
