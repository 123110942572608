import { useApolloClient } from "@apollo/client";
import { submitLogout } from "api";
import { useSessionContext } from "context/session-context";
import React from "react";
import { useHistory } from "react-router";
import * as Routes from "routes";
import LogoutModal from "./logout-modal";
import { useLogoutModalContext } from "context/logout-modal-context";

const LogoutContainer = () => {
  const { clearSession } = useSessionContext();
  const { isOpen, setIsOpen } = useLogoutModalContext();
  const history = useHistory();
  const apolloClient = useApolloClient();

  const closeModal = () => {
    setIsOpen(false);
  };

  const logout = async () => {
    await submitLogout();
    apolloClient.stop();
    await apolloClient.resetStore();
    clearSession();

    history.push(Routes.Logout.generate({}));
    closeModal();
  };

  return (
    <LogoutModal isOpen={isOpen} logout={logout} closeModal={closeModal} />
  );
};

export default LogoutContainer;
