import React from "react";
import styles from "./api-error-view.module.scss";
import { Translation } from "components/translation";
import clsx from "clsx";

type Props = {
  className?: string;
};

export const ApiErrorView: React.FC<Props> = props => {
  const computedClass = () =>
    props.className
      ? clsx(styles["api-error-cases"], styles[props.className])
      : clsx(styles["api-error-cases"]);

  return (
    <div className={computedClass()}>
      <img
        className={styles["icon"]}
        src={
          process.env.REACT_APP_ASSETS_BASE +
          "/images/error/illu-bad-request.svg"
        }
      ></img>
      <h3 className={styles["head"]} data-testid="errorTitle">
        <Translation tag={"global.apiError.title"} />
      </h3>
      <h4 className={styles["context"]} data-testid="errorMsg">
        <Translation tag={"global.apiError.msg"} />
      </h4>
    </div>
  );
};
