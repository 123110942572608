import {
  //add,
  addDays,
  addMinutes,
  format,
  getHours,
  getMinutes,
  parseISO
} from "date-fns";

export type TimePeriod = "am" | "pm";
// 12-hour time
export interface Time {
  hour: number;
  minute: number;
  period: TimePeriod;
}

// 24-hour time
export interface Time24 {
  hour: number;
  minute: number;
}

export type TimeInterval = {
  date: string;
  startTime: Time;
  endTime: Time;
};

/**
 * Parses a string into a Time object, replacing invalid values with defaults.
 * @param timeStr string value representing the time to parse
 * @param defaults default values if the string contains invalid data
 */
export function parseTime(timeStr: string, defaults: Time): Time {
  const periodMatchAM = timeStr.match(/(a|am|a.m.)$/i);
  const periodMatchPM = timeStr.match(/(p|pm|p.m.)$/i);
  
  return periodMatchAM || periodMatchPM
    ? parseTime12(timeStr, defaults)
    : parseTime24(timeStr, defaults);
}

function parseTime12(timeStr: string, defaults: Time): Time {
  const hourMatch = timeStr.match(/^[0-9]{1,2}/);
  const minuteMatch = timeStr.match(/:([0-9]{1,2})/);
  const periodMatchAM = timeStr.match(/(a|am|a.m.)$/i);
  const periodMatchPM = timeStr.match(/(p|pm|p.m.)$/i);

  const hour = hourMatch ? parseInt(hourMatch[0], 10) : 0;
  const minute = minuteMatch ? parseInt(minuteMatch[1].padEnd(2, "0"), 10) : 0;
  const period = periodMatchAM ? "am" : periodMatchPM ? "pm" : defaults.period;

  return {
    hour: 1 <= hour && hour <= 12 ? hour : defaults.hour,
    minute: 0 <= minute && minute <= 59 ? minute : defaults.minute,
    period
  };
}

function parseTime24(timeStr: string, defaults: Time): Time {
  const hourMatch = timeStr.match(/^[0-9]{1,2}/);
  const minuteMatch = timeStr.match(/:([0-9]{1,2})/);

  let hour = hourMatch ? parseInt(hourMatch[0], 10) : 0;
  const minute = minuteMatch ? parseInt(minuteMatch[1].padEnd(2, "0"), 10) : 0;
  const period = hour >= 12 ? "pm" : "am";

  if (hour === 0) {
    hour = 12;
  } else if (12 < hour && hour < 24) {
    hour = hour - 12;
  } else if (hour >= 24) {
    hour = defaults.hour;
  }

  return {
    hour,
    minute: 0 <= minute && minute <= 59 ? minute : defaults.minute,
    period
  };
}

function makeDate(date: Date, time: Time24): Date {
  const d = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.hour,
    time.minute
  );
  return d;
}

export function toDateInterval(interval: TimeInterval) {
  const date = parseISO(interval.date);
  const startTime = to24HourTime(interval.startTime);
  const endTime = to24HourTime(interval.endTime); 
  const isNextDay = isTimeAfter(interval.startTime, interval.endTime) || areTimesEqual(interval.startTime, interval.endTime);
  
  return {
    startDate: makeDate(date, startTime),
    endDate: addDays(makeDate(date, endTime), isNextDay ? 1 : 0)
    // startDate: add(date, {
    //   hours: startTime.hour,
    //   minutes: startTime.minute
    // }),
    // endDate: addDays(
    //   add(date, {
    //     hours: endTime.hour,
    //     minutes: endTime.minute
    //   }),
    //   isNextDay ? 1 : 0
    // )
  };
}

export const extractTime = (date: Date): Time => {
  const hours = getHours(date);
  return {
    hour: hours % 12 || 12,
    minute: getMinutes(date),
    period: hours < 12 ? "am" : "pm"
  };
};

export const toTimeInterval = (from: Date, to: Date): TimeInterval => {
  return {
    date: format(from, "yyyy-MM-dd"),
    startTime: extractTime(from),
    endTime: extractTime(to)
  };
};

export const createTimeInterval = (
  date: Date,
  startTime: Time,
  endTime: Time
): TimeInterval => {
  return {
    date: format(date, "yyyy-MM-dd"),
    startTime,
    endTime
  };
};

export const formatTimeInterval = (timeInterval: TimeInterval): string => {
  return `${formatTime(timeInterval.startTime)} to ${formatTime(
    timeInterval.endTime
  )}`;
};

export const monthAndDay = (date: Date): string =>
  `${date.getMonth() + 1}-${date.getDate()}`;

export function formatTime(time: Time): string {
  const hour = time.hour.toString();
  const minute = time.minute.toString().padStart(2, "0");
  const period = time.period === "am" ? "AM" : "PM";

  return `${hour}:${minute} ${period}`;
}

/**
 * Round a valid time to the nearest minute interval.
 */
export function clampTime(time: Time, minuteInterval?: number) {
  if (minuteInterval) {
    const roundedMinute =
      Math.round(time.minute / minuteInterval) * minuteInterval;
    return { ...time, minute: roundedMinute };
  } else {
    return time;
  }
}

export function normalizeTime(
  timeStr: string,
  defaults: Time,
  minuteInterval?: number
): string {
  return formatTime(clampTime(parseTime(timeStr, defaults), minuteInterval));
}

export function countdownTimerFromSeconds(timeLeft: number): string {
  if (timeLeft < 0) return "00:00";

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function minutesSinceMidnight(time: Time) {
  return (
    (time.hour % 12) * 60 + time.minute + (time.period === "pm" ? 12 * 60 : 0)
  );
}

export function minutesToMidnight(time: Time) {
  return (
    1440 - minutesSinceMidnight(time)
  );
}

export function to24HourTime(time: Time): Time24 {
  const totalMinutes = minutesSinceMidnight(time);
  return {
    hour: Math.floor(totalMinutes / 60),
    minute: totalMinutes % 60
  };
}

export function minutesToHours(totalMinutes: number): string {
  const hour = Math.floor(totalMinutes / 60);
  const min = totalMinutes % 60;
  if (min < 10) {
    return hour.toString() + ":0" + min.toString(); 
  }
  return hour.toString() + ":" + min.toString();  
}

export function isTimeBefore(time: Time, timeToCompare: Time) {
  return minutesSinceMidnight(time) < minutesSinceMidnight(timeToCompare);
}

export function isTimeAfter(time: Time, timeToCompare: Time) {
  return isTimeBefore(timeToCompare, time);
}

export function areTimesEqual(time1: Time, time2: Time) {
  return (
    time1.period === time2.period &&
    time1.hour === time2.hour &&
    time1.minute === time2.minute
  );
}


export function valid24Hours(maxShiftLengthThresholdInMinutes: number, time1: Time, time2: Time) {      
  if (maxShiftLengthThresholdInMinutes && maxShiftLengthThresholdInMinutes > 0 && areTimesEqual(time1, time2))
  {
    return (true);
  } 
  return (false);  
}

export function ValidMaxShiftTime(maxShiftLengthThresholdInMinutes: number, time1: Time, time2: Time) {    

  //overnight scenario
  if (isTimeAfter(time1, time2)) {    
    const absenceMinutes = minutesToMidnight(time1) + minutesSinceMidnight(time2);        
    if (maxShiftLengthThresholdInMinutes && maxShiftLengthThresholdInMinutes > 0 && absenceMinutes > maxShiftLengthThresholdInMinutes) {
      return (false);
    }
  }

  //same day
  if (isTimeBefore(time1, time2)) {
    const absenceMinutes = minutesSinceMidnight(time2) - minutesSinceMidnight(time1);         
    if (maxShiftLengthThresholdInMinutes && maxShiftLengthThresholdInMinutes > 0 && absenceMinutes > maxShiftLengthThresholdInMinutes) {
      return (false);
    }
  }

  //24 hours shift for maxShiftLengthThresholdInMinutes < 24hrs
  if (areTimesEqual(time1, time2)) {        
    if (maxShiftLengthThresholdInMinutes && maxShiftLengthThresholdInMinutes > 0 && maxShiftLengthThresholdInMinutes !== 1440 && areTimesEqual(time1, time2)) {
      return (false);
    }
  }


  return (true);
}



export function makeTime(
  hour: number,
  minute: number,
  period: "am" | "pm"
): Time {
  return { hour, minute, period };
}

export function toLocalTime(date: Date) {
  return addMinutes(date, date.getTimezoneOffset());
}
