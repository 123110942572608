import {
  AbsenceIncident,
  AbsenceIncidentLinkability,
  AbsenceShift
} from "features/report-absence/incident";
import {
  addBusinessDays,
  formatISO,
  isAfter,
  isBefore,
  max,
  startOfDay
} from "date-fns";
import { isValidDate } from "shared-types/date";
import _ from "lodash-es";

export function suggestNextShiftDate(
  absenceDates: Pick<AbsenceShift, "shiftStartTime" | "isPartialAbsence">[]
): Date | undefined {
  const nextBusinessDay = addBusinessDays(
    max(absenceDates.map(date => date.shiftStartTime)),
    1
  );

  return isValidDate(nextBusinessDay) ? startOfDay(nextBusinessDay) : undefined;
}

export function determineIsPartialDate(date: AbsenceShift): boolean {
  if (date.scheduledShiftStartTime && date.scheduledShiftEndTime) {
    return (
      isAfter(date.shiftStartTime, date.scheduledShiftStartTime) ||
      isBefore(date.shiftEndTime, date.scheduledShiftEndTime)
    );
  }

  return false;
}

export function shouldAskIfCanBeLinked(incident: AbsenceIncident) {
  const isLinkabilityKnown = incident.linkability.canBeLinked !== undefined;
  const hasSufficientDataToAsk =
    incident.primaryReason > "" && incident.absenceDates.length > 0;

  if (isLinkabilityKnown) {
    const cacheMatches = _.isEqual(
      incident.linkability.cache,
      linkabilityCache(incident)
    );
    return !cacheMatches;
  } else {
    return hasSufficientDataToAsk;
  }
}

export function linkabilityCache(incident: AbsenceIncident) {
  // These are the things that affect whether an absence can be linked.
  // Finding out whether an absence can be linked is a potentially expensive operation,
  // so we only ask when one of these things has changed.
  return {
    primaryReason: incident.primaryReason,
    secondaryReason: incident.secondaryReason,
    dates: incident.absenceDates.map(ad =>
      formatISO(ad.shiftStartTime, { representation: "date" })
    )
  };
}

export function maybeUpdateLinkability(
  askedIfCanBeLinked: boolean,
  apiIncident: AbsenceIncident,
  previousIncident: AbsenceIncident
): AbsenceIncidentLinkability {
  // Only use the linkability from the API if we asked for it. If we didn't ask,
  // it will just be undefined, so continue to use the old linkability in that case.
  if (askedIfCanBeLinked) {
    return {
      ...apiIncident.linkability,
      cache: linkabilityCache(apiIncident)
    };
  } else {
    return previousIncident.linkability;
  }
}
