import React from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import clsx from "clsx";
import styles from "./styles.module.scss";

export const Tooltip: React.FC<TooltipProps> = ({ className, ...props }) => (
  <ReactTooltip
    arrowColor={"transparent"}
    effect={"solid"}
    place={"top"}
    className={clsx(styles["morneau-tooltip"], className)}
    {...props}
  />
);
