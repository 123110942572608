import React, { useContext } from "react";
import AuthService, { AuthContextService } from "./auth-service";
import { AppContext } from "context/app-context";

interface authContextStateType {
  authContext: AuthContextService | null;
}

export const AuthContext = React.createContext<authContextStateType>({
  authContext: {}
});

export const AuthProvider: React.FunctionComponent = props => {
  const authContext = AppContext.isSandbox() ? null : new AuthService();
  return (
    <AuthContext.Provider value={{ authContext }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext: () => authContextStateType = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("AuthContext requires a Provider");
  }
  return context;
};
