import React from "react";
import DecisionBlock from "components/decision-block/decision-block";
import { IconButton, TextButton } from "components/buttons";
import styles from "./styles.module.scss";
import { useDesktopBreakpoint } from "hooks";
import { useTranslation } from "hooks/translation";
import SummaryLabel from "components/summary-label/summary-label";
import { useHistory } from "react-router";
import { LeftArrowIcon } from "components/icons";
import {
  ClaimDecision,
  ClaimRelationship,
  ClaimType
} from "features/claims/claims-model";
import { Translation } from "components/translation";
import { ClientTranslationProps } from "i18n";

export const DecisionsSection: React.FC<{
  showAll: boolean;
  decisions: ClaimDecision[];
  setShowAllDecisions: (show: boolean) => void;
}> = props => {
  return props.showAll ? (
    <>
      <div className={styles["flexible-decisions"]}>
        {props.decisions.map(decision => (
          <DecisionBlock
            key={decision.start.toISOString()}
            className={styles["flexible-block"]}
            decision={decision}
          />
        ))}
      </div>
      <TextButton
        className={styles["text-button"]}
        onClick={() => props.setShowAllDecisions(false)}
      >
        <Translation tag={"general.show_less"} />
      </TextButton>
    </>
  ) : (
    <>
      {props.decisions.length > 0 && (
        <>
          <DecisionBlock decision={props.decisions[0]} />
          <div className={styles["left-and-right"]}>
            <TextButton
              className={styles["text-button"]}
              onClick={() => props.setShowAllDecisions(true)}
            >
              <Translation tag={"claims.show_all_decisions"} />
            </TextButton>
            <span className={styles["fineprint"]}>
              {props.decisions.length - 1}{" "}
              <Translation tag={"general.items_not_shown"} />
            </span>
          </div>
        </>
      )}
    </>
  );
};

export const DesktopClaimIdLabel: React.FC<{ claimId: string }> = ({
  claimId
}) => {
  const isDesktop = useDesktopBreakpoint();
  const t = useTranslation();

  return (
    <>
      {isDesktop && (
        <div className={styles["case-id-container"]}>
          <SummaryLabel
            label={t({ tag: "claims.label.case_id" })}
            value={claimId}
          />
        </div>
      )}
    </>
  );
};

export const DesktopConfirmationNumberLabel: React.FC<{ claimId: string }> = ({
  claimId
}) => {
  const isDesktop = useDesktopBreakpoint();
  const t = useTranslation();

  return (
    <>
      {isDesktop && (
        <div>
          <SummaryLabel
            label={t({ tag: "absence_detail.confirmation_number" })}
            value={claimId}
          />
        </div>
      )}
    </>
  );
};

export const ClaimDesktopHeaderFragment = () => {
  const history = useHistory();

  return (
    <>
      <TextButton
        className={styles["back-button"]}
        onClick={() => history.goBack()}
      >
        <LeftArrowIcon className={styles["back-button-icon"]} />
        <Translation tag={"buttons.back"} />
      </TextButton>
    </>
  );
};

export const calculateCardElementsHeight = (
  elements: HTMLElement[],
  numberOfColumns: number,
  numberOfRows: number
) => {
  for (let i = 0; i < numberOfRows; i++) {
    const elementsInIRow = elements.slice(
      i * numberOfColumns,
      numberOfColumns * (i + 1)
    );
    const elementsHeights = elementsInIRow.map(el => el.clientHeight);
    const maxElementHeight = elementsHeights.length
      ? Math.max(...elementsHeights)
      : "auto";
    const elementHeight = Number.isNaN(maxElementHeight)
      ? maxElementHeight.toString()
      : `${maxElementHeight}px`;

    elementsInIRow.forEach(el =>
      el.parentElement ? (el.parentElement.style.height = elementHeight) : null
    );
  }
};

export const setAutoHeightOfCardElements = (elements: HTMLElement[]) => {
  elements.forEach(el =>
    el.parentElement ? (el.parentElement.style.height = "auto") : null
  );
};

type CardProps = {
  cardList: HTMLElement;
  windowWidth: number;
  mobileBreakPoint: number;
  numberOfColumns?: number;
  cardWidth?: number;
  gridGap?: number;
};

export const setCardLayout = ({
  cardList,
  mobileBreakPoint,
  windowWidth,
  numberOfColumns,
  cardWidth,
  gridGap
}: CardProps) => {
  const titles = Array.from(
    cardList.querySelectorAll<HTMLElement>('[data-card="title"]')
  );
  const tags = Array.from(
    cardList.querySelectorAll<HTMLElement>('[data-card="tag"]')
  );
  const dates = Array.from(
    cardList.querySelectorAll<HTMLElement>('[data-card="date"]')
  );
  const numberOfCards = titles.length;

  if (windowWidth >= mobileBreakPoint) {
    if (!numberOfColumns) {
      const totalCardWidth = cardWidth ?? 1 + (gridGap ?? 0);
      const listWidth = cardList.clientWidth;
      numberOfColumns =
        1 + Math.floor((listWidth - (cardWidth ?? 1)) / totalCardWidth);
    }

    const numberOfRows = Math.ceil(numberOfCards / numberOfColumns);

    calculateCardElementsHeight(titles, numberOfColumns, numberOfRows);
    calculateCardElementsHeight(tags, numberOfColumns, numberOfRows);
    calculateCardElementsHeight(dates, numberOfColumns, numberOfRows);
  } else {
    setAutoHeightOfCardElements(titles);
    setAutoHeightOfCardElements(tags);
    setAutoHeightOfCardElements(dates);
  }
};

export const MobileHeaderFragment: React.FC = ({ children }) => {
  const history = useHistory();

  return (
    <>
      <IconButton
        className={styles["back-button"]}
        onClick={() => history.goBack()}
      >
        <LeftArrowIcon className={styles["back-button-icon"]} />
      </IconButton>
      {children}
    </>
  );
};

export const ClaimMobileHeaderFragment: React.FC<{ caseId: string }> = ({
  caseId
}) => {
  return (
    <MobileHeaderFragment>
      <Translation tag={"claims.label.case"} /> {caseId}
    </MobileHeaderFragment>
  );
};

export function translateRelationship(
  relationship: ClaimRelationship
): ClientTranslationProps {
  switch (relationship) {
    case "child-adopted":
      return { tag: "claims.relationship.child_adopted" };
    case "child-biological":
      return { tag: "claims.relationship.child_biological" };
    case "child-adult":
      return { tag: "claims.relationship.child_adult" };
    case "child-partner":
      return { tag: "claims.relationship.child_partner" };
    case "child-foster":
      return { tag: "claims.relationship.child_foster" };
    case "child-step":
      return { tag: "claims.relationship.child_step" };
    case "in-loco-parentis":
      return { tag: "claims.relationship.in_loco_parentis" };
    case "legal-ward":
      return { tag: "claims.relationship.legal_ward" };
    case "next-of-kin":
      return { tag: "claims.relationship.next_of_kin" };
    case "parent":
      return { tag: "claims.relationship.parent" };
    case "parent-step":
      return { tag: "claims.relationship.parent_step" };
    case "spouse":
      return { tag: "claims.relationship.spouse" };
    case "self":
      return { tag: "claims.relationship.self" };
  }
}

export function translateAbbreviatedRelationship(
  relationship: ClaimRelationship | string | undefined
): ClientTranslationProps | undefined {
  // Using the abbreviated form for some relationships, to keep tags short
  switch (relationship) {
    case "child-adopted":
    case "child-biological":
    case "child-adult":
    case "child-partner":
    case "child-foster":
    case "child-step":
    case "in-loco-parentis":
      return { tag: "claims.relationship.child" };
    case "legal-ward":
      return { tag: "claims.relationship.legal_ward" };
    case "next-of-kin":
      return { tag: "claims.relationship.next_of_kin" };
    case "parent":
      return { tag: "claims.relationship.parent" };
    case "parent-step":
      return { tag: "claims.relationship.parent_step" };
    case "spouse":
      return { tag: "claims.relationship.spouse" };
    case "self":
      return { tag: "claims.relationship.self" };
  }
}

export function getFilterLabel(filter: FilterType): ClientTranslationProps {
  switch (filter) {
    case "all":
      return { tag: "claims.tag.all" };
    case "absence":
      return { tag: "claims.tag.absence" };
    case "fml":
      return { tag: "claims.tag.fml" };
    case "std":
      return { tag: "claims.tag.std" };
    case "wcb":
      return { tag: "claims.tag.wcb" };
  }
}

export type FilterType = "all" | ClaimType;

export type ClaimTypeFilter = {
  filter: FilterType;
  label: ClientTranslationProps;
};

export const filters: ClaimTypeFilter[] = [
  { filter: "all", label: { tag: "claims.tag.all" } },
  { filter: "absence", label: { tag: "claims.tag.absence" } },
  { filter: "fml", label: { tag: "claims.tag.fml" } },
  { filter: "std", label: { tag: "claims.tag.std" } },
  { filter: "wcb", label: { tag: "claims.tag.wcb" } }
];

export const getFilterTypeFromString = (
  text: string
): FilterType | undefined => {
  const filterType =
    text === "all" ||
    text === "std" ||
    text === "absence" ||
    text === "fml" ||
    text === "wcb"
      ? text
      : undefined;
  return filterType;
};

export enum ClaimFilterUrlParamName {
  ClaimType = "claimType"
}
