import React from "react";
import { IconComponent } from "..";
import styles from "./framed-icon.module.scss";
import clsx from "clsx";

type Props = {
  icon: IconComponent;
  className?: string;
};

const FramedIcon: React.FC<Props> = ({ icon, className }) => {
  const Icon = icon;

  return (
    <div className={clsx(styles["icon-frame"], className)}>
      <Icon className={styles["icon"]} />
    </div>
  );
};

export default FramedIcon;
