import React, { ReactElement } from "react";
import { Translation } from "components/translation";
import { Tag, TagStyle } from "components/tag/tag";
import {
  AbsenceClaimStatus,
  DecisionStatus,
  LeaveClaimStatus,
  StdClaimStatus
} from "features/claims/claims-model";
import { useTranslation } from "hooks/translation";
import { LocaleTexts } from "shared-types/api";

type ClaimTagProps = {
  status: AbsenceClaimStatus | StdClaimStatus | LeaveClaimStatus;
  locale?: LocaleTexts;
};

type DecisionTagProps = {
  status: DecisionStatus;
};

export const TagForClaimStatus: React.FC<ClaimTagProps> = ({
  status,
  locale
}) => {
  const t = useTranslation();
  const tagStyle = getTagStyle(status);
  const shortTagText = getTagText(status);
  const translatedTagText = locale ? t(locale) : "";

  return (
    <Tag tagStyle={tagStyle}>
      {translatedTagText !== "" ? translatedTagText : shortTagText}
    </Tag>
  );
};

function getTagStyle(
  status: AbsenceClaimStatus | StdClaimStatus | LeaveClaimStatus
): TagStyle {
  switch (status) {
    case "open":
    case "approved":
    case "submitted":
      return "green";
    case "cancelled":
    case "denied":
      return "red";
    case "pending":
    case "pending-review":
    case "pending-paperwork":
    case "missing-paperwork":
    case "in-progress":
      return "orange";
    case "completed":
    case "closed":
      return "gray";
    case "incomplete":
      return "purple";
    case "outcomed":
      return "orange";
  }
}

function getTagText(
  status: AbsenceClaimStatus | StdClaimStatus | LeaveClaimStatus
): ReactElement {
  switch (status) {
    case "open":
      return <Translation tag={"claims.status.open"} />;
    case "closed":
      return <Translation tag={"claims.status.closed"} />;
    case "approved":
      return <Translation tag={"claims.status.approved"} />;
    case "pending":
      return <Translation tag={"claims.status.pending"} />;
    case "pending-review":
      return <Translation tag={"claims.status.pending_review"} />;
    case "pending-paperwork":
      return <Translation tag={"claims.status.pending_paperwork"} />;
    case "cancelled":
      return <Translation tag={"claims.status.cancelled"} />;
    case "denied":
      return <Translation tag={"claims.status.denied"} />;
    case "missing-paperwork":
      return <Translation tag={"claims.status.missing_paperwork"} />;
    case "submitted":
      return <Translation tag={"claims.status.submitted"} />;
    case "in-progress":
      return <Translation tag={"claims.status.in_progress"} />;
    case "completed":
      return <Translation tag={"claims.status.completed"} />;
    case "incomplete":
      return <Translation tag={"claims.status.incomplete"} />;
    case "outcomed":
      return <Translation tag={"claims.status.outcomed"} />;
  }
}

export const TagForDecisionStatus: React.FC<DecisionTagProps> = ({
  status
}) => {
  switch (status) {
    case "APPROVED":
      return (
        <Tag tagStyle="green">
          <Translation tag={"claims.status.approved"} />
        </Tag>
      );
    case "PENDING":
      return (
        <Tag tagStyle="orange">
          <Translation tag={"claims.status.pending"} />
        </Tag>
      );
    case "DENIED":
      return (
        <Tag tagStyle="red">
          <Translation tag={"claims.status.denied"} />
        </Tag>
      );
  }
};
