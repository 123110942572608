import { useLanguageContext } from "context/user-language-context";
import {
  datePlaceholder,
  formatDate,
  formatDateLong,
  parseDate
} from "i18n/dates";
import _, { uniqueId } from "lodash-es";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory } from "react-router-dom";

import * as Routes from "routes";
import { ReportAnAbsencePermission } from "permissions";
import { getSessionService } from "features/session/session-service";
import { ARWorkflow } from "features/report-absence/absence-reporting-workflow";
import { getLocale } from "features/translations/locales";

/**
 * Generate a unique id to use within a component. This ensures that ids from
 * multiple instances of the component will not interfere.
 */
export function useUniqueId(prefix: string) {
  const [id] = useState(() => uniqueId(prefix));
  return id;
}

/**
 * Provides functions to prevent the default browser outline on an element when
 * the user clicks on it, but keeps the outline behavior when the user uses the
 * TAB key to select it.
 *
 * Inspired by: https://www.darrenlester.com/blog/focus-only-on-tab
 */
export function usePreventOutlineOnClick() {
  const mouseDown = useRef(false);

  const onMouseDown = () => {
    mouseDown.current = true;
  };

  const onMouseUp = () => {
    mouseDown.current = false;
  };

  const onFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    if (mouseDown.current) {
      event.target.blur();
    }
  };

  return { onMouseDown, onMouseUp, onFocus };
}

/**
 * Hook to detect clicks outside of an element. Useful for e.g. closing a modal
 * when clicking outside of it. Courtesy of https://usehooks.com/useOnClickOutside/
 * with modifications to allow an option reference to an anchor element that should
 * not trigger the event.
 */
export function useOnClickOutside(
  popoverRef: React.RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void,
  anchorRef?: React.RefObject<HTMLElement>
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        // Or the element responsible for originally activating the ref
        if (
          !popoverRef.current ||
          popoverRef.current.contains(event.target as Node) ||
          anchorRef?.current?.contains(event.target as Node)
        ) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },

    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [popoverRef, handler, anchorRef]
  );
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Store a value of any JSON-serializable type in the browser session storage.
 * @param key A unique string key for the sessionStorage dictionary
 * @param defaultValue Returned if there is currently no value stored for the key
 */
export function useSessionStorage<T>(
  key: string,
  defaultValue: T
): [T, (newValue: T) => void] {
  const valueSerialized = sessionStorage.getItem(key);
  const value: T = valueSerialized ? JSON.parse(valueSerialized) : defaultValue;

  const setValue = (newValue: T) =>
    sessionStorage.setItem(key, JSON.stringify(newValue));

  return [value, setValue];
}

type LastLoginType = {
  clientId: string;
};
export function useLastLogin(): {
  lastLogin: LastLoginType | null;
  setLastLogin: (value: LastLoginType) => void;
} {
  const [lastLogin, setLastLogin] = useSessionStorage<LastLoginType | null>(
    "lastLogin",
    null
  );
  return { lastLogin, setLastLogin };
}

export type LocalizedDateParser = (s: string) => Date | null;
export type LocalizedDateFormatter = (d: Date) => string;
export const useLocalizedDateParsing = (): {
  parseDate: LocalizedDateParser;
  formatDate: LocalizedDateFormatter;
  formatDateLong: LocalizedDateFormatter;

  placeholder: string;
} => {
  const { currentLang } = useLanguageContext();
  return useMemo(
    () => ({
      parseDate: (s: string) => parseDate(currentLang, s),
      formatDate: (d: Date) => formatDate(currentLang, d),
      formatDateLong: (d: Date) => formatDateLong(currentLang, d),
      placeholder: datePlaceholder(currentLang)
    }),
    [currentLang]
  );
};

/**
 * A hook that can be used to reflect the desktop media breakpoint in
 * React components.
 * Adapted from https://usehooks.com/useMedia/
 */
export function useDesktopBreakpoint(): boolean {
  const mql = window.matchMedia("(min-width: 1024px)");

  const getValue = () => {
    return mql.matches;
  };

  // State and setter for matched value
  const [isDesktopView, setIsDesktopView] = useState<boolean>(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setIsDesktopView(getValue);

      // Set a listener for the media query
      mql.addListener(handler);

      // Remove listener on cleanup
      return () => mql.removeListener(handler);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Empty array ensures effect is only run on mount and unmount
  );

  return isDesktopView;
}

export const useReportAbsenceLink = (): {
  canReportAbsence: boolean;
  goToReportAbsence: (incidentId?: number) => void;
  getReportAbsenceLink: (incidentId?: number) => string;
  goToReportAbsencePrimaryReason: (incidentId: number) => void;
} => {
  const history = useHistory();

  const sessionService = getSessionService();
  const { currentLang } = useLanguageContext();
  const locale = getLocale(currentLang);

  return {
    canReportAbsence: sessionService.hasPrivileges(ReportAnAbsencePermission),
    goToReportAbsence: (incidentId?: number) => {
      // TODO Might be better to make this a method of the workflow context
      ARWorkflow.start(locale);
      history.push(Routes.ReportAbsence.generate({ incidentId: incidentId }));
    },
    getReportAbsenceLink: (incidentId?: number) =>
      Routes.ReportAbsence.generate({ incidentId: incidentId }),
    goToReportAbsencePrimaryReason: (incidentId: number) => {
      ARWorkflow.start(locale);
      history.push(Routes.ReportAbsencePrimaryReason.generate({ incidentId }));
    }
  };
};

export function useHistoryDebugging() {
  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      console.log("Navigating to:", location.pathname);
    });
  }, [history]);
}

/**
 * Avoid excessive re-rendering by only passing through an updated value when it
 * stops changing for a period of time.
 * @param value Value to pass through (usually the output of another useState)
 * @param delay Minimum period of time (in milliseconds) before passing through value
 *
 * @see https://usehooks.com/useDebounce/
 */
export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount).
      // This is how we prevent debounced value from updating if value is changed
      // within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

/**
 * Get a function that can be called to force a component to rerender.
 */
export function useForceRender() {
  const [, _setForceRender] = useState(0);
  return useCallback(() => _setForceRender(i => i + 1), []);
}

/**
 * Get the scroll position of the window, debounced with configurable interval and options.
 *
 * @param debounceInterval
 * @param debounceOptions
 */
export function useWindowScrollTop(
  debounceInterval: number,
  debounceOptions = {
    leading: true,
    trailing: true
  }
) {
  const [scrollTop, setScrollTop] = useState(
    window.scrollY || window.pageYOffset
  );

  useEffect(() => {
    const setScrollTopThrottled = _.debounce(
      setScrollTop,
      debounceInterval,
      debounceOptions
    );
    const onScroll = () => {
      setScrollTopThrottled(window.scrollY || window.pageYOffset); // pageYOffset is for IE11
    };
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
      setScrollTopThrottled.cancel();
    };
  }, [debounceInterval, debounceOptions]);

  return scrollTop;
}

/**
 * Asynchronously perform an API request, with loading and error states.
 *
 * @param doRequest Async callback that performs the request.
 * @param deps An array of dependencies for useEffect. The request is repeated when any of the
 * dependencies change.
 */
export function useApiRequest<TResponse>(
  doRequest: (op: { cancelled: boolean }) => Promise<TResponse>,
  deps: ReadonlyArray<unknown>
) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<TResponse>();
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    const op = { cancelled: false };

    async function sendRequestAsync() {
      setLoading(true);
      setData(undefined);
      setError(undefined);

      try {
        const data = await doRequest(op);

        if (!op.cancelled) {
          setData(data);
        }
      } catch (e) {
        if (!op.cancelled) {
          console.error("Error in API request", e);
          setError(e);
        }
      } finally {
        if (!op.cancelled) {
          setLoading(false);
        }
      }
    }

    void sendRequestAsync();

    return function cleanup() {
      op.cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { isLoading, data, error };
}
