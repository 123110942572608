import { AbsenceConfig, WorkShift } from "features/report-absence/incident";
import { first } from "lodash-es";
import { makeTime } from "shared-types/time";

export function getDefaultShift(shiftOptions?: WorkShift[]): WorkShift {
  return (
    shiftOptions?.find(so => so.isDefault) ??
    first(shiftOptions) ??
    FALLBACK_SHIFT
  );
}

export function getWorkShiftOptions(
  config?: AbsenceConfig | null
): WorkShift[] {
  return config && config.workShifts.length > 0
    ? config.workShifts
    : [FALLBACK_SHIFT];
}

const FALLBACK_SHIFT: WorkShift = {
  startTime: makeTime(9, 0, "am"),
  endTime: makeTime(5, 0, "pm"),
  order: 0,
  isDefault: true
};
