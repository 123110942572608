import React, { useContext, useEffect, useState } from "react";
import { languageIsSupported } from "i18n";
import { useCookies } from "react-cookie";
import { initTranslations } from "features/translations/translation-source";
import { SupportedLanguage } from "features/translations/locales";

type SetLanguageFunction = (lang: SupportedLanguage) => void;
const USER_LANGUAGE_COOKIE_NAME = "user-lang";

type UserLanguageContextType = {
  currentLang: SupportedLanguage;
  setLanguage: SetLanguageFunction;
};
const UserLanguageContext = React.createContext<
  UserLanguageContextType | undefined
>(undefined);

export const UserLanguageProvider: React.FunctionComponent = props => {
  const [cookies, setCookie] = useCookies([USER_LANGUAGE_COOKIE_NAME]);
  const userLangCookie = cookies[USER_LANGUAGE_COOKIE_NAME];

  let currentLang: SupportedLanguage;
  if (languageIsSupported(userLangCookie)) {
    // Language stored in cookie is highest priority
    currentLang = userLangCookie;
  } else if (languageIsSupported(navigator.language)) {
    // If there's no valid cookie, try and get a language from the browser
    currentLang = navigator.language;
  } else {
    // Default to US English
    currentLang = SupportedLanguage.EnglishUS;
  }

  //currentLang = SupportedLanguage.FrenchCA;

  useEffect(() => {
    void initTranslations(currentLang);
  }, [currentLang]);

  const setLanguage: SetLanguageFunction = (lang: SupportedLanguage) => {
    setCookie(USER_LANGUAGE_COOKIE_NAME, lang);
  };

  return (
    <UserLanguageContext.Provider value={{ currentLang, setLanguage }}>
      {props.children}
    </UserLanguageContext.Provider>
  );
};

export const TestingLanguageProvider: React.FunctionComponent<{
  initialLang: SupportedLanguage;
}> = ({ initialLang, children }) => {
  const [currentLang, setLanguage] = useState(initialLang);

  return (
    <UserLanguageContext.Provider value={{ currentLang, setLanguage }}>
      {children}
    </UserLanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(UserLanguageContext);
  if (context === undefined) {
    throw new Error("UserLanguageContext requires a provider");
  }
  return context;
};
