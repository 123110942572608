import { suggestNextShiftDate } from "../absence-reporting-utils";
import { isValid, isAfter, isSameDay } from "date-fns";
import {
  QuestionSummary,
  QuestionAnswer,
  ValidationResult
} from "../absence-reporting-types";
import { ARWorkflowState } from "../absence-reporting-state";
import { validateRTWAnswer } from "./section-helpers";
import isBefore from "date-fns/isBefore";

const _isContinuous = (state: ARWorkflowState) => {
  const threshold = state.config?.minimumThresholdForContinousAbsence ?? 2;
  if (state.incident.absenceDates.length < threshold) return false;

  return true;
};

const _rtwDateMandatoryForAbsenceType = (state: ARWorkflowState) => {
  return _isContinuous(state)
    ? state.config?.isRTWDateMandatoryContinuous
    : state.config?.isRTWDateMandatoryIntermittent;
  // switch (state.incident.absenceType) {
  //   case "continuous":
  //     return state.config?.isRTWDateMandatoryContinuous;
  //   case "intermittent":
  //     return state.config?.isRTWDateMandatoryIntermittent;
  //   default:
  //     return false;
  // }
};

const _rtwDateQuestionShown = (state: ARWorkflowState) => {
  return _isContinuous(state)
    ? state.config?.displayRTWDateContinuous
    : state.config?.displayRTWDateIntermittent;
};

export const buildRtwSections = (state: ARWorkflowState) => {
  if (!_rtwDateQuestionShown(state)) return [];
  return [
    {
      id: "return-to-work",
      type: "ReturnToWorkQuestion",
      promptTag: () => ({ tag: "reportAbsence.return_to_work.prompt" }),
      answer: () => {
        const last = state.incident.absenceDates.length
          ? state.incident.absenceDates[state.incident.absenceDates.length - 1]
          : null;

        const isValidRtw =
          state.incident.returnToWorkDate &&
          last?.shiftEndTime &&
          (isAfter(state.incident.returnToWorkDate, last.shiftEndTime) ||
            (last.scheduledShiftEndTime &&
              isBefore(last.shiftEndTime, last.scheduledShiftEndTime) &&
              isSameDay(state.incident.returnToWorkDate, last.shiftEndTime)));

        const returnToWorkDate = isValidRtw
          ? state.incident.returnToWorkDate
          : _rtwDateMandatoryForAbsenceType(state)
          ? suggestNextShiftDate(state.incident.absenceDates)
          : undefined;

        return {
          type: "rtw",
          value: isValid(returnToWorkDate)
            ? {
                returnToWorkDate
              }
            : undefined
        };
      },
      summary: () => {
        return {
          type: "date",
          label: { tag: "reportAbsence.summary.return_to_work_date" },
          answer: state.incident.returnToWorkDate
        } as QuestionSummary;
      },
      validateAnswer: (answer: QuestionAnswer): ValidationResult => {
        return validateRTWAnswer(
          answer,
          state.incident.absenceDates,
          _rtwDateMandatoryForAbsenceType(state) ?? false
        );
      },
      setAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "rtw") {
          state.incident.returnToWorkDate = answer.value.returnToWorkDate;
        }
      },
      hidden: () => state.incident.absenceDates.length === 0,
      requiresAnswer: () =>
        _rtwDateMandatoryForAbsenceType(state) &&
        state.incident.returnToWorkDate === undefined,
      isSubmissionMode: true
    }
  ];
};
