import React from "react";
import styles from "./table.module.scss";
import clsx from "clsx";

type Header = {
  header: string;
  key: string;
  align?: string;
};

type DesktopProps = {
  headers: Header[];
  data: Record<string, string | number | JSX.Element | null>[];
  footer?: Record<string, string | number | null>;
};

export const DesktopTable: React.FC<DesktopProps> = ({
  headers,
  data,
  footer
}) => {
  const prepareCellClass = (align?: string) =>
    align
      ? clsx(styles["table-cell"], styles[`table-cell--${align}`])
      : clsx(styles["table-cell"], styles["table-cell--left"]);

  return (
    <>
      <table className={styles["table"]}>
        <thead className={styles["table-header"]}>
          <tr>
            {headers.map(header => (
              <th key={header.key} className={prepareCellClass(header.align)}>
                {header.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles["table-body"]}>
          {data.map((row, idx) => (
            <tr key={idx} className={styles["table-body__row"]}>
              {headers.map(header => (
                <td key={header.key} className={prepareCellClass(header.align)}>
                  {row[header.key] !== undefined ? row[header.key] : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot className={styles["table-footer"]}>
          <tr>
            {footer &&
              headers.map(header => (
                <td key={header.key} className={prepareCellClass(header.align)}>
                  {footer[header.key]}
                </td>
              ))}
          </tr>
        </tfoot>
      </table>
    </>
  );
};

type MobileProps = {
  data: {
    upperHeader?: string;
    header?: string;
    lowerHeader?: string;
    footer?: string;
  }[];
};

export const MobileTable: React.FC<MobileProps> = ({ data }) => {
  return (
    <div className={styles["table-mobile"]}>
      {data.map((cell, idx) => (
        <div key={idx} className={styles["table-mobile__cell"]}>
          <div
            className={styles["table-mobile__upper-header"]}
            dangerouslySetInnerHTML={{ __html: cell.upperHeader || "" }}
          />
          <div
            className={styles["table-mobile__header"]}
            dangerouslySetInnerHTML={{ __html: cell.header || "" }}
          />
          <div
            className={styles["table-mobile__lower-header"]}
            dangerouslySetInnerHTML={{ __html: cell.lowerHeader || "" }}
          />
          <div
            className={styles["table-mobile__footer"]}
            dangerouslySetInnerHTML={{ __html: cell.footer || "" }}
          />
        </div>
      ))}
    </div>
  );
};
