import React from "react";
import { useWebConfigurationContext } from "context/web-configuration-context";
import { useTranslation } from "hooks/translation";
import * as Routes from "routes";
import { Link } from "react-router-dom";
import { useLanguageContext } from "context/user-language-context";
import { SupportedLanguage } from "features/translations/locales";

type Props = {
  logoStyle: "light" | "dark";
  clickable?: boolean;
  className?: string;
  height?: string;
};

export const ClientLogo: React.FunctionComponent<Props> = props => {
  const webConfigurationContext = useWebConfigurationContext();
  const t = useTranslation();
  const { currentLang } = useLanguageContext();

  const logoFile = webConfigurationContext?.webConfigurationContext?.logoFile;
  const logoPath = process.env.REACT_APP_ASSETS_BASE + "/images/logo/";
  let defaultLogo =
    props.logoStyle === "light"
    ? logoPath + "telus_health_white.svg"
    : logoPath + "telus_health_color.svg";
  if (currentLang === SupportedLanguage.FrenchCA) {
    defaultLogo =
      props.logoStyle === "light"
      ? logoPath + "telus_health_white_fr.svg"
      : logoPath + "telus_health_color_fr.svg";
  }

  const imgSrc = logoFile ? `data:image/jpeg;base64,${logoFile}` : defaultLogo;

  const LogoImage = () => (
    <img
      className={props.className}
      height={props.height ?? 50}
      alt={t({ tag: "login.imgAlts.companyLogo" })}
      style={{ maxWidth: "100%" }}
      // Keep client logo config in place but hard code the default Morneau Shepell logo for now
      src={true ? defaultLogo : imgSrc}
    />
  );

  return props.clickable ? (
    <Link to={Routes.Dashboard.generate({})}>
      <LogoImage />
    </Link>
  ) : (
    <LogoImage />
  );
};
