import React, { useState } from "react";
import ReactModal from "react-modal";
import CloseButton from "components/buttons/close-button/close-button";
import styles from "./base-modal.module.scss";
import clsx from "clsx";

type Props = {
  isOpen: boolean;
  title: string;
  customStyles?: ReactModal.Styles;
  titleRowClass?: string;
  titleClass?: string;
  buttonClass?: string;
  testId?: string;
  className?: string;
  closeModal: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
};

export const modalStyle: ReactModal.Styles = {
  overlay: {
    zIndex: 20,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    placeContent: "center"
  },
  content: {
    position: "static",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 0 8px rgba(0, 0, 0, 0.24)",
    margin: "auto 24px",
    padding: "20px 24px 24px 24px",
    maxWidth: "632px",
    inset: "unset"
  }
};

export const BaseModal: React.FC<Props> = props => {
  const [overflowValue, setOverflowValue] = useState("");

  const onAfterOpen = () => {
    setOverflowValue(document.body.style.overflow);

    // Prevent scrolling on content behind modal
    document.body.style.overflow = "hidden";
    props.onAfterOpen && props.onAfterOpen();
  };

  const onAfterClose = () => {
    // Remove scrolling restriction
    document.body.style.overflow = overflowValue;
    props.onAfterClose && props.onAfterClose();

    setOverflowValue("");
  };

  return (
    <ReactModal
      aria={{ labelledby: "expanded" }}
      isOpen={props.isOpen}
      style={props.customStyles ? props.customStyles : modalStyle}
      testId={props.testId}
      className={props.className}
      onRequestClose={props.closeModal}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
    >
      <div className={clsx(styles["title-row"], props.titleRowClass)}>
        <h4 id="expanded" className={clsx(styles.title, props.titleClass)}>
          {props.title}
        </h4>
        <CloseButton className={props.buttonClass} onClick={props.closeModal} />
      </div>

      {props.children}
    </ReactModal>
  );
};
