import React from "react";
import { IconButton } from "components/buttons";
import { ChevronBackIcon, ChevronNextIcon } from "components/icons";
import { useTranslation } from "hooks/translation";
import styles from "./pagination.module.scss";
import clsx from "clsx";

type Props = {
  allItemsNumber: number | string;
  pagesNumber: number;
  currentPage: number;
  numberOfRows: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
};

const Pagination: React.FC<Props> = ({
  allItemsNumber,
  pagesNumber,
  currentPage,
  numberOfRows,
  rowsPerPage,
  onChangePage
}) => {
  const pages = [];
  const t = useTranslation();

  for (let i = 0; i < pagesNumber; i++) {
    pages.push(i + 1);
  }

  return (
    <div className={styles["pagination__container"]}>
      <div className={styles["pagination__items"]}>
        {t({
          tag: "claims.pagination.pages",
          vars: {
            start: ((currentPage - 1) * rowsPerPage + 1).toString(),
            end: ((currentPage - 1) * rowsPerPage + numberOfRows).toString(),
            all: allItemsNumber.toString()
          }
        })}
      </div>
      {pagesNumber > 1 && (
        <>
          <IconButton
            className={clsx(
              styles["pagination__button"],
              styles["pagination__button--prev"]
            )}
            disabled={currentPage === 1}
            onClick={() => onChangePage(currentPage - 1)}
          >
            <ChevronBackIcon />
          </IconButton>
          {pages.map(page => (
            <div
              key={page}
              className={
                page === currentPage
                  ? clsx(
                      styles["pagination__page-number"],
                      styles["pagination__page-number--active"]
                    )
                  : styles["pagination__page-number"]
              }
              onClick={() => onChangePage(page)}
            >
              {page}
            </div>
          ))}
          <IconButton
            className={styles["pagination__button"]}
            disabled={currentPage === pagesNumber}
            onClick={() => onChangePage(currentPage + 1)}
          >
            <ChevronNextIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default Pagination;
