import React from "react";
import { IconButton } from "..";
import { CloseIcon } from "components/icons";
import styles from "./close-button.module.scss";
import clsx from "clsx";
import { useTranslation } from "hooks/translation";

type Props = {
  className?: string;
  onClick: () => void;
  theme?: "light" | "dark";
  label?: string;
};

const CloseButton: React.FC<Props> = props => {
  const iconClassname = (() => {
    switch (props.theme) {
      case "light":
      default:
        return styles["icon--black"];
      case "dark":
        return styles["icon--white"];
    }
  })();

  const t = useTranslation();
  return (
    <IconButton
      onClick={props.onClick}
      className={clsx(styles["button"], props.className)}
      testId="close-button"
      ariaLabel={props.label ? props.label : t({ tag: "buttons.cancel" })}
    >
      <CloseIcon className={iconClassname} />
    </IconButton>
  );
};

export default CloseButton;
