import React, { useContext, useState } from "react";
import { useSessionStorage } from "hooks";
import { Capabilities } from "api";

type Session =
  | { isLoggedIn: false }
  | {
      isLoggedIn: true;
      capabilities: Capabilities;
    };

const DEFAULT_SESSION: Session = { isLoggedIn: false };

interface SessionContextType {
  session: Session;
  setSession: (session: Session) => void;
  clearSession: () => void;
}

const SessionContext = React.createContext<SessionContextType | undefined>(
  undefined
);

export const BrowserSessionProvider: React.FunctionComponent = props => {
  // Persist to sessionStorage as well so that state is not lost if the user reloads the page
  const [storedSession, setStoredSession] = useSessionStorage<Session>(
    "userSession",
    DEFAULT_SESSION
  );
  const [sessionState, setSessionState] = useState<Session>(storedSession);
  const setSession = (newSession: Session) => {
    setStoredSession(newSession);
    setSessionState(newSession);
  };
  const clearSession = () => setSession(DEFAULT_SESSION);

  return (
    <SessionContext.Provider
      value={{ session: sessionState, setSession, clearSession }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

/**
 * This provider should only be used in tests.
 */
type TestingProps = { initialSession?: Session };
export const MemorySessionProvider: React.FunctionComponent<TestingProps> = props => {
  const [session, setSession] = useState<Session>(
    props.initialSession || DEFAULT_SESSION
  );
  const clearSession = () => setSession(DEFAULT_SESSION);

  return (
    <SessionContext.Provider value={{ session, setSession, clearSession }}>
      {props.children}
    </SessionContext.Provider>
  );
};

export const useSessionContext: () => SessionContextType = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSessionContext requires a SessionProvider");
  }
  return context;
};
