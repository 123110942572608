import {
  ApiCallResult,
  AbsenceConfig,
  AddNewEmployeePermission,
  EmployeeVerificationFields,
  OrganizationHierarchy,
  AbsenceIncident,
  AbsenceReasonAnswerInfo,
  AbsenceShift,
  AbsenceType,
  ApiValidationError,
  IncidentQuestionModel,
  IncidentSaveResult,
  IncidentsCancelResult,
  IncidentSubmitResult,
  LatestInProgressAbsenceResult,
  WorkShift,
  WorkShiftForDate,
  IncidentClosingScript
} from "./incident";
import { ClientTranslationProps } from "i18n";
import { AnswerChoice, ConfigAbsenceQuestion } from "./absence-questions";
import { TimeInterval } from "shared-types/time";
import { LocaleTextEntry, LocaleTexts } from "shared-types/api";
import {
  EmployeeSchedule,
  PreviousAbsence
} from "features/employee/employee-model";
import {
  CancelAbsenceRequestModelInput,
  SaveEmployeeModelInput
} from "graphql-types.gen";

export interface IAbsenceReportingService {
  loadConfig(clientCode: string, state: string): Promise<AbsenceConfig>;
  loadAddingNewEmployeePermission(
    clientCode: string
  ): Promise<AddNewEmployeePermission>;
  loadEmployeeVerificationFields(
    clientCode: string
  ): Promise<EmployeeVerificationFields[]>;
  loadOrganizationHierarchy(
    clientCode: string
  ): Promise<OrganizationHierarchy[]>;
  addNewEmployee(
    clientCode: string,
    input: SaveEmployeeModelInput
  ): Promise<ApiCallResult>;
  saveIncident(
    clientCode?: string,
    incident?: AbsenceIncident,
    askIfCanBeLinked?: boolean,
    locale?: string
  ): Promise<IncidentSaveResult>;
  submitIncident(
    incident: AbsenceIncident,
    locale?: string
  ): Promise<IncidentSubmitResult>;
  loadConfigQuestions(incidentId: number): Promise<IncidentQuestionModel>;
  loadIncident(incidentId: number): Promise<AbsenceIncident | null>;
  cancelIncidents(
    cancelRequest: CancelAbsenceRequestModelInput,
    incidentIds: number[],
    locale?: string
  ): Promise<IncidentsCancelResult>;
  getLatestInProgressAbsence(
    employeeNumber?: string,
    clientCode?: string
  ): Promise<LatestInProgressAbsenceResult | null>;
  loadClosingScript(incidentId: number): Promise<IncidentClosingScript[]>;
}

export type ValidationResult =
  | {
      isValid: true;
    }
  | {
      isValid: false;
      message?: ClientTranslationProps;
    };

export type QuestionValidationCallback = (validation: ValidationResult) => void;

export type AbsenceDateSelection = {
  absenceType: AbsenceType;
  dates: Date[];
};

export type AbsenceTimeSelection = {
  absenceType?: AbsenceType;
  times: TimeInterval[];
};

export type AbsenceShiftSelection = {
  shifts: WorkShiftForDate[];
};

export type QuestionAnswer =
  | { type: "empty" }
  | { type: "date"; value?: Date }
  | { type: "string"; value?: string }
  | { type: "absence-dates"; value: AbsenceDateSelection }
  | { type: "absence-shifts"; value: AbsenceShiftSelection }
  | { type: "absence-times"; value: AbsenceTimeSelection }
  | { type: "rtw"; value: RTWAnswer }
  | { type: "boolean"; value?: boolean }
  | { type: "submit"; value?: SubmitResult };

export type WorkflowQuestionType =
  | "MultipleChoiceListQuestion"
  | "CallCenterEmployeePhoneNumber"
  | "ReportStart"
  | "ConfigQuestion"
  | "MultipleChoiceTileQuestion"
  | "MultipleChoiceListQuestion"
  | "BooleanQuestion"
  | "CalendarQuestion"
  | "ReturnToWorkQuestion"
  | "DatePickerQuestion"
  | "TimeQuestion"
  | "ShiftQuestion"
  | "AbsenceSummary"
  | "ConfirmationMessage";

export type QuestionSummary = (
  | {
      type: "text";
      answer?: string | LocaleTexts;
    }
  | {
      type: "on-behalf";
      answer: {
        company?: string;
        employeeName?: string;
        employeeId?: string;
        phoneNumber?: string;
      };
    }
  | {
      type: "boolean";
      answer: boolean;
    }
  | {
      type: "reason";
      answer: {
        primary?: LocaleTexts;
        secondary?: LocaleTexts;
      };
    }
  | {
      type: "absence-dates";
      answer: {
        absenceType?: AbsenceType;
        dates: AbsenceShift[];
      };
    }
  | {
      type: "date";
      answer: Date | null;
    }
) & {
  label: LocaleTexts | ClientTranslationProps;
};

export interface WorkflowQuestionBaseTemplate {
  id: string;
  index?: number;
  answer: () => QuestionAnswer;
  setAnswer: (answer: QuestionAnswer) => void;
  validateAnswer?: (answer: QuestionAnswer) => ValidationResult;
  requiresAnswer: () => boolean;
  config?: ConfigAbsenceQuestion;
  choices?: () => AnswerChoice[];
  hidden?: () => boolean;
  absenceReasonInfo?: () => AbsenceReasonAnswerInfo | undefined;
  label?: string;
  promptTag?: () => ClientTranslationProps;
  descriptionTag?: () => ClientTranslationProps;
  postSubmit?: boolean;
  summary?: () => QuestionSummary;
  hideInSummary?: boolean;
  isSubmissionMode?: boolean;
  getSubmitErrorMsg?: () => string | undefined;
}

export interface CalendarQuestionTemplate extends WorkflowQuestionBaseTemplate {
  type: "CalendarQuestion";
  schedule: () => EmployeeSchedule;
  previousAbsences: () => PreviousAbsence[];
}

export interface ShiftQuestionTemplate extends WorkflowQuestionBaseTemplate {
  type: "ShiftQuestion";
  workShiftOptions: () => WorkShift[];
  addTempShift: (shift: WorkShift) => void;
  initialSameShiftToggle: () => boolean;
  sameShiftToggleHandler: (val: boolean) => void;
  maxShiftLengthThresholdInMinutes: number;
}

export interface TimeQuestionTemplate extends WorkflowQuestionBaseTemplate {
  type: "TimeQuestion";
  scheduleOverrideWarning: () => boolean;
  scheduleOverrideMessage: () => LocaleTextEntry[] | ClientTranslationProps;
  selectedWorkShifts: () => WorkShiftForDate[];
  previousAbsences: () => PreviousAbsence[];
  maxShiftLengthThresholdInMinutes: number;
}

export type WorkflowQuestionTemplate =
  | ({ type: "MultipleChoiceListQuestion" } & WorkflowQuestionBaseTemplate)
  | ({ type: "CallCenterEmployeePhoneNumber" } & WorkflowQuestionBaseTemplate)
  | ({ type: "ReportStart" } & WorkflowQuestionBaseTemplate)
  | ({ type: "ConfigQuestion" } & WorkflowQuestionBaseTemplate)
  | ({ type: "MultipleChoiceTileQuestion" } & WorkflowQuestionBaseTemplate)
  | ({ type: "MultipleChoiceListQuestion" } & WorkflowQuestionBaseTemplate)
  | CalendarQuestionTemplate
  | ({ type: "BooleanQuestion" } & WorkflowQuestionBaseTemplate)
  | ({ type: "ReturnToWorkQuestion" } & WorkflowQuestionBaseTemplate)
  | ({ type: "DatePickerQuestion" } & WorkflowQuestionBaseTemplate)
  | TimeQuestionTemplate
  | ShiftQuestionTemplate
  | ({ type: "AbsenceSummary" } & WorkflowQuestionBaseTemplate)
  | ({ type: "ConfirmationMessage" } & WorkflowQuestionBaseTemplate);

export interface WorkflowQuestionBase {
  id: string;
  index?: number;
  answer: QuestionAnswer;
  setAnswer: (answer: QuestionAnswer) => void;
  validateAnswer?: (answer: QuestionAnswer) => ValidationResult;
  requiresAnswer: boolean;
  isValid: boolean;
  config?: ConfigAbsenceQuestion;
  choices?: AnswerChoice[];
  hidden?: boolean;
  absenceReasonInfo?: AbsenceReasonAnswerInfo;
  promptTag?: ClientTranslationProps;
  descriptionTag?: ClientTranslationProps;
  postSubmit?: boolean;
  summary?: QuestionSummary;
  hideInSummary?: boolean;
  isSubmissionMode?: boolean;
  getSubmitErrorMsg?: () => string | undefined;
}

export interface CalendarQuestion extends WorkflowQuestionBase {
  type: "CalendarQuestion";
  schedule: EmployeeSchedule;
  previousAbsences: PreviousAbsence[];
}

export interface ShiftQuestion extends WorkflowQuestionBase {
  type: "ShiftQuestion";
  workShiftOptions: WorkShift[];
  addTempShift: (shift: WorkShift) => void;
  initialSameShiftToggle: () => boolean;
  sameShiftToggleHandler: (val: boolean) => void;
  maxShiftLengthThresholdInMinutes: number;
}

export interface TimeQuestion extends WorkflowQuestionBase {
  type: "TimeQuestion";
  scheduleOverrideWarning: boolean;
  scheduleOverrideMessage: LocaleTextEntry[] | ClientTranslationProps;
  selectedWorkShifts: WorkShiftForDate[];
  previousAbsences: PreviousAbsence[];
  maxShiftLengthThresholdInMinutes: number;
}

export type WorkflowSection =
  | ({ type: "MultipleChoiceListQuestion" } & WorkflowQuestionBase)
  | ({ type: "CallCenterEmployeePhoneNumber" } & WorkflowQuestionBase)
  | ({ type: "ReportStart" } & WorkflowQuestionBase)
  | ({ type: "ConfigQuestion" } & WorkflowQuestionBase)
  | ({ type: "MultipleChoiceTileQuestion" } & WorkflowQuestionBase)
  | ({ type: "MultipleChoiceListQuestion" } & WorkflowQuestionBase)
  | CalendarQuestion
  | ({ type: "BooleanQuestion" } & WorkflowQuestionBase)
  | ({ type: "ReturnToWorkQuestion" } & WorkflowQuestionBase)
  | ({ type: "DatePickerQuestion" } & WorkflowQuestionBase)
  | TimeQuestion
  | ShiftQuestion
  | ({ type: "AbsenceSummary" } & WorkflowQuestionBase)
  | ({ type: "ConfirmationMessage" } & WorkflowQuestionBase);

export type RTWAnswer = { returnToWorkDate?: Date };

export type WorkflowAnswer = {
  answer: string;
  answered?: boolean;
};

export type SubmitResult = {
  absenceIncidentId?: number;
  success: boolean;
  closingScripts?: LocaleTexts[];
  validationErrors?: ApiValidationError[];
};

export type CancelResult = {
  success: boolean;
};

export type Employee = {
  id: string;
  firstName: string;
  lastName: string;
};

export enum OnBehalfOf {
  Myself = "I’m reporting for myself",
  OtherEmployee = "I’m reporting on behalf of an employee"
}
