import { parse, format, addYears, isValid } from "date-fns";
import { enCA, enUS, es, frCA } from "date-fns/locale";
import { SupportedLanguage } from "features/translations/locales";

const referenceDate = addYears(new Date(), -40);

export const parseDate = (
  language: SupportedLanguage,
  str: string
): Date | null => {
  switch (language) {
    case SupportedLanguage.EnglishUS:
      return isValid(parse(str, "yyyy-M-d", referenceDate))
        ? parse(str, "yyyy-M-d", referenceDate)
        : parse(str, "yyyyMMdd", referenceDate);
    case SupportedLanguage.EnglishCA:
    case SupportedLanguage.FrenchCA:
      return isValid(parse(str, "yyyy-M-d", referenceDate))
        ? parse(str, "yyyy-M-d", referenceDate)
        : parse(str, "yyyyMMdd", referenceDate);
    case SupportedLanguage.Spanish:
      return isValid(parse(str, "d-M-yyyy", referenceDate))
        ? parse(str, "d-M-yyyy", referenceDate)
        : parse(str, "ddMMyyyy", referenceDate);
  }
};

export const formatDate = (language: SupportedLanguage, d: Date): string => {
  if (!isValid(d)) return "";
  switch (language) {
    case SupportedLanguage.EnglishUS:
      return format(d, "yyyy-MM-dd");
    case SupportedLanguage.FrenchCA:
    case SupportedLanguage.EnglishCA:
      return format(d, "yyyy-MM-dd");
    case SupportedLanguage.Spanish:
      return format(d, "dd-MM-yyyy");
  }
};

export const formatDateLong = (
  language: SupportedLanguage,
  d: Date
): string => {
  if (!isValid(d)) return "";
  switch (language) {
    case SupportedLanguage.EnglishUS:
      return format(d, "iiii, MMMM d, yyyy", { locale: enUS });
    case SupportedLanguage.FrenchCA:
      return format(d, "iiii, MMMM d, yyyy", { locale: frCA });
    case SupportedLanguage.EnglishCA:
      return format(d, "iiii, MMMM d, yyyy", { locale: enCA });
    case SupportedLanguage.Spanish:
      return format(d, "iiii, MMMM d, yyyy", { locale: es });
  }
};

/** get the human-readable placeholder string for a SupportedLanguage */
export const datePlaceholder = (language: SupportedLanguage): string => {
  switch (language) {
    case SupportedLanguage.EnglishUS:
      return "YYYY-MM-DD";
    case SupportedLanguage.FrenchCA:
    case SupportedLanguage.EnglishCA:
      return "YYYY-MM-DD";
    case SupportedLanguage.Spanish:
      return "DD-MM-YYYY";
  }
};
