import React, { useState, useEffect } from "react";
import styles from "./claims-list.module.scss";
import { TagButton } from "components/tag/tag";
import { Translation } from "components/translation";
import { useApiRequest, useDesktopBreakpoint, useWindowSize } from "hooks";
import {
  ClaimFilterUrlParamName,
  ClaimTypeFilter,
  FilterType,
  getFilterLabel,
  getFilterTypeFromString,
  MobileHeaderFragment,
  setCardLayout
} from "pages/claims/helpers";
import { ClaimCard } from "components/claim-card/claim-card";
import { StandardPage } from "page-templates/standard";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LoadingSpinner } from "components/loading-spinner";
import { getClaimsService } from "features/claims/claims-service";
import { getSessionService } from "features/session/session-service";
import { ClaimSummary } from "features/claims/claims-model";
import * as Routes from "routes";
import { useTranslation } from "hooks/translation";
import { ApiErrorView } from "components/api-error/api-error-view";

export const ClaimsListPage: React.FC = () => {
  const isDesktop = useDesktopBreakpoint();
  const sessionService = getSessionService();
  const history = useHistory();

  const queryClaimType =
    new URLSearchParams(useLocation().search).get(
      ClaimFilterUrlParamName.ClaimType
    ) || "all";

  const [activeFilter, setActiveFilter] = useState<FilterType>("all");

  useEffect(() => {
    const filterType = getFilterTypeFromString(queryClaimType);
    setActiveFilter(filterType || "all");
  }, [queryClaimType]);

  const { data: filters = [] } = useApiRequest<ClaimTypeFilter[]>(
    async () =>
      getClaimsService()
        .loadClaimTypeFilters(sessionService.getUser()?.client_code || "")
        .then(result => result),
    []
  );

  const { isLoading, data, error } = useApiRequest<ClaimSummary[]>(
    async () =>
      getClaimsService()
        .listActiveClaims(sessionService.getUser()?.ee_no || "")
        .then(result => result.claims),
    []
  );

  const t = useTranslation();

  const claims = data ?? [];

  const filteredClaims =
    activeFilter === "all"
      ? claims
      : claims.filter(c => c.claimType === activeFilter);

  const [windowWidth] = useWindowSize();
  const MOBILE_BREAK_POINT = 1024;
  const CARD_WIDTH = 352;
  const GRID_GAP = 32;
  const cardListClass = styles["card-list"];

  useEffect(() => {
    const cardList = document.querySelector<HTMLElement>(`.${cardListClass}`);

    if (cardList) {
      setCardLayout({
        cardList,
        windowWidth,
        mobileBreakPoint: MOBILE_BREAK_POINT,
        cardWidth: CARD_WIDTH,
        gridGap: GRID_GAP
      });
    }
  });

  const updateFilter = (filter: FilterType) => {
    history.push({
      search: `?${ClaimFilterUrlParamName.ClaimType}=${filter}`
    });
  };

  return (
    <StandardPage
      getHeaderFlexItems={({ isDesktop }) =>
        !isDesktop ? (
          <MobileHeaderFragment>
            <Translation tag={"nav.all_cases"} />
          </MobileHeaderFragment>
        ) : undefined
      }
      fixedPaneContents={
        <>
          {isDesktop && (
            <h1 className={styles["page-title"]}>
              <Translation tag={"claims.all_recent_activity"} />
            </h1>
          )}
          <span>
            <Translation tag={"claims.select_a_case"} />
          </span>
          <div className={styles["tag-filter-row"]}>
            {filters.map(f => (
              <TagButton
                key={f.filter}
                selectedStyle={"blue"}
                selected={f.filter === activeFilter}
                onClick={() => updateFilter(f.filter)}
              >
                <Translation {...f.label} />
              </TagButton>
            ))}
          </div>
        </>
      }
      bottomNavbarContents={
        <Link to={Routes.ClaimsHistory.generate({})}>
          <Translation tag={"nav.history"} />
        </Link>
      }
    >
      {isLoading || error ? (
        <>{isLoading ? <LoadingSpinner /> : <ApiErrorView />}</>
      ) : (
        <>
          {filteredClaims.length > 0 && (
            <div className={cardListClass}>
              {filteredClaims.map(claim => (
                <ClaimCard
                  key={`${claim.claimType}-${claim.incidentId}`}
                  className={styles["claim-card"]}
                  claim={claim}
                />
              ))}
            </div>
          )}
          {filteredClaims.length === 0 && (
            <>
              {activeFilter === "all" ? (
                <div className={styles["empty-cases"]}>
                  <img
                    className={styles["icon"]}
                    src={
                      process.env.REACT_APP_ASSETS_BASE +
                      "/images/claims/folder-empty-state.svg"
                    }
                  ></img>
                  <h3 className={styles["head"]}>
                    <Translation
                      tag={"claims.no_case.all"}
                      vars={{
                        navSection: t({ tag: "dashboard.recent_activity" })
                      }}
                    />
                  </h3>
                  <h4 className={styles["context"]}>
                    <Translation tag={"claims.no_case.start_reporting"} />
                  </h4>
                </div>
              ) : (
                <div className={styles["empty-cases"]}>
                  <img
                    className={styles["icon"]}
                    src={
                      process.env.REACT_APP_ASSETS_BASE +
                      "/images/claims/empty-state.svg"
                    }
                  ></img>
                  <h3 className={styles["head"]}>
                    <Translation
                      strong
                      tag={"claims.no_case.filtered"}
                      vars={{ filter: t(getFilterLabel(activeFilter)) }}
                      html={true}
                    />
                  </h3>
                  <h4 className={styles["context"]}>
                    <Translation
                      strong
                      tag={"claims.no_case.try_another_filter"}
                      vars={{
                        filter: t(getFilterLabel(activeFilter)),
                        navSection: t({ tag: "nav.history" })
                      }}
                      html={true}
                    />
                  </h4>
                </div>
              )}
            </>
          )}
        </>
      )}
    </StandardPage>
  );
};
