import React, { useContext, useState } from "react";
import {
  ClientTranslationProps,
  getDefaultLocalizedString,
  TranslationsMap
} from "i18n";
import { SupportedLanguage } from "features/translations/locales";

import { tryGetTranslation } from "i18n/core";
import _ from "lodash-es";
import { textForLang } from "shared-types/api";

type PartialTranslationsMap = Partial<TranslationsMap>;
type SetTranslationsFunction = (translations: PartialTranslationsMap) => void;

export type GetLocalizedStringFunction = (
  props: ClientTranslationProps,
  lang: SupportedLanguage
) => string;

type TranslationContextType = {
  currentTranslations: PartialTranslationsMap;
  setTranslations: SetTranslationsFunction;
  getLocalizedString: GetLocalizedStringFunction;
};

const TranslationContext = React.createContext<TranslationContextType>({
  currentTranslations: {},
  setTranslations: translations => {
    console.log(
      "Error: default setTranslations() called. Do you have a Provider?"
    );
    return;
  },
  getLocalizedString: (props, lang) => {
    console.log("default getLocalizedString() called. Do you have a provider?");
    return getDefaultLocalizedString(props, lang);
  }
});

export const TranslationProvider: React.FunctionComponent = props => {
  const [state, setState] = useState<PartialTranslationsMap>({});
  return (
    <TranslationContext.Provider
      value={{
        currentTranslations: state,
        setTranslations: t => {
          setState(t);
        },
        getLocalizedString: (props, lang) => {
          if (Object.keys(state).includes(lang)) {
            const thisLangMap = state[lang];

            if (thisLangMap) {
              const vars = _.mapValues(props.vars, v => {
                if (typeof v === "string") {
                  return v;
                } else {
                  return textForLang(v, lang);
                }
              });
              const customTranslation = tryGetTranslation(
                thisLangMap,
                props.tag,
                vars
              );
              if (customTranslation.result === "found") {
                return customTranslation.value;
              }
            }
          }

          return getDefaultLocalizedString(props, lang);
        }
      }}
    >
      {props.children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = () => useContext(TranslationContext);
