import { getTranslation, I18nMap, I18nProfile, PropsOf } from "./core";
import EN_CA from "./en-CA.json";
import EN_US from "./en-US.json";
import ES from "./es.json";
import FR_CA from "./fr-CA.json";
import { textForLang } from "shared-types/api";
import _ from "lodash-es";
import { SupportedLanguage } from "features/translations/locales";

export const ClientI18nProfile = I18nProfile
  /* Global */
  .with("global.welcomeMessage")
  .with("global.errorMessage")
  .with("global.apiError.msg")
  .with("global.apiError.title")
  .with("global.accessDenied.msg", "contactUs")
  .with("global.accessDenied.title")
  .with("global.accessDenied.contactUs")
  //   .with("global.help")
  /* Languages */
  .with("languages.selectLanguage")
  .with("languages.englishUnitedStates")
  .with("languages.englishCanada")
  .with("languages.english")
  .with("languages.french")
  .with("languages.spanishSelected")
  .with("languages.frenchCanada")
  .with("languages.spanish")
  //   /* Footer */
  //   .with("footer.copyright")
  //   .with("footer.privacyPolicy")
  //   .with("footer.termsConditions")
  /* Login */
  .with("login.loadApplication")
  .with("login.redirectToAuthServer")
  .with("login.welcomeToAbiliti")
  .with("login.morneauInstructions")
  .with("login.morningstarInstructions")
  .with("login.lastName")
  .with("login.employeeId")
  .with("login.dateOfBirth")
  .with("login.companyCode")
  .with("login.pin")
  .with("login.logIn")
  .with("login.imgAlts.companyLogo")
  .with("login.lastNameRequired")
  .with("login.employeeIdRequired")
  .with("login.dateOfBirthRequired")
  .with("login.dateOfBirthInvalidFormat", "dateFormat")
  .with("login.companyCodeRequired")
  .with("login.pinRequired")
  .with("login.pinMustHaveLength")
  .with("login.unexpectedError")
  .with("login.networkError")
  .with("login.incompleteDetails")
  .with("login.userNotAuthenticated", ["lockoutMinutes", "attemptsRemaining"])
  // .with("login.accountLocked")
  // .with("login.requireAssistance")
  // .with("login.pinTooLongError");
  /* Buttons */
  .with("buttons.cancel")
  .with("buttons.next")
  .with("buttons.previous")
  .with("buttons.edit")
  .with("buttons.save")
  .with("buttons.yes")
  .with("buttons.no")
  .with("buttons.back")
  .with("buttons.copy")
  .with("buttons.newCase")
  .with("buttons.leave")
  .with("buttons.keepReporting")
  .with("buttons.cancelAbsence")
  .with("buttons.continue")
  .with("buttons.restart")
  .with("buttons.logout")
  /* Time Periods*/
  .with("timePeriods.starting", "date")
  .with("timePeriods.startToEnd", ["startDate", "endDate"])
  .with("timePeriods.startAndEnd", ["startDate", "endDate"])
  .with("timePeriods.daysBetween", ["numDays", "startDate", "endDate"])
  .with("timePeriods.days")
  .with("timePeriods.weeks")
  .with("timePeriods.hours")
  .with("timePeriods.months")
  .with("timePeriods.years")
  .with("timePeriods.day")
  .with("timePeriods.week")
  .with("timePeriods.hour")
  .with("timePeriods.month")
  .with("timePeriods.year")
  /* Report an Absence */
  .with("reportAbsence.title")
  .with("reportAbsence.intro1")
  .with("reportAbsence.intro2")
  .with("reportAbsence.previousSelection", "previousSelection")
  .with("reportAbsence.validation.please_select_date")
  .with("reportAbsence.validation.please_save_changes")
  .with("reportAbsence.validation.existing_absence_day")
  .with("reportAbsence.validation.existing_absence_range")
  .with("reportAbsence.validation.return_to_work_date")
  .with("reportAbsence.validation.phone")
  .with("reportAbsence.validation.email")
  .with("reportAbsence.validation.numeric_min", "min")
  .with("reportAbsence.validation.numeric_max", "max")
  .with("reportAbsence.validation.please_answer_required_question")
  .with("reportAbsence.confirmation.thank_you")
  .with("reportAbsence.confirmation.confirmation_number")
  .with("reportAbsence.confirmation.forgot_number")
  .with("reportAbsence.confirmation.back_to_dashboard")
  .with("reportAbsence.confirmation.copied")
  .with("reportAbsence.confirmation.back_to_report_absence")
  .with("reportAbsence.all_for_now")
  .with("reportAbsence.continuation.back_to_work")
  .with("reportAbsence.continuation.description1", ["primaryReason", "date"])
  .with("reportAbsence.continuation.description2", [
    "primaryReason",
    "secondaryReason",
    "date"
  ])
  .with("reportAbsence.absence_date.prompt")
  .with("reportAbsence.absence_date.description")
  .with("reportAbsence.absence_shift.prompt")
  .with("reportAbsence.absence_shift.description")
  .with("reportAbsence.absence_shift.your_shift")
  .with("reportAbsence.absence_shift.add_shift")
  .with("reportAbsence.absence_shift.same_shift_toggle")
  .with("reportAbsence.absence_shift.shift_start")
  .with("reportAbsence.absence_shift.shift_end")
  .with("reportAbsence.absence_shift.overnight_shift")
  .with("reportAbsence.absence_shift.next_day")
  .with("reportAbsence.absence_shift.max_shift", "hrs")
  .with("reportAbsence.absence_shift.max_24_shift")
  .with("reportAbsence.absence_shift.add_diff_shift")

  .with("reportAbsence.absence_time.prompt")
  .with("reportAbsence.absence_time.description")
  .with("reportAbsence.absence_time.invalid_duration", "duration")
  .with("reportAbsence.absence_time.overlapping_absences", ["from", "to"])
  .with("reportAbsence.absence_time.overlapping_many_absences", [
    "from1",
    "to1",
    "from2",
    "to2"
  ])
  .with("reportAbsence.absence_time.change_absence_time")
  .with("reportAbsence.absence_duration.prompt")
  .with("reportAbsence.absence_duration.description_one_day", "date")
  .with("reportAbsence.absence_duration.description_two_days", [
    "date1",
    "date2"
  ])
  .with(
    "reportAbsence.absence_duration.description_more_than_two_days",
    "count"
  )
  .with("reportAbsence.absence_duration.description_confirmation")
  .with("reportAbsence.absence_duration.full_day_off_title")
  .with("reportAbsence.absence_duration.full_day_off_description")
  .with("reportAbsence.absence_duration.partial_day_off_title")
  .with("reportAbsence.absence_association.not_associated")
  .with("reportAbsence.absence_association.associated")
  .with("reportAbsence.employee_search.prompt")
  .with("reportAbsence.employee_search.description")
  .with("reportAbsence.employee_search.placeholder")
  .with("reportAbsence.employee_search.clear_button")
  .with("reportAbsence.employee_search.no_result")
  .with("reportAbsence.employee_search.title")
  .with("reportAbsence.employee_search.department")
  .with("reportAbsence.employee_search.office")
  .with("reportAbsence.employee_search.change_employee_warning_msg")
  .with("reportAbsence.employee_search.add_employee")
  .with("reportAbsence.employee_search.add_button")
  .with("reportAbsence.call_center.prompt", "employee_full_name")
  .with("reportAbsence.company_search.prompt")
  .with("reportAbsence.company_search.description")
  .with("reportAbsence.company_search.placeholder")
  .with("reportAbsence.company_search.clear_button")
  .with("reportAbsence.company_search.no_result")
  .with("reportAbsence.add_new_employee.prompt")
  .with("reportAbsence.add_new_employee.description")
  .with("reportAbsence.add_new_employee.optional")
  .with("reportAbsence.add_new_employee.errors.text")
  .with("reportAbsence.add_new_employee.errors.name")
  .with("reportAbsence.add_new_employee.errors.phone")
  .with("reportAbsence.add_new_employee.errors.email")
  .with("reportAbsence.add_new_employee.buttons.add")
  .with("reportAbsence.add_new_employee.buttons.change")
  .with("reportAbsence.add_new_employee.modal.title")
  .with("reportAbsence.add_new_employee.modal.content.top")
  .with("reportAbsence.add_new_employee.modal.content.middle")
  .with("reportAbsence.add_new_employee.modal.content.bottom")
  .with("reportAbsence.add_new_employee.modal.buttons.new_number")
  .with("reportAbsence.add_new_employee.modal.buttons.select")
  .with("reportAbsence.primary_absence_reason.prompt")
  .with("reportAbsence.return_to_work.prompt")
  .with("reportAbsence.secondary_absence_reason.description")
  .with("reportAbsence.continuation.exceedMaxDays", "days")
  .with("reportAbsence.summary.title")
  .with("reportAbsence.summary.prompt")
  .with("reportAbsence.summary.subtitle")
  .with("reportAbsence.summary.company")
  .with("reportAbsence.summary.employee_name")
  .with("reportAbsence.summary.employee_id")
  .with("reportAbsence.summary.phone_number")
  .with("reportAbsence.summary.reason")
  .with("reportAbsence.summary.primary_reason")
  .with("reportAbsence.summary.secondary_reason")
  .with("reportAbsence.summary.absence_period")
  .with("reportAbsence.summary.submit")
  .with("reportAbsence.summary.leaving")
  .with("reportAbsence.summary.returning")
  .with("reportAbsence.summary.return_to_work_date")
  .with("reportAbsence.summary.show_all_dates")
  .with("reportAbsence.summary.shift_ends")
  .with("reportAbsence.summary.to")
  .with("reportAbsence.summary.your_shift", ["start_shift", "end_shift"])
  .with("reportAbsence.summary.cic_email_will_be_sent_to_label")
  .with("reportAbsence.absence_on_behalf.title")
  .with("reportAbsence.absence_on_behalf.myself")
  .with("reportAbsence.absence_on_behalf.other")
  .with("reportAbsence.cancel.title")
  .with("reportAbsence.cancel.support_text")
  .with("reportAbsence.restart.title")
  .with("reportAbsence.restart.support_text")
  .with("errors.page_not_found")
  .with("errors.select_at_least_one_value")
  .with("nav.dashboard")
  .with("nav.resources")
  .with("nav.all_cases")
  .with("nav.apply_for_fml")
  .with("nav.apply_for_std")
  .with("nav.apply_for_wcb")
  .with("nav.need_help")
  .with("nav.settings")
  .with("nav.log_out")
  .with("nav.history")
  .with("nav.goto_connect")
  .with("general.items_not_shown")
  .with("general.show_less")
  .with("dashboard.welcome")
  .with("dashboard.things_to_do")
  .with("dashboard.active_claims")
  .with("dashboard.report_absence_button")
  .with("dashboard.to_do_activities", "things_to_do")
  .with("dashboard.recent_activity")
  .with("dashboard.breakdown_by_policy")
  .with("dashboard.period_left", "period")
  .with("dashboard.view")
  /* Calendar */
  .with("calendar.one_day_at_a_time")
  .with("calendar.next_month")
  .with("calendar.previous_month")
  .with("calendar.toggle_year_selection")
  .with("calendar.toggle_one_day_at_a_time")
  .with("calendar.day_selected")
  .with("calendar.day_not_selected")
  .with("calendar.day_unselectable")
  /* Idle Modal */
  .with("idle_modal.title")
  .with("idle_modal.description")
  .with("idle_modal.stay_logged_in")
  /* Logout Modal */
  .with("logout_modal.title")
  .with("logout_modal.exit")
  /* Warning Messages */
  .with("warnings.overnight_shift_no_date")
  .with("warnings.overnight_shift", "date")
  .with("warnings.overnight_shift_start")
  .with("warnings.overnight_shift_end")

  .with("warnings.intermittent_all_overnight_shift")
  .with("warnings.intermittent_some_overnight_shift")
  .with("warnings.schedule_override")
  /* Date Picker */
  .with("date_picker.helper_text")
  .with("date_picker.enter_date")
  .with("date_picker.calendar")
  .with("date_picker.correct_format_text")
  .with("date_label.starting")
  .with("date_label.ending")
  .with("date_label.separator")
  .with("claims.show_all_decisions")
  .with("claims.continuous_leave")
  .with("claims.intermittent_leave")
  .with("claims.reduced_hours_leave")
  .with("claims.claim_hours_desc")
  .with("claims.all_recent_activity")
  .with("claims.select_a_case")
  .with("claims.see_discharged_cases")
  .with("claims.time", ["startTime", "endTime"])
  .with("claims.created_by", "createdBy")
  .with("claims.show_all")
  .with("claims.show_less")
  .with("claims.items_not_shown", "itemsNotShown")
  .with("claims.label.case")
  .with("claims.label.case_id")
  .with("claims.label.std_start_date")
  .with("claims.label.type_of_leave")
  .with("claims.label.pertains_to")
  .with("claims.label.date_of_request")
  .with("claims.label.decision_start")
  .with("claims.label.decision_end")
  .with("claims.label.ongoing")
  .with("claims.label.fmla")
  .with("claims.tag.all")
  .with("claims.tag.absence")
  .with("claims.tag.fml")
  .with("claims.tag.std")
  .with("claims.tag.wcb")
  .with("claims.no_case.all", "navSection")
  .with("claims.no_case.start_reporting")
  .with("claims.no_case.filtered", "filter")
  .with("claims.no_case.try_another_filter", ["filter", "navSection"])
  .with("claims.no_case.cases_will_appear")
  .with("claims.tab.case_data")
  .with("claims.tab.documents")
  .with("claims.status.open")
  .with("claims.status.closed")
  .with("claims.status.approved")
  .with("claims.status.pending")
  .with("claims.status.pending_review")
  .with("claims.status.pending_paperwork")
  .with("claims.status.cancelled")
  .with("claims.status.denied")
  .with("claims.status.missing_paperwork")
  .with("claims.status.submitted")
  .with("claims.status.in_progress")
  .with("claims.status.completed")
  .with("claims.status.incomplete")
  .with("claims.status.outcomed")
  .with("claims.relationship.child")
  .with("claims.relationship.child_adopted")
  .with("claims.relationship.child_biological")
  .with("claims.relationship.child_adult")
  .with("claims.relationship.child_partner")
  .with("claims.relationship.child_foster")
  .with("claims.relationship.child_step")
  .with("claims.relationship.in_loco_parentis")
  .with("claims.relationship.legal_ward")
  .with("claims.relationship.next_of_kin")
  .with("claims.relationship.parent")
  .with("claims.relationship.parent_step")
  .with("claims.relationship.spouse")
  .with("claims.relationship.self")
  .with("claims.table.header", "caseId")
  .with("claims.table.confirmation_number")
  .with("claims.table.date")
  .with("claims.table.start_time")
  .with("claims.table.end_time")
  .with("claims.table.duration")
  .with("claims.table.unpaid_time_in_minutes")
  .with("claims.table.footer")
  .with("claims.pagination.pages", ["start", "end", "all"])
  .with("fml_detail.hours_planned")
  .with("fml_detail.hours_used")
  .with("fml_detail.days_planned")
  .with("fml_detail.days_used")
  .with("fml_detail.weeks_planned")
  .with("fml_detail.weeks_used")
  .with("fml_detail.months_planned")
  .with("fml_detail.months_used")
  .with("absence_detail.absence_period_with_duration", "absence_duration")
  .with("absence_detail.absence_period")
  .with("absence_detail.absence_time_period", [
    "absence_time_from",
    "absence_time_to"
  ])
  .with("absence_detail.std_case_id")
  .with("absence_detail.fml_case_id")
  .with("absence_detail.linked_cases")
  .with("absence_detail.rtw_date")
  .with("absence_detail.continuous_start_label", "dayOfWeek")
  .with("absence_detail.non_continuous_start_label", "daysCount")
  .with("absence_detail.continuous_end_label", "dayOfWeek")
  .with("absence_detail.absence_hours", "hoursCount")
  .with("absence_detail.unpaid_minutes", "minutesCount")
  .with("absence_detail.confirmation_number")
  .with("absence_detail.items_not_shown", "daysCount")
  .with("absence_detail.absence_hours_and_minutes", [
    "hoursCount",
    "minutesCount"
  ])
  .with("absence_detail.absence_minutes", "minutesCount")
  .with("absence_detail.all_dates")
  .with("in_progress_absence_modal.title")
  .with("in_progress_absence_modal.support_text")
  .with("in_progress_absence_modal.employee_support_text", "employeeFullName")
  .with("leave-policy.claims_list_header", "policyName")
  .with("leave-policy.no_claims", "policyName")
  .with("leave-policy.no_claims_subtext")
  .with("docs.upload_documents")
  .with("docs.upload_error")
  .with("docs.upload_drag_drop_or")
  .with("docs.upload_ask_description")
  .with("docs.upload_pick_description")
  .with("docs.browse_files")
  .with("docs.upload_documents")
  .with("docs.upload_close")
  .with("docs.upload")
  .with("docs.uploaded")
  .with("docs.uploading");

export type ClientTranslationProps = PropsOf<typeof ClientI18nProfile>;

export type TranslationsMap = Record<SupportedLanguage, I18nMap>;

export const AbsenceI18n: TranslationsMap = {
  [SupportedLanguage.EnglishCA]: ClientI18nProfile.verifyI18nMap(EN_CA),
  [SupportedLanguage.EnglishUS]: ClientI18nProfile.verifyI18nMap(EN_US),
  [SupportedLanguage.FrenchCA]: ClientI18nProfile.verifyI18nMap(FR_CA),
  [SupportedLanguage.Spanish]: ClientI18nProfile.verifyI18nMap(ES)
};

/**
 * Look up a translation from among the fixed, default UI strings
 * built in to the app. (doesn't look for client-customized strings)
 * @param props
 * @param lang
 */
export function getDefaultLocalizedString(
  props: ClientTranslationProps,
  lang: SupportedLanguage
): string {
  const translations = AbsenceI18n[lang];
  const vars = _.mapValues(props.vars, v => {
    if (typeof v === "string") {
      return v;
    } else {
      return textForLang(v, lang);
    }
  });
  return getTranslation(translations, props.tag, vars);
}

const SUPPORTED_LANGUAGES: string[] = Object.values(SupportedLanguage);
export function languageIsSupported(
  locale: string
): locale is SupportedLanguage {
  return SUPPORTED_LANGUAGES.includes(locale);
}
