import { SupportedLanguage } from "features/translations/locales";
import { TranslationSet } from "features/translations/translation-model";

export type AppContextEnv = "sandbox" | "development" | "qa" | "production";

export class AppContext {
  private static _instance: AppContext;

  private appEnv: string = process.env.REACT_APP_ENV || process.env.NODE_ENV;

  private translations: TranslationSet = new TranslationSet(
    SupportedLanguage.EnglishCA,
    new Map()
  );

  static setSandbox = () => AppContext.setEnvironment("sandbox");

  static setEnvironment = (env: AppContextEnv) => {
    AppContext.get().appEnv = env;
  };

  private static get(): AppContext {
    if (!AppContext._instance) AppContext._instance = new AppContext();
    return AppContext._instance;
  }

  static env = () => AppContext.get().appEnv;

  static isSandbox = () => AppContext.env() === "sandbox";

  static setTranslations = (t: TranslationSet) => {
    AppContext.get().translations = t;
  };

  static getTranslations = () => AppContext.get().translations;
}
