import { ISessionService } from "./session-model";
import { AppContext } from "../../context/app-context";
import { MockSessionService } from "./session-service-mock";
import { TokenSessionService } from "./token-session-service";

export const getSessionService = (): ISessionService => {
  if (AppContext.isSandbox()) {
    return new MockSessionService();
  }
  return new TokenSessionService();
};
