import React from "react";
import { BaseModal, modalStyle } from "components/base-modal/base-modal";
import { ContainedButton, TextButton } from "components/buttons";
import styles from "./leave-proceed-modal.module.scss";
import { cloneDeep } from "lodash-es";

type Props = {
  onRequestClose: () => void;
  onRequestLeave: () => void;
  onRequestProceed: () => void;
  title: string;
  contentText: string | JSX.Element;
  leaveButtonText: string;
  proceedButtonText: string;
  isOpen: boolean;
};

export const LeaveProceedModal: React.FC<Props> = ({
  onRequestClose,
  onRequestLeave,
  onRequestProceed,
  title,
  contentText,
  leaveButtonText,
  proceedButtonText,
  isOpen
}) => {
  const modalStyles = cloneDeep(modalStyle);
  if (modalStyles.content) {
    modalStyles.content.width = "544px";
  }

  return (
    <BaseModal
      isOpen={isOpen}
      title={title}
      closeModal={onRequestClose}
      customStyles={modalStyles}
      buttonClass={styles["close-button"]}
    >
      <div className={styles["content"]}>{contentText}</div>
      <div>
        <div className={styles["button-row"]}>
          <TextButton onClick={onRequestLeave} testId="leave-btn">
            {leaveButtonText}
          </TextButton>

          <ContainedButton onClick={onRequestProceed} testId="proceed-btn">
            {proceedButtonText}
          </ContainedButton>
        </div>
      </div>
    </BaseModal>
  );
};
