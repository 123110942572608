import React from "react";
import styles from "./logout-modal.module.scss";
import { ContainedButton, TextButton } from "components/buttons";
import { Translation } from "components/translation";
import { cloneDeep } from "lodash-es";
import { BaseModal, modalStyle } from "components/base-modal/base-modal";
import { useTranslation } from "hooks/translation";

type ModalProps = {
  isOpen: boolean;
  logout: () => void;
  closeModal: () => void;
};

const LogoutModal = ({ isOpen, logout, closeModal }: ModalProps) => {
  const t = useTranslation();

  const logoutPopupStyles = cloneDeep(modalStyle);
  if (logoutPopupStyles.content) {
    logoutPopupStyles.content.width = "auto";
  }

  return (
    <BaseModal
      isOpen={isOpen}
      title={t({ tag: "logout_modal.title" })}
      customStyles={logoutPopupStyles}
      titleRowClass={styles["title-row"]}
      titleClass={styles["title"]}
      buttonClass={styles["logout-button"]}
      closeModal={closeModal}
      testId="logout-modal"
    >
      <div>
        <div className={styles["button-row"]}>
          <TextButton onClick={closeModal} testId="cancel">
            <Translation tag="buttons.cancel" />
          </TextButton>

          <ContainedButton onClick={logout} testId={"modal-logout"}>
            <Translation tag="nav.log_out" />
          </ContainedButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default LogoutModal;
