import {
  add,
  addDays,
  eachDayOfInterval,
  isWeekend,
  startOfToday,
  subDays
} from "date-fns";
import {
  IEmployeeService,
  EmployeeSchedule,
  QuickSearchEmployee,
  QuickEmployeeSearchResult,
  SearchCompanies,
  EmployeeData,
  CheckEmployeeByNumber
} from "./employee-model";
import { random, range } from "lodash-es";

export class MockEmployeeService implements IEmployeeService {
  getSchedule = async (
    clientCode?: string,
    employeeNumber?: string,
    from?: Date | undefined,
    to?: Date | undefined
  ): Promise<EmployeeSchedule> =>
    new Promise<EmployeeSchedule>((resolve, reject) => {
      const dates = eachDayOfInterval({
        start: from || addDays(new Date(), -30),
        end: to || addDays(new Date(), 30)
      });
      const schedule: EmployeeSchedule = {
        dates: dates.map(d => {
          return {
            date: d,
            hoursWorked: isWeekend(d) ? 0 : 8
          };
        })
      };
      resolve(schedule);
    });

  checkEmployeeByNumber = async (clientCode: string, employeeNumber: string) =>
    new Promise<CheckEmployeeByNumber | null>(resolve => {
      resolve({
        employeeId: 170160,
        firstName: "Michael",
        lastName: "Smith",
        employeeNumber: "17016",
        hasAccess: true
      });
    });

  getEmployeeData = async (eeNumber: string) =>
    new Promise<EmployeeData>((resolve, reject) => {
      resolve({
        firstName: "John",
        lastName: "Washington",
        phoneNumber: "1234512345"
      } as EmployeeData);
    });

  searchEmployees = async (searchTerm: string) =>
    new Promise<QuickSearchEmployee>((resolve, reject) => {
      const field: keyof QuickEmployeeSearchResult = "firstName";
      const items = [
        {
          document: {
            birthDate: "9/21/1959 12:00:00 AM",
            clientCode: "DISD",
            employeeData: null,
            employeeDropDownLabelDetails: ["WASHINGTON", "SHAWANA", "20482"],
            employeeId: 1049481,
            employeeNumber: "20482",
            firstName: "SHAWANA",
            lastName: "WASHINGTON",
            jobTitle: "MAINTENANCE SPECIALIST",
            organizationId: 130718,
            organizationName: "Dallas Independent School District"
          },
          highlightData: [
            {
              field,
              highlightData: ["SHAWANA"]
            }
          ]
        }
      ];

      resolve({ items });
    });

  searchCompany = async (absenceReportingVersion: string, searchTerm: string) =>
    new Promise<SearchCompanies>((resolve, reject) => {
      const items = [
        {
          clientCode: "DISD",
          companyId: 1,
          name: "Dallas Independent School District"
        }
      ];

      resolve({ items });
    });

  getCompaniesCount = async (absenceReportingVersion: string) =>
    new Promise<number>((resolve, reject) => {
      resolve(2);
    });

  async getAbsences() {
    const today = startOfToday();
    const randomDay = () => subDays(today, random(1, 30));
    const dates = range(5).map(randomDay);

    const previousAbsences = dates.map((d, i) => ({
      absenceIncidentId: i,
      isPartialAbsence: random(0, 1) === 0,
      shiftStartTime: add(d, { hours: 9 }),
      shiftEndTime: add(d, { hours: 17 })
    }));

    const now = new Date(Date.now());
    const recentAbsence = {
      absenceIncidentId: 99,
      isPartialAbsence: random(0, 1) === 0,
      shiftStartTime: subDays(now, 3),
      shiftEndTime: subDays(now, 2),
      primaryReason: "67abb32e-8142-48d8-859f-6b7f609e8132", // Personal day off
      secondaryReason: "ab2c675d-96e3-42e6-955b-ec7f3da32425" // Personal emergency
    };

    return Promise.resolve({
      previousAbsences: [...previousAbsences, recentAbsence]
    });
  }

  async getAbsencesForEmployee(clientCode: string, employeeNumber: string) {
    const today = startOfToday();
    const randomDay = () => subDays(today, random(1, 30));
    const dates = range(5).map(randomDay);

    const previousAbsences = dates.map((d, i) => ({
      absenceIncidentId: i,
      isPartialAbsence: random(0, 1) === 0,
      shiftStartTime: add(d, { hours: 9 }),
      shiftEndTime: add(d, { hours: 17 })
    }));

    const now = new Date(Date.now());
    const recentAbsence = {
      absenceIncidentId: 99,
      isPartialAbsence: random(0, 1) === 0,
      shiftStartTime: subDays(now, 3),
      shiftEndTime: subDays(now, 2),
      primaryReason: "67abb32e-8142-48d8-859f-6b7f609e8132", // Personal day off
      secondaryReason: "ab2c675d-96e3-42e6-955b-ec7f3da32425" // Personal emergency
    };

    return Promise.resolve({
      previousAbsences: [...previousAbsences, recentAbsence]
    });
  }
}
