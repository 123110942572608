export type EmployeeScheduleDate = {
  date: Date;
  hoursWorked: number;
};

export type EmployeeSchedule = {
  dates: EmployeeScheduleDate[];
};

export type CheckEmployeeByNumber = {
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  hasAccess: boolean;
  employeeId: number;
} | null;

export type QuickSearchDetails = {
  title: string;
  description: string;
};

export type QuickEmployeeSearchResult = {
  birthDate?: string;
  clientCode?: string;
  employeeData?: string;
  employeeId?: number;
  employeeNumber?: string;
  employeeDropDownLabelDetails?: string[];
  rawEmployeeNumber?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  organizationId?: number;
  organizationName?: string;
  details?: QuickSearchDetails[];
};

export type ResponseDocumentHighlight = {
  field?: keyof QuickEmployeeSearchResult;
  highlightData?: string[] | null;
};

interface Document {
  [key: string]: unknown;
}

export type ResponseDocumentOfQuickEmployeeSearchResult = {
  document?: Document;
  highlightData?: ResponseDocumentHighlight[];
};

export type QuickSearchEmployee = {
  items: ResponseDocumentOfQuickEmployeeSearchResult[] | null;
};

export type SearchCompanies = {
  items: SearchCompaniesResult[];
};

export type SearchCompaniesResult = {
  clientCode?: string | null;
  companyId?: number | null;
  name?: string | null;
  rawName?: string | null;
};

export type PreviousAbsence = {
  absenceIncidentId?: number | null;
  isPartialAbsence: boolean;
  shiftStartTime?: Date;
  shiftEndTime?: Date;
  primaryReason?: string;
  secondaryReason?: string;
  startDate?: Date;
  endDate?: Date;
  scheduledShiftStartTime?: Date;
  scheduledShiftEndTime?: Date;
};

export type EmployeeHistory = {
  previousAbsences: PreviousAbsence[];
};

export type EmployeeData = {
  phoneNumber?: string;
  firstName: string;
  lastName: string;
  email?: string;
  employeeCountry: string;
  employeeState?: string;
};

export const emptySchedule = (): EmployeeSchedule => {
  return { dates: [] };
};

export const emptyHistory = (): EmployeeHistory => {
  return { previousAbsences: [] };
};

export interface IEmployeeService {
  getSchedule(
    clientCode: string,
    employeeNumber: string,
    from?: Date,
    to?: Date
  ): Promise<EmployeeSchedule>;
  searchEmployees(
    searchTerms: string,
    clientCode: string
  ): Promise<QuickSearchEmployee>;
  getAbsences(
    clientCode?: string,
    employeeNumber?: string
  ): Promise<EmployeeHistory>;
  checkEmployeeByNumber(
    clientCode: string,
    employeeNumber: string
  ): Promise<CheckEmployeeByNumber>;
  getEmployeeData(clientCode: string, eeNumber: string): Promise<EmployeeData>;
  searchCompany(
    absenceReportingVersion: string,
    searchTerm: string
  ): Promise<SearchCompanies>;
  getCompaniesCount(absenceReportingVersion: string): Promise<number>;
}
