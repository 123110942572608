import React, { ButtonHTMLAttributes } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

/*
 * Note about tooltips:
 * If you want to add a react-tooltip to a button, put it on some kind of wrapper:
 *   <span data-tip="This is my tooltip"><ContainedButton .../></span>
 * Adding the tip directly to a <button> element doesn't work in all browsers when the
 * button is disabled. And wrapping all buttons in a span would mess with flexbox layouts.
 */

type Props = {
  onClick?: () => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLElement>) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  id?: string;
  disabled?: boolean;
  readonly?: boolean;
  ariaLabel?: string;
  testId?: string;
  className?: string;
  dataText?: string;
};

export const ContainedButton: React.FunctionComponent<Props> = props => {
  return (
    <BaseButton
      {...props}
      className={clsx(styles["btn--contained"], props.className)}
    />
  );
};

export const OutlinedButton: React.FunctionComponent<Props> = props => {
  return (
    <BaseButton
      {...props}
      className={clsx(styles["btn--outlined"], props.className)}
    />
  );
};

export const IconButton: React.FunctionComponent<Props> = props => {
  return (
    <BaseButton
      {...props}
      className={clsx(styles["btn--icon"], props.className)}
    />
  );
};

export const TextButton: React.FunctionComponent<Props> = props => {
  return (
    <BaseButton
      {...props}
      className={clsx(styles["btn--text"], props.className)}
    />
  );
};

const BaseButton: React.FunctionComponent<Props> = props => {
  return (
    <button
      type={props.type ?? "button"}
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      className={clsx(
        styles["btn"],
        props.readonly && styles["readonly"],
        props.className
      )}
      disabled={props.disabled || props.readonly}
      aria-label={props.ariaLabel}
      data-testid={props.testId}
      data-text={props.dataText}
    >
      {props.children}
    </button>
  );
};
