import {
  ClaimsListResult,
  IClaimsService,
  ListClaimsOptions
} from "features/claims/claims-service";
import {
  AbsenceClaimStatus,
  ClaimDecision,
  ClaimPolicy,
  ClaimRelationship,
  LeaveClaimStatus,
  StdClaimStatus,
  ClaimSummary,
  ClaimType,
  ClaimTypes,
  DecisionStatus,
  FmlClaimData,
  StdCaseData,
  ClaimListIncidentType,
  LeaveTypeCode,
  AbsenceClaimData,
  LeavePolicy
} from "features/claims/claims-model";
import { gql } from "@apollo/client";
import {
  AbilitiReferral,
  ClaimDate,
  DecisionViewModel,
  LeaveClaim,
  LeaveDeterminationViewModel,
  LeavePolicyViewModel,
  ListActiveClaimsQuery,
  SearchClaimsQuery,
  ListHistoricalClaimsQuery,
  LoadCompanyListIncidentTypesQuery,
  LoadFmlClaimQuery,
  LoadStdClaimQuery,
  LocaleModel,
  Maybe,
  MyAbilitiArClaim,
  RefIncidentTypes,
  SearchRequestOfClaimSearchRequestInput,
  StdClaim,
  Connect_LocaleModel,
  GetLeavePolicySummaryQuery,
  ListClaimsQuery
} from "graphql-types.gen";
import { parseISO } from "date-fns";
import { getApiGraphQL } from "api/api-graphql";
import {
  TimeInterval,
  toDateInterval,
  toTimeInterval
} from "shared-types/time";
import { LocaleTextEntry, LocaleTexts, toLocaleText } from "shared-types/api";
import { AbsenceType } from "features/report-absence/incident";
import _ from "lodash-es";
import { hasValue } from "utils/util";
import { isValidDate } from "shared-types/date";
import { parseLocaleModel } from "features/report-absence/api-parse-helpers";
import { ClaimTypeFilter, filters } from "pages/claims/helpers";

const fragments = {
  searchClaimsResults: gql`
    fragment SearchClaimsResults on IClaim {
      __typename
      incidentId
      incidentTypeCode
      status
      caseCancelled
      claimStatus
      ... on STDClaim {
        caseTypeLocale {
          description
          locale
        }
        dateOfIncident
        firstDateOfAbsence
        relationShip
      }
      ... on LeaveClaim {
        leaveTypeCode
        leaveTypeLocale {
          description
          locale
        }
        leaveStartDate
        relationShip
        conditionCategory
        conditionCategoryLocale {
          description
          locale
        }
        primaryReasonOfAbsenceLocale {
          description
          locale
        }
        secondaryReasonOfAbsenceLocale {
          description
          locale
        }
        leaveStatus
        leaveStatusLocale {
          description
          locale
        }
      }
      ... on MyAbilitiARClaim {
        absenceType
        relationShip
        primaryReasonLocale {
          description
          locale
        }
        secondaryReasonLocale {
          description
          locale
        }
        claimDates {
          startDate
          endDate
        }
        claimStatus
      }
      ... on AbilitiReferral {
        caseType
        leaveTypeLocale {
          description
          locale
        }
        caseTypeLocale {
          description
          locale
        }
        firstDateOfAbsence
        relationShip
        isWCBReferral
        isLeavesReferral
        primaryReasonOfAbsenceLocale {
          description
          locale
        }
        secondaryReasonOfAbsenceLocale {
          description
          locale
        }
        leaveTypeCode
      }
    }
  `
};

export class ApiClaimsService implements IClaimsService {
  async listActiveClaims(
    employeeNumber = "",
    policyCodes: string[] = [],
    clientCode = "",
    claimNumbers: string[] = []
  ): Promise<ClaimsListResult> {
    const request: SearchRequestOfClaimSearchRequestInput = {
      pageNumber: 1,
      pageSize: 100,
      clientCode,
      parameters: {
        employeeNumber,
        andFields: [
          {
            fieldName: "claimStatus",
            fieldValue: "I,C",
            operator: "NOT_IN",
            includeNullValues: false
          },
          {
            fieldName: "leaveStatus",
            fieldValue: "CANCELLED",
            operator: "NOT_IN",
            includeNullValues: false
          }
        ],
        claimNumbers,
        policyCodes
      }
    };

    const query = await getApiGraphQL().query<ListActiveClaimsQuery>({
      query: gql`
        query ListActiveClaims(
          $request: SearchRequestOfClaimSearchRequestInput
        ) {
          results: searchActiveClaims(
            request: $request
            incidentTypes: [
              ABILITI_REFERRAL
              INCIDENT_AR
              INCIDENT_GENERIC_LEAVE
              INCIDENT_STD
              INCIDENT_MYABILITI_AR
            ]
          ) {
            items {
              ...SearchClaimsResults
            }
            totalRecords
          }
        }
        ${fragments.searchClaimsResults}
      `,
      variables: {
        request
      }
    });

    if (query.errors) {
      throw query.errors;
    }

    const claims = (query.data.results?.items ?? [])
      .filter(hasValue)
      .map(parseClaim)
      .filter(hasValue);
    return {
      claims: claims,
      total: query.data.results?.totalRecords
    };
  }

  async getClaimsByIds(
    employeeNumber = "",
    clientCode = "",
    claimNumbers: string[] = []
  ): Promise<ClaimsListResult> {
    if (claimNumbers.length === 0) {
      return Promise.resolve({
        total: 0,
        claims: []
      });
    }
    return this.listActiveClaims(employeeNumber, [], clientCode, claimNumbers);
  }

  async getIncompleteClaims(
    employeeNumber: string,
    clientCode: string
  ): Promise<ClaimSummary[]> {
    const request: SearchRequestOfClaimSearchRequestInput = {
      pageNumber: 1,
      pageSize: 100,
      clientCode,
      parameters: {
        employeeNumber,
        claimStatus: "I"
      },
      sortingFields: [
        {
          fieldName: "createdDate",
          fieldType: "Date",
          order: "DESC"
        }
      ]
    };

    const query = await getApiGraphQL().query<SearchClaimsQuery>({
      query: gql`
        query SearchClaims($request: SearchRequestOfClaimSearchRequestInput) {
          results: searchClaims(
            request: $request
            incidentTypes: [INCIDENT_MYABILITI_AR]
          ) {
            items {
              ... on MyAbilitiARClaim {
                incidentId
                createdBy
                createdDate
                relationShip
                absenceType
                primaryReasonLocale {
                  description
                  locale
                }
                claimDates {
                  startDate
                  endDate
                }
              }
            }
          }
        }
      `,
      variables: {
        request
      }
    });
    const claims = (query.data.results?.items || []).map(c =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseSearchClaims(c!)
    );

    if (query.errors) {
      throw query.errors;
    }

    return claims;
  }

  async listHistoricalClaims(
    options: ListClaimsOptions,
    employeeNumber: string
  ): Promise<ClaimsListResult> {
    const request: SearchRequestOfClaimSearchRequestInput = {
      pageNumber: options.page,
      pageSize: options.pageSize,
      parameters: {
        employeeNumber
      },
      sortingFields: [
        {
          fieldName: "firstDateOfAbsence",
          fieldType: "Date",
          order: "DESC"
        }
      ]
    };

    const query = await getApiGraphQL().query<ListHistoricalClaimsQuery>({
      query: gql`
        query ListHistoricalClaims(
          $incidentTypes: [RefIncidentTypes!]!
          $request: SearchRequestOfClaimSearchRequestInput
        ) {
          results: searchClaimsWithQuickSearchType(
            quickSearchType: HISTORICALCLAIMS
            incidentTypes: $incidentTypes
            request: $request
          ) {
            items {
              ...SearchClaimsResults
            }
            totalRecords
          }
        }
        ${fragments.searchClaimsResults}
      `,
      variables: {
        incidentTypes: options.claimType
          ? [toIncidentTypeCode(options.claimType)]
          : ClaimTypes.map(toIncidentTypeCode),
        request
      }
    });

    return {
      claims: (query.data.results?.items ?? [])
        .filter(hasValue)
        .map(parseClaim)
        .filter(hasValue),
      total: query.data.results?.totalRecords
    };
  }

  async loadFmlClaim(caseId: number): Promise<FmlClaimData> {
    const query = await getApiGraphQL().query<LoadFmlClaimQuery>({
      query: gql`
        query LoadFmlClaim($caseId: Int!) {
          leaveCaseDetails(caseId: $caseId) {
            caseId
            claimStatus
            claimStatusLocale {
              locale
              description
            }
            caseType
            conditionCategoryLocale {
              description
              locale
            }
            primaryReasonLocale {
              description
              locale
            }
            secondaryReasonLocale {
              description
              locale
            }
            caseTypeGroupType
            determinations {
              resolution
              startDate
              endDate
              policyCode
              policyCodeLocale {
                description
                locale
              }
            }
            dischargeDate
            firstDate
            lastDate
            leaveTypeCode
            leaveTypeCodeLocale {
              description
              locale
            }
            leaveStatus
            leaveStatusLocale {
              description
              locale
            }
            leaveAbsenceType
            outcomeDate
            outcomeType
            phaseType
            policies {
              planned
              policyCode
              policyCodeLocale {
                description
                locale
              }
              plannedHours
              usedHours
              remaining
              remainingHours
              displayHours
              total
              unit
              used
              eligibility
            }
            relationShip
            returnToWorkDate
            claimTypeLocale {
              description
              locale
            }
          }
        }
      `,
      variables: {
        caseId
      }
    });

    if (query.errors) {
      return Promise.reject(query.errors);
    }

    const caseDetails = query.data?.leaveCaseDetails;
    if (caseDetails) {
      const fmlClaimData = parseLeaveCaseDetail(caseDetails);
      if (fmlClaimData) {
        return fmlClaimData;
      }
    }

    return Promise.reject();
  }

  async loadStdClaim(caseId: number): Promise<StdCaseData> {
    const query = await getApiGraphQL().query<LoadStdClaimQuery>({
      query: gql`
        query LoadStdClaim($caseId: Int!) {
          sTDCaseDetails(caseId: $caseId) {
            caseId
            sTDStartDate
            firstDate
            claimStatus
            claimStatusLocale {
              locale
              description
            }
            caseTypeLocale {
              locale
              description
            }
            decisions {
              decisionType
              startDate
              endDate
            }
          }
        }
      `,
      variables: {
        caseId
      }
    });

    if (query.errors) {
      return Promise.reject(query.errors);
    }

    const caseDetails = query.data?.sTDCaseDetails;
    if (caseDetails) {
      const stdClaimData = parseStdCaseDetails(caseDetails);
      if (stdClaimData) {
        return stdClaimData;
      }
    }

    return Promise.reject();
  }

  async loadAbsenceDetails(
    id: number
  ): Promise<
    AbsenceClaimData & { childIncidents: MyAbilitiArClaim["childIncidents"] }
  > {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query = await getApiGraphQL().query<any>({
      query: gql`
        query LoadAbsenceClaim($id: Long!) {
          absenceIncidentSnapShot(id: $id) {
            associatedLeaveCaseId
            associatedSTDCaseId
            aRClaim {
              childIncidents {
                incidentId
                claimDates {
                  startDate
                  endDate
                  isPartialAbsence
                  scheduledShiftEndTime
                  scheduledShiftStartTime
                  unpaidTimeInMinutes
                }
                returnToWorkDate
              }
              claimNumber
              claimStatus
              claimStatusLocale {
                description
                locale
              }
              caseType
              status
              expectedRTWDate
              returnToWorkDate
              firstDateOfAbsence
              lastDateOfAbsence
              leaveIncidentId
              caseState
              primaryReasonLocale {
                description
                locale
              }
              secondaryReasonLocale {
                description
                locale
              }
              incidentId
              claimDates {
                startDate
                endDate
                isPartialAbsence
                scheduledShiftEndTime
                scheduledShiftStartTime
                unpaidTimeInMinutes
              }
              absenceType
            }
          }
        }
      `,
      variables: {
        id
      }
    });

    if (query.errors) {
      return Promise.reject(query.errors);
    }

    const caseDetails = query.data;
    if (caseDetails) {
      const absenceClaimData = parseAbsenceDetails(
        caseDetails.absenceIncidentSnapShot
      );
      if (absenceClaimData) {
        return absenceClaimData;
      }
    }

    return Promise.reject();
  }

  async loadClaimTypeFilters(clientCode: string): Promise<ClaimTypeFilter[]> {
    const claimTypesFilters = await this.loadCompanyClaimListIncidentTypes(
      clientCode
    )
      .then(result => {
        const incidentTypeCodes = result.map(incidentType =>
          incidentType.incidentTypeCode.toLocaleUpperCase()
        );
        return (
          filters.filter(filter => {
            if (filter.filter === "all") return true;
            const incidentType = filterToIncidentTypeCode(filter.filter);
            if (!incidentType) return false;
            return incidentTypeCodes.includes(incidentType);
          }) || []
        );
      })
      .catch(() => {
        return filters;
      });

    return claimTypesFilters;
  }

  async loadCompanyClaimListIncidentTypes(
    clientCode: string
  ): Promise<ClaimListIncidentType[]> {
    const query = await getApiGraphQL().query<LoadCompanyListIncidentTypesQuery>(
      {
        query: gql`
          query LoadCompanyListIncidentTypes($clientCode: String!) {
            companyClaimListIncidentTypes(clientCode: $clientCode) {
              incidentTypeCode
              headerText {
                description
                locale
              }
              order
            }
          }
        `,
        variables: {
          clientCode
        }
      }
    );

    if (query.errors) {
      return Promise.reject(query.errors);
    }

    return (
      query.data.companyClaimListIncidentTypes?.map<ClaimListIncidentType>(
        incidentType => ({
          incidentTypeCode: incidentType?.incidentTypeCode || "",
          headerText:
            incidentType?.headerText?.map<LocaleTextEntry>(headerTextProp => ({
              description: headerTextProp?.description || "",
              locale: headerTextProp?.locale || ""
            })) || [],
          order: incidentType?.order || 0
        })
      ) || []
    ).sort((x, y) => x.order - y.order);
  }

  async getLeavePolicySummary(): Promise<LeavePolicy[]> {
    const query = await getApiGraphQL().query<GetLeavePolicySummaryQuery>({
      query: gql`
        query GetLeavePolicySummary {
          leavePolicySummary {
            policies {
              policyCode
              unit
              remaining
              remainingHours
              displayHours
              totalHours
              usedHours
              used
              plannedHours
              planned
              policyCodeLocale {
                description
                locale
              }
              eligibility
              total
            }
          }
        }
      `
    });

    if (query.errors) {
      return Promise.reject(query.errors);
    }

    const policySummary = query.data?.leavePolicySummary;
    if (policySummary) {
      return (
        policySummary.policies?.map(policy => ({
          policyCode: policy?.policyCode ?? undefined,
          unit: policy?.unit ?? undefined,
          remaining: policy?.remaining ?? undefined,
          remainingHours: policy?.remainingHours ?? undefined,
          used: policy?.used ?? undefined,
          usedHours: policy?.usedHours ?? undefined,
          eligibility: policy?.eligibility ?? undefined,
          total: policy?.total ?? undefined,
          totalHours: policy?.totalHours ?? undefined,
          policyCodeLocale: policy?.policyCodeLocale || []
        })) ?? []
      );
    }

    return Promise.reject();
  }

  async listClaims(
    employeeNumber = "",
    policyCodes: string[] = [],
    claimNumbers: string[] = []
  ): Promise<ClaimsListResult> {
    const request: SearchRequestOfClaimSearchRequestInput = {
      pageNumber: 1,
      pageSize: 100,
      parameters: {
        employeeNumber,
        claimNumbers,
        policyCodes
      }
    };

    const query = await getApiGraphQL().query<ListClaimsQuery>({
      query: gql`
        query ListClaims($request: SearchRequestOfClaimSearchRequestInput) {
          results: searchClaims(
            request: $request
            incidentTypes: [
              ABILITI_REFERRAL
              INCIDENT_AR
              INCIDENT_GENERIC_LEAVE
              INCIDENT_STD
              INCIDENT_MYABILITI_AR
              INCIDENT_WC
            ]
          ) {
            items {
              ...SearchClaimsResults
            }
            totalRecords
          }
        }
        ${fragments.searchClaimsResults}
      `,
      variables: {
        request
      }
    });

    if (query.errors) {
      throw query.errors;
    }

    const claims = (query.data.results?.items ?? [])
      .filter(hasValue)
      .map(parseClaim)
      .filter(hasValue);
    return {
      claims: claims,
      total: query.data.results?.totalRecords
    };
  }
}

export type StdCaseDetails = NonNullable<LoadStdClaimQuery["sTDCaseDetails"]>;
export type LeaveCaseDetails = NonNullable<
  LoadFmlClaimQuery["leaveCaseDetails"]
>;

export type ClaimsQueryItem = NonNullable<
  NonNullable<NonNullable<ListActiveClaimsQuery["results"]>["items"]>[number]
>;

export function parseClaim(item: ClaimsQueryItem): ClaimSummary | undefined {
  switch (item.__typename) {
    case "AbilitiReferral":
      return parseReferral(item);

    case "LeaveClaim":
      return parseFmlClaimSummary(item, false);

    case "STDClaim":
      return parseStdClaimSummary(item, false);

    case "MyAbilitiARClaim":
      return parseAbsenceReport(item);
  }
}

const parseSearchClaims = (
  claim: Pick<
    MyAbilitiArClaim,
    | "incidentId"
    | "createdBy"
    | "claimDates"
    | "primaryReasonLocale"
    | "relationShip"
    | "absenceType"
  >
): ClaimSummary => {
  const absenceDates = parseAbsenceDates(claim?.claimDates ?? []);
  const absenceType = parseAbsenceType(claim?.absenceType ?? "") ?? undefined;

  return {
    absenceDates,
    absenceType,
    claimType: "incomplete",
    incidentId: claim.incidentId,
    relationship: mapMaybe(parseRelationship, claim.relationShip),
    createdBy: claim.createdBy,
    title: claim.primaryReasonLocale,
    isReferral: false,
    status: "incomplete"
  };
};

function parseAbsenceTitle(
  item: Pick<MyAbilitiArClaim, "primaryReasonLocale" | "secondaryReasonLocale">
) {
  const translations = Array<LocaleModel>();
  item.primaryReasonLocale?.forEach(primary => {
    const secondary = item.secondaryReasonLocale?.find(locale => locale?.locale === primary?.locale);
  
    if (primary !== undefined) {
      if (secondary !== undefined) {
        translations.push({
          ...(primary as LocaleModel),
          description: `${primary?.description} - ${secondary?.description ?? ''}`
        });
      } else {
        translations.push(primary as LocaleModel);
      }
    }
  });
  return translations;
}

function parseAbsenceReport(
  item: Pick<
    MyAbilitiArClaim,
    | "absenceType"
    | "incidentId"
    | "primaryReasonLocale"
    | "secondaryReasonLocale"
    | "relationShip"
    | "claimDates"
    | "claimStatus"
  >
): ClaimSummary | undefined {
  if (item.claimStatus !== "S") {
    // Status "S" is for "submitted". We are only interested in Absence Reports that have been
    // submitted, not ones in draft state, etc.
    return undefined;
  }

  const absenceType = parseAbsenceType(item.absenceType ?? "") ?? undefined;
  if (absenceType === undefined) {
    console.error("Rejecting absence report with invalid absenceType", item);
    return undefined;
  }

  const absenceDates = parseAbsenceDates(item.claimDates ?? []);
  if (absenceDates.length === 0) {
    console.error("Rejecting absence report with zero absence dates", item);
    return undefined;
  }

  return {
    claimType: "absence",
    incidentId: item.incidentId,
    title: parseAbsenceTitle(item) as LocaleTexts,
    status: deriveAbsenceClaimStatus(absenceDates),
    relationship: mapMaybe(parseRelationship, item.relationShip),
    absenceType,
    absenceDates,
    isReferral: false
  };
}

function parseReferral(
  item: Pick<
    AbilitiReferral,
    | "caseType"
    | "incidentId"
    | "firstDateOfAbsence"
    | "relationShip"
    | "leaveTypeLocale"
    | "caseTypeLocale"
    | "caseCancelled"
    | "isWCBReferral"
    | "isLeavesReferral"
  >
): ClaimSummary | undefined {
  if (item.isLeavesReferral) {
    return parseFmlClaimSummary(
      {
        ...item,
        leaveStartDate: item.firstDateOfAbsence
      },
      true
    );
  } else if (item.isWCBReferral) {
    console.error("Unknown referral case type", item);
    return undefined;
  } else {
    return parseStdClaimSummary(
      {
        ...item,
        dateOfIncident: item.firstDateOfAbsence
      },
      true
    );
  }
}

const fmlTitle = (
  conditionCategoryLocale:
    | Maybe<readonly Maybe<Connect_LocaleModel>[]>
    | undefined,
  leaveTypeCode: Maybe<string> | undefined,
  primaryReasonOfAbsenceLocale:
    | Maybe<readonly Maybe<Connect_LocaleModel>[]>
    | undefined,
  secondaryReasonOfAbsenceLocale:
    | Maybe<readonly Maybe<Connect_LocaleModel>[]>
    | undefined,
  leaveTypeLocale: readonly Connect_LocaleModel[],
  incidentId: number
) => {
  let title;
  if (
    conditionCategoryLocale !== null &&
    conditionCategoryLocale !== undefined &&
    conditionCategoryLocale.length > 0
  ) {
    title = conditionCategoryLocale;
  } else if (
    (leaveTypeCode === "medicalNonOccupational" ||
      leaveTypeCode === "medicalOccupational") &&
    primaryReasonOfAbsenceLocale !== null &&
    primaryReasonOfAbsenceLocale !== undefined &&
    primaryReasonOfAbsenceLocale.length > 0 &&
    secondaryReasonOfAbsenceLocale !== null &&
    secondaryReasonOfAbsenceLocale !== undefined &&
    secondaryReasonOfAbsenceLocale.length > 0
  ) {
    const translations = Array<LocaleModel>();
    primaryReasonOfAbsenceLocale?.forEach(primary => {
      const secondary = secondaryReasonOfAbsenceLocale?.find(locale => {
        locale?.locale === primary?.locale;
        return locale as LocaleModel;
      });

      if (primary !== undefined) {
        if (secondary !== undefined) {
          translations.push({
            ...(primary as LocaleModel),
            description: `${primary?.description} - ${secondary?.description}`
          });
        } else {
          translations.push(primary as LocaleModel);
        }
      }
    });
    title = translations;
  } else {
    title = toLocaleText(leaveTypeLocale, `Case #${incidentId}`);
  }
  return title as LocaleModel[];
};

const parseFmlTitle = (
  item: Pick<
    LeaveClaim,
    | "incidentId"
    | "claimStatus"
    | "leaveStartDate"
    | "relationShip"
    | "leaveTypeLocale"
    | "leaveTypeCode"
    | "caseCancelled"
    | "conditionCategoryLocale"
    | "primaryReasonOfAbsenceLocale"
    | "secondaryReasonOfAbsenceLocale"
    | "leaveStatus"
    | "leaveStatusLocale"
  >
): LocaleModel[] => {
  return fmlTitle(
    item.conditionCategoryLocale,
    item.leaveTypeCode,
    item.primaryReasonOfAbsenceLocale,
    item.secondaryReasonOfAbsenceLocale,
    item.leaveTypeLocale,
    item.incidentId
  );
};

function parseFmlClaimSummary(
  item: Pick<
    LeaveClaim,
    | "incidentId"
    | "claimStatus"
    | "leaveStartDate"
    | "relationShip"
    | "leaveTypeLocale"
    | "leaveTypeCode"
    | "caseCancelled"
    | "conditionCategoryLocale"
    | "primaryReasonOfAbsenceLocale"
    | "secondaryReasonOfAbsenceLocale"
    | "leaveStatus"
    | "leaveStatusLocale"
  >,
  isReferral: boolean
): ClaimSummary | undefined {
  const status = parseLeaveClaimStatus(item.leaveStatus ?? item.claimStatus);

  if (status === undefined) {
    console.error("Rejecting referral with indeterminate status", item);
    return undefined;
  }

  return {
    claimType: "fml",
    incidentId: item.incidentId,
    title: parseFmlTitle(item) as LocaleTexts,
    status,
    statusLocale:
      item.leaveStatusLocale !== null && item.leaveStatusLocale !== undefined
        ? parseLocaleModel(item.leaveStatusLocale)
        : undefined,
    startDate: parseOptionalDate(
      item.leaveStartDate,
      "FML claim leaveStartDate"
    ),
    relationship: mapMaybe(parseRelationship, item.relationShip),
    isReferral,
    conditionCategoryLocale: item.conditionCategoryLocale
      ? toLocaleText(item.conditionCategoryLocale as LocaleModel[])
      : undefined,
    primaryReasonOfAbsenceLocale:
      item.primaryReasonOfAbsenceLocale !== undefined
        ? toLocaleText(item.primaryReasonOfAbsenceLocale as LocaleModel[])
        : undefined,
    secondaryReasonOfAbsenceLocale:
      item.secondaryReasonOfAbsenceLocale !== undefined
        ? toLocaleText(item.secondaryReasonOfAbsenceLocale as LocaleModel[])
        : undefined,
    leaveTypeCode: parseLeaveTypeCode(item.leaveTypeCode)
  };
}

function parseLeaveTypeCode(
  leaveTypeCode?: Maybe<string>
): LeaveTypeCode | undefined {
  if (leaveTypeCode !== undefined) {
    switch (leaveTypeCode) {
      case "medicalOccupational":
        return "medicalOccupational";
      case "medicalNonOccupational":
        return "medicalNonOccupational";
    }
  }

  return undefined;
}

/**
 * Allow a date to be absent; but if it is present, make sure it is valid.
 */
function parseOptionalDate(
  isoDate: string | null | undefined,
  logDescription: string
): Date | undefined {
  if (typeof isoDate === "string") {
    const date = parseISO(isoDate);
    if (isValidDate(date)) {
      return date;
    } else {
      console.error(`Date is invalid for ${logDescription}`, isoDate);
      return undefined;
    }
  } else {
    return undefined;
  }
}

function parseStdClaimSummary(
  item: Pick<
    StdClaim,
    | "claimStatus"
    | "incidentId"
    | "dateOfIncident"
    | "firstDateOfAbsence"
    | "relationShip"
    | "caseTypeLocale"
    | "caseCancelled"
  >,
  isReferral: boolean
): ClaimSummary | undefined {
  const status = parseStdClaimStatus(item.claimStatus);

  if (status === undefined) {
    console.error("Rejecting referral with indeterminate status", item);
    return undefined;
  }

  return {
    claimType: "std",
    incidentId: item.incidentId,
    title: toLocaleText(item.caseTypeLocale, `Case #${item.incidentId}`),
    status,
    startDate: parseOptionalDate(
      item.dateOfIncident,
      "STD claim dateOfIncident"
    ),
    firstDateOfAbsence: parseOptionalDate(
      item.firstDateOfAbsence,
      "STD claim firstDateOfAbsence"
    ),
    relationship: mapMaybe(parseRelationship, item.relationShip),
    isReferral
  };
}

function parseRelationship(
  relationShip: string
): ClaimRelationship | undefined {
  switch (relationShip) {
    case "adoptedChild":
      return "child-adopted";
    case "biologicalChild":
      return "child-biological";
    case "AdultChild":
      return "child-adult";
    case "childOfDomestic":
      return "child-partner";
    case "fosterChild":
      return "child-foster";
    case "in_loco_parentis":
      return "in-loco-parentis";
    case "legal_ward":
      return "legal-ward";
    case "next_of_kin":
      return "next-of-kin";
    case "parent":
      return "parent";
    case "self":
      return "self";
    case "spouse":
      return "spouse";
    case "stepChild":
      return "child-step";
    case "step_parent":
      return "parent-step";
    default:
      return undefined;
  }
}

function parseAbsenceType(str?: string): AbsenceType | undefined {
  switch (str) {
    case "continuous":
      return "continuous";
    case "intermittent":
      return "intermittent";
    default:
      return undefined;
  }
}

function parseISOWithoutTimezone(dateString?: string | null): Date | undefined{
  // Remove the timezone offset from the string
  if (dateString != null){
  const withoutTimezone = dateString.replace(/[+-]\d{2}:\d{2}$/, '');

  // Parse the modified string into a Date object
  return new Date(withoutTimezone);
}
  return undefined;
}

function parseAbsenceDates(
  claimDates: readonly Pick<ClaimDate, "startDate" | "endDate">[]
) {
  return claimDates
    .map(d => {
        const startDate = parseISOWithoutTimezone(d.startDate);
        const endDate = parseISOWithoutTimezone(d.endDate);
      if (isValidDate(startDate) && isValidDate(endDate)) {
        return toTimeInterval(startDate, endDate);
      } else {
        console.error("Invalid/missing startDate and/or endDate", d);
        return undefined;
      }
    })
    .filter(hasValue);
}

function toIncidentTypeCode(claimType: ClaimType): RefIncidentTypes {
  switch (claimType) {
    case "fml":
      return "INCIDENT_GENERIC_LEAVE";
    case "std":
      return "INCIDENT_STD";
    case "absence":
      return "INCIDENT_MYABILITI_AR";
    case "wcb":
      return "INCIDENT_WC";
  }
}

function filterToIncidentTypeCode(
  claimType: ClaimType | "all"
): RefIncidentTypes | undefined {
  switch (claimType) {
    case "fml":
      return "INCIDENT_GENERIC_LEAVE";
    case "std":
      return "INCIDENT_STD";
    case "absence":
      return "INCIDENT_MYABILITI_AR";
    case "wcb":
      return "INCIDENT_WC";
    case "all":
      return undefined;
  }
}

function deriveAbsenceClaimStatus(
  absenceDates: TimeInterval[]
): AbsenceClaimStatus {
  const now = new Date(Date.now());

  const areAllDatesPast = _.every(
    absenceDates,
    ad => toDateInterval(ad).endDate < now
  );
  return areAllDatesPast ? "completed" : "submitted";
}

function parseStdClaimStatus(
  status?: string | null
): StdClaimStatus | undefined {
  switch (status?.toUpperCase()) {
    case "OPEN":
      return "open";
    case "CLOSED":
      return "closed";
  }

  return undefined;
}

function parseLeaveClaimStatus(
  claimStatus?: string | null
): LeaveClaimStatus | undefined {
  switch (claimStatus?.toUpperCase()) {
    case "APPROVED":
      return "approved";
    case "PENDING":
    case "OPEN":
      return "pending";
    case "PENDING_PAPERWORK":
      return "pending-paperwork";
    case "PENDING_REVIEW":
      return "pending-review";
    case "MISSING_PAPERWORK":
      return "missing-paperwork";
    case "CANCELLED":
      return "cancelled";
    case "DENIED":
      return "denied";
    case "CLOSED":
      return "closed";
    case "OUTCOMED":
      return "outcomed";
  }

  return undefined;
}

function parseAbsenceClaimStatus(
  absenceDates: TimeInterval[]
  ): AbsenceClaimStatus {
    const now = new Date(Date.now());
  
    const areAllDatesPast = absenceDates.every(ad => toDateInterval(ad).endDate < now);
    return areAllDatesPast ? "completed" : "submitted";
  }

function parseDecisionStatus(decisionType: string): DecisionStatus | undefined {
  switch (decisionType.toUpperCase()) {
    case "APPROVED":
    case "SUPPORT":
    case "PREAPROV":
    case "SUP.TRTW":
      return "APPROVED";

    case "PENDING":
      return "PENDING";

    case "DENIED":
    case "NON.PARTIC":
    case "NONSUPPORT":
      return "DENIED";

    default:
      console.error("Unknown decisionType", decisionType);
      return undefined;
  }
}

function parseStdCaseDetails(details: StdCaseDetails): StdCaseData | undefined {
  return {
    caseId: details.caseId,
    title: details.caseTypeLocale,
    startDate: parseOptionalDate(
      details.sTDStartDate,
      "STD detail stdStartDate"
    ),
    firstDateOfAbsence: parseOptionalDate(
      details.firstDate,
      "STD detail firstDate"
    ),
    status: parseStdClaimStatus(details.claimStatus),
    statusLocale: parseLocaleModel(details.claimStatusLocale),
    decisions: parseStdDecisions(details.decisions)
  };
}

function parseAbsenceDetails(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any
): AbsenceClaimData & { childIncidents: MyAbilitiArClaim["childIncidents"] } {
  const absenceDates= parseAbsenceDates(details.aRClaim.claimDates ?? []); 
  return {
    caseType: details.aRClaim.caseType,
    associatedLeaveCaseId: details.associatedLeaveCaseId,
    associatedSTDCaseId: details.associatedSTDCaseId,
    childIncidents: details.aRClaim.childIncidents,
    claimNumber: details.aRClaim.claimNumber,
    claimStatus: parseAbsenceClaimStatus(absenceDates),
    claimStatusLocale: parseLocaleModel(details.aRClaim.claimStatusLocale),
    status: details.aRClaim.status,
    expectedRTWDate: details.aRClaim.expectedRTWDate,
    firstDateOfAbsence: details.aRClaim.firstDateOfAbsence,
    lastDateOfAbsence: details.aRClaim.lastDateOfAbsence,
    leaveIncidentId: details.aRClaim.leaveIncidentId,
    caseState: details.aRClaim.caseState,
    primaryReasonLocale: parseLocaleModel(details.aRClaim.primaryReasonLocale),
    secondaryReasonLocale: parseLocaleModel(
      details.aRClaim.secondaryReasonLocale
    ),
    incidentId: details.aRClaim.incidentId,
    absenceDates: details.aRClaim.claimDates ?? [],
    absenceType: details.aRClaim.absenceType,
    returnToWorkDate: details.aRClaim.returnToWorkDate
  };
}

function parseStdDecisions(
  decisions: readonly Pick<
    DecisionViewModel,
    "decisionType" | "startDate" | "endDate"
  >[]
): ClaimDecision[] {
  // TODO All of the DecisionViewModel fields are optional; all of the ClaimDecision fields are required
  // It seems like the end date might actually be nullable depending on the decisionType but who knows
  return decisions
    .map(decision => {
      if (
        typeof decision.decisionType === "string" &&
        typeof decision.startDate === "string" &&
        typeof decision.endDate === "string"
      ) {
        const status = parseDecisionStatus(decision.decisionType);
        if (status !== undefined) {
          return {
            status,
            start: parseISO(decision.startDate),
            end: parseISO(decision.endDate)
          };
        }
      }

      console.error("Rejecting incomplete STD decision", decision);
      return undefined;
    })
    .filter(hasValue);
}

function parseLeaveCaseDetail(
  details: LeaveCaseDetails
): FmlClaimData | undefined {
  return {
    title: fmlTitle(
      details.conditionCategoryLocale,
      details.leaveTypeCode,
      details.primaryReasonLocale,
      details.secondaryReasonLocale,
      details.leaveTypeCodeLocale,
      details.caseId
    ) as LocaleTexts,
    caseId: details.caseId,
    caseType: details.caseType,
    status: parseLeaveClaimStatus(details.leaveStatus ?? details.claimStatus),
    statusLocale: parseLocaleModel(details.claimStatusLocale),
    condition: details.leaveTypeCodeLocale,
    absenceType: parseAbsenceType(details.leaveAbsenceType ?? ""),
    decisions: parseLeaveDeterminations(details.determinations),
    policies: parseLeavePolicies(details.policies),
    pertainsTo: mapMaybe(parseRelationship, details.relationShip),
    leaveStatusLocale:
      details.leaveStatusLocale !== null &&
      details.leaveStatusLocale !== undefined
        ? parseLocaleModel(details.leaveStatusLocale)
        : undefined,
    claimTypeLocale:
      details.claimTypeLocale !== null && details.claimTypeLocale !== undefined
        ? parseLocaleModel(details.claimTypeLocale)
        : undefined
  };
}

function parseLeaveDeterminations(
  determinations: readonly Pick<
    LeaveDeterminationViewModel,
    "resolution" | "startDate" | "endDate" | "policyCode" | "policyCodeLocale"
  >[]
): ClaimDecision[] {
  return determinations
    .map(decision => {
      if (
        typeof decision.resolution === "string" &&
        typeof decision.startDate === "string" &&
        typeof decision.endDate === "string" &&
        typeof decision.policyCode === "string"
      ) {
        const policyCodeText = decision.policyCodeLocale as LocaleTexts;
        const status = parseDecisionStatus(decision.resolution);
        if (status !== undefined) {
          return {
            status,
            start: parseISO(decision.startDate),
            end: parseISO(decision.endDate),
            policyCode: decision.policyCode,
            policyCodeText
          };
        }
      }

      console.error("Rejecting incomplete Leave Determination", decision);
      return undefined;
    })
    .filter(hasValue);
}

function parseLeavePolicies(
  policies: readonly LeavePolicyViewModel[]
): ClaimPolicy[] {
  return policies
    .map(policy => {
      if (
        typeof policy.used === "number" &&
        typeof policy.planned === "number" &&
        typeof policy.policyCode === "string"
      ) {
        return {
          used: policy.used,
          unit: parsePolicyUnit(policy.unit),
          planned: policy.planned,
          policyCode: policy.policyCode,
          policyCodeText: policy.policyCodeLocale,
          plannedHours: policy.plannedHours ?? undefined,
          usedHours: policy.usedHours ?? undefined
        };
      }

      console.error("Rejecting incomplete Leave Policy", policy);
      return undefined;
    })
    .filter(hasValue);
}

function parsePolicyUnit(
  unit?: string | null
): "hours" | "days" | "weeks" | "months" | undefined {
  switch (unit) {
    case "hours":
      return "hours";
    case "days":
      return "days";
    case "weeks":
      return "weeks";
    case "months":
      return "months";
    default:
      break;
  }
}

/**
 * Map a value using mapFn(), if the value is not nullish; otherwise return undefined
 */
function mapMaybe<T, R>(
  mapFn: (value: T) => R,
  maybeValue: T | null | undefined
) {
  if (hasValue(maybeValue)) {
    return mapFn(maybeValue);
  } else {
    return undefined;
  }
}
