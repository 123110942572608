import {
  QuestionSummary,
  QuestionAnswer,
  WorkflowQuestionType,
  WorkflowQuestionBaseTemplate,
  ValidationResult
} from "../absence-reporting-types";
import { ARWorkflowState } from "../absence-reporting-state";
import {
  validatePhoneNumber,
  validateEmail,
  formatPhoneNumber,
  formatDateTimeAnswer
} from "./section-helpers";
import { getSessionService } from "features/session/session-service";
import { ConfigAbsenceQuestion } from "../absence-questions";
import { LocaleTexts } from "shared-types/api";
import { ClientTranslationProps } from "i18n";
import { isValid, parseISO } from "date-fns";

const prepareLabelForCIC = (
  question: ConfigAbsenceQuestion
): LocaleTexts | ClientTranslationProps => {
  if (question.name === "CQ_EMAIL_ADDRESS_TO_RECEIVE") {
    return {
      tag: "reportAbsence.summary.cic_email_will_be_sent_to_label"
    };
  }

  return question.label;
};

const normalizeValue = (cq: ConfigAbsenceQuestion, value?: string) => {
  if (cq.questionType === "boolean" && value) {
    return value.toLowerCase();
  }
  return value || "";
};

export const buildConfigSections = (
  state: ARWorkflowState,
  locale: Locale
): WorkflowQuestionBaseTemplate[] => {
  const isCICUser = getSessionService().isCICUser();

  return state.configQuestions.map(cq => {
    if (cq.resolvedValue && !state.answers.has(cq.name)) {
      state.answers.set(cq.name, {
        answer: normalizeValue(cq, cq.resolvedValue)
      });
    } else if (cq.defaultValue && !state.answers.has(cq.name)) {
      state.answers.set(cq.name, {
        answer: normalizeValue(cq, cq.defaultValue)
      });
    }
    return {
      config: cq,
      id: cq.name,
      type: "ConfigQuestion" as WorkflowQuestionType,
      answer: () =>
        ({
          type: "string",
          value: state.answers.get(cq.name)?.answer
        } as QuestionAnswer),
      setAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "string") {
          state.answers.set(cq.name, {
            answer: normalizeValue(cq, answer.value),
            answered: true
          });
        }
      },
      absenceReasonInfo: state.getReasonOfAbsenceInfo,
      requiresAnswer: () => {
        const a = state.answers.get(cq.name);
        return (
          cq.validationConstraint?.required === true &&
          (!a?.answer || a?.answer === "")
        );
      },
      hidden: () => cq.isVisible === false,
      summary: (): QuestionSummary => {
        const answer = state.answers.get(cq.name)?.answer || "";
        const label = isCICUser ? prepareLabelForCIC(cq) : cq.label;

        switch (cq.questionType) {
          case "boolean":
            return {
              type: "boolean",
              label: label,
              answer: answer === "true"
            };
          case "dropdown":
            return {
              type: "text",
              label: label,
              answer: cq.options?.find(o => o.value === answer)?.text
            };
          case "date":
            return {
              type: "text",
              label: label,
              answer: answer ? formatDateTimeAnswer(answer, locale) : ""
            };
          case "phone":
            return {
              type: "text",
              label: label,
              answer: answer ? formatPhoneNumber(answer) : ""
            };
          case "card":
            const summaryObject: QuestionSummary = {
              type: "text",
              label,
              answer
            };

            if (answer && answer !== "0") {
              summaryObject.answer = "Yes";
            }

            if (answer === "0") {
              summaryObject.answer = "It's new";
            }

            return summaryObject;
          case "numeric":
          case "text":
          default:
            return {
              type: "text",
              label: label,
              answer: answer
            };
        }
      },
      validateAnswer: (answer: QuestionAnswer): ValidationResult => {
        switch (cq.questionType) {
          case "phone":
            if (
              answer.type === "string" &&
              answer.value === "" &&
              !cq.validationConstraint?.required
            ) {
              return { isValid: true };
            }
            if (answer.type === "string" && answer.value !== undefined) {
              return validatePhoneNumber(answer.value)
                ? { isValid: true }
                : {
                    isValid: false,
                    message: { tag: "reportAbsence.validation.phone" }
                  };
            } else {
              return { isValid: false };
            }

          case "email":
            if (
              answer.type === "string" &&
              answer.value === "" &&
              !cq.validationConstraint?.required
            ) {
              return { isValid: true };
            }
            if (answer.type === "string" && answer.value !== undefined) {
              return validateEmail(answer.value)
                ? { isValid: true }
                : {
                    isValid: false,
                    message: { tag: "reportAbsence.validation.email" }
                  };
            } else {
              return { isValid: false };
            }

          case "numeric":
            if (
              answer.type === "string" &&
              answer.value === "" &&
              !cq.validationConstraint?.required
            ) {
              return { isValid: true };
            }
            if (answer.type === "string" && answer.value !== undefined) {
              const max = cq.validationConstraint?.max;
              const min = cq.validationConstraint?.min;
              const asNum: number = +answer.value;
              if (min && asNum < min) {
                return {
                  isValid: false
                };
              }

              if (max && asNum > max) {
                return { isValid: false };
              }
              return { isValid: true };
            } else {
              return { isValid: false };
            }

            case "date":
              return { isValid: answer.type === "string" && (!answer.value || isValid(parseISO(answer.value))) };  

          default:
            return { isValid: true };
        }
      },
      isSubmissionMode: true,
      getSubmitErrorMsg: (): string | undefined =>
        state.getSubmitErrorMsgForQuestion(cq.name)
    };
  });
};
