import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  BehaviorMapValidator,
  ClickAnalyticsPlugin
} from "@microsoft/applicationinsights-clickanalytics-js";
import { createBrowserHistory } from "history";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
let logMsg = "";

// Click Analytics configuration
const behaviorMap = {
  CLICK: 1
};

const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    customDataPrefix: "data-custom-",
    useDefaultContentNameOrId: true
  },
  behaviorValidator: BehaviorMapValidator(behaviorMap),
  dropInvalidEvents: true
};

const config = {
  instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
  extensions: [clickPluginInstance, reactPlugin],
  extensionConfig: {
    [clickPluginInstance.identifier]: clickPluginConfig,
    [reactPlugin.identifier]: { history: browserHistory }
  }
};

const appInsights = new ApplicationInsights({ config });

if (process.env.REACT_APP_INSIGHTS_KEY) {
  appInsights.loadAppInsights();
}

const appInsightLog = (msg: string) =>
  process.env.REACT_APP_INSIGHTS_KEY && appInsights.trackEvent({ name: msg });

const appInsightLogQuestion = (msg: string) => {
  if (logMsg !== msg) {
    process.env.REACT_APP_INSIGHTS_KEY && appInsights.trackEvent({ name: msg });
    logMsg = msg;
  }
};

const appInsightSetUserId = (id: string) => {
  if (process.env.REACT_APP_INSIGHTS_KEY) {
    appInsights.context.user.id = id;
  }
};

export {
  reactPlugin,
  appInsights,
  appInsightLog,
  appInsightLogQuestion,
  appInsightSetUserId
};
