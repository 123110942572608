import React from "react";

/**
 * To colorize an icon component, assign the className of a style containing
 * a "fill" property set to the desired color.
 */

type IconProps = {
  className?: string;
  onClick?: () => void;
};
export type IconComponent = React.FunctionComponent<IconProps>;

//#region Action

export const BookletIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M4 3.75C4 2.784 4.784 2 5.75 2H20v20H5.75C4.784 22 4 21.216 4 20.25V3.75zm6.5-.25h-2v4.458l.628-.36c.23-.13.514-.13.744 0l.628.36V3.5zM7 3.5v5.75c0 .267.142.514.374.649.23.134.516.135.748.002L9.5 9.114l1.378.787c.232.133.517.132.748-.002.232-.135.374-.382.374-.649V3.5h6.5v14H6.25c-.263 0-.515.045-.75.128V3.75c0-.138.112-.25.25-.25H7zM5.5 19.75c0 .414.336.75.75.75H18.5V19H6.25c-.414 0-.75.336-.75.75z"
    />
  </svg>
);

export const PlusIcon: IconComponent = props => (
  <svg className={props.className} width="16" height="16" viewBox="0 0 16 16">
    <path d="M8 1.333c-.276 0-.5.224-.5.5V7.5H1.833c-.276 0-.5.224-.5.5s.224.5.5.5H7.5v5.667c0 .276.224.5.5.5s.5-.224.5-.5V8.5h5.666c.277 0 .5-.224.5-.5s-.223-.5-.5-.5H8.5V1.833c0-.276-.224-.5-.5-.5z" />
  </svg>
);

export const CalendarIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M7.25 2.75c0-.414.336-.75.75-.75s.75.336.75.75V4h6.5V2.75c0-.414.336-.75.75-.75s.75.336.75.75V4h2.5c.966 0 1.75.784 1.75 1.75v14.5c0 .966-.784 1.75-1.75 1.75H4.75C3.784 22 3 21.216 3 20.25V5.75C3 4.784 3.784 4 4.75 4h2.5V2.75zm0 2.75h-2.5c-.138 0-.25.112-.25.25V9h15V5.75c0-.138-.112-.25-.25-.25h-2.5v1.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75V5.5h-6.5v1.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75V5.5zM4.5 20.25V10.5h15v9.75c0 .138-.112.25-.25.25H4.75c-.138 0-.25-.112-.25-.25zm10-5.25c-.276 0-.5.224-.5.5v3c0 .276.224.5.5.5h3c.276 0 .5-.224.5-.5v-3c0-.276-.224-.5-.5-.5h-3z"
    />
  </svg>
);

export const TimeIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M22.134 20.6c.331-.442.241-1.069-.2-1.4L17 15.5V9.333c0-.552-.447-1-1-1-.552 0-1 .448-1 1V16c0 .315.149.611.4.8l5.334 4c.441.331 1.068.242 1.4-.2z" />
    <path
      fillRule="evenodd"
      d="M16 29.333c-7.363 0-13.333-5.97-13.333-13.333C2.667 8.636 8.637 2.667 16 2.667c7.364 0 13.334 5.97 13.334 13.333 0 7.364-5.97 13.333-13.334 13.333zm0-2c6.26 0 11.334-5.074 11.334-11.333C27.334 9.74 22.26 4.667 16 4.667 9.741 4.667 4.667 9.74 4.667 16c0 6.26 5.074 11.333 11.333 11.333z"
    />
  </svg>
);

export const QueryOutlineIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12.75 12.146V13.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-2c0-.414.336-.75.75-.75.69 0 1.25-.56 1.25-1.25S12.69 8.25 12 8.25s-1.25.56-1.25 1.25c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.519 1.231-2.75 2.75-2.75s2.75 1.231 2.75 2.75c0 1.259-.846 2.32-2 2.646zM12 17.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75-.75.336-.75.75.336.75.75.75z" />
    <path
      fillRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-1.5 0c0 4.694-3.806 8.5-8.5 8.5S3.5 16.694 3.5 12 7.306 3.5 12 3.5s8.5 3.806 8.5 8.5z"
    />
  </svg>
);

export const SettingsIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-1.5c1.38 0 2.5-1.12 2.5-2.5S13.38 9.5 12 9.5 9.5 10.62 9.5 12s1.12 2.5 2.5 2.5z" />
    <path d="M14.739 18.999l-.87 2.574c-.087.255-.329.427-.601.427h-2.536c-.272 0-.514-.172-.6-.427l-.87-2.574c-.73-.278-1.404-.663-2.005-1.136l-2.704.542c-.264.053-.534-.071-.67-.307l-1.268-2.196c-.136-.236-.108-.532.07-.734l1.795-2.042c-.056-.367-.085-.743-.085-1.126 0-.383.03-.759.085-1.126L2.685 8.832c-.178-.202-.206-.498-.07-.734l1.268-2.196c.136-.236.406-.36.67-.307l2.704.542c.6-.473 1.275-.858 2.004-1.136l.87-2.574c.087-.255.329-.427.601-.427h2.536c.272 0 .514.172.6.427l.87 2.574c.73.278 1.404.663 2.005 1.136l2.704-.542c.264-.053.534.071.67.307l1.268 2.196c.136.236.108.532-.07.734l-1.795 2.042c.056.367.085.743.085 1.126 0 .383-.03.759-.085 1.126l1.795 2.042c.178.202.206.498.07.734l-1.268 2.196c-.136.236-.406.36-.67.307l-2.704-.542c-.6.473-1.275.858-2.004 1.136zm5.037-3.31l-1.383-1.573c-.293-.333-.423-.778-.356-1.216.045-.296.068-.596.068-.9 0-.304-.023-.604-.068-.9-.067-.438.063-.883.356-1.216l1.383-1.573-.648-1.122-2.09.419c-.431.086-.878-.021-1.223-.293-.487-.383-1.03-.691-1.61-.912-.419-.16-.744-.498-.887-.922l-.67-1.981h-1.296l-.67 1.981c-.143.424-.468.762-.887.922-.58.221-1.123.53-1.61.912-.345.272-.792.379-1.222.293l-2.091-.42-.648 1.123 1.383 1.573c.293.333.423.778.356 1.216-.045.296-.068.596-.068.9 0 .304.023.604.068.9.067.438-.063.883-.356 1.216L4.224 15.69l.648 1.122 2.09-.419c.432-.086.878.02 1.224.293.486.383 1.028.69 1.61.912.418.16.743.497.886.921l.67 1.982h1.296l.67-1.982c.143-.424.468-.762.887-.921.58-.221 1.123-.53 1.61-.912.345-.272.791-.379 1.222-.293l2.091.42.648-1.123z" />
  </svg>
);

//#endregion Action

//#region Alert

export const AlertIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M11.25 2.75c0-.414.336-.75.75-.75s.75.336.75.75v1.541c3.375.373 6 3.235 6 6.709v6.25h1.5c.414 0 .75.336.75.75s-.336.75-.75.75H3.75c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5V11c0-3.474 2.625-6.336 6-6.709V2.75zm6 14.5V11c0-2.9-2.35-5.25-5.25-5.25S6.75 8.1 6.75 11v6.25h10.5z" />
    <path d="M12 22c-1.105 0-2-.895-2-2h4c0 1.105-.895 2-2 2z" />
  </svg>
);

export const ExclamationInCircleIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M12.75 13c0 .414-.336.75-.75.75s-.75-.336-.75-.75V7c0-.414.336-.75.75-.75s.75.336.75.75v6zM11.25 17c0-.414.336-.75.75-.75s.75.336.75.75-.336.75-.75.75-.75-.336-.75-.75z" />
    <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10zM3.75 12c0-4.556 3.694-8.25 8.25-8.25s8.25 3.694 8.25 8.25-3.694 8.25-8.25 8.25S3.75 16.556 3.75 12z" />
  </svg>
);

//#endregion Alert

//#region Content

export const DocumentIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8l-.001 13c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V3c0-.553.448-1 1-1h9c.265 0 .52.105.707.293l5 5c.188.187.293.442.293.707zm-5.25-3.543V7.25h2.793L14.75 4.457zM18.5 8.75l-.001 11.75H5.5v-17h7.75V8c0 .414.336.75.75.75h4.5z"
    />
  </svg>
);

export const AddDocumentIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M14 11.25c.414 0 .75.336.75.75s-.336.75-.75.75h-1.25V14c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.25H10c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.25V10c0-.414.336-.75.75-.75s.75.336.75.75v1.25H14z" />
    <path
      fillRule="evenodd"
      d="M20 8v13c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h9c.265 0 .52.105.707.293l5 5c.188.187.293.442.293.707zm-5.25-3.543V7.25h2.793L14.75 4.457zM18.5 8.75V20.5h-13v-17h7.75V8c0 .414.336.75.75.75h4.5z"
    />
  </svg>
);

//#endregion Content

//#region Maps

export const HomeIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M11.5 2.19c.285-.253.715-.253 1 0l9.25 8.25c.308.276.335.75.06 1.06-.276.308-.75.335-1.06.06l-1-.893v9.583c0 .966-.784 1.75-1.75 1.75h-3c-.966 0-1.75-.784-1.75-1.75v-5.5c0-.138-.112-.25-.25-.25h-2c-.138 0-.25.112-.25.25v5.5c0 .966-.784 1.75-1.75 1.75H6c-.966 0-1.75-.784-1.75-1.75v-9.583l-1 .893c-.31.275-.784.248-1.06-.06-.275-.31-.248-.784.06-1.06l3-2.675V3.75c0-.414.336-.75.75-.75h3c.414 0 .75.336.75.75v.002L11.5 2.19zm-3.25 2.9V4.5h-1.5v1.927l1.5-1.337zM12 3.755l6.25 5.574V20.25c0 .138-.112.25-.25.25h-3c-.138 0-.25-.112-.25-.25v-5.5c0-.966-.784-1.75-1.75-1.75h-2c-.966 0-1.75.784-1.75 1.75v5.5c0 .138-.112.25-.25.25H6c-.138 0-.25-.112-.25-.25V9.33L12 3.754z"
    />
  </svg>
);

//#endregion Maps

//#region Navigation

export const ChevronDownIcon: IconComponent = props => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M12 13.94l5.47-5.47c.293-.293.767-.293 1.06 0 .293.293.293.767 0 1.06l-6 6c-.293.293-.767.293-1.06 0l-6-6c-.293-.293-.293-.767 0-1.06.293-.293.767-.293 1.06 0L12 13.94z" />
    </svg>
  );
};

export const ChevronUpIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 10.06l-5.47 5.47c-.293.293-.767.293-1.06 0-.293-.293-.293-.767 0-1.06l6-6c.293-.293.767-.293 1.06 0l6 6c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L12 10.06z" />
  </svg>
);

export const ChevronBackIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M10.06 12l5.47 5.47c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0l-6-6c-.293-.293-.293-.767 0-1.06l6-6c.293-.293.767-.293 1.06 0 .293.293.293.767 0 1.06L10.06 12z" />
  </svg>
);

export const ChevronNextIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M13.94 12L8.47 6.53c-.293-.293-.293-.767 0-1.06.293-.293.767-.293 1.06 0l6 6c.293.293.293.767 0 1.06l-6 6c-.293.293-.767.293-1.06 0-.293-.293-.293-.767 0-1.06L13.94 12z" />
  </svg>
);

export const UpArrowIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M11.25 4.81l-7.72 7.72c-.293.293-.768.293-1.06 0-.293-.292-.293-.767 0-1.06l9-9c.293-.293.768-.293 1.06 0l9 9c.293.293.293.768 0 1.06-.293.293-.767.293-1.06 0l-7.72-7.72V21c0 .414-.336.75-.75.75s-.75-.336-.75-.75V4.81z" />
  </svg>
);

export const DownArrowIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M12.75 19.19l7.72-7.72c.293-.293.768-.293 1.06 0 .293.293.293.768 0 1.06l-9 9c-.293.293-.768.293-1.06 0l-9-9c-.293-.292-.293-.767 0-1.06.292-.293.767-.293 1.06 0l7.72 7.72V3c0-.414.336-.75.75-.75s.75.336.75.75v16.19z" />
  </svg>
);

export const LeftArrowIcon: IconComponent = props => (
  <svg
    className={props.className}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path d="M2.25 12c0-.414.336-.75.75-.75h18c.414 0 .75.336.75.75s-.336.75-.75.75H3c-.414 0-.75-.336-.75-.75z" />
    <path d="M12.53 2.47c.293.293.293.767 0 1.06L4.06 12l8.47 8.47c.293.293.293.768 0 1.06-.293.293-.767.293-1.06 0l-9-9c-.293-.293-.293-.767 0-1.06l9-9c.293-.293.767-.293 1.06 0z" />
  </svg>
);

export const RightArrowIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M19.19 13.25H3c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h16.19l-7.72-7.72c-.293-.293-.293-.767 0-1.06.293-.293.767-.293 1.06 0l9 9c.072.072.127.155.163.243.037.089.057.186.057.287 0 .206-.083.393-.218.529l-.002.002m-2.34.22l-7.72 7.719c-.293.293-.293.768 0 1.06.293.293.767.293 1.06 0l9-9" />
  </svg>
);

export const SmallRightArrowIcon: IconComponent = props => (
  <svg className={props.className} width="17" height="16" viewBox="0 0 17 16">
    <path d="M13.293 8.5H2.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10.793L8.146 2.354c-.195-.196-.195-.512 0-.708.196-.195.512-.195.708 0l6 6c.048.048.084.104.108.163.024.059.038.123.038.191 0 .138-.056.262-.145.353h-.001m-1.561.147l-5.147 5.146c-.195.196-.195.512 0 .708.196.195.512.195.708 0l6-6" />
  </svg>
);

export const CloseIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M4.47 19.53c.293.293.767.293 1.06 0L12 13.06l6.47 6.47c.293.293.767.293 1.06 0 .293-.293.293-.767 0-1.06L13.06 12l6.47-6.47c.293-.293.293-.767 0-1.06-.293-.293-.767-.293-1.06 0L12 10.94 5.53 4.47c-.293-.293-.767-.293-1.06 0-.293.293-.293.767 0 1.06L10.94 12l-6.47 6.47c-.293.293-.293.767 0 1.06z" />
  </svg>
);

export const RefreshIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#212121"
      d="M16.292 4.626c-.39.39-.39 1.024 0 1.415l.96.96h-1.253C10.292 7 5.666 11.626 5.666 17.332c0 5.707 4.626 10.334 10.333 10.334s10.334-4.627 10.334-10.334c0-.552-.448-1-1-1-.553 0-1 .448-1 1 0 4.603-3.731 8.334-8.334 8.334-4.602 0-8.333-3.731-8.333-8.334C7.666 12.732 11.397 9 15.999 9h1.253l-.96.96c-.39.39-.39 1.023 0 1.414.39.39 1.024.39 1.414 0l2.667-2.667c.39-.39.39-1.023 0-1.414l-2.667-2.667c-.39-.39-1.023-.39-1.414 0z"
    />
  </svg>
);

export const LogoutIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M3.5 12c0-4.142 3.358-7.5 7.5-7.5 1.84 0 3.522.661 4.827 1.76.317.266.79.226 1.057-.091.267-.317.226-.79-.091-1.057C15.228 3.794 13.206 3 11 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c2.206 0 4.23-.795 5.794-2.113.317-.267.358-.74.09-1.057-.266-.317-.74-.357-1.056-.09C14.523 18.838 12.84 19.5 11 19.5c-4.142 0-7.5-3.358-7.5-7.5zm6.75.75h9.19l-1.72 1.72c-.293.293-.293.768 0 1.06.293.293.767.293 1.06 0l3-3 .002-.001c.135-.136.218-.323.218-.529 0-.101-.02-.198-.057-.287-.037-.088-.09-.171-.163-.243l-3-3c-.293-.293-.767-.293-1.06 0-.293.293-.293.767 0 1.06l1.72 1.72h-9.19c-.414 0-.75.336-.75.75s.336.75.75.75z" />
  </svg>
);

export const MenuIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M2 5.75c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75H2.75c-.414 0-.75-.336-.75-.75zM2 18.25c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75H2.75c-.414 0-.75-.336-.75-.75zM2.75 11.25c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75H2.75z" />
  </svg>
);

//#endregion Navigation

export const CopyIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M3.75 2c-.414 0-.75.336-.75.75v14.5c0 .414.336.75.75.75H7v-1.5H4.5v-13h10V5H16V2.75c0-.414-.336-.75-.75-.75H3.75zm5 4c-.414 0-.75.336-.75.75v14.5c0 .414.336.75.75.75h11.5c.414 0 .75-.336.75-.75V6.75c0-.414-.336-.75-.75-.75H8.75zm.75 14.5v-13h10v13h-10z" />
  </svg>
);

export const MoonIcon: IconComponent = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 2.32c.167.239.18.553.035.805-.785 1.36-1.3 3.188-1.3 4.875 0 5.11 4.132 9.25 9.225 9.25h.287c.293-.001.56.168.683.434.124.266.081.579-.109.802C18.98 20.651 15.997 22 12.977 22 7.465 22 3 17.521 3 12c0-4.92 3.551-9.169 8.256-9.989.287-.05.577.07.744.31zm-1.985 1.583C6.803 5.132 4.5 8.34 4.5 12c0 4.696 3.797 8.5 8.477 8.5 2.01 0 3.991-.7 5.5-1.852C13.254 17.924 9.235 13.431 9.235 8c0-1.375.29-2.814.78-4.097z" />
  </svg>
);

export const ProfileIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M8.876 11.328c-.997-.87-1.626-2.15-1.626-3.578C7.25 5.127 9.377 3 12 3s4.75 2.127 4.75 4.75c0 1.427-.63 2.707-1.626 3.578C17.952 12.228 20 14.874 20 18v2c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1v-2c0-3.126 2.048-5.773 4.876-6.672zM11 12.5c-3.038 0-5.5 2.462-5.5 5.5v1.5h13V18c0-3.038-2.462-5.5-5.5-5.5h-2zm1-1.5c1.795 0 3.25-1.455 3.25-3.25S13.795 4.5 12 4.5 8.75 5.955 8.75 7.75 10.205 11 12 11z" />
  </svg>
);

export const SearchIcon: IconComponent = props => (
  <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
    <path d="M10 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8c0 1.94-.69 3.717-1.838 5.102l5.618 5.618c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0l-5.618-5.618C13.717 17.31 11.939 18 10 18zm0-1.5c3.59 0 6.5-2.91 6.5-6.5S13.59 3.5 10 3.5 3.5 6.41 3.5 10s2.91 6.5 6.5 6.5z" />
  </svg>
);

export const CheckedIcon: IconComponent = props => (
  <svg className={props.className} width="18" height="18" viewBox="0 0 18 18">
    <path d="M15.016 4.242c.273-.272.714-.272.987 0 .274.273.274.717 0 .99l-8.881 8.854c-.22.219-.575.219-.794 0L2.06 9.833c-.31-.308-.31-.81 0-1.118.309-.308.807-.308 1.116 0l3.676 3.664 8.163-8.137z" />
  </svg>
);

export const ExternalLinkIcon: IconComponent = props => (
  <svg
    className={props.className}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M21.25 14.25c-.414 0-.75.336-.75.75v5.5h-17v-17H9c.414 0 .75-.336.75-.75S9.414 2 9 2H2.75c-.414 0-.75.336-.75.75v18.5c0 .414.336.75.75.75h18.5c.414 0 .75-.336.75-.75V15c0-.414-.336-.75-.75-.75z"
    />
    <path
      fill="#fff"
      d="M12.75 3.5h6.69l-7.97 7.97c-.293.293-.293.767 0 1.06.293.293.767.293 1.06 0l7.97-7.97v6.69c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.102-.02-.199-.057-.287-.037-.089-.09-.171-.163-.243-.146-.147-.338-.22-.53-.22h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75z"
    />
  </svg>
);

export const UploadIcon: IconComponent = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" className={props.className}>
    <path d="M9.53033 6.28033C9.23744 6.57322 8.76256 6.57322 8.46967 6.28033C8.17678 5.98744 8.17678 5.51256 8.46967 5.21967L11.4697 2.21967C11.5416 2.14776 11.6245 2.09351 11.7129 2.05691C11.9848 1.94443 12.3093 1.99868 12.5303 2.21967L15.5303 5.21967C15.8232 5.51256 15.8232 5.98744 15.5303 6.28033C15.2374 6.57322 14.7626 6.57322 14.4697 6.28033L12.75 4.56066V14.25C12.75 14.6642 12.4142 15 12 15C11.5858 15 11.25 14.6642 11.25 14.25V4.56066L9.53033 6.28033Z" />
    <path d="M15 9.75C15 10.1642 15.3358 10.5 15.75 10.5H17.5V20.5H6.5V10.5H8.25C8.66421 10.5 9 10.1642 9 9.75C9 9.33579 8.66421 9 8.25 9H5.75C5.33579 9 5 9.33579 5 9.75V21.25C5 21.6642 5.33579 22 5.75 22H18.25C18.6642 22 19 21.6642 19 21.25V9.75C19 9.33579 18.6642 9 18.25 9H15.75C15.3358 9 15 9.33579 15 9.75Z" />
  </svg>
);

export const AllIcons: IconComponent[] = [
  AddDocumentIcon,
  AlertIcon,
  BookletIcon,
  CalendarIcon,
  ChevronBackIcon,
  ChevronDownIcon,
  ChevronNextIcon,
  ChevronUpIcon,
  CloseIcon,
  RefreshIcon,
  CopyIcon,
  DocumentIcon,
  DownArrowIcon,
  ExclamationInCircleIcon,
  HomeIcon,
  LogoutIcon,
  MenuIcon,
  MoonIcon,
  PlusIcon,
  ProfileIcon,
  QueryOutlineIcon,
  RightArrowIcon,
  SettingsIcon,
  SmallRightArrowIcon,
  UpArrowIcon,
  SearchIcon,
  CheckedIcon,
  TimeIcon,
  ExternalLinkIcon
];
