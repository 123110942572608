import * as React from "react";
import { ClientTranslationProps } from "i18n";
import { useTranslation } from "hooks/translation";
import _ from "lodash-es";
import { textForLang } from "shared-types/api";
import { substitute } from "i18n/core";
import { useLanguageContext } from "context/user-language-context";

type TranslationProps = ClientTranslationProps & {
  /** Render text as full HTML */
  html?: boolean;
  /** Surround variables with <strong> when interpolating (input text is considered plain unless html prop is also set) */
  strong?: boolean;
};

export const Translation: React.FunctionComponent<TranslationProps> = props => {
  const t = useTranslation();
  const { currentLang } = useLanguageContext();

  const strongText = (text: ClientTranslationProps) => {
    const maybeEscapeHtml = props.html ? (html: string) => html : escapeHtml;
    const htmlTemplate = maybeEscapeHtml(
      t({
        tag: text.tag,
        vars: _.mapValues(text.vars, (v, k) => `{${k}}`)
      } as ClientTranslationProps)
    );
    const htmlVars = _.mapValues(
      text.vars,
      v =>
        `<strong>${maybeEscapeHtml(
          typeof v === "string" ? v : textForLang(v, currentLang)
        )}</strong>`
    );
    return substitute(htmlTemplate, htmlVars);
  };

  const text = props.strong ? strongText(props) : t(props);
  return props.html || props.strong ? (
    <span
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  ) : (
    <>{text}</>
  );
};

/**
 * Escape HTML markup in a plaintext string.
 */
function escapeHtml(plaintext: string) {
  return plaintext
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/'/g, "&apos;")
    .replace(/"/g, "&quot;");
}
