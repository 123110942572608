import { useLanguageContext } from "context/user-language-context";
import { getWebConfigurationService } from "features/config/web-configuration-service";
import { getSessionService } from "features/session/session-service";
import { getParentLanguageCode } from "features/translations/locales";
import { useEffect, useState } from "react";

export const useFeatures = () => {
  const [connectLink, setConnectLink] = useState<string>();
  const { currentLang } = useLanguageContext();

  useEffect(() => {
    const load = async () => {
      const sessionService = getSessionService();
      const canAccessConnect = sessionService.hasEmployees();
      const clientCode = sessionService.getClientCode();

      if (clientCode && canAccessConnect) {
        const language = getParentLanguageCode(currentLang);
        await getWebConfigurationService()
          .getClientFeatureUrl(clientCode, "connect", language)
          .then(result => {
            setConnectLink(result);
          });
      }
    };
    void load();
  }, [currentLang]);

  return {
    connectLink
  };
};
