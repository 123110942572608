import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReportAbsencePrimaryReason } from "routes";
import { ClaimCard } from "components/claim-card/claim-card";
import { LeaveProceedModal } from "components/leave-proceed-modal/leave-proceed-modal";
import { TextButton } from "components/buttons";
import { Translation } from "components/translation";
import { useWindowSize } from "hooks";
import { setCardLayout } from "pages/claims/helpers";
import { getAbsenceReportingService } from "features/report-absence/absence-reporting-service";
import { ClaimSummary } from "features/claims/claims-model";
import { useTranslation } from "hooks/translation";
import styles from "./to-do-activities.module.scss";
import { useFormat } from "hooks/format";

type Props = {
  claims: ClaimSummary[];
};

export const ToDoActivities: React.FC<Props> = ({ claims }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [cardHeigth, setCardHeight] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [incidentId, setIncidentId] = useState<number>(0);
  const [itemsNotShown, setItemsNotShown] = useState<number>(0);
  const [windowWidth] = useWindowSize();
  const history = useHistory();

  const MOBILE_BREAK_POINT = 768;
  const CARD_WIDTH = 352;
  const GRID_GAP = 32;
  const cardListClass = styles["to-do-activities__wrapper"];
  const cardClass = styles["to-do-activities__claim-card"];
  const t = useTranslation();
  const { currentLocale } = useFormat();

  useEffect(() => {
    const cardList = document.querySelector<HTMLElement>(`.${cardListClass}`);
    const card = cardList?.querySelector<HTMLElement>(`.${cardClass}`);

    if (cardList) {
      const totalCardWidth = CARD_WIDTH + GRID_GAP;
      const listWidth = cardList.clientWidth;
      const numberOfColumns =
        1 + Math.floor((listWidth - CARD_WIDTH) / totalCardWidth);
      const itemsNotShown = claims.length - numberOfColumns;

      setCardLayout({
        cardList,
        windowWidth,
        numberOfColumns,
        mobileBreakPoint: MOBILE_BREAK_POINT
      });

      setCardHeight(card?.clientHeight ? card?.clientHeight : 0);
      setItemsNotShown(itemsNotShown);
    }
  }, [cardListClass, cardClass, claims.length, windowWidth]);

  const prepareCardListStyle = () => {
    const isExpandedStyle = {
      maxHeigt: "auto",
      overflowY: "auto"
    } as React.CSSProperties;

    const isNotExpandedStyle = {
      maxHeight: cardHeigth,
      overflowY: "hidden"
    } as React.CSSProperties;

    return isExpanded ? isExpandedStyle : isNotExpandedStyle;
  };

  const cancelAbsence: () => Promise<{ success: boolean }> = async () => {
    try {
      const result = await getAbsenceReportingService().cancelIncidents(
        {},
        [incidentId],
        currentLocale.code
      );
      return result;
    } catch (error) {
      return { success: false };
    }
  };

  const proceed = () =>
    history.push(ReportAbsencePrimaryReason.generate({ incidentId }));

  const handleInProgressClaim = (incidentId: number) => {
    setIncidentId(incidentId);
    setIsModalOpen(true);
  };

  const handleCancelClaim = async () => {
    const result = await cancelAbsence();
    setIsModalOpen(false);

    if (result.success) {
      window.location.reload();
    }
  };

  const handleContinueClaim = () => {
    proceed();
    setIsModalOpen(false);
  };

  return (
    <>
      <LeaveProceedModal
        onRequestClose={() => setIsModalOpen(false)}
        onRequestLeave={handleCancelClaim}
        onRequestProceed={handleContinueClaim}
        title={t({ tag: "in_progress_absence_modal.title" })}
        contentText={t({ tag: "in_progress_absence_modal.support_text" })}
        leaveButtonText={t({ tag: "buttons.cancelAbsence" })}
        proceedButtonText={t({ tag: "buttons.continue" })}
        isOpen={isModalOpen}
      />
      <div>
        <h2>
          <Translation
            tag={"dashboard.to_do_activities"}
            vars={{ things_to_do: claims.length.toString() }}
          />
        </h2>
      </div>
      <div className={cardListClass} style={prepareCardListStyle()}>
        {claims.map(claim => (
          <div
            key={claim.incidentId}
            onClick={() => handleInProgressClaim(claim.incidentId)}
            className={cardClass}
          >
            <ClaimCard claim={claim} />
          </div>
        ))}
      </div>
      {itemsNotShown > 0 && (
        <div className={styles["to-do-activities__footer"]}>
          <TextButton
            className={styles["to-do-activities__expand-button"]}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded
              ? t({ tag: "claims.show_less" })
              : t({ tag: "claims.show_all" })}
          </TextButton>
          {!isExpanded && (
            <div className={styles["to-do-activities__items-not-shown"]}>
              {t({
                tag: "claims.items_not_shown",
                vars: { itemsNotShown: itemsNotShown.toString() }
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};
