import React, { memo } from "react";
import Card from "components/card/card";
import styles from "./claim-card.module.scss";
import {
  ClaimRelationship,
  ClaimSummary,
  ClaimSummaryWithTranslatedRelationship
} from "features/claims/claims-model";
import { Tag } from "components/tag/tag";
import { TagForClaimStatus } from "components/tag/tag-for-claim-status";
import {
  areTimesEqual,
  formatTimeInterval,
  formatTime,
  TimeInterval
} from "shared-types/time";
import { every, orderBy } from "lodash-es";
import {
  containsOvernightShift,
  formatClaimDates,
  getOvernightShiftMessage
} from "./claim-card-helper";
import { MoonIcon } from "components/icons";
import { Tooltip } from "components/tool-tip";
import clsx from "clsx";
import { ClientTranslationProps } from "i18n";
import { Translation } from "components/translation";
import { Link } from "react-router-dom";
import * as Routes from "routes";
import { useTranslation } from "hooks/translation";
import { useFormat } from "hooks/format";

type Props = {
  className?: string;
  claim: ClaimSummary | ClaimSummaryWithTranslatedRelationship;
  notLinked?: boolean;
  tabIndex?: boolean;
};

export const ClaimCard: React.FC<Props> = memo(
  ({ className, claim, notLinked, tabIndex = true }) => {
    const t = useTranslation();
    const { currentLocale } = useFormat();
    const ts = (s: string | ClientTranslationProps) =>
      typeof s === "string" ? s : t(s);

    const sortedDates =
      claim.claimType === "absence"
        ? orderBy(claim.absenceDates, ["date"])
        : [];

    const overnightMessage = () => {
      if (claim.claimType === "absence") {
        const translationProps = getOvernightShiftMessage(
          claim.absenceDates,
          claim.absenceType,
          currentLocale
        );
        if (translationProps) {
          return t(translationProps);
        }
      } else {
        return undefined;
      }
    };

    const formattedDates = formatClaimDates(claim, currentLocale);

    const tagTextForClaimType = () => {
      switch (claim.claimType) {
        case "absence":
          return t({ tag: "claims.tag.absence" });
        case "fml":
          return t({ tag: "claims.tag.fml" });
        case "std":
          return t({ tag: "claims.tag.std" });
      }
    };

    const firstClaim = 0;
    const lastClaim =
      claim.claimType === "incomplete" ? claim.absenceDates.length - 1 : 0;

    const card = (
      <Card testId={"claim-card"} className={styles["card"]}>
        <div className={styles["title-wrapper"]}>
          <p className={styles["title"]} data-card="title">
            {t(claim.title)}, {t({text: "case.number"})} {(claim.incidentId)}
          </p>
        </div>
        <div>
          <div className={styles["tag-row"]} data-card="tag">
            {claim.claimType !== "incomplete" && (
              <Tag tagStyle="blue">{tagTextForClaimType()}</Tag>
            )}
            {claim.relationship && (
              <Tag tagStyle="blue">
                <Translation
                  {...translateAbbreviatedRelationship(claim.relationship)}
                />
              </Tag>
            )}
            <TagForClaimStatus status={claim.status} />
          </div>
        </div>

        <div className={styles["dates-container-wrapper"]}>
          <div className={styles["dates-container__content"]} data-card="date">
            <div className={styles["dates-container"]}>
              <div>
                <p className={styles["date-label"]}>{ts(formattedDates)}</p>
                {claim.claimType === "absence" &&
                  checkEqualTimes(claim.absenceDates) && (
                    <p className={styles["time-label"]}>
                      {formatTimeInterval(sortedDates[0])}
                    </p>
                  )}
              </div>

              {claim.claimType === "absence" &&
                containsOvernightShift(claim.absenceDates) && (
                  <>
                    <div
                      data-tip={overnightMessage()}
                      data-for="overnight-shift"
                    >
                      <MoonIcon className={styles["moon"]} />
                    </div>
                    <Tooltip
                      id="overnight-shift"
                      place="bottom"
                      offset={{ left: 120, top: 0 }}
                      className={styles["tooltip"]}
                    />
                  </>
                )}
            </div>

            {claim.claimType === "incomplete" &&
              claim.absenceDates.length !== 0 &&
              (claim.absenceType === "continuous" ||
                claim.absenceDates.length === 1) && (
                <div>
                  <p className={styles["date-label"]}>
                    {t({
                      tag: "claims.time",
                      vars: {
                        startTime: formatTime({
                          hour: claim.absenceDates[firstClaim].startTime.hour,
                          minute:
                            claim.absenceDates[firstClaim].startTime.minute,
                          period:
                            claim.absenceDates[firstClaim].startTime.period
                        }),
                        endTime: formatTime({
                          hour: claim.absenceDates[lastClaim].endTime.hour,
                          minute: claim.absenceDates[lastClaim].endTime.minute,
                          period: claim.absenceDates[lastClaim].endTime.period
                        })
                      }
                    })}
                  </p>
                </div>
              )}

            {claim.claimType === "incomplete" && claim.createdBy && (
              <div>
                <p className={styles["date-label"]}>
                  {t({
                    tag: "claims.created_by",
                    vars: { createdBy: claim.createdBy }
                  })}
                </p>
              </div>
            )}
          </div>
        </div>
      </Card>
    );

    const linkTo = linkForClaim(claim);
    return linkTo && !notLinked ? (
      <Link className={clsx(styles["card-link"], className)} to={linkTo}>
        {card}
      </Link>
    ) : (
      <div className={className} tabIndex={tabIndex ? 0 : undefined}>
        {card}
      </div>
    );
  }
);

function checkEqualTimes(timeIntervals: TimeInterval[]) {
  const first = timeIntervals[0];
  return every(
    timeIntervals,
    ti =>
      areTimesEqual(first.startTime, ti.startTime) &&
      areTimesEqual(first.endTime, ti.endTime)
  );
}

function translateAbbreviatedRelationship(
  relationship: ClaimRelationship
): ClientTranslationProps {
  // Using the abbreviated form for some relationships, to keep tags short
  switch (relationship) {
    case "child-adopted":
    case "child-biological":
    case "child-adult":
    case "child-partner":
    case "child-foster":
    case "child-step":
    case "in-loco-parentis":
      return { tag: "claims.relationship.child" };
    case "legal-ward":
      return { tag: "claims.relationship.legal_ward" };
    case "next-of-kin":
      return { tag: "claims.relationship.next_of_kin" };
    case "parent":
      return { tag: "claims.relationship.parent" };
    case "parent-step":
      return { tag: "claims.relationship.parent_step" };
    case "spouse":
      return { tag: "claims.relationship.spouse" };
    case "self":
      return { tag: "claims.relationship.self" };
  }
}

function linkForClaim(claim: ClaimSummary) {
  if (claim.isReferral) {
    return "";
  }

  switch (claim.claimType) {
    case "fml":
      return Routes.ViewFmlClaim.generate({
        claimId: claim.incidentId.toString()
      });
    case "std":
      return Routes.ViewStdClaim.generate({
        claimId: claim.incidentId.toString()
      });
    case "absence":
      return Routes.ViewAbsenceClaim.generate({
        claimId: claim.incidentId.toString()
      });
  }
}
