export const URL_CONFIG = {
  authServerURL: process.env.REACT_APP_ABILITI_API_AUTH_URL,
  react_app_redirect_url: window.location.origin,
  client_id: process.env.REACT_APP_ABILITI_API_AUTH_CLIENT_ID
};

export const IDENTITY_CONFIG = {
  authority: URL_CONFIG.authServerURL, //(string): The URL of the OIDC provider.
  client_id: URL_CONFIG.client_id, //THIS WILL BE SET IN WEB CONFIRUATION PROVIDER  //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: URL_CONFIG.react_app_redirect_url + "/signin-oidc", //The URI of your client application to receive a response from the OIDC provider.
  //login: URL_CONFIG.authServerURL + "/login",
  silentRequestTimeout: 100000,
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: URL_CONFIG.react_app_redirect_url + "/silentrenew", //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: window.location.origin, // (string): The OIDC post-logout redirect URI.
  //audience: "https://example.com", //is there a way to specific the audience when making the jwt
  response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  //grantType: "password",
  scope: "openid profile connect config offline_access", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: "id_token access_token",
  revokeAccessTokenOnSignout: true
};

export const METADATA_OIDC = {
  issuer: URL_CONFIG.authServerURL,
  jwks_uri: URL_CONFIG.authServerURL + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: URL_CONFIG.authServerURL + "/connect/authorize",
  token_endpoint: URL_CONFIG.authServerURL + "/connect/token",
  userinfo_endpoint: URL_CONFIG.authServerURL + "/connect/userinfo",
  end_session_endpoint: URL_CONFIG.authServerURL + "/connect/endsession",
  check_session_iframe: URL_CONFIG.authServerURL + "/connect/checksession"
  //revocation_endpoint: URL_CONFIG.authServerURL + "/connect/revocation",
  //introspection_endpoint: URL_CONFIG.authServerURL + "/connect/introspect",
};

export const SIGNIN_PARAMS = {
  language: "signinLanguage",
  clientCode: "signinClientCode"
};
