import Axios, { AxiosError, AxiosResponse } from "axios";

const TOP_LEVEL_LOGIN_ENDPOINT = "/api/login";
const MORNEAU_LOGIN_ENDPOINT = "/api/login/morneau";
const MORNINGSTAR_LOGIN_ENDPOINT = "/api/login/morningstar";

export enum ApiResponse {
  SUCCESS,
  NETWORK_ERROR,
  UNEXPECTED_ERROR,
  SERVER_ERROR,
  AUTHENTICATION_ERROR,
  VALIDATION_ERROR
}

export type Capability = "canReportAbsence"; //| "canDoOtherStuff";
export type Capabilities = Record<Capability, boolean>;
export type UserInfo = {
  capabilities: Capabilities;
};

type LoginResponse<T> =
  | {
      type: ApiResponse.NETWORK_ERROR | ApiResponse.UNEXPECTED_ERROR;
    }
  | {
      type: ApiResponse.SUCCESS;
      userInfo: UserInfo;
    }
  | {
      type: ApiResponse.VALIDATION_ERROR;
      errors: Partial<T>;
    }
  | {
      type: ApiResponse.AUTHENTICATION_ERROR;
      message: { lockoutMinutes: number; attemptsRemaining: number };
    }
  | {
      type: ApiResponse.SERVER_ERROR;
      message: string;
    };

export type MorneauLoginCredentials = {
  lastName: string;
  employeeID: string;
  dateOfBirth: string;
};
export async function submitMorneauLogin(
  companyCode: string,
  data: MorneauLoginCredentials
): Promise<LoginResponse<MorneauLoginCredentials>> {
  try {
    const response = await Axios.post<
      MorneauLoginCredentials,
      AxiosResponse<UserInfo>
    >(MORNEAU_LOGIN_ENDPOINT, { companyCode, ...data });

    return { type: ApiResponse.SUCCESS, userInfo: response.data };
  } catch (error) {
    return handleLoginError(error);
  }
}

export type MorningstarLoginCredentials = {
  companyCode: string;
  employeeID: string;
  pin: string;
};
export async function submitMorningstarLogin(
  data: MorningstarLoginCredentials
): Promise<LoginResponse<MorningstarLoginCredentials>> {
  try {
    const response = await Axios.post<
      MorningstarLoginCredentials,
      AxiosResponse<UserInfo>
    >(MORNINGSTAR_LOGIN_ENDPOINT, data);

    return { type: ApiResponse.SUCCESS, userInfo: response.data };
  } catch (error) {
    return handleLoginError(error);
  }
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function handleLoginError<T>(error: any): LoginResponse<T> {
  if (isAxiosError(error)) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          // This is the automatic error returned by the ApiController
          // https://docs.microsoft.com/en-us/aspnet/core/web-api/?view=aspnetcore-3.1#automatic-http-400-responses
          return {
            type: ApiResponse.VALIDATION_ERROR,
            errors: error.response.data.errors
          };
        case 401:
          return {
            type: ApiResponse.AUTHENTICATION_ERROR,
            message: {
              attemptsRemaining: error.response.data.attemptsRemaining,
              lockoutMinutes: error.response.data.lockoutMinutes
            }
          };
        default:
          return {
            type: ApiResponse.SERVER_ERROR,
            message: error.response.data.message
          };
      }
    } else if (error.request) {
      return { type: ApiResponse.NETWORK_ERROR };
    }
  }

  return { type: ApiResponse.UNEXPECTED_ERROR };
}

export async function submitLogout(): Promise<ApiResponse> {
  try {
    await Axios.delete(TOP_LEVEL_LOGIN_ENDPOINT);
    return ApiResponse.SUCCESS;
  } catch (error) {
    return ApiResponse.UNEXPECTED_ERROR;
  }
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function isAxiosError(obj: any): obj is AxiosError {
  return (obj as AxiosError).isAxiosError;
}
