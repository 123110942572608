import { defineRoute } from "./definition";

export const Root = defineRoute("/");
export const Dashboard = defineRoute("/dashboard");

export const ReportAbsence = defineRoute("/report-absence/:incidentId?");
export const ReportAbsencePrimaryReason = defineRoute(
  "/report-absence/:incidentId?#primary-reason"
);

export const ViewFmlClaim = defineRoute("/claims/fml/:claimId", ["claimId"]);
export const ViewStdClaim = defineRoute("/claims/std/:claimId", ["claimId"]);
export const ViewAbsenceClaim = defineRoute("/claims/absence/:claimId", [
  "claimId"
]);
export const ViewAbsenceClaimAllDates = defineRoute(
  "/claims/absence/:claimId/all-dates",
  ["claimId"]
);

export const ClaimsList = defineRoute("/claims");
export const ClaimsHistory = defineRoute("/claims/history");

export const Logout = defineRoute("/logout");
export const AccessDenied = defineRoute("/access-denied");

export const LeavePolicy = defineRoute("/leave-policy/:policyCode", [
  "policyCode"
]);
