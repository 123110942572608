import React from "react";

interface State {
  hasError: boolean;
  error?: Error;
  info?: string;
}

const apiURL = process.env.REACT_APP_ABILITI_API_REST_URL;
const pingUrl = apiURL + "/config/v1.0/webconfig/test";
const desc = " ENV: " + process.env.NODE_ENV + " API URL: " + apiURL;

class AppHealth extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  public componentDidMount() {
    fetch(pingUrl)
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      .then(res => {
        if (res.ok) {
          return res.text();
        }
        this.setState({
          hasError: true,
          info: "API error [" + res.statusText + "]"
        });
        throw new Error("Network response was not ok.");
      })
      .then(text => this.setState({ info: text }))
      .catch(error => {
        console.error("error", error);
      });
  }

  render() {
    if (this.state.hasError) {
      return "Unhealthy - " + this.state.info + " " + desc;
    }
    if (!this.state.info) {
      return "Unhealthy - api" + desc;
    }

    return " Healthy " + desc + " response: " + this.state.info;
  }
}

export default AppHealth;
