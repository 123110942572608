import React from "react";
import styles from "./application-access.module.scss";
import { Translation } from "components/translation";
import { useTranslation } from "hooks/translation";
import { ContainedButton } from "components/buttons";
import { useLogoutModalContext } from "context/logout-modal-context";

export const ApplicationRestrictionView: React.FC = () => {
  const { setIsOpen } = useLogoutModalContext();

  const t = useTranslation();
  const contactUs = t({ tag: "global.accessDenied.contactUs" });
  return (
    <div className={styles["application-access-container"]}>
      <img
        className={styles["icon"]}
        src={`${process.env.REACT_APP_ASSETS_BASE}/images/illu-cannot-access.svg`}
      ></img>
      <h3 className={styles["head"]} data-testid="accessDeniedTitle">
        <Translation tag={"global.accessDenied.title"} />
      </h3>
      <h4 className={styles["context"]} data-testid="accessDeniedMsg">
        <Translation
          tag={"global.accessDenied.msg"}
          vars={{
            contactUs: `<u style="color: #0078ab"><a href="mailto:abilitiproductsupport@morneaushepell.com">${contactUs}</a></u>`
          }}
          html={true}
        />
      </h4>
      <ContainedButton
        className={styles["logout-button"]}
        onClick={() => setIsOpen(true)}
      >
        {t({ tag: "buttons.logout" })}
      </ContainedButton>
    </div>
  );
};
