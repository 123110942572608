// GENERATED FILE - DO NOT EDIT

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IClaim": [
      "AbilitiReferral",
      "LeaveClaim",
      "MyAbilitiARClaim",
      "STDClaim"
    ]
  }
};
      export default result;
    