import { OutlinedButton } from "components/buttons";
import { UploadIcon } from "components/icons";
import { useTranslation } from "hooks/translation";
import React, { useState } from "react";
import { DocumentEntityType } from "./documents";
import styles from "./styles.module.scss";
import { UploadDocumentModal } from "./upload-modal";

interface DocumentUploadProps {
  entityType: DocumentEntityType;
  entityId: number;
}

export const DocumentUpload: React.FC<DocumentUploadProps> = ({
  entityType,
  entityId
}: DocumentUploadProps) => {
  const t = useTranslation();

  const [isUploadOpen, setUploadOpen] = useState(false);

  return (
    <>
      <div className={styles["doc-upload"]}>
        <OutlinedButton
          className={styles["doc-upload-button"]}
          onClick={() => setUploadOpen(true)}
        >
          <UploadIcon className={styles["doc-upload-button--icon"]} />
          {t({ tag: "docs.upload_documents" })}
        </OutlinedButton>
      </div>
      <UploadDocumentModal
        isOpen={isUploadOpen}
        onClose={() => setUploadOpen(false)}
        entityType={entityType}
        entityId={entityId}
      />
    </>
  );
};
