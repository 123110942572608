import React, {
  CSSProperties,
  KeyboardEventHandler,
  Reducer,
  useEffect,
  useReducer,
  useRef
} from "react";
import { useOnClickOutside } from "hooks";
import { useTranslation } from "hooks/translation";
import { isEqual } from "lodash-es";
import { KeyCode, KeyName } from "shared-types/key-events";
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "components/icons";
import styles from "./styles.module.scss";
import { IconButton } from "components/buttons";
import clsx from "clsx";
import { appInsightLog } from "app-insights/appInsights";

export type Option<T> = { label: string; value: T };
type OptionChangeEvent<T> = (option: Option<T>) => void;

type Props<T> = {
  options: Option<T>[];
  onChange: OptionChangeEvent<T>;

  className?: string;
  anchorTestId?: string;
  popoverTestId?: string;

  align?: "left" | "right";
  value?: T;
  placeholder?: string;
  labelText?: string;
  selectorLabelText?: string; //as a title for mobile options selector
  ariaLabelledBy?: string;
  ariaLabel?: string;
};

/**
 * A reusable select component that provides both mouse and keyboard interactivity.
 *
 * Desired behavior for keyboard interactions were referenced from
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/listbox_role.
 * Note that type-ahead is not currently supported.
 */
export function DropdownSelect<T>(props: Props<T>): JSX.Element {
  const { value, options } = props;

  const [state, dispatch] = useReducer(
    selectReducer,
    props as Props<unknown>,
    initializeState
  );

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleKeyDown: KeyboardEventHandler = event => {
    if (event.keyCode === KeyCode.ENTER || event.key === KeyName.ENTER) {
      dispatch({ type: "toggleOpen" });
    }
  };

  const handleListClose = () => {
    dispatch({ type: "toggleOpen" });
    anchorRef.current?.focus();
  };

  const validSelection = options.find(o => isEqual(o.value, value));

  if (value && !validSelection) {
    console.error(
      "The value specified as the current selection does not match one of the available options.\nValue is:",
      value,
      "\nOptions are:",
      options
    );
  }

  const label =
    validSelection && validSelection.label
      ? validSelection.label
      : props.placeholder || "";

  const shouldShowListElements = props.options.length > 0;

  const handleOnClick = () => {
    appInsightLog(`User clicks on select: ${props.anchorTestId}`);
    if (shouldShowListElements) {
      dispatch({ type: "toggleOpen" });
    }
  };

  return (
    <div className={clsx(styles["select-container"], props.className)}>
      <div
        aria-labelledby={props.ariaLabelledBy}
        aria-label={props.ariaLabel}
        aria-haspopup={shouldShowListElements ? "listbox" : false}
        aria-readonly={shouldShowListElements ? false : true}
        tabIndex={0}
        ref={anchorRef}
        className={clsx(
          styles["select"],
          !shouldShowListElements && styles["readonly"],
          !props.labelText && styles["select--no-label"]
        )}
        onClick={handleOnClick}
        onKeyDown={handleKeyDown}
        data-testid={props.anchorTestId}
      >
        {props.labelText && (
          <div className={styles["select-label"]}>{props.labelText}</div>
        )}

        <div className={styles["select-value"]}>{label}</div>

        {shouldShowListElements && (
          <div className={styles["select-icon"]}>
            {state.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        )}
      </div>

      {state.isOpen && (
        <OptionList
          {...props}
          state={state}
          dispatch={dispatch}
          anchorRef={anchorRef}
          handleListClose={handleListClose}
        />
      )}
    </div>
  );
}

type SelectState = {
  isOpen: boolean;
  focusedIdx: number;
  selectedIdx: number;
  count: number;
};
type SelectActions =
  | {
      type: "toggleOpen";
    }
  | {
      type: "focusPrevious";
    }
  | {
      type: "focusNext";
    }
  | {
      type: "focusFirst";
    }
  | {
      type: "focusLast";
    }
  | {
      type: "selectOption";
      index: number;
    };

const selectReducer: Reducer<SelectState, SelectActions> = (state, action) => {
  switch (action.type) {
    case "toggleOpen":
      if (!state.isOpen) {
        // When opening, focus the currently selected index if present
        return {
          ...state,
          isOpen: true,
          focusedIdx: Math.max(state.selectedIdx, 0)
        };
      } else {
        return { ...state, isOpen: false };
      }
    case "focusPrevious":
      return { ...state, focusedIdx: Math.max(state.focusedIdx - 1, 0) };
    case "focusNext":
      return {
        ...state,
        focusedIdx: Math.min(state.focusedIdx + 1, state.count - 1)
      };
    case "focusFirst":
      return { ...state, focusedIdx: 0 };
    case "focusLast":
      return { ...state, focusedIdx: state.count - 1 };
    case "selectOption":
      return { ...state, isOpen: false, selectedIdx: action.index };
  }
};

const initializeState = (props: Props<unknown>): SelectState => {
  const initialIdx = props.options.findIndex(o => isEqual(o, props.value));
  return {
    isOpen: false,
    count: props.options.length,
    focusedIdx: initialIdx,
    selectedIdx: initialIdx
  };
};

type OptionListProps<T> = Props<T> & {
  anchorRef: React.RefObject<HTMLElement>;
  state: SelectState;
  dispatch: (action: SelectActions) => void;
  handleListClose: () => void;
};
function OptionList<T>(props: OptionListProps<T>) {
  const { anchorRef, state, dispatch, options, value, handleListClose } = props;

  const t = useTranslation();
  const popoverRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    popoverRef,
    event => {
      event.preventDefault();
      props.handleListClose();
    },
    anchorRef
  );

  const handleSelectOption = (option: Option<T>, index: number) => {
    dispatch({ type: "selectOption", index });
    props.onChange(option);
    anchorRef.current?.focus();
  };

  const anchorRect = anchorRef.current?.getBoundingClientRect();

  const listStyle: CSSProperties = {};
  if (anchorRect) {
    listStyle.top = anchorRect.height + 8;
  }
  if (props.align === "right") {
    listStyle.right = 0;
  } else {
    listStyle.left = 0;
  }

  return (
    <>
      <div
        className={styles["options-container"]}
        style={listStyle}
        ref={popoverRef}
      >
        <div className={styles["options-shadow"]} />
        <div className={styles["options-header"]}>
          <div className={styles["options-header-text"]}>
            {props.selectorLabelText}
          </div>
          <IconButton
            className={styles["close-button"]}
            ariaLabel={t({ tag: "buttons.cancel" })}
            onClick={handleListClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <ul
          role="listbox"
          tabIndex={-1}
          className={styles["options-list"]}
          data-testid={props.popoverTestId}
        >
          {options.map((o, i) => (
            <OptionItem
              key={i}
              option={o}
              isFocused={i === state.focusedIdx}
              isSelected={isEqual(value, o)}
              handleSelectOption={() => handleSelectOption(o, i)}
              handleArrowUp={() => dispatch({ type: "focusPrevious" })}
              handleArrowDown={() => dispatch({ type: "focusNext" })}
              handleHome={() => dispatch({ type: "focusFirst" })}
              handleEnd={() => dispatch({ type: "focusLast" })}
              handleListClose={handleListClose}
            />
          ))}
        </ul>
      </div>
    </>
  );
}

type OptionItemProps<T> = {
  option: Option<T>;
  isFocused: boolean;
  isSelected: boolean;
  handleSelectOption: () => void;
  handleArrowUp: () => void;
  handleArrowDown: () => void;
  handleHome: () => void;
  handleEnd: () => void;
  handleListClose: () => void;
};
function OptionItem<T>(props: OptionItemProps<T>) {
  const { isFocused, isSelected, option } = props;
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus();
    }
  }, [ref, isFocused]);

  const handleKeyDown: KeyboardEventHandler = event => {
    const { keyCode, key } = event;

    if (keyCode === KeyCode.ARROW_UP || key === KeyName.ARROW_UP) {
      props.handleArrowUp();
    } else if (keyCode === KeyCode.ARROW_DOWN || key === KeyName.ARROW_DOWN) {
      props.handleArrowDown();
    } else if (keyCode === KeyCode.HOME || key === KeyName.HOME) {
      props.handleHome();
    } else if (keyCode === KeyCode.END || key === KeyName.END) {
      props.handleEnd();
    } else if (keyCode === KeyCode.ENTER || key === KeyName.ENTER) {
      props.handleSelectOption();
    } else if (keyCode === KeyCode.TAB || key === KeyName.TAB) {
      props.handleListClose();
    } else {
      // Bail for unsupported inputs to avoid overriding misc default behavior
      return;
    }

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <li
      role="option"
      aria-selected={isSelected}
      tabIndex={0}
      ref={ref}
      onClick={() => {
        props.handleSelectOption();
        appInsightLog(`User clicks: ${option.label}`);
      }}
      onKeyDown={handleKeyDown}
      className={styles["option"]}
      data-value={option.value}
      data-testid={option.label}
    >
      {option.label}
    </li>
  );
}
