import {
  ClientFeature,
  IWebConfigurationService,
  WebConfiguration
} from "./web-configuration-model";

export class MockWebConfigurationService implements IWebConfigurationService {
  getWebConfigurationByUrl = async (url: string): Promise<WebConfiguration> =>
    new Promise<WebConfiguration>((resolve, reject) => {
      const configuration: WebConfiguration = {
        clientCode: "DEMOCLIENT",
        instanceId: "LEAVES"
      };
      resolve(configuration);
    });

  canAccessApplication = async (clientCode: string): Promise<boolean> =>
    new Promise<boolean>(resolve => resolve(true));

  getClientFeatureUrl = async (
    clientCode: string,
    featureName: ClientFeature,
    language: string
  ) =>
    new Promise<string>(resolve =>
      resolve("https://clientportal.morneaushepell.com")
    );
}
