import { makeExecutableSchema } from "@graphql-tools/schema";
import { loader } from "graphql.macro";
import faker from "faker";
import { addMocksToSchema, MockList } from "@graphql-tools/mock";
import { addHours } from "date-fns";
import _, { sample } from "lodash-es";
import {
  AbilitiReferral,
  AbsenceIncidentInputModelInput,
  Connect_LocaleModel,
  LeaveClaim,
  MyAbilitiArClaim,
  QuickSearchTypes,
  SearchRequestOfClaimSearchRequestInput,
  StdClaim
} from "graphql-types.gen";

export function mockApiSchema() {
  const schema = makeExecutableSchema({ typeDefs: loader("schema.graphql") });

  const scenario = {
    activeClaims: _.flatten([
      _.range(2).map(() => makeMyAbilitiARClaim(true)),
      _.range(2).map(() => makeSTDClaim(true)),
      _.range(2).map(() => makeLeaveClaim(true)),
      _.range(2).map(() => makeAbilitiReferral("std")),
      _.range(2).map(() => makeAbilitiReferral("leave"))
    ]),
    historicalClaims: _.flatten([
      _.range(20).map(() => makeMyAbilitiARClaim(false)),
      _.range(20).map(() => makeSTDClaim(false)),
      _.range(20).map(() => makeLeaveClaim(false))
    ])
  };

  const mocks = {
    Int: () => faker.datatype.number(),
    Decimal: () => faker.datatype.number(),
    Long: () => faker.datatype.number(),
    Uuid: () => faker.random.uuid(),
    DateTime: () => faker.date.future().toISOString(),
    TimeSpan: () => faker.datatype.number(8),
    String: () => faker.random.words(3),
    DecisionViewModel: () => ({
      decisionType: () => _.sample(allDecisionStatusTypes)
    }),
    LeaveDeterminationViewModel: () => ({
      resolution: () => _.sample(allDecisionStatusTypes)
    }),
    ValidationErrorGraphQLOfSaveAbsenceIncidentReturnModel: () => ({
      success: () => true
    }),
    ValidationErrorGraphQLOfSubmitAbsenceIncidentReturnModel: () => ({
      success: () => true
    }),
    AbsenceQuestionReturnModel: () => ({
      questions: () => [],
      preQualifyingQuestions: () => [],
      reasonOfAbsenceAlerts: () => new MockList([0, 2])
    }),
    connect_QuestionModel: () => ({
      alerts: () => new MockList(0)
    }),
    WorkShiftModel: () => ({
      endTimeHours: () => faker.datatype.number(23),
      endTimeMinutes: () => faker.datatype.number(59),
      startTimeHours: () => faker.datatype.number(23),
      startTimeMinutes: () => faker.datatype.number(59)
    }),
    STDCaseViewModel: () => ({
      claimStatus: () => sample(StdClaimStatusOptions)
    }),
    LeaveCaseViewModel: () => ({
      claimStatus: () => sample(LeaveClaimStatusOptions)
    }),
    LeavePolicyViewModel: () => ({
      planned: () => faker.datatype.number({ min: 15, max: 30 }),
      used: () => faker.datatype.number(15),
      total: () => faker.datatype.number(30),
      unit: () => sample(["hours", "days", "weeks"])
    }),
    Query: () => ({
      searchActiveClaims: () => ({
        totalRecords: scenario.activeClaims.length,
        items: scenario.activeClaims
      }),
      searchClaimsWithQuickSearchType: ({
        quickSearchType,
        request
      }: {
        quickSearchType: QuickSearchTypes;
        request: SearchRequestOfClaimSearchRequestInput;
      }) => {
        const { pageSize, pageNumber } = request ?? {
          pageSize: 10,
          pageNumber: 1
        };

        if (quickSearchType !== "HISTORICALCLAIMS") {
          console.error("Unknown quickSearchType", quickSearchType);
          return undefined;
        }

        // pageNumber is 1-based
        const pageIndex = pageNumber - 1;
        const items = _.slice(
          scenario.historicalClaims,
          pageIndex * pageSize,
          pageIndex * pageSize + pageSize
        );

        return {
          totalRecords: scenario.historicalClaims.length,
          items
        };
      }
    }),
    Mutation: () => ({
      saveAbsenceIncident: (
        _: unknown,
        inputs: AbsenceIncidentInputModelInput
      ) => {
        // TODO: Make this work. The inputs contain more absenceDates than
        // we actually send. Leaving it for now since we still rely on
        // the MockAbsenceReportingService for sandbox mode.

        return {
          absenceIncidentModel: () => {
            return {
              primaryReason: () => inputs.primaryReason,
              absenceDates: () => inputs.absenceDates
            };
          }
        };
      }
    })
  };
  return addMocksToSchema({ schema, mocks });
}

const StdClaimStatusOptions = ["OPEN", "CLOSED"];
const LeaveClaimStatusOptions = [
  "APPROVED",
  "PENDING",
  "PENDING_PAPERWORK",
  "PENDING_REVIEW",
  "MISSING_PAPERWORK",
  "CANCELLED",
  "DENIED"
];

const allDecisionStatusTypes = [
  "APPROVED",
  "SUPPORT",
  "PREAPROV",
  "SUP.TRTW",
  "PENDING",
  "DENIED",
  "NON.PARTIC",
  "NONSUPPORT"
];

const allRelationships = [
  "adoptedChild",
  "biologicalChild",
  "AdultChild",
  "childOfDomestic",
  "fosterChild",
  "in_loco_parentis",
  "legal_ward",
  "next_of_kin",
  "parent",
  "self",
  "spouse",
  "stepChild",
  "step_parent"
];

function maybeNull<T>(chanceOfNull: number, makeValue: () => T) {
  if (faker.random.float({ min: 0, max: 1 }) <= chanceOfNull) {
    return null;
  } else {
    return makeValue();
  }
}

function makeRelationshipOrNull() {
  return maybeNull(1 / 5, () => faker.random.arrayElement(allRelationships));
}

type GqlObject = { __typename: string };

function makeAbilitiReferral(
  subtype: "std" | "leave"
): GqlObject & Partial<AbilitiReferral> {
  const date = faker.date.future();

  return {
    __typename: "AbilitiReferral",
    incidentTypeCode: "ABILITI_REFERRAL",
    isLeavesReferral: subtype === "leave",
    isWCBReferral: false,
    status: "1",
    caseCancelled: 0,
    firstDateOfAbsence: date.toISOString(),
    caseType: { std: "STD", leave: "LEAVE" }[subtype],
    claimStatus: sample(
      { std: StdClaimStatusOptions, leave: LeaveClaimStatusOptions }[subtype]
    ),
    leaveTypeLocale: { leave: makeLocaleText(), std: [] }[subtype],
    caseTypeLocale: { std: makeLocaleText(), leave: [] }[subtype],
    relationShip: makeRelationshipOrNull()
  };
}

function makeLeaveClaim(active: boolean): GqlObject & Partial<LeaveClaim> {
  const date = active ? faker.date.soon(30) : faker.date.recent(30);

  return {
    __typename: "LeaveClaim",
    incidentTypeCode: "INCIDENT_GENERIC_LEAVE",
    status: active ? "1" : "0",
    caseCancelled: active ? 0 : faker.datatype.number({ min: 0, max: 1 }),
    leaveStartDate: date.toISOString(),
    leaveTypeLocale: makeLocaleText(),
    leaveTypeCode: "PENDING",
    leaveStatus: sample(LeaveClaimStatusOptions),
    claimStatus: sample(LeaveClaimStatusOptions),
    relationShip: makeRelationshipOrNull()
  };
}

function makeSTDClaim(active: boolean): GqlObject & Partial<StdClaim> {
  const date = active ? faker.date.soon(30) : faker.date.recent(30);

  return {
    __typename: "STDClaim",
    incidentTypeCode: "INCIDENT_STD",
    status: active ? "1" : "0",
    caseCancelled: active ? 0 : faker.datatype.number({ min: 0, max: 1 }),
    dateOfIncident: date.toISOString(),
    caseTypeLocale: makeLocaleText(),
    claimStatus: active ? "Open" : "Closed",
    relationShip: makeRelationshipOrNull()
  };
}

function makeMyAbilitiARClaim(
  active: boolean
): GqlObject & Partial<MyAbilitiArClaim> {
  const date = active ? faker.date.soon(30) : faker.date.recent(30);

  return {
    __typename: "MyAbilitiARClaim",
    incidentTypeCode: "INCIDENT_MYABILITI_AR",
    caseCancelled: active ? 0 : faker.datatype.number({ min: 0, max: 1 }),
    absenceType: "intermittent",
    relationShip: makeRelationshipOrNull(),
    primaryReasonLocale: makeLocaleText(),
    claimDates: [
      {
        startDate: date.toISOString(),
        endDate: addHours(date, 2).toISOString()
      }
    ],
    claimStatus: "S"
  };
}

function makeLocaleText(): Connect_LocaleModel[] {
  return ["EN", "FR"].map(locale => ({
    description: faker.company.catchPhrase(),
    locale
  }));
}
