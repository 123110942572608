import {
  QuestionAnswer,
  ValidationResult,
  WorkflowQuestionTemplate
} from "../absence-reporting-types";
import { ARWorkflowState } from "../absence-reporting-state";
import {
  areTimesEqual,
  formatTime,
  TimeInterval,
  toDateInterval,
  toTimeInterval
} from "shared-types/time";
import { differenceInMinutes } from "date-fns";
import { WorkShift } from "../incident";
import { flatten } from "lodash-es";
import { getWorkShiftOptions } from "utils/shift-utils";
import {
  EmployeeSchedule,
  PreviousAbsence
} from "features/employee/employee-model";

const validateTime = (interval: TimeInterval, duration: number) => {
  const times = toDateInterval(interval);
  const diff = differenceInMinutes(times.endDate, times.startDate);
  return diff % duration === 0;
};

const validateInterval = (interval: TimeInterval): boolean => {
  const times = toDateInterval(interval);

  return times.startDate.getTime() !== times.endDate.getTime();
};

const validateTimeIntervals = (
  state: ARWorkflowState,
  intervals: TimeInterval[]
): ValidationResult => {
  if (state.incident.absenceType !== "continuous") {
    if (!intervals.every(i => validateInterval(i))) {
      return {
        isValid: false,
        message: {
          tag: "reportAbsence.absence_time.invalid_duration",
          vars: {
            duration: state.config?.absenceDurationMinutes.toString() || ""
          }
        }
      };
    }
  }

  const duration = state.config?.absenceDurationMinutes || 0;

  if (duration > 0 && !intervals.every(i => validateTime(i, duration))) {
    return {
      isValid: false,
      message: {
        tag: "reportAbsence.absence_time.invalid_duration",
        vars: {
          duration: state.config?.absenceDurationMinutes.toString() || ""
        }
      }
    };
  }
  return { isValid: true };
};

const getOverlappingAbsenceTime = (
  abs1StartDate: Date,
  abs1EndDate: Date,
  abs2StartDate: Date,
  abs2EndDate: Date
): TimeInterval => {
  const from = abs1StartDate > abs2StartDate ? abs1StartDate : abs2StartDate;
  const to = abs1EndDate > abs2EndDate ? abs2EndDate : abs1EndDate;

  return toTimeInterval(from, to);
};

export const getOverlappingAbsenceTimes = (
  interval: TimeInterval,
  previousAbsences: PreviousAbsence[]
): TimeInterval[] => {
  const times: TimeInterval[] = [];

  const dateInterval = toDateInterval(interval);
  for (let i = 0; i < previousAbsences.length; i++) {
    const absence = previousAbsences[i];
    if (!absence.startDate || !absence.endDate) {
      continue;
    }

    if (
      (dateInterval.startDate >= absence.startDate &&
        dateInterval.startDate < absence.endDate) ||
      (dateInterval.endDate > absence.startDate &&
        dateInterval.endDate <= absence.endDate) ||
      (absence.startDate > dateInterval.startDate &&
        absence.startDate < dateInterval.endDate) ||
      (absence.endDate > dateInterval.startDate &&
        absence.endDate < dateInterval.endDate) ||
      (absence.startDate === dateInterval.startDate &&
        absence.endDate === dateInterval.endDate)
    ) {
      const time = getOverlappingAbsenceTime(
        absence.startDate,
        absence.endDate,
        dateInterval.startDate,
        dateInterval.endDate
      );

      if (
        times.length > 0 &&
        times[times.length - 1].date === time.date &&
        areTimesEqual(times[times.length - 1].endTime, time.startTime)
      ) {
        times[times.length - 1] = {
          date: time.date,
          startTime: times[times.length - 1].startTime,
          endTime: time.endTime
        };
      } else {
        times.push(time);
      }
    }
  }

  return times;
};

const validateTimeOverlaps = (
  previousAbsences: PreviousAbsence[],
  intervals: TimeInterval[]
): ValidationResult => {
  for (let i = 0; i < intervals.length; i++) {
    const overlappingAbsence = getOverlappingAbsenceTimes(
      intervals[i],
      previousAbsences
    );

    if (overlappingAbsence.length === 1) {
      const from = overlappingAbsence[0].startTime
        ? formatTime(overlappingAbsence[0].startTime)
        : "?";
      const to = overlappingAbsence[0].endTime
        ? formatTime(overlappingAbsence[0].endTime)
        : "?";
      return {
        isValid: false,
        message: {
          tag: "reportAbsence.absence_time.overlapping_absences",
          vars: {
            from,
            to
          }
        }
      };
    } else if (overlappingAbsence.length > 1) {
      const from1 = overlappingAbsence[0].startTime
        ? formatTime(overlappingAbsence[0].startTime)
        : "?";
      const to1 = overlappingAbsence[0].endTime
        ? formatTime(overlappingAbsence[0].endTime)
        : "?";
      const from2 = overlappingAbsence[1].startTime
        ? formatTime(overlappingAbsence[1].startTime)
        : "?";
      const to2 = overlappingAbsence[1].endTime
        ? formatTime(overlappingAbsence[1].endTime)
        : "?";
      return {
        isValid: false,
        message: {
          tag: "reportAbsence.absence_time.overlapping_many_absences",
          vars: {
            from1,
            to1,
            from2,
            to2
          }
        }
      };
    }
  }

  return { isValid: true };
};

export const buildDateTimesSections = (
  state: ARWorkflowState,
  schedule: EmployeeSchedule,
  previousAbsences: PreviousAbsence[]
): WorkflowQuestionTemplate[] => {
  const getAllWorkShiftOptions = () => {
    return flatten([getWorkShiftOptions(state.config), state.tempShiftOptions]);
  };

  
  return [
    {
      id: "absence-dates",
      type: "CalendarQuestion",
      promptTag: () => ({ tag: "reportAbsence.absence_date.prompt" }),
      descriptionTag: () => ({
        tag: "reportAbsence.absence_date.description"
      }),
      absenceReasonInfo: state.getReasonOfAbsenceInfo,
      answer: () => ({
        type: "absence-dates",
        value: {
          dates: state.incident.absenceDates.map(d => d.shiftStartTime),
          absenceType: state.incident.absenceType || "continuous"
        }
      }),
      summary: () => {
        return {
          type: "absence-dates",
          label: { tag: "reportAbsence.absence_date.prompt" },
          answer: {
            dates: state.incident.absenceDates,
            absenceType: state.incident.absenceType
          }
        };
      },
      setAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "absence-dates") {
          state.incident.absenceType = answer.value.absenceType;
          state.setDates(answer.value.dates);
        }
      },
      requiresAnswer: () => state.incident.absenceDates.length === 0,
      schedule: () => schedule,
      previousAbsences: () => previousAbsences,
      isSubmissionMode: true
    },
    {
      id: "absence-shifts",
      type: "ShiftQuestion",
      promptTag: () => ({ tag: "reportAbsence.absence_shift.prompt" }),
      descriptionTag: () => ({
        tag: "reportAbsence.absence_shift.description"
      }),
      workShiftOptions: getAllWorkShiftOptions,
      addTempShift: (shift: WorkShift) => {
        state.tempShiftOptions.push(shift);
      },
      answer: () => ({
        type: "absence-shifts",
        value: { shifts: state.getShifts() }
      }),
      setAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "absence-shifts") {
          state.setShifts(answer.value.shifts, true);
        }
      },
      requiresAnswer: () => false,
      initialSameShiftToggle: () => {
        return state.showSameShiftForDates !== undefined
          ? state.showSameShiftForDates
          : state.incident.absenceType === "continuous";
      },
      sameShiftToggleHandler: val => state.setShowSameShiftForDates(val),
      isSubmissionMode: true,   
      maxShiftLengthThresholdInMinutes: state.config?.aR3IvrConfiguration?.maxShiftLengthThresholdInMinutes || 0 
    },
    {
      id: "absence-times",
      type: "TimeQuestion",
      scheduleOverrideWarning: () =>
        state.config?.workScheduleInfo.scheduleOverrideWarning ?? false,
      scheduleOverrideMessage: () =>
        state.config?.workScheduleInfo.warningMessages ?? {
          tag: "warnings.schedule_override"
        },
      selectedWorkShifts: () => state.shifts,      
      maxShiftLengthThresholdInMinutes: state.config?.aR3IvrConfiguration?.maxShiftLengthThresholdInMinutes || 0,      
      answer: () => ({
        type: "absence-times",
        value: {
          absenceType: state.incident.absenceType || "intermittent",
          times: state.getTimes(),
          minuteInterval: state.config?.absenceDurationMinutes || 1
        }
      }),
      promptTag: () => ({ tag: "reportAbsence.absence_time.prompt" }),
      descriptionTag: () => ({
        tag: "reportAbsence.absence_time.description"
      }),
      setAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "absence-times") {
          state.setTimes(answer.value.times);
          state.restoredTimeIntervals = JSON.parse(
            JSON.stringify(answer.value.times)
          );
          if (answer.value.absenceType) {
            state.incident.absenceType = answer.value.absenceType;
          }
        }
      },
      validateAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "absence-times") {
          const timeOverlapsValidation = validateTimeOverlaps(
            previousAbsences,
            answer.value.times
          );

          return !timeOverlapsValidation.isValid
            ? timeOverlapsValidation
            : validateTimeIntervals(state, answer.value.times);
        }
        return { isValid: true };
      },
      requiresAnswer: () => false,
      previousAbsences: () => previousAbsences,
      isSubmissionMode: true
    }
  ];
};
