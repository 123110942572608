import { gql } from "@apollo/client";

export const sectionQuery = gql`
  fragment sectionQuery on PageModel {
    __typename
    pageHeading {
      description
      locale
    }
    pageHeadingDescription {
      description
      locale
    }
    pageId
    sections {
      questions {
        config {
          changeMonth
          changeYear
          linkedField
          maxDate
          maxLength
          uRL
        }
        defaultValue
        isReadOnly
        isVisible
        label {
          description
          locale
        }
        longDescription {
          description
          locale
        }
        name
        options {
          text {
            description
            locale
          }
          value
        }
        placeholder {
          description
          locale
        }
        questionType
        reloadIncidentData
        resolvedValue
        source
        alerts {
          alertId
          alertText {
            description
            locale
          }
        }
        validationConstraint {
          compare {
            fieldToCompare
            operator
            valueToCompare
          }
          digits
          max
          min
          number
          required
        }
        visibilityConditions {
          id
          operator
          values
        }
      }
      sectionHeading {
        description
        locale
      }
      sectionHeadingDescription {
        description
        locale
      }
      sectionId
    }
  }
`;

export const absenceIncidentFields = gql`
  fragment absenceIncidentFields on AbsenceIncidentModel {
    __typename
    absenceDates {
      isPartialAbsence
      scheduledShiftEndTime
      scheduledShiftStartTime
      shiftDuration
      shiftEndTime
      shiftStartTime
    }
    absenceIncidentId
    absenceType
    bestPhoneNumber
    employeeNumber
    clientCode
    firstName
    lastName
    linkedIncident
    primaryReason
    provinceCode
    questionResponses {
      key
      value
    }
    requestDate
    returnToWorkDate
    secondaryReason
    maxDaysAllowed
    maxReportableDays
  }
`;

export const saveAbsenceIncidentModel = gql`
  fragment saveAbsenceIncidentModel on SaveAbsenceIncidentReturnModel {
    __typename
    absenceIncidentModel {
      ...absenceIncidentFields
    }
  }
  ${absenceIncidentFields}
`;

export const submitAbsenceIncidentModel = gql`
  fragment submitAbsenceIncidentModel on SubmitAbsenceIncidentReturnModel {
    __typename
    absenceIncidentModel {
      ...absenceIncidentFields
    }
  }
  ${absenceIncidentFields}
`;

export const incidentQuestionFields = gql`
  fragment incidentQuestionFields on AbsenceQuestionReturnModel {
    __typename
    questions {
      ...sectionQuery
    }
    preQualifyingQuestions {
      ...sectionQuery
    }
    reasonOfAbsenceAlerts {
      alertId
      alertLevel
      alertText {
        description
        locale
      }
    }
  }
  ${sectionQuery}
`;

export const incidentSaveReturn = gql`
  fragment incidentSaveReturn on ValidationErrorGraphQLOfSaveAbsenceIncidentReturnModel {
    __typename
    returnValue {
      ...saveAbsenceIncidentModel
      absenceQuestionReturnModel {
        ...incidentQuestionFields
      }
    }
    success
    validationErrors {
      errorCode
      errorDescription
      errorDescriptionLocales {
        description
        locale
      }
    }
  }
  ${saveAbsenceIncidentModel}
  ${incidentQuestionFields}
`;

export const incidentSubmitReturn = gql`
  fragment incidentSubmitReturn on ValidationErrorGraphQLOfSubmitAbsenceIncidentReturnModel {
    __typename
    returnValue {
      ...submitAbsenceIncidentModel
      closingScripts {
        closingScriptId
        text {
          description
          locale
        }
      }
    }
    success
    validationErrors {
      errorCode
      errorDescription
      errorFields
      errorDescriptionLocales {
        description
        locale
      }
    }
  }
  ${submitAbsenceIncidentModel}
`;
