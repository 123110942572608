import * as React from "react";

import { AuthContext } from "./auth-provider";
import { ClientLogo } from "components/clientLogo";
import { Translation } from "components/translation";
import { LoadingSpinner } from "components/loading-spinner";
import { useLanguageContext } from "context/user-language-context";
import JwtDecode from "jwt-decode";
import { IAbilitiUser } from "features/session/session-model";
import { getLanguageFromCode } from "features/translations/locales";
import { SIGNIN_PARAMS } from "./auth-config";

export const Callback: React.FunctionComponent = () => {
  const { authContext } = React.useContext(AuthContext);
  const { setLanguage } = useLanguageContext();

  const [errorMessage, setErrorMessage] = React.useState("");

React.useEffect(() => {
  let retryAttempts = Number(sessionStorage.getItem("refetchTokenAttempts")) || 0;
    authContext &&
      authContext.signinRedirectCallback != null &&
      authContext
        .signinRedirectCallback()
        .then(user => {
          let lang = localStorage.getItem(SIGNIN_PARAMS.language);
          if (lang) {
            localStorage.removeItem(SIGNIN_PARAMS.language);
          } else {
            const token = JwtDecode<IAbilitiUser>(user.access_token);
            lang = token.locale ?? "";
          }
        })
        .catch(err => {
          if (retryAttempts < 5){
            retryAttempts++;
            sessionStorage.setItem("refetchTokenAttempts", retryAttempts.toString());
            window.location.replace("");
          } else if (retryAttempts >= 5) {
            sessionStorage.setItem("refetchTokenAttempts", "5");
            setErrorMessage(err.message);
          }
        });
  });
  return (
    <div>
      <header className="app-header">
        <ClientLogo height="50px" logoStyle="dark" />
        <div className="loading">
          <h3>
            {" "}
            <Translation tag="login.loadApplication" />
          </h3>
          <LoadingSpinner />
        </div>
        {errorMessage && <p>{errorMessage}</p>}
      </header>
    </div>
  );
};
