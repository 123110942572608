import clsx from "clsx";
import React from "react";
import styles from "./tabs.module.scss";

export type TabEntry = {
  label: string;
  tabId: string;
};

interface TabsProps {
  tabId?: string;
  tabs: TabEntry[];
  onChange: (tabId: string) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  tabId,
  onChange
}: TabsProps) => {
  return (
    <div className={styles["container"]}>
      {tabs.map(t => (
        <div
          key={t.tabId}
          className={clsx(
            styles["tab-entry"],
            t.tabId === tabId ? styles["tab-entry-active"] : null
          )}
          onClick={() => {
            onChange(t.tabId);
          }}
        >
          <div> {t.label}</div>
          <div className={styles["tab-entry-bottom"]}>
            <div></div>
          </div>
        </div>
      ))}
    </div>
  );
};
