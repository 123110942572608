import { signinCallbackPath } from "auth/auth-service";
import jwtDecode from "jwt-decode";
import { IAbilitiUser, ISessionService, IAbilitiToken } from "./session-model";

export class TokenSessionService implements ISessionService {
  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken: string | null) => {
    if (accessToken) {
      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("refetchTokenAttempts","0");
    } else {
      sessionStorage.clear();
    }
  };

  //remove token to force auth check form identity server
  //keep token within 5s to allow for storing by signin and page reload
  resetSessionToken = () => {
    const current = new Date().getTime();
    if (window.location.pathname !== "/" + signinCallbackPath) {
      const timestamp = +(sessionStorage.getItem("session_ts") || 0);

      if (timestamp < current - 60000) {
        sessionStorage.removeItem("access_token");
      }
    }
    sessionStorage.setItem("session_ts", "" + current);
  };

  getAccessToken = () => sessionStorage.getItem("access_token");

  getUser = (): IAbilitiUser | null => {
    const accessToken = this.getAccessToken();
    if (accessToken === null || !this.isValidToken(accessToken)) return null;
    return jwtDecode(accessToken);
  };

  getClientCode = (): string | null => {
    const session_code = sessionStorage.getItem("client_code");
    if (session_code) return session_code;
    return this.getUser()?.client_code ?? null;
  };

  setClientCode = (clientCode: string) => {
    sessionStorage.setItem("client_code", clientCode);
  };

  getUserPrivileges = (): string[] => {
    const privileges = sessionStorage.getItem("privileges");
    if (!privileges) {
      return [];
    }

    return JSON.parse(privileges);
  };

  isValidToken = (accessToken: string | null) => {
    if (!accessToken) {
      return false;
    }
    const decoded: IAbilitiToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  };

  isAuthenticated = () => this.isValidToken(this.getAccessToken());

  hasPrivileges = (privileges: string[], checkAll?: boolean) => {
    const user = this.getUser();
    const userPrivileges = (user && this.getUserPrivileges()) || [];

    if (checkAll) {
      return (
        privileges.filter(f => userPrivileges?.indexOf(f) < 0).length === 0
      );
    } else {
      return privileges.filter(f => userPrivileges?.indexOf(f) >= 0).length > 0;
    }
  };

  hasEmployees = (): boolean => {
    return sessionStorage.getItem("hasEmployees") === "true";
  };

  isCICUser = (): boolean => {
    return sessionStorage.getItem("isCICUser") === "true";
  };

  hasAccess = (): boolean => sessionStorage.getItem("hasAccess") !== "false";
}
