import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { useTranslation } from "hooks/translation";
import { TimeIcon } from "components/icons";

export type SummaryLabelProps = {
  label: string;
  value: string;
  labelTestId?: string;
  valueTestId?: string;
  shiftLabel?: string;
  isOvernight?: boolean;
  subValue?: string;
  absenceTime?: string;
  unpaidAbsenceTime?: string;
};

const SummaryLabel: React.FC<SummaryLabelProps> = ({
  label,
  value,
  labelTestId,
  valueTestId,
  shiftLabel,
  isOvernight,
  subValue,
  absenceTime,
  unpaidAbsenceTime
}) => {
  const t = useTranslation();

  return (
    <div className={clsx(styles["summary-label-container"])}>
      <div data-testid={labelTestId ?? label} className={clsx(styles["label"])}>
        {label}
      </div>
      <p data-testid={valueTestId ?? value} className={clsx(styles["value"])}>
        {value}
      </p>
      <p data-testid={subValue ?? subValue} className={styles["value"]}>
        {subValue}
      </p>
      {shiftLabel && (
        <div
          data-testid={labelTestId ?? label}
          className={clsx(styles["label"])}
        >
          {shiftLabel}
          {isOvernight && (
            <span className={styles["warning-text-date"]}>
              {" " +
                t({
                  tag: "reportAbsence.absence_shift.next_day"
                })}
            </span>
          )}
        </div>
      )}
      {absenceTime && (
        <div className={styles["absence-duration"]}>
          <TimeIcon className={styles["time-icon"]}></TimeIcon>
          <p className={styles["absence-time"]}>{absenceTime}</p>
          {unpaidAbsenceTime && (
            <p className={styles["unpaid-absence-time"]}>{unpaidAbsenceTime}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SummaryLabel;
