/* eslint-disable @typescript-eslint/no-explicit-any */

import { LocaleTexts } from "shared-types/api";

type GetTranslationResult =
  | { result: "found"; value: string }
  | { result: "not_found"; message: string };

type VarValue = string | LocaleTexts;

export function tryGetTranslation(
  translations: I18nMap,
  tag: string,
  vars: { [p: string]: string } | undefined
): GetTranslationResult {
  const match = tag.match(/([\w-]+)(?:\.(.+))?/);
  if (!match) {
    throw new Error("Invalid key. Must be in the format 'foo.bar.baz'");
  }
  const head = match[1];
  const rest = match[2];
  const template = translations[head];
  if (template === undefined) {
    return {
      result: "not_found",
      message: `Tag ${tag} missing from translations file`
    };
  }

  if (typeof template === "string") {
    const translation = substitute(template, vars ?? {});
    return { result: "found", value: translation };
  } else {
    return tryGetTranslation(template, rest, vars);
  }
}

export function substitute(template: string, vars: Record<string, string>) {
  return template.replace(/{(\w+)}/g, (_: string, name: string) => {
    const value = vars[name];
    if (value === undefined) {
      throw new Error(`Variable ${name} used in translation was not valid`);
    }
    return value;
  });
}

/** Retrieve the translation for the given tag and vars from this I18nMap. Throw if not found.*/
export function getTranslation(
  translations: I18nMap,
  tag: string,
  vars?: { [k: string]: string }
): string {
  const result = tryGetTranslation(translations, tag, vars);
  switch (result.result) {
    case "found":
      return result.value;
    case "not_found":
      throw new Error(result.message);
  }
}

/** Definition of a tag for a translation that has no interpolated variables. */
interface I18nPlain<Tag extends string> {
  tag: Tag;
  vars?: undefined;
}

/** Definition of a tag for a translation and its interpolated variables. */
interface I18nVars<Tag extends string, Arg extends string> {
  tag: Tag;
  vars: Record<Arg, VarValue>;
}

/** A translation tag and variables that an I18nProfile supports. */
interface I18nProfileEntry {
  readonly tag: string;
  readonly vars: string[];
}

/** A map of nested translation tags to their translation strings for a single language. */
export interface I18nMap {
  [k: string]: string | I18nMap | undefined;
}

/** The profile of all translations that an application expects. */
export class I18nProfile<TProps> {
  private readonly profileEntries: I18nProfileEntry[];
  _props: TProps = undefined as any;

  constructor() {
    this.profileEntries = [];
  }

  with<K extends string>(k: K): I18nProfile<TProps | I18nPlain<K>>;
  with<K extends string, A extends string>(
    k: K,
    args: A
  ): I18nProfile<TProps | I18nVars<K, A>>;
  with<K extends string, A extends string>(
    k: K,
    args: [A, A]
  ): I18nProfile<TProps | I18nVars<K, A>>;
  with<K extends string, A extends string>(
    k: K,
    args: [A, A, A]
  ): I18nProfile<TProps | I18nVars<K, A>>;
  with<K extends string, A extends string>(
    k: K,
    args: [A, A, A, A]
  ): I18nProfile<TProps | I18nVars<K, A>>;
  with<K extends string, A extends string>(
    k: K,
    args: [A, A, A, A, A]
  ): I18nProfile<TProps | I18nVars<K, A>>;
  with(k: string, args?: string | string[]) {
    if (typeof args === "string") {
      args = [args];
    }
    this.profileEntries.push({
      tag: k,
      vars: args || []
    });
    return this as any;
  }

  /** Verify that the input is a valid I18nMap for this TranslationProfile,
   * and throw an exception if any errors are found. */
  verifyI18nMap(map: any): I18nMap {
    const errors = this.checkI18nMap(map);
    if (errors.length > 0) {
      throw new Error(`Invalid I18nMap. ${errors.join(", ")}`);
    }
    return map;
  }

  /** Verify that the input is a valid I18nMap for this TranslationProfile. */

  isValidI18nMap(map: any): map is I18nMap {
    const result = this.checkI18nMap(map);
    return result.length === 0;
  }

  /** Check that the input I18nMap has a valid translation for every
   * entry in this TranslationProfile, and return a list of any failures. */
  checkI18nMap(map: I18nMap): string[] {
    const errors: string[] = [];

    for (const profileEntry of this.profileEntries) {
      try {
        const dummy = profileEntry.vars.reduce((obj, varName) => {
          obj[varName] = "dog";
          return obj;
        }, {} as any);
        getTranslation(map, profileEntry.tag, dummy);
      } catch (e) {
        errors.push(`Problem with ${profileEntry.tag}: ${e.message}`);
      }
    }

    return errors;
  }

  static with<K extends string>(k: K): I18nProfile<I18nPlain<K>>;
  static with<K extends string, A extends string>(
    k: K,
    args: A
  ): I18nProfile<I18nVars<K, A>>;
  static with<K extends string, A extends string>(
    k: K,
    args: [A, A]
  ): I18nProfile<I18nVars<K, A>>;
  static with<K extends string, A extends string>(
    k: K,
    args: [A, A, A]
  ): I18nProfile<I18nVars<K, A>>;
  static with<K extends string, A extends string>(
    k: K,
    args: [A, A, A, A]
  ): I18nProfile<I18nVars<K, A>>;
  static with<K extends string, A extends string>(
    k: K,
    args: [A, A, A, A, A]
  ): I18nProfile<I18nVars<K, A>>;
  static with(k: string, args?: string | any[]) {
    const self: any = new I18nProfile();
    return self.with(k, args);
  }
}

export type PropsOf<TP extends I18nProfile<any>> = TP["_props"];
