import { AppContext } from "../../context/app-context";
import { IWebConfigurationService } from "./web-configuration-model";
import { MockWebConfigurationService } from "./web-configuration-service-mock";
import { ApiWebConfigurationService } from "api/api-config";

export const getWebConfigurationService = (): IWebConfigurationService => {
  if (AppContext.isSandbox()) {
    return new MockWebConfigurationService();
  }
  return new ApiWebConfigurationService();
};
