import React from "react";
import { ExclamationInCircleIcon } from "components/icons";
import styles from "./styles.module.scss";
import clsx from "clsx";

type Props = {
  className?: string;
  testId?: string;
};

export const ErrorMessage: React.FunctionComponent<Props> = props => {
  return (
    <div
      className={clsx(styles["error-message"], props.className)}
      data-testid={props.testId ?? "error"}
    >
      <ExclamationInCircleIcon className={styles["error-icon"]} />
      <div>{props.children}</div>
    </div>
  );
};
