import React, { useEffect } from "react";
import { InlineButton } from "components/buttons/inline-button";

/**
 * The FreshDesk widget shows up on all pages by default, as a button in the lower-right
 * corner (as long as the widget code has been pasted into index.html). If you want to hide
 * that launcher, simply render this component. FreshDesk can still be launched manually,
 * e.g. by using the FreshdeskButton below.
 *
 * Current limitation: this component can only be rendered in one place at a time, or else
 * it will not work as expected (because this component shows the Freshdesk launcher again
 * on teardown).
 */
export const SuppressFreshdeskLauncher: React.FC = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window["FreshworksWidget"]?.("hide", "launcher");

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window["FreshworksWidget"]?.("show", "launcher");
    };
  }, []);

  return null;
};

export const FreshdeskButton: React.FC = ({ children }) => {
  return (
    <InlineButton onClick={() => openFreshdesk()}>{children}</InlineButton>
  );
};

export function openFreshdesk() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window["FreshworksWidget"]?.("open");
}
