import { ARWorkflowState } from "features/report-absence/absence-reporting-state";
import {
  QuestionAnswer,
  WorkflowQuestionTemplate
} from "features/report-absence/absence-reporting-types";

export function buildConfirmationSection(
  state: ARWorkflowState
): WorkflowQuestionTemplate {
  return {
    id: "confirmation",
    type: "ConfirmationMessage",
    promptTag: () => ({ tag: "reportAbsence.confirmation.thank_you" }),
    answer: () => ({ type: "submit", value: state.submitResult }),
    setAnswer: (answer: QuestionAnswer) => {
      return;
    },
    requiresAnswer: () => false,
    postSubmit: true,
    isSubmissionMode: false
  };
}
