import React from "react";
import { useTranslation } from "hooks/translation";

type Props = {
  unit: string;
  remaining: number;
};

export const PolicyRemainingPeriod: React.FC<Props> = ({ unit, remaining }) => {
  const t = useTranslation();
  const singular = remaining === 1;

  const translatedUnit = ["weeks", "week"].includes(unit.toLowerCase())
    ? singular
      ? t({ tag: "timePeriods.week" })
      : t({ tag: "timePeriods.weeks" })
    : ["days", "day"].includes(unit.toLowerCase())
    ? singular
      ? t({ tag: "timePeriods.day" })
      : t({ tag: "timePeriods.days" })
    : ["hours", "hour"].includes(unit.toLowerCase())
    ? singular
      ? t({ tag: "timePeriods.hour" })
      : t({ tag: "timePeriods.hours" })
    : ["months", "month"].includes(unit.toLowerCase())
    ? singular
      ? t({ tag: "timePeriods.month" })
      : t({ tag: "timePeriods.months" })
    : ["years", "year"].includes(unit.toLowerCase())
    ? singular
      ? t({ tag: "timePeriods.year" })
      : t({ tag: "timePeriods.years" })
    : "";

  return (
    <span>
      {t({
        tag: "dashboard.period_left",
        vars: {
          period: translatedUnit
        }
      })}
    </span>
  );
};
