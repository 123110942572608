import React, { useState, useContext } from "react";

type LogoutModalContextProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const LogoutModalContext = React.createContext<LogoutModalContextProps>({
  isOpen: false,
  setIsOpen: () => {
    // Default function will noop, override using a Provider
  }
});

// 2020-10-01 (AZ) - This Context represents the open state of the LogoutModal
// component. Moving the LogoutModal outside of the component that opens it
// ensures that the modal isn't accidentally removed from the DOM if the parent
// component is removed. (e.g. trying to click "logout" from the mobile menu clicks
// outside of the menu which closes it, and prevents the logout function from being
// called)
export const LogoutModalProvider: React.FunctionComponent = props => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <LogoutModalContext.Provider
      value={{
        isOpen,
        setIsOpen
      }}
    >
      {props.children}
    </LogoutModalContext.Provider>
  );
};

export const useLogoutModalContext: () => LogoutModalContextProps = () => {
  return useContext(LogoutModalContext);
};
