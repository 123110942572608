import { AnswerChoice } from "../absence-questions";
import { ARWorkflowState } from "../absence-reporting-state";
import { QuestionAnswer, QuestionSummary } from "../absence-reporting-types";

const getSecondaryReasons = (state: ARWorkflowState) => {
  return (
    state.config?.reasonOfAbsence.answers.find(
      a => a.answerId === state.incident.primaryReason
    )?.subAnswers || []
  );
};

export function buildReasonSection(state: ARWorkflowState) {
  return [
    {
      id: "primary-reason",
      type: "MultipleChoiceTileQuestion",
      promptTag: () => ({
        tag: "reportAbsence.primary_absence_reason.prompt"
      }),
      answer: () => ({
        type: "string",
        value: state.incident.primaryReason
      }),
      summary: (): QuestionSummary => {
        return {
          type: "reason",
          label: { tag: "reportAbsence.absence_date.prompt" },
          answer: {
            primary: state.config?.reasonOfAbsence.answers.find(
              a => a.answerId === state.incident.primaryReason
            )?.locales,
            secondary: getSecondaryReasons(state).find(
              a => a.answerId === state.incident.secondaryReason
            )?.locales
          }
        };
      },
      setAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "string") {
          const newReason = answer.value || "";
          const hasAnswerChanged = state.incident.primaryReason !== newReason;
          if (!hasAnswerChanged) return;

          state.incident.primaryReason = newReason;

          // Automatically set the secondary reason if there is only one option
          const secondaryReasons = getSecondaryReasons(state);
          if (secondaryReasons.length === 1) {
            state.incident.secondaryReason = secondaryReasons[0].answerId;
          } else {
            // Clear secondary reason to ensure we don't submit an invalid primary/secondary combo
            state.incident.secondaryReason = undefined;
          }
        }
      },
      requiresAnswer: () => !state.incident.primaryReason,
      choices: () =>
        state.config?.reasonOfAbsence.answers.map(r => {
          return {
            value: r.answerId,
            text: r.locales,
            imageUrl: r.imageUrl
          } as AnswerChoice;
        }) || [],
      isSubmissionMode: true
    },
    {
      id: "secondary-reason",
      type: "MultipleChoiceListQuestion",
      promptTag: () => ({
        tag: "reportAbsence.secondary_absence_reason.description"
      }),
      answer: () => ({
        type: "string",
        value: state.incident.secondaryReason
      }),
      setAnswer: (answer: QuestionAnswer) => {
        if (answer.type === "string") {
          const hasReasonChanged =
            state.incident.secondaryReason !== answer.value;
          if (hasReasonChanged) {
            state.incident.secondaryReason = answer.value;
          }
        }
      },
      requiresAnswer: () =>
        !state.incident.secondaryReason &&
        getSecondaryReasons(state).length > 0,
      choices: () =>
        getSecondaryReasons(state).map(r => {
          return {
            value: r.answerId,
            text: r.locales
          };
        }) || [],
      hidden: () => getSecondaryReasons(state).length < 2,
      isSubmissionMode: true
    }
  ];
}
