import React from "react";
import styles from "./decision-block.module.scss";
import SummaryLabel from "components/summary-label/summary-label";
import { RightArrowIcon } from "components/icons";
import { TagForDecisionStatus } from "components/tag/tag-for-claim-status";
import Card from "components/card/card";
import clsx from "clsx";
import { ClaimDecision } from "features/claims/claims-model";
import { useTranslation } from "hooks/translation";
import { useFormat } from "hooks/format";

type Props = {
  decision: ClaimDecision;
  className?: string;
};

const DecisionBlock: React.FC<Props> = ({ decision, className }) => {
  const t = useTranslation();
  const { formatLocal } = useFormat();

  const getEndLabel = () => {
    switch (decision.status) {
      case "PENDING":
        return (
          <SummaryLabel label={t({ tag: "claims.label.ongoing" })} value="-" />
        );
      default:
        return (
          <SummaryLabel
            label={t({ tag: "claims.label.decision_end" })}
            value={formatLocal(decision.end, "MMM d, yyyy")}
          />
        );
    }
  };

  return (
    <Card className={clsx(styles["container"], className)}>
      <div className={styles["title-wrapper"]}>
        <p className={styles["title"]} data-card="title">
          {t(decision.policyCodeText)}
        </p>
      </div>
      <div>
        <TagForDecisionStatus status={decision.status} />
      </div>
      <div className={styles["date-label-container"]}>
        <SummaryLabel
          label={t({ tag: "claims.label.decision_start" })}
          value={formatLocal(decision.start, "MMM d, yyyy")}
        />
        <RightArrowIcon className={styles["arrow"]} />
        {getEndLabel()}
      </div>
    </Card>
  );
};

export default DecisionBlock;
