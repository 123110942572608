import { IEmployeeService } from "./employee-model";
import { AppContext } from "../../context/app-context";
import { ApiEmployeeService } from "../../api/api-manage";
import { MockEmployeeService } from "./employee-mock";

export const getEmployeeService = (): IEmployeeService => {
  if (AppContext.isSandbox()) {
    return new MockEmployeeService();
  }
  return new ApiEmployeeService();
};
