import { IAbilitiUser, ISessionService } from "./session-model";
import faker from "faker";

export class MockSessionService implements ISessionService {
  private static _firstName = faker.name.firstName();
  private static _lastName = faker.name.lastName();

  private static _testUser: IAbilitiUser = {
    name: `${MockSessionService._firstName} ${MockSessionService._lastName}`,
    given_name: MockSessionService._firstName,
    family_name: MockSessionService._lastName,
    role: faker.name.jobTitle(),
    email: faker.internet.email(),
    client_code: "DEMOCLIENT",
    ee_no: faker.datatype.number(20000).toString()
  };

  getUser(): IAbilitiUser | null {
    return MockSessionService._testUser;
  }

  getClientCode = (): string | null => MockSessionService._testUser.client_code;

  setClientCode = (clientCode: string) =>
    (MockSessionService._testUser.client_code = clientCode);

  isAuthenticated = () => true;

  getAccessToken = () => JSON.stringify(MockSessionService._testUser);

  hasPrivileges = (privileges: string[], checkAll?: boolean) => true;

  setSession = (accessToken: string | null) => {
    return;
  };

  resetSessionToken = () => {
    return;
  };

  hasEmployees = () => sessionStorage.getItem("hasEmployees") === "true";

  isCICUser = () => sessionStorage.getItem("isCICUser") === "true";

  hasAccess = () => true;
}
